import HomeAdmin from "components/Home/HomeAdmin";
import HomeSuperAdmin from "components/Home/HomeSuperAdmin";
import { useUser } from "hooks/useUser";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Home() {
  const { authUser } = useUser();
  const navigate = useNavigate();

  const isHomeRoute = useLocation().pathname === `/home` || useLocation().pathname.endsWith(`/home`);

  useEffect(() => {
    if(authUser?.roles === "external_service_provider") {
      if(isHomeRoute) navigate(`/workspace/${authUser?.workspaces?.uuid}/support/${authUser?.support?.uuid}`)
    };
  }, [isHomeRoute])

  return (
    <>
      {authUser?.roles === "super_admin" ? (
        <>
          <HomeSuperAdmin />
        </>
      ) : (
        <>
        <HomeAdmin />
        </>
      )}
    </>
  );
}
