/* eslint-disable react-hooks/rules-of-hooks */
import { OptionsProps } from "components/ListMembers";
import { useWorkspaces } from "hooks/useWorkspaces";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Step1 from "./Step1";
import Step3 from "./Step3";

import { MapboxGeoJSONPolygon } from "components/MapComponent";
import {
  E164Number
} from 'libphonenumber-js/core';
import { toast } from "utils/toast";
import { GeographicalAreaMap } from "./GeographicalAreaMap";
import { PointsOfAnalysisMap } from "./PointsOfAnalysisMap";
import ProgressHeader from "./ProgressHeader";
import Step2 from "./Step2";
import Step5 from "./Step5";

export interface MembersProps {
  name: string;
  email: string;
  phone?: string;
  role: string | null;
  uuid: string;
}

export default function index() {
  const { t } = useTranslation();
  const { createNewWorkspace, checkIfEmailExists } = useWorkspaces();
  const navigate = useNavigate();
  const [step, setStep] = useState(16);
  const [unableNext, setUnableNext] = useState(true);
  const [unabledInvite, setUnabledInvite] = useState(true);
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [emailExistis, setEmailExistis] = useState(false);

  const [azulfyCode, setAzulfyCode] = useState("");
  const [nameCouncil, setNameCouncil] = useState("");
  const [nameAdmin, setNameAdmin] = useState("");
  const [emailAdmin, setEmailAdmin] = useState("");
  const [phoneAdmin, setPhoneAdmin] = useState<E164Number>();
  const [roleSelected, setRoleSelected] = useState<string | null>(null);

  const [numberOfMembersAdded, setNumberOfMembersAdded] = useState<number | null>(null);
  const [unableAddMoreMembers, setUnableAddMoreMembers] = useState(true);

  const [isLicenseSelected, setIsLicenseSelected] = useState(false);

  const [geoGraphicalArea, setGeoGraphicalArea] = useState<
    MapboxGeoJSONPolygon | undefined
  >();
  const [pointsOfAnalysisPolygons, setPointsOfAnalysisPolygons] = useState<
    MapboxGeoJSONPolygon[] | undefined
  >();

  const azulfyCodeRef = useRef<HTMLInputElement>(null);
  const nameCouncilRef = useRef<HTMLInputElement>(null);
  const adminEmailRef = useRef<HTMLInputElement>(null);
  const adminNameRef = useRef<HTMLInputElement>(null);
  const adminPhoneRef = useRef<HTMLInputElement>(null);
  const nameMemberRef = useRef<HTMLInputElement>(null);
  const emailMemberRef = useRef<HTMLInputElement>(null);
  const [phoneMember, setPhoneMember] = useState<E164Number>();

  const [nameMember, setNameMember] = useState("");
  const [emailMember, setEmailMember] = useState("");

  const rolesOptions: OptionsProps[] = [
    { value: "admin", label: t("admin") },
    { value: "technicians", label: t("technicians") },
    {
      value: "external_service_provider",
      label: t("external_service_provider"),
    },
  ];

  function generateUniqueId() {
    return (
      Math.random().toString(36).substring(2) +
      new Date().getTime().toString(36)
    );
  }

  const [formDataNewCouncil, setFormDataNewCouncil] = useState({
    code_azulfy: "",
    name: "",
    admin_email: "",
    admin_name: "",
    admin_phone: "",
    license_id: "",
    region_map_area: {} as JSON,
    region_map_area_points: [],
    members: [] as MembersProps[],
  });

  const handlePhoneMember = (value: E164Number | undefined) => {
    setPhoneMember(value)
  }

  const handleAdminPhone = (value: E164Number | undefined) => {
    setPhoneAdmin(value)
  }

  useEffect(() => {
    if (localStorage.getItem("new-council")) {
      localStorage.removeItem("new-council");
    }

    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = "";

      const confirmationMessage = t(
        "are_you_sure_you_want_to_leave_all_unsaved_data_will_be_lost"
      );
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const loadLocalStorageData = () => {
    const savedData = localStorage.getItem("new-council");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormDataNewCouncil(parsedData);

      setNameCouncil(parsedData.name);
      setNameAdmin(parsedData.admin_name);
      setEmailAdmin(parsedData.admin_email);
      setPhoneAdmin(parsedData.admin_phone);
    }
  };

  useEffect(() => {
    if (step === 32) {
      setUnableNext(!isLicenseSelected);
    }
  }, [isLicenseSelected, step]);

  useEffect(() => {
    if (step === 16) {
      if (azulfyCodeRef.current) azulfyCodeRef.current.value = azulfyCode;
      if (nameCouncilRef.current) nameCouncilRef.current.value = nameCouncil;
      if (adminNameRef.current) adminNameRef.current.value = nameAdmin;
      if (adminEmailRef.current) adminEmailRef.current.value = emailAdmin;
    }

    // if (step === 64) {
    //   //quando clicar em next e o geographical area não estiver preenchido definir o step para 96
    //   if (geoGraphicalArea === undefined ) {
    //     setStep(96);
    //   }
    // }

    // if (step === 80) {
    //   if (pointsOfAnalysisPolygons !== undefined && pointsOfAnalysisPolygons.length > 0) {
    //     setUnableNext(false);
    //   } else {
    //     setUnableNext(true);
    //   }
    // }
  }, [step, geoGraphicalArea, pointsOfAnalysisPolygons]);

  useEffect(() => {
    loadLocalStorageData();
  }, []);

  useEffect(() => {
    const delayDebounce = setTimeout(async () => {
      const isCodeValid = azulfyCodeRef.current?.value !== "";
      const isNameValid = nameCouncilRef.current?.value !== "";
      const isAdminNameValid = adminNameRef.current?.value !== "";
      const isAdminEmailValid = isEmailValid(
        adminEmailRef.current?.value as string
      );

      if (isCodeValid && isNameValid && isAdminNameValid && isAdminEmailValid) {
        checkIfEmailExists(adminEmailRef.current?.value as string)
          .then((emailExists) => {
            if (!emailExists) {
              // setUnableNext(true);
              setEmailExistis(true);
            } else {
              setUnableNext(false);
              setEmailExistis(false);
            }
          })
          .catch(() => {
            // setUnableNext(true);
          });
      } else {
        // setUnableNext(true);
      }
    }, 500);
    return () => clearTimeout(delayDebounce);
  }, [
    azulfyCodeRef.current?.value,
    nameCouncilRef.current?.value,
    adminNameRef.current?.value,
    adminEmailRef.current?.value,
  ]);

  useEffect(() => {
    if (
      nameMemberRef.current?.value !== "" &&
      emailMemberRef.current?.value !== "" &&
      roleSelected !== null
    ) {
      setUnabledInvite(false);
      // setUnableNext(false);
    } else {
      setUnabledInvite(true);
    }
  }, [
    nameMemberRef.current?.value,
    emailMemberRef.current?.value,
    roleSelected,
  ]);

  useEffect(() => {
    if (nameMember !== "" && emailMember !== "" && roleSelected !== null) {
      setUnabledInvite(false);
    } else {
      setUnabledInvite(true);
    }
  }, [nameMember, emailMember, roleSelected]);

  const handleNext = () => {
    if (step === 16) {
      const newFormData = {
        ...formDataNewCouncil,
        code_azulfy: azulfyCodeRef.current?.value,
        name: nameCouncilRef.current?.value,
        admin_name: adminNameRef.current?.value,
        admin_email: adminEmailRef.current?.value,
        admin_phone: adminPhoneRef.current?.value,
      };
      localStorage.setItem("new-council", JSON.stringify(newFormData));
    }

    const value = Math.min(step + 16, 96);
    setStep(value);
  };

  const handlePrevious = () => {
    const value = Math.max(step - 16, 16);
    setStep(value);
  };

  function isEmailValid(email: string) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    return emailRegex.test(email);
  }

  const handleInviteMember = async () => {
    setUnableNext(false);
    if (nameMemberRef.current && emailMemberRef.current) {
      const email = emailMemberRef.current.value;

      if (!isEmailValid(email)) {
        setEmailIsInvalid(true);
        return;
      } else {
        setEmailIsInvalid(false);
      }
      if(emailAdmin === email) {
        setEmailExistis(true);
        return;
      }

      const emailExists = await checkIfEmailExists(email);

      if (!emailExists) {
        setEmailExistis(true);
      } else {
        setEmailExistis(false);

        const isEmailAlreadyExists =
          formDataNewCouncil.members.some((member) => member.email === email) ||
          formDataNewCouncil.admin_email === email;

        if (isEmailAlreadyExists) {
          setEmailExistis(true);
          return;
        }

        const newMember = {
          name: nameMemberRef.current.value,
          email: email,
          role: roleSelected || null,
          phone: phoneMember,
          uuid: generateUniqueId(),
        };

        const localStorageData = JSON.parse(
          localStorage.getItem("new-council") || "{}"
        );
        const { code_azulfy, admin_email, admin_name, admin_phone, name, license_id } = localStorageData;

        const updatedMembers = [...formDataNewCouncil.members, newMember];

        const updatedFormData = {
          ...formDataNewCouncil,
          code_azulfy: code_azulfy,
          admin_email: admin_email,
          admin_name: admin_name,
          admin_phone: admin_phone,
          name: name,
          members: updatedMembers,
          license_id: license_id,
        };

        setFormDataNewCouncil(updatedFormData);

        localStorage.setItem("new-council", JSON.stringify(updatedFormData));
      }
    }

    if (
      nameMemberRef.current?.value &&
      emailMemberRef.current?.value &&
      roleSelected &&
      phoneMember
    ) {
      nameMemberRef.current.value = "";
      emailMemberRef.current.value = "";
      setPhoneMember(undefined);
      setRoleSelected(null);
    }
  };

  useEffect(() => {
    const storedData = localStorage.getItem("new-council");
    if (!storedData) {
      return;
    }
    const localStorageData = JSON.parse(
      localStorage.getItem("new-council") || "{}"
    );
    const { members } = localStorageData;
    setNumberOfMembersAdded(members.length + 1);
  });

  useEffect(() => {
    const maxMembers = localStorage.getItem("maxMembers");
    if (!maxMembers) {
      return;
    }
    if(Number(maxMembers) === numberOfMembersAdded){
      setUnableAddMoreMembers(true);
    } else {
      setUnableAddMoreMembers(false);
    }
  });

  function getInitials(name: string | undefined): string {
    if (!name) return "";

    const trimmedName = name.trim();
    const nameParts = trimmedName.split(" ");
    let initials = "";

    for (let i = 0; i < nameParts.length && initials.length < 2; i++) {
      initials += nameParts[i][0].toUpperCase();
    }

    return initials;
  }

  const undoMember = (index: number) => {
    const updatedData = { ...formDataNewCouncil };
    updatedData.members.splice(index, 1);
    localStorage.setItem("new-council", JSON.stringify(updatedData));
    loadLocalStorageData();
  };

  const updateMemberRole = (uuid: string, newRole: string) => {
    const updatedData = { ...formDataNewCouncil };
    const memberUpdated = updatedData.members.find(
      (member) => member.uuid === uuid
    );
    if (!memberUpdated) return;
    const index = updatedData.members.indexOf(memberUpdated);
    updatedData.members[index!].role = newRole;
    localStorage.setItem("new-council", JSON.stringify(updatedData));
    loadLocalStorageData();
  };

  async function handleCreateNewWorkspace() {

    try {

      const storedData = localStorage.getItem("new-council");
      if (!storedData) {
        throw new Error("Data not found in localStorage");
      }
      const newCouncilData = JSON.parse(storedData);

      if (!newCouncilData.region_map_area || Object.keys(newCouncilData.region_map_area).length === 0) {
        newCouncilData.region_map_area = null;
      }
      
      const response = await createNewWorkspace(newCouncilData);
      if (response) {
        toast({
          label: t("success"),
          message: t("council_created_successfully"),
          type: "success",
        });
        navigate("/home");
        localStorage.removeItem("new-council");
        localStorage.removeItem("selectedLicenseLabel");
        localStorage.removeItem("maxMembers");
        return;
      }
      toast({
        label: t("error"),
        message: t("an_error_occurred_while_creating_the_council"),
        type: "error",
      });
    } catch (error) {
      toast({
        label: t("error"),
        message: t("an_error_occurred_while_creating_the_council"),
        type: "error",
      });
    }
  }

  useEffect(() => {
    const dataFromLocalStorage = localStorage.getItem('new-council');

    if (dataFromLocalStorage) {
      const localStorageData = JSON.parse(dataFromLocalStorage);

      const updatedData = {
        ...localStorageData,
        region_map_area: JSON.stringify(geoGraphicalArea),
      };

      localStorage.setItem('new-council', JSON.stringify(updatedData));
    }
  }, [geoGraphicalArea]);

  useEffect(() => {
    const dataFromLocalStorage = localStorage.getItem('new-council');

    if (dataFromLocalStorage) {
      const localStorageData = JSON.parse(dataFromLocalStorage);

      if (pointsOfAnalysisPolygons) {
        const mappedPoints = pointsOfAnalysisPolygons.map(point => ({
          name: point.properties.name,
          json: JSON.stringify(point)
        }));

        const updatedData = {
          ...localStorageData,
          region_map_area_points: mappedPoints
        };

        localStorage.setItem('new-council', JSON.stringify(updatedData));
      }
    }
  }, [pointsOfAnalysisPolygons]);

  // console.log(geoGraphicalArea, pointsOfAnalysisPolygons)

  return (
    <>
      <div>
        <ProgressHeader
          step={step}
          unableNext={unableNext}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          handleCreateNewWorkspace={handleCreateNewWorkspace}
          className="sticky pt-[32px] -mt-[32px] top-[80px] z-[11] bg-white"
        />

        <main className="w-full mx-auto pb-8 md:pb-16">
          {step === 16 && (
            <Step1
              emailIsInvalid={emailIsInvalid}
              emailExistis={emailExistis}
              adminEmailRef={adminEmailRef}
              adminNameRef={adminNameRef}
              azulfyCodeRef={azulfyCodeRef}
              formDataNewCouncil={formDataNewCouncil}
              setAzulfyCode={setAzulfyCode}
              setNameAdmin={setNameAdmin}
              setEmailAdmin={setEmailAdmin}
              setNameCouncil={setNameCouncil}
              adminPhone={phoneAdmin}
              handleAdminPhone={handleAdminPhone}
              nameCouncilRef={nameCouncilRef}
            />
          )}
          {step === 32 && (
            <>
              <Step2
                isLicenseSelected={isLicenseSelected}
                setIsLicenseSelected={setIsLicenseSelected}
              />
            </>
          )}
          {step === 48 && (
            <Step3
              formDataNewCouncil={formDataNewCouncil}
              emailAdmin={emailAdmin}
              emailExistis={emailExistis}
              emailIsInvalid={emailIsInvalid}
              emailMemberRef={emailMemberRef}
              getInitials={getInitials}
              handleInviteMember={handleInviteMember}
              nameAdmin={nameAdmin}
              phoneAdmin={phoneAdmin}
              nameMemberRef={nameMemberRef}
              phoneMember={phoneMember}
              handlePhoneMember={handlePhoneMember}
              rolesOptions={rolesOptions}
              roleSelected={roleSelected}
              setRoleSelected={setRoleSelected}
              updateMemberRole={updateMemberRole}
              undoMember={undoMember}
              unabledInvite={unabledInvite}
              numberOfMembersAdded={numberOfMembersAdded!}
              unableAddMoreMembers={unableAddMoreMembers}
              nameMember={nameMember}
              emailMember={emailMember}
              setNameMember={setNameMember}
              setEmailMember={setEmailMember}
            />
          )}
          {step === 64 && (
            <>
              <div className="flex flex-col w-full gap-5">
                <p className="text-2xl font-bold text-center text-azulfy-blue font-comfortaa">
                  {t("define_the_points_of_analysis")}
                </p>
                <div>
                  <GeographicalAreaMap
                    geoGraphicalArea={geoGraphicalArea}
                    setGeoGraphicalArea={setGeoGraphicalArea}
                  />
                </div>
              </div>
            </>
          )}
          {step === 80 && (
            <>
              <div className="flex flex-col w-full gap-5">
                <p className="text-2xl font-bold text-center text-azulfy-blue font-comfortaa">
                  {t("define_the_points_of_analysis")}
                </p>
                <div>
                  <PointsOfAnalysisMap
                    geoArea={geoGraphicalArea}
                    pointsOfAnalysisPolygons={pointsOfAnalysisPolygons}
                    setPointsOfAnalysisPolygons={setPointsOfAnalysisPolygons}
                  />
                </div>
              </div>
            </>
          )}
          {step >= 81 && (
            <Step5
              emailAdmin={emailAdmin}
              phoneAdmin={phoneAdmin}
              nameAdmin={nameAdmin}
              formDataNewCouncil={formDataNewCouncil}
              getInitials={getInitials}
              rolesOptions={rolesOptions}
              updateMemberRole={updateMemberRole}
            />
          )}
        </main>
      </div>
    </>
  );
}
