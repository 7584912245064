import { useRef } from "react";
import * as Popover from "@radix-ui/react-popover";
import DatePicker from "components/DatePicker";

interface WhenPopoverProps {
  setWhen: React.Dispatch<
    React.SetStateAction<{
      startDate: Date;
      endDate: Date;
      label: string;
    } | null>
  >;
  when: {
    startDate: Date;
    endDate: Date;
    label: string;
  } | null;
  handleApply: () => void;
  handleClear: () => void;
}

export default function WhenPopover({
  when,
  setWhen,
  handleApply,
  handleClear,
}: WhenPopoverProps) {
  const closeButton = useRef<HTMLButtonElement>(null);

  return (
    <Popover.Portal>
      <Popover.Content
        sideOffset={5}
        align="start"
        className="overflow-y-auto bg-white border rounded-md shadow-lg border-grey-400 border-opacity-30"
      >
        <Popover.Close ref={closeButton} hidden />
        <div className="flex flex-col items-center justify-center w-full gap-3 overflow-y-auto">
          <DatePicker
            setSelectedDate={setWhen}
            selectedDate={when}
            handleApply={handleApply}
            closeButton={closeButton}
            handleClear={handleClear}
          />
        </div>
      </Popover.Content>
    </Popover.Portal>
  );
}
