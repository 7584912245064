import { MapboxGeoJSONPolygon } from "components/MapComponent";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PointsOfAnalysisMap } from "./PointsOfAnalysisMap";
import { useWorkspaces } from "hooks/useWorkspaces";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "services/axios";
import { toast } from "utils/toast";
import { BsChevronLeft } from "react-icons/bs";

export default function CreateLiveCam() {
  const { t } = useTranslation();
  const { uuidWorkspace } = useParams<{ uuidWorkspace: string }>();
  const { getWorkspacesByUuid, workspace } = useWorkspaces();

  const [geoGraphicalArea, setGeoGraphicalArea] = useState<
    MapboxGeoJSONPolygon | undefined
  >();
  const [pointsOfAnalysisPolygons, setPointsOfAnalysisPolygons] = useState<
    MapboxGeoJSONPolygon[] | undefined
  >();

  const handleGetWorkspacesByUuid = async () => {
    await getWorkspacesByUuid(uuidWorkspace!);
  };

  useEffect(() => {
    handleGetWorkspacesByUuid();
  }, []);

  useEffect(() => {
    setGeoGraphicalArea(workspace?.region_map_area);
  }, [workspace?.region_map_area]);

  useEffect(() => {
    if (workspace && workspace.area_points?.length > 0) {
      const extractedPolygons = workspace.area_points.map(
        (item) => item.region_map_area
      );
      setPointsOfAnalysisPolygons(extractedPolygons);
    }
  }, [workspace]);

  const navigate = useNavigate()

  return (
    <main className="pb-8">
      <header
        className="flex items-center w-full gap-2 py-1 lg:mb-5 capitalize"
      >
        <Link to={`/workspace/${uuidWorkspace}/live-cams`} className={`hidden md:block`}>
          <BsChevronLeft size={20} className="text-azulfy-blue" />
        </Link>
        <p className="text-azulfy-blue text-xl font-bold font-comfortaa">
          {t("create_a_new_live_cam")}
        </p>
      </header>
      <PointsOfAnalysisMap
        geoArea={geoGraphicalArea}
        pointsOfAnalysisPolygons={pointsOfAnalysisPolygons}
        setPointsOfAnalysisPolygons={setPointsOfAnalysisPolygons}
        onCreateNewWorkspaceVideo={async (data) => {
          try {
            const response = await api.post(
              `/workspace/${uuidWorkspace}/videos`,
              {
                workspace_id: uuidWorkspace,
                name: data?.properties?.name,
                url: data?.properties?.urlLiveCam,
                region_map_area: JSON.stringify(data),
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            toast({
              label: t("success"),
              message: t("live_cam_created_successfully"),
              type: "success",
            })
            // @ts-ignore
            const createdLiveCamUid = response?.data.content?.data?.uuid
            navigate(`/workspace/${uuidWorkspace}/live-cams`)
          } catch (error: unknown) {
            console.error(error);
          }
        }}
        onUpdateWorkspaceVideo={() => {

        }}
      />
    </main>
  );
}
