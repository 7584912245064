import { LinearButton } from "components/LinearButton";
import { useUser } from "hooks/useUser";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCheck2 } from "react-icons/bs";
import { toast } from "utils/toast";
import HeaderAccount from "../components/HeaderAccount";

export default function Language() {
  const { t, i18n } = useTranslation();
  const { authUser, updateUser } = useUser();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    authUser?.user?.language || "en"
  );

  async function handleUpdateLanguage(language: string) {
    i18n.changeLanguage(selectedLanguage);
    const response = await updateUser({ language });
    if (response) {
      toast({
        label: t('success'),
        message: t('language_updated_successfully'),
        type: "success",
      });
    }
  }

  return (
    <main className="w-full">
      <HeaderAccount title={t("language")} navigateTo="/account" />
      <div className="flex flex-col w-full items-center max-w-2xl mx-auto">
        <div className="w-full flex flex-col mb-5 font-comfortaa gap-5">
          <button
            className="px-3 py-2 w-full hover:opacity-70 flex items-center gap-5"
            onClick={() => setSelectedLanguage("pt")}
          >
            <span
              className={`text-lg ${
                selectedLanguage === "pt" ? "text-black-100" : "text-gray-500"
              }`}
            >
              {t("portuguese")}
            </span>
            {selectedLanguage === "pt" && (
              <BsCheck2 size={24} className="text-success" />
            )}
          </button>
          <button
            className="px-3 py-2 w-full hover:opacity-70 flex items-center gap-5"
            onClick={() => setSelectedLanguage("en")}
          >
            <span
              className={`text-lg text-black-100 ${
                selectedLanguage === "pt" ? "text-black-100" : "text-gray-500"
              }`}
            >
              {t("english")}
            </span>
            {selectedLanguage === "en" && (
              <BsCheck2 size={24} className="text-success" />
            )}
          </button>
        </div>
        <LinearButton
          text={t("save")}
          onClick={() => handleUpdateLanguage(selectedLanguage)}
        />
      </div>
    </main>
  );
}
