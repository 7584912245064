import Map from "components/MapComponent";
import { useFilters } from "hooks/useFilters";
import { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

const MapLegend = () => {
  const { t } = useTranslation();
  return (
    <Map.Item className="select-none left-4 bottom-4 max-md:w-[90%]">
      <ul className="flex p-0 m-0 w-full">
        <MapBar title={t('excellent')} barHtmlProps={{ className: "bg-success" }} />
        {/* <MapBar title={t('good')} barHtmlProps={{ className: "bg-azulfy-blue" }} />
        <MapBar title={t('reasonable')} barHtmlProps={{ className: "bg-warning" }} />
        <MapBar title={t('poor')} barHtmlProps={{ className: "bg-error" }} /> */}
      </ul>
    </Map.Item>
  );
};
export default MapLegend;

interface IMapBarProps {
  title: string;
  barHtmlProps?: HTMLAttributes<HTMLDivElement>;
}
const MapBar = ({}: IMapBarProps) => {
  const { t } = useTranslation();
  const { mapStyle } = useFilters();
  return (
    <li className="flex flex-col gap-1 w-full">
      <div className="flex justify-around w-full">
        <span className={`text-xs font-bold text-center font-raleway ${mapStyle === 'light' ? 'text-azulfy-rich-black' : 'text-white'}`}>
          {t('poor')}
        </span>
        <span className={`text-xs font-bold text-center font-raleway ${mapStyle === 'light' ? 'text-azulfy-rich-black' : 'text-white'}`}>
          {t('reasonable')}
        </span>
        <span className={`text-xs font-bold text-center font-raleway ${mapStyle === 'light' ? 'text-azulfy-rich-black' : 'text-white'}`}>
          {t('good')}
        </span>
        <span className={`text-xs font-bold text-center font-raleway ${mapStyle === 'light' ? 'text-azulfy-rich-black' : 'text-white'}`}>
          {t('excellent')}
        </span>
      </div>
      <div
        className="h-2 w-full md:w-80 custom-gradient-legend"
      ></div>
    </li>
  );
};
