import { LinearButton } from "components/LinearButton";
import Loading from "components/Loading";
import { SearchInput } from "components/SearchInput";
import { useWorkspaces } from "hooks/useWorkspaces";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronRight } from "react-icons/bs";
import { FaCircle } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import { Tooltip, Whisper } from "rsuite";

export default function HomeSuperAdmin() {
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<{
    current: number;
    next: any[];
    previous: any[];
  }>();

  const { t } = useTranslation();
  const { getAllWorkspaces, workspaces, pagination } = useWorkspaces();
  const navigate = useNavigate();

  async function getWorkspaces() {
    await getAllWorkspaces(
      "?sortBy=identifier&sortDirection=desc&page=1&take=10"
    );
    setIsLoading(false);
  }

  useEffect(() => {
    getWorkspaces();
  }, []);

  useEffect(() => {
    let current = 0;
    const next = [];
    const previous = [];
    const MAX_PAGE = 2;
    if (pagination) {
      for (
        let i = pagination.current_page;
        i <= pagination.current_page + MAX_PAGE;
        i++
      ) {
        if (i > pagination.last_page) break;
        if (pagination.current_page === i) {
          current = i;
        }
        if (pagination.current_page < i) {
          next.push(i);
        }
      }
      for (
        let i = pagination.current_page;
        i >= pagination.current_page - MAX_PAGE;
        i--
      ) {
        if (i <= 0) break;
        if (pagination.current_page > i) {
          previous.unshift(i);
        }
      }
      if (pagination.current_page > MAX_PAGE + 1) {
        previous.unshift("...");
        previous.unshift(1);
      }
      if (pagination.last_page - pagination.current_page > MAX_PAGE + 1) {
        next.push("...");
        next.push(pagination.last_page);
      }
    }
    setPage({ current: current, next: next, previous: previous });
  }, [pagination]);

  async function handleSelectPage(selectedPage: number) {
    setIsLoading(true);
    await getAllWorkspaces(
      `?sortBy=identifier&sortDirection=desc&page=${selectedPage}&take=10`
    );
    setIsLoading(false);
  }

  useEffect(() => {
    const delayDebounce = setTimeout(async () => {
      if (!isSearching) return;
      const currentPage = pagination.current_page;
      if (search) {
        await getAllWorkspaces(
          `?sortBy=identifier&sortDirection=desc&page=${currentPage}&take=10&search=${search}`
        );
      } else {
        await getAllWorkspaces(
          `?sortBy=identifier&sortDirection=desc&page=${currentPage}&take=10`
        );
      }
      setIsSearching(false);
    }, 300);
    return () => clearTimeout(delayDebounce);
  }, [search, isSearching, pagination]);

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-[400px]">
          <Loading />
        </div>
      ) : (
        <div className="flex flex-col gap-8 h-full relative">
          <header className="flex max-md:flex-col gap-y-3 items-center justify-between max-md:justify-center">
            <h1 className="text-azulfy-blue text-2xl max-md:text-base font-bold">
              {t("clients")}
            </h1>
            <SearchInput
              placeholder={`${t("search_council")}...`}
              onChange={(e) => {
                setIsSearching(true);
                setSearch(e.target.value);
              }}
              value={search}
              showSearchIcon
              classNamesDiv="w-[300px]"
            />
          </header>
          <main className="w-full max-h-[600px] overflow-y-auto pr-6">
            <table className="text-azulfy-rich-black font-comfortaa w-full">
              <thead>
                <tr className="border-b border-grey-700 border-opacity-20">
                  <th className="font-bold text-start py-2 px-1 lg:text-base text-sm">
                    #
                  </th>
                  <th className="font-bold text-start py-2 px-1 lg:text-base text-sm">
                    {t("client")}
                  </th>
                  <th className="font-bold text-start py-2 px-1 lg:text-base text-sm">
                    {t("council")}
                  </th>
                  <th className="font-bold text-start py-2 px-1 lg:text-base text-sm">
                    {t("email")}
                  </th>
                  <th className="font-bold text-start py-2 px-1 lg:text-base text-sm">
                    {t("license")}
                  </th>
                </tr>
              </thead>
              <tbody className="px-10">
                {workspaces?.map((workspace) => {
                  const tooltip = (
                    <Tooltip arrow={false} className="bg-azulfy-rich-black font-normal text-xs text-center text-white font-raleway px-3 py-2">
                      <p>
                        Status: <br></br>
                        <span className="font-bold">
                          {t('pending_approval')}
                        </span>
                      </p>
                    </Tooltip>
                  );
                  return (
                    <tr
                      key={workspace?.uuid}
                      className="border-b border-grey-700 border-opacity-20 hover:opacity-70 cursor-pointer"
                      onClick={() => {
                        navigate(`/workspace/${workspace?.uuid}`);
                      }}
                    >
                      <td className="py-5 px-1 lg:text-base text-sm text-azulfy-blue">
                        #{workspace?.identifier || 0}
                      </td>
                      <td className="py-5 px-1 lg:text-base text-sm break-all">
                        {workspace?.admin_name}
                      </td>
                      <td className="py-5 px-1 lg:text-base text-sm break-all">
                        {workspace?.name}
                      </td>
                      <td className="py-5 px-1 lg:text-base text-sm break-all">
                        {workspace?.admin_email}
                      </td>
                      <td className="py-5 px-1 lg:text-base text-sm">
                        <span>
                          {t(workspace?.license.name)}
                        </span>
                      </td>
                      <td className="w-4">
                        <button className="hover:opacity-70">
                          <BsChevronRight
                            size={16}
                            className="text-azulfy-rich-black "
                          />
                        </button>
                      </td>
                      {workspace.status === "pending" && workspace?.region_map_area_pending !== null && (
                        <td className="py-5">
                          <Whisper placement="bottom" controlId="control-id-hover" trigger="hover" speaker={tooltip}>
                            <button>
                              <FaCircle size={16} className="text-[#FC6530]" />
                            </button>
                          </Whisper>
                        </td>
                      )}
                    </tr>
                  )

                })}
              </tbody>
            </table>
          </main>

          <div className="flex flex-col gap-3 pb-6">
            {workspaces?.length > 0 && (
              <footer className="flex justify-center items-center gap-3 py-2">
                {pagination && (
                  <>
                    {page?.previous && page?.previous?.length > 0 && (
                      <button
                        disabled={page?.previous?.length === 0}
                        onClick={() =>
                          handleSelectPage((page?.current || 2) - 1)
                        }
                        className="text-azulfy-rich-black font-raleway hover:opacity-70 disabled:opacity-50"
                      >
                        Prev
                      </button>
                    )}

                    {page?.previous.map((page) => {
                      return (
                        <button
                          onClick={() => handleSelectPage(page)}
                          className="border border-[#F1F1F1] flex items-center justify-center rounded-md font-raleway w-8 h-8 hover:opacity-70"
                          key={page}
                        >
                          {page}
                        </button>
                      );
                    })}
                    <button
                      className={`hidden ${page?.previous && page?.previous?.length > 0 && `!flex`
                        } ${page?.next && page?.next?.length > 0 && `!flex`
                        } bg-azulfy-blue hover:bg-azulfy-blue-hover active:bg-azulfy-blue-pressed text-white flex items-center justify-center rounded-md font-raleway w-8 h-8`}
                    >
                      {page?.current}
                    </button>
                    {page?.next.map((page) => {
                      if (typeof page === "string") {
                        return (
                          <span
                            className="text-azulfy-rich-black px-[10px]"
                            key={page}
                          >
                            {page}
                          </span>
                        );
                      } else {
                        return (
                          <button
                            onClick={() => handleSelectPage(page)}
                            className="border border-[#F1F1F1] flex items-center justify-center rounded-md font-raleway w-8 h-8 hover:opacity-70"
                            key={page}
                          >
                            {page}
                          </button>
                        );
                      }
                    })}
                    {page?.next && page?.next?.length > 0 && (
                      <button
                        disabled={page?.next?.length === 0}
                        onClick={() =>
                          handleSelectPage((page?.current || 0) + 1)
                        }
                        className="text-azulfy-rich-black font-raleway hover:opacity-70 disabled:opacity-50"
                      >
                        Next
                      </button>
                    )}
                  </>
                )}
              </footer>
            )}
            <div className="flex w-full justify-end">
              <span className="w-full max-w-xs flex flex-col md:flex-row gap-5">
                <LinearButton
                  textSize="16"
                  text={`+ ${t("new_council")}`}
                  onClick={() => {
                    navigate("/workspace/new-workspace");
                  }}
                />
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
