import * as Popover from "@radix-ui/react-popover";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Alert, useAlerts } from "hooks/useAlerts";

import { SearchInput } from "components/SearchInput";

import { ButtonFilter } from "components/ButtonFilter";
import Loading from "components/Loading";
import NoAlerts from "components/NoAlerts";
import dayjs from "dayjs";
import { FilterProps, IndicatorsProps } from "hooks/useFilters";
import { useHeader } from "hooks/useHeader";
import { useUser } from "hooks/useUser";
import { BiWater } from "react-icons/bi";
import { BsChevronRight, BsPeople } from "react-icons/bs";
import { GiGroundSprout } from "react-icons/gi";
import { TbWind } from "react-icons/tb";
import { getAlertStatuses } from "../AlertStatuses";
import WhatPopover from "./WhatPopover";
import WhenPopover from "./WhenPopover";
import WherePopover from "./WherePopover";

interface ListAlertsProps {
  selectedAlert: string | null;
  setSelectedAlert: (uuid: string | null) => void;
}

export default function ListAlerts({ selectedAlert }: ListAlertsProps) {
  const { t } = useTranslation();
  const { authUser } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [search, setSearch] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [type, setType] = useState<"all" | "deleted">("all");
  const [where, setWhere] = useState<FilterProps | null | undefined>(
    null
  );
  const [what, setWhat] = useState<IndicatorsProps | null | undefined>(
    null
  );
  const [when, setWhen] = useState<{
    startDate: Date;
    endDate: Date;
    label: string;
  } | null>(null);

  const [whenApplied, setWhenApplied] = useState<{
    startDate: Date;
    endDate: Date;
    label: string;
  } | null>(null);

  const statuses = getAlertStatuses();

  const { getAlerts, alerts, alertsPagination, setAlerts, setAlertsFiltered } = useAlerts();
  const { setShowHeader } = useHeader();

  const listAlertDiv = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  async function getAllAlerts() {
    setIsLoading(true);
    await getAlerts(
      `?page=1&sortBy=alert_datetime&sortDirection=desc${type === "all" ? "" : "&closed=1"
      }${where ? `&where=${where.id}` : ""}${what ? `&what=${what.id}` : ""
      }&search=${search}&lt=${whenApplied
        ? `${dayjs(whenApplied?.startDate).unix()}&gt=${dayjs(
          whenApplied?.endDate
        ).unix()}`
        : ``
      }`
    );
    setIsLoading(false);
  }
//        }&search=${search}&lt=${dayjs(when?.startDate).unix()}&gt=${dayjs(when?.endDate).unix()}`

  useEffect(() => {
    setShowHeader(true);
  }, []);

  async function handleSelectAlert(alert: Alert) {
    const alertIndex = alerts.indexOf(alert);
    if (alertIndex > -1) {
      alerts[alertIndex].total_unread_messages = 0;
      setAlerts([...alerts]);
    }
    navigate(
      `/workspace/${authUser?.workspaces.uuid}/alerts?alert=${alert.uuid}`
    );
  }

  useEffect(() => {
    const delayDebounce = setTimeout(async () => {
      if (!isTyping) return;
      await getAllAlerts();
      setIsTyping(false);
    }, 300);
    return () => clearTimeout(delayDebounce);
  }, [search, isTyping]);

  useEffect(() => {
    const onScroll = async () => {
      if (
        listAlertDiv?.current &&
        listAlertDiv?.current?.scrollTop +
        listAlertDiv?.current?.clientHeight ===
        listAlertDiv?.current?.scrollHeight &&
        !isLoading &&
        alertsPagination.current_page < alertsPagination.last_page
      ) {
        setLoadingMore(true);
        getAlerts(
          `?page=1&sortBy=alert_datetime&sortDirection=desc${type === "all" ? "" : "&closed=1"
          }${where ? `&where=${where.id}` : ""}${what ? `&what=${what.id}` : ""
          }&search=${search}&page=${alertsPagination.current_page + 1}&lt=${whenApplied
            ? `${dayjs(whenApplied?.startDate).unix()}&gt=${dayjs(
              whenApplied?.endDate
            ).unix()}`
            : ``
          }`,
          true
        );
        setLoadingMore(false);
      }
    };

    listAlertDiv?.current?.addEventListener("scroll", onScroll);

    return () => listAlertDiv?.current?.removeEventListener("scroll", onScroll);
  }, [alertsPagination, listAlertDiv, isLoading]);

  async function handleApply() {
    setIsLoading(true);
    setWhenApplied(when);
    if (when) {
      await getAlerts(
        `?page=1&sortBy=alert_datetime&sortDirection=desc${type === "all" ? "" : "&closed=1"
        }${where ? `&where=${where.id}` : ""}${what ? `&what=${what.id}` : ""
        }&search=${search}&lt=${dayjs(when?.startDate).unix()}&gt=${dayjs(when?.endDate).unix()}`
      );
    } else {
      await getAlerts(
        `?page=1&sortBy=alert_datetime&sortDirection=desc${type === "all" ? "" : "&closed=1"
        }${where ? `&where=${where.id}` : ""}${what ? `&what=${what.id}` : ""
        }&search=${search}`
      );
    }
    setIsLoading(false);
  }

  async function handleClear() {
    setIsLoading(true);
    setWhen(null);
    setWhenApplied(null);
    setAlertsFiltered(null);
    await getAlerts(
      `?page=1&sortBy=alert_datetime&sortDirection=desc${type === "all" ? "" : "&closed=1"
      }${where ? `&where=${where.id}` : ""}${what ? `&what=${what.id}` : ""
      }&search=${search}`
    );
    setIsLoading(false);
  }

  useEffect(() => {
    getAllAlerts();
  }, [type, where, what]);

  useEffect(() => {
    if (where == null) {
      setWhat(null);
    }
  }, [where]);

  return (
    <div className="flex flex-col items-center py-4 gap-4 h-full max-h-full">
      <div className="hidden max-md:flex items-center justify-center w-full px-3 mb-2">
        <SearchInput
          placeholder={`${t("search")}...`}
          showSearchIcon
          value={search}
          onChange={(e) => {
            setIsTyping(true);
            setSearch(e.target.value);
          }}
          classNamesInput="max-md:text-sm"
          classNamesDiv="w-full px-4 py-3"
        />
      </div>
      <div className="hidden max-md:flex flex-wrap items-center justify-center gap-x-3 gap-y-2 w-full px-3 mb-2">
        <Popover.Root>
          <Popover.Trigger asChild>
            <ButtonFilter text={t(where?.name.toLowerCase() || "") || t("where")} />
          </Popover.Trigger>
          <WherePopover where={where} setWhere={setWhere} />
        </Popover.Root>
        <Popover.Root>
          <Popover.Trigger asChild>
            <ButtonFilter disabled={!where} text={t(what?.name || "") || t("what")} />
          </Popover.Trigger>
          <WhatPopover what={what} setWhat={setWhat} whereSelected={where?.indicators!} />
        </Popover.Root>
        <Popover.Root>
          <Popover.Trigger asChild>
            <ButtonFilter disabled={!where || !what} text={t(whenApplied?.label || "") || t("when")} />
          </Popover.Trigger>
          <WhenPopover
            when={when}
            setWhen={setWhen}
            handleApply={handleApply}
            handleClear={handleClear}
          />
        </Popover.Root>
      </div>
      <h3 className="font-bold text-azulfy-blue max-md:text-xl">
        {t("alerts")}
      </h3>
      <div className="grid grid-cols-2 w-full">
        <button
          onClick={() => {
            setType("all");
          }}
          className={`py-[5px] text-azulfy-blue font-bold text-xs ${type === "all" ? "border-b-2 border-azulfy-blue" : ""
            }`}
        >
          {t("all")}
        </button>
        <button
          onClick={() => {
            setType("deleted");
          }}
          className={`py-[5px] text-azulfy-blue font-bold text-xs ${type === "deleted" ? "border-b-2 border-azulfy-blue" : ""
            }`}
        >
          {t("closed")}
        </button>
      </div>
      <div className="hidden md:flex items-center justify-center w-full px-3">
        <SearchInput
          placeholder={`${t("search")}...`}
          value={search}
          onChange={(e) => {
            setIsTyping(true);
            setSearch(e.target.value);
          }}
          showSearchIcon
          classNamesInput="max-lg:text-xs"
        />
      </div>
      <div className="hidden md:flex flex-wrap items-center justify-center gap-x-3 gap-y-2 w-full px-5 mb-2">
        <Popover.Root>
          <Popover.Trigger asChild>
            <ButtonFilter text={t(where?.name.toLowerCase() || "") || t("where")} />
          </Popover.Trigger>
          <WherePopover where={where} setWhere={setWhere} />
        </Popover.Root>
        <Popover.Root>
          <Popover.Trigger asChild>
            <ButtonFilter disabled={!where} text={t(what?.name || "") || t("what")} />
          </Popover.Trigger>
          <WhatPopover what={what} setWhat={setWhat} whereSelected={where?.indicators!} />
        </Popover.Root>
        <Popover.Root>
          <Popover.Trigger asChild>
            <ButtonFilter disabled={!where || !what} text={t(whenApplied?.label || "") || t("when")} />
          </Popover.Trigger>
          <WhenPopover
            when={when}
            setWhen={setWhen}
            handleApply={handleApply}
            handleClear={handleClear}
          />
        </Popover.Root>
      </div>

      <div
        ref={listAlertDiv}
        className="flex flex-col w-full text-azulfy-rich-black h-full overflow-y-auto overflow-x-hidden"
      >
        {isLoading ? (
          <div className="flex h-full items-center justify-center">
            <Loading />
          </div>
        ) : (
          <>
            {alerts?.length === 0 && (
              <div className="py-10">
                <NoAlerts />
              </div>
            )}
            {alerts?.length > 0 &&
              alerts?.map((alert) => {
                const dateFormatted = dayjs(alert.alert_datetime).format(
                  "DD/MM/YYYY"
                );
                const isSelected = selectedAlert === alert.uuid;
                return (
                  <button
                    onClick={() => handleSelectAlert(alert)}
                    key={alert.uuid}
                    className={`flex items-center gap-2 justify-between w-full p-3 border-b border-grey-400 border-opacity-50 ${isSelected ? "bg-azulfy-blue" : ""
                      }`}
                  >
                    <div className="flex items-start gap-[10px] w-full">
                      <span
                        className={`p-2 rounded-full ${isSelected ? "bg-white" : "bg-azulfy-blue"
                          }`}
                      >
                        {alert?.indicators.filter_id === 1 && (
                          <BiWater
                            color={isSelected ? "#0072FF" : "#fff"}
                            size={16}
                          />
                        )}
                        {alert?.indicators.filter_id === 2 && (
                          <TbWind
                            color={isSelected ? "#0072FF" : "#fff"}
                            size={16}
                          />
                        )}

                        {alert?.indicators.filter_id === 3 && (
                          <GiGroundSprout
                            color={isSelected ? "#0072FF" : "#fff"}
                            size={16}
                          />
                        )}

                        {alert?.indicators.filter_id === 4 && (
                          <BsPeople
                            color={isSelected ? "#0072FF" : "#fff"}
                            size={16}
                          />
                        )}
                      </span>
                      <div className="flex flex-col items-start gap-1 w-full">
                        <h3
                          className={`text-sm max-lg:text-xs font-semibold ${isSelected ? "text-white" : ""
                            }`}
                        >
                          {alert.name}
                        </h3>
                        <span
                          className={`max-lg:text-xs line-clamp-1 text-start ${isSelected ? "text-white" : ""
                            }`}
                        >
                          {alert.indicators.name}
                        </span>
                        <div className="flex items-center justify-between w-full">
                          <span
                            className={`max-lg:text-[10px] text-xs font-bold font-raleway text-start line-clamp-1 ${isSelected ? "text-white" : ""
                              }`}
                          >
                            {statuses.find((status) => status.value === alert?.status?.type)?.label}
                          </span>
                          <span
                            className={`max-lg:text-[10px] text-xs font-raleway ml-3 ${isSelected ? "text-white" : ""
                              }`}
                          >
                            {dateFormatted}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-1">
                      {alert.total_unread_messages > 0 && (
                        <span className="bg-[#FC6530] rounded-full w-4 h-4 max-lg:w-3 max-lg:h-3 text-white text-[8px] max-lg:text-[7px] font-semibold py-[3px] max-lg:py-[2px] px-[1px] text-center">
                          {alert.total_unread_messages}
                        </span>
                      )}
                      <span className="hover:opacity-70">
                        <BsChevronRight
                          size={12}
                          className={"text-azulfy-rich-black"}
                        />
                      </span>
                    </div>
                  </button>
                );
              })}
          </>
        )}
        {loadingMore && (
          <div className="flex items-center justify-center py-2">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
}
