import { useTranslation } from "react-i18next";

import { OptionsProps } from "components/ListMembers";
import { MembersProps } from "..";

import { SelectPicker } from "rsuite";
import { BsFillTagsFill, BsPeopleFill } from "react-icons/bs";
import { useUser } from "hooks/useUser";

interface Step4Props {
  nameAdmin: string;
  emailAdmin: string;
  rolesOptions: OptionsProps[];
  getInitials(name: string | undefined): string;
  updateMemberRole: (uuid: string, newRole: string) => void;
  formDataNewCouncil: {
    members: MembersProps[];
  };
}

export default function Step4({
  nameAdmin,
  emailAdmin,
  rolesOptions,
  getInitials,
  updateMemberRole,
  formDataNewCouncil,
}: Step4Props) {
  const { t } = useTranslation();
  const { authUser } = useUser();

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-28 w-full max-w-5xl mx-auto pb-12">
        <div className="flex flex-col gap-12">
          <div className="flex flex-col">
            <div className="hidden lg:flex items-center justify-between">
              <p className="font-comfortaa text-base font-bold text-azulfy-blue">
                {t("client_info")}
              </p>
            </div>
            <div className="py-3 flex items-center justify-between border-b border-backgray">
              <div className="flex gap-2">
                <BsPeopleFill size={20} className="text-azulfy-rich-black" />
                <p className="font-raleway text-sm font-normal text-azulfy-rich-black">
                  {t("council")}
                </p>
              </div>
              <p className="font-raleway text-sm font-normal text-gray-500">
                {authUser?.workspaces.name}
              </p>
            </div>
            <div className="py-3 flex items-center justify-between border-b border-backgray">
              <div className="flex gap-2">
                <BsFillTagsFill size={20} className="text-azulfy-rich-black" />
                <p className="font-raleway text-sm font-normal text-azulfy-rich-black">
                  {t("license_type")}
                </p>
              </div>
              <p className="font-raleway text-sm font-normal text-gray-500">
                {authUser?.workspaces?.license.name}
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:hidden">
            <div className="flex items-center justify-between">
              <p className="font-comfortaa text-base font-bold text-azulfy-blue">
                {t("members")}
              </p>
            </div>
            <div className="py-3 flex items-center justify-between border-b border-backgray">
              <div className="flex gap-2">
                <BsPeopleFill size={20} className="text-azulfy-rich-black" />
                <p className="font-raleway text-sm font-normal text-azulfy-rich-black">
                  {t("members")}
                </p>
              </div>
              <p className="font-raleway text-sm font-normal text-gray-500">
                {formDataNewCouncil.members.length + 1}/{authUser?.workspaces?.license?.members}
              </p>
            </div>
          </div>
          <div className="flex flex-col"></div>
          <div className="flex flex-col"></div>
        </div>
        <div className="hidden lg:flex flex-col ">
          <div className="flex flex-col">
            <div className="flex items-center justify-between">
              <p className="font-comfortaa text-base font-bold text-azulfy-blue">
                {t("members")}
              </p>
            </div>
            <div className="py-3 flex items-center justify-between border-b border-backgray">
              <div className="flex gap-2">
                <BsPeopleFill size={20} className="text-azulfy-rich-black" />
                <p className="font-raleway text-sm font-normal text-azulfy-rich-black">
                  {t("members")}
                </p>
              </div>
              <p className="font-raleway text-sm font-normal text-gray-500">
                {formDataNewCouncil.members.length + 1}/{authUser?.workspaces?.license?.members}
              </p>
            </div>
          </div>
          <div className="w-full flex flex-col gap-4 justify-start">
            <div className="flex items-center justify-between font-comfortaa mt-9">
              <p className="text-base font-bold text-gray-500">{t("name")}</p>
              <p className="text-base font-bold text-gray-500">{t("role")}</p>
            </div>
            <div className="flex flex-col gap-4">
              <p className="text-azulfy-blue font-comfortaa font-bold text-xl">
                {t("admin")}
              </p>
              <div className="flex items-center justify-between">
                <div className="flex gap-4 items-center">
                  <div className="flex items-center justify-center bg-azulfy-rich-black w-8 h-8 rounded-full">
                    <span className="font-comfortaa text-xs font-normal text-white">
                      {getInitials(nameAdmin)}
                    </span>
                  </div>
                  <div className="flex flex-col font-comfortaa">
                    <h3 className="text-base font-bold text-azulfy-rich-black">
                      {nameAdmin}
                    </h3>
                    <p className="text-xs font-normal text-azulfy-rich-black">
                      {emailAdmin}
                    </p>
                  </div>
                </div>
                <div className="font-comfortaa flex gap-5">
                  <SelectPicker
                    data={rolesOptions}
                    searchable={false}
                    style={{
                      width: "8rem",
                      fontSize: "14px",
                      color: "#061425",
                    }}
                    cleanable={false}
                    placement="auto"
                    className="text-azulfy-rich-black"
                    value={"admin"}
                    disabled
                  />
                </div>
              </div>
              {formDataNewCouncil.members
                .filter((member) => member.role === "admin")
                .map((adminMember, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between"
                  >
                    <div className="flex gap-4 items-center">
                      <div className="flex items-center justify-center bg-azulfy-rich-black w-8 h-8 rounded-full">
                        <span className="font-comfortaa text-xs font-normal text-white">
                          {getInitials(adminMember.name)}
                        </span>
                      </div>
                      <div className="flex flex-col font-comfortaa">
                        <h3 className="text-base font-bold text-azulfy-rich-black">
                          {adminMember.name}
                        </h3>
                        <p className="text-xs font-normal text-azulfy-rich-black">
                          {adminMember.email}
                        </p>
                      </div>
                    </div>
                    <div className="font-comfortaa flex gap-5">
                      <SelectPicker
                        data={rolesOptions}
                        searchable={false}
                        style={{
                          width: "8rem",
                          fontSize: "14px",
                          color: "#061425",
                        }}
                        cleanable={false}
                        className="text-azulfy-rich-black"
                        value={adminMember.role}
                        placement="auto"
                        onSelect={(value) =>
                          updateMemberRole(adminMember.uuid, value)
                        }
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className="flex flex-col gap-4">
              {formDataNewCouncil.members.filter(
                (member) => member.role === "technicians"
              ).length > 0 && (
                <p className="text-azulfy-blue font-comfortaa font-bold text-xl">
                  {t("technicians")}
                </p>
              )}
              {formDataNewCouncil.members
                .filter((member) => member.role === "technicians")
                .map((adminMember, index) => (
                  <div
                    key={index + 10}
                    className="flex items-center justify-between"
                  >
                    <div className="flex gap-4 items-center">
                      <div className="flex items-center justify-center bg-azulfy-rich-black w-8 h-8 rounded-full">
                        <span className="font-comfortaa text-xs font-normal text-white">
                          {getInitials(adminMember.name)}
                        </span>
                      </div>
                      <div className="flex flex-col font-comfortaa">
                        <h3 className="text-base font-bold text-azulfy-rich-black">
                          {adminMember.name}
                        </h3>
                        <p className="text-xs font-normal text-azulfy-rich-black">
                          {adminMember.email}
                        </p>
                      </div>
                    </div>
                    <div className="font-comfortaa flex gap-5">
                      <SelectPicker
                        data={rolesOptions}
                        searchable={false}
                        style={{
                          width: "8rem",
                          fontSize: "14px",
                          color: "#061425",
                        }}
                        cleanable={false}
                        className="text-azulfy-rich-black"
                        value={adminMember.role}
                        placement="auto"
                        onSelect={(value) =>
                          updateMemberRole(adminMember.uuid, value)
                        }
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className="flex flex-col gap-4">
              {formDataNewCouncil.members.filter(
                (member) => member.role === "external_service_provider"
              ).length > 0 && (
                <p className="text-azulfy-blue font-comfortaa font-bold text-xl">
                  {t("external_service_provider")}
                </p>
              )}
              {formDataNewCouncil.members
                .filter((member) => member.role === "external_service_provider")
                .map((adminMember, index) => (
                  <div
                    key={index + 1}
                    className="flex items-center justify-between"
                  >
                    <div className="flex gap-4 items-center">
                      <div className="flex items-center justify-center bg-azulfy-rich-black w-8 h-8 rounded-full">
                        <span className="font-comfortaa text-xs font-normal text-white">
                          {getInitials(adminMember.name)}
                        </span>
                      </div>
                      <div className="flex flex-col font-comfortaa">
                        <h3 className="text-base font-bold text-azulfy-rich-black">
                          {adminMember.name}
                        </h3>
                        <p className="text-xs font-normal text-azulfy-rich-black">
                          {adminMember.email}
                        </p>
                      </div>
                    </div>
                    <div className="font-comfortaa flex gap-5">
                      <SelectPicker
                        data={rolesOptions}
                        searchable={false}
                        style={{
                          width: "8rem",
                          fontSize: "14px",
                          color: "#061425",
                        }}
                        cleanable={false}
                        className="text-azulfy-rich-black"
                        value={adminMember.role}
                        placement="auto"
                        onSelect={(value) =>
                          updateMemberRole(adminMember.uuid, value)
                        }
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
