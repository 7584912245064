import { useEffect, useState } from "react";

import LoginComponent from "./components/LoginComponent";
import TutorialDesktop from "./components/TutorialDesktop";
import TutorialMobile from "./components/TutorialMobile";

export default function Login() {
  const [showTutorial, setShowTutorial] = useState(true);

  useEffect(() => {
    const isTutorialViewed = localStorage.getItem("tutorialViewed");
    if (isTutorialViewed) {
      setShowTutorial(false);
    }
  }, []);

  function handleCloseTutorial() {
    localStorage.setItem("tutorialViewed", "true");
    setShowTutorial(false);
  }

  return (
    <main>
      <div className="hidden md:grid grid-cols-2 h-screen font-comfortaa">
        <LoginComponent />
        <TutorialDesktop />
      </div>
      <div className="hidden max-md:block h-screen">
        {showTutorial ? (
          <TutorialMobile handleCloseTutorial={handleCloseTutorial} />
        ) : (
          <LoginComponent />
        )}
      </div>
    </main>
  );
}
