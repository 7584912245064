import { useEffect, useState } from "react";
import { calendarBuild } from "..";
import moment from "moment";
import { useTranslation } from "react-i18next";
import DayCard from "../DayCard";

interface MonthCardProps {
  month: string;
  currentYear: number;
  selectedDate: {
    startDate: Date;
    endDate: Date;
    label: string;
  } | null;
  setSelectedDate: React.Dispatch<
    React.SetStateAction<{
      startDate: Date;
      endDate: Date;
      label: string;
    } | null>
  >;
}

export default function MonthCard({
  month,
  currentYear,
  selectedDate,
  setSelectedDate,
}: MonthCardProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState<moment.Moment>(
    moment().locale("en").month(month).year(currentYear)
  );
  const [calendar, setCalendar] = useState<moment.Moment[][]>([]);
  const weekDays = [
    t("mon"),
    t("tue"),
    t("wed"),
    t("thu"),
    t("fri"),
    t("sat"),
    t("sun"),
  ];

  useEffect(() => {
    setValue(value.month(month).year(currentYear));
    setCalendar(calendarBuild(value));
  }, [value, month]);

  return (
    <div className="h-full">
      <div className="grid grid-cols-7 mb-2">
        {weekDays.map((value, index) => {
          return (
            <div
              className="text-xs text-azulfy-rich-black mb-2 text-center w-[40px]"
              key={index}
            >
              {value}
            </div>
          );
        })}
      </div>
      {calendar.map((week, index) => (
        <div className="grid grid-cols-7 gap-1" key={week.toString()}>
          {week.map((day) => {
            return (
              <DayCard
                key={day.unix()}
                calendarIndex={index}
                day={day}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
}
