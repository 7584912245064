import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { useUser } from "hooks/useUser";
import { zodResolver } from "@hookform/resolvers/zod";

import { InputLogin } from "components/InputLogin";
import { LinearButton } from "components/LinearButton";

import loginLogo from "assets/login-logo.png";

import { toast } from "utils/toast";
import { useTranslation } from "react-i18next";

const renewPasswordSchema = z.object({
  password: z.string(),
  password_confirmation: z.string(),
});

type renewPasswordInputs = z.infer<typeof renewPasswordSchema>;

export default function PasswordExpired() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<renewPasswordInputs>({
    resolver: zodResolver(renewPasswordSchema),
  });
  const navigate = useNavigate();
  const { renewPassword, logout } = useUser();
  const { t } = useTranslation();

  async function handleRenewPassword(data: renewPasswordInputs) {
    const { password, password_confirmation } = data;
    if (password !== password_confirmation) {
      toast({
        label: "Error",
        message: "Passwords do not match",
        type: "error",
      });
      return;
    }
    const response = await renewPassword(data);
    if (response) {
      toast({
        label: "Success",
        message: response?.message || "Password updated successfully",
        type: "success",
      });
      navigate("/home");
    }
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen text-azulfy-rich-black font-comfortaa px-10 max-md:pb-10">
      <img
        className="w-[100px] max-md:w-[75px]"
        src={loginLogo}
        alt="Azulfy logo"
      />
      <span className="font-raleway text-lg block max-md:hidden mt-3">
        Let’s Azulfy the world together.
      </span>
      <form
        className="flex flex-col gap-12 mt-12"
        onSubmit={handleSubmit(handleRenewPassword)}
      >
        <div className="flex flex-col items-center">
          <h1 className="text-5xl max-md:text-3xl text-azulfy-rich-black mb-2 text-center">
            {t("enter_new_password")}
          </h1>
          <span className="text-center font-raleway text-xl md:w-[570px] max-md:text-xs">
            {t("update_password_description")}
          </span>
        </div>

        <div className="flex flex-col gap-5">
          <InputLogin
            {...register("password")}
            required
            type="password"
            placeholder={t("new_password")}
            showPassword
          />
          <InputLogin
            {...register("password_confirmation")}
            required
            type="password"
            placeholder={t("confirm_new_password")}
            showPassword
          />
        </div>
        <LinearButton
          disabled={isSubmitting}
          text={t("change_password")}
          type="submit"
        />
      </form>
      <button
        onClick={logout}
        disabled={isSubmitting}
        className="text-xl max-md:text-base font-bold text-azulfy-rich-black text-center mt-7 hover:underline"
      >
        {t("logout_out")}
      </button>
    </div>
  );
}
