import * as Popover from "@radix-ui/react-popover";
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsChevronRight, BsSearch } from 'react-icons/bs';
import { useParams, useSearchParams } from 'react-router-dom';

import { membersWorkspaceByTypeProps, useAlerts } from 'hooks/useAlerts';
import checkIconSvg from '../../../../../assets/tabler-icon-check.svg';

export default function ObserverAlert() {
    const { t } = useTranslation();
    const { membersWorkspaceByTypeObserver, updateAlertObserver, getAllMembersofWorkspaceByTypeObserver, getAlerts } = useAlerts();
    const { uuidWorkspace } = useParams<{ uuidWorkspace: string }>();
    const [searchParams,] = useSearchParams();
    const alertUuid = searchParams.get("alert");

    const [selectedMembers, setSelectedMembers] = useState<membersWorkspaceByTypeProps[]>([]);
    const [changes, setChanges] = useState<{ add: string[], remove: string[] }>({ add: [], remove: [] });
    const [filteredMembers, setFilteredMembers] = useState<membersWorkspaceByTypeProps[]>([]);

    const closeButton = useRef<HTMLButtonElement>(null);
    const searchManagerInputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setFilteredMembers(membersWorkspaceByTypeObserver);
    }, [membersWorkspaceByTypeObserver]);

    useEffect(() => {
        const observerInitials = membersWorkspaceByTypeObserver?.filter((member) => member.selected === true);
        setSelectedMembers(observerInitials || []);
    }, [membersWorkspaceByTypeObserver])

    const handleMemberClick = async (memberUuid: string) => {
        const memberIndex = selectedMembers.findIndex(member => member.user.uuid === memberUuid);
        const isInAddList = changes.add.includes(memberUuid);
        const isInRemoveList = changes.remove.includes(memberUuid);
        const isInitiallySelected = memberIndex !== -1;

        const updatedSelectedMembers = [...selectedMembers];
        const updatedChanges = { ...changes };

        if (isInitiallySelected) {
            updatedSelectedMembers.splice(memberIndex, 1);
            if (isInAddList) {
                updatedChanges.add = updatedChanges.add.filter(uuid => uuid !== memberUuid);
            } else {
                updatedChanges.remove = [...updatedChanges.remove, memberUuid];
            }
        } else {
            updatedSelectedMembers.push(membersWorkspaceByTypeObserver.find(member => member.user.uuid === memberUuid)!);
            if (isInRemoveList) {
                updatedChanges.remove = updatedChanges.remove.filter(uuid => uuid !== memberUuid);
            } else if (!isInAddList) {
                updatedChanges.add = [...updatedChanges.add, memberUuid];
            }
        }

        setSelectedMembers(updatedSelectedMembers);
        setChanges(updatedChanges);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = event.target.value.toLowerCase();
        const filtered = membersWorkspaceByTypeObserver.filter(member =>
            member.user.name.toLowerCase().includes(searchText)
        );
        setFilteredMembers(filtered);
    };

    async function handleUpdateObserver() {
        await updateAlertObserver(uuidWorkspace!, alertUuid!, changes);
        await getAlerts();
        
        setChanges({ add: [], remove: [] });
    }

    return (
        <div className='flex flex-col gap-1 w-full'>
            <p className='text-sm text-azulfy-rich-black font-raleway'>
                {t("assigned_to")}
            </p>
            <div className='flex flex-col gap-1 w-full'>
                <Popover.Root>
                    <Popover.Trigger asChild>
                        <div className='flex items-center justify-between py-2 px-3 rounded-md shadow-md bg-white cursor-pointer hover:opacity-70 w-full min-h-[44px]'>
                            <div className='flex items-center gap-1 font-comfortaa'>
                                <div className='flex items-center gap-1 font-comfortaa'>
                                    {selectedMembers?.slice(0, 5).map((member, index) => (
                                        <span key={member.user.uuid} className={`w-7 h-7 rounded-full bg-azulfy-rich-black border-2 border-white flex items-center justify-center min-w-[28px] text-white text-[10px] ${index > 0 ? '-ml-3' : ''}`}>
                                            {member.user.initials}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <BsChevronRight className='text-azulfy-rich-black' size={12} />
                        </div>
                    </Popover.Trigger>
                    <Popover.Portal >
                        <Popover.Content
                            onCloseAutoFocus={async () => {
                                await handleUpdateObserver()
                                if (searchManagerInputRef?.current) {
                                    searchManagerInputRef.current!.value = ""
                                }
                                await getAllMembersofWorkspaceByTypeObserver(uuidWorkspace!, alertUuid!, "observer")
                            }}
                            sideOffset={5}
                            align="start"
                            className="bg-white shadow-lg py-2 px-3 rounded-md border border-grey-400 border-opacity-30 w-[var(--radix-popover-trigger-width)] max-h-60 divide-y divide-slate-200 overflow-y-scroll overflow-x-hidden"
                        >
                            <Popover.Close ref={closeButton} hidden />
                            <div className='mb-4 flex items-center justify-between bg-grey-600 py-2 px-3 rounded-lg border border-grey-400 sticky top-0 z-10'>
                                <input
                                    type="text"
                                    name="Search"
                                    id="search"
                                    ref={searchManagerInputRef}
                                    onChange={handleSearchChange}
                                    placeholder={`${t("search")}...`}
                                    className='active:outline-none focus-visible:outline-none bg-transparent'
                                />
                                <BsSearch size={16} />
                            </div>
                            {filteredMembers.map((member) => (
                                <div
                                    key={member.user.uuid}
                                    onClick={() => handleMemberClick(member.user.uuid)}
                                    className="flex items-center justify-between py-2 cursor-pointer hover:opacity-70"
                                >
                                    <div className='flex items-center gap-1 font-comfortaa break-all'>
                                        <span className="w-7 h-7 rounded-full bg-azulfy-rich-black flex items-center justify-center min-w-[28px] text-white text-[10px]">
                                            {member.user.initials}
                                        </span>

                                        <p className='text-xs font-bold text-azulfy-rich-black select-none line-clamp-1'>{member.user.name}</p>
                                    </div>
                                    {selectedMembers.some(selectedMember => selectedMember.user.uuid === member.user.uuid) && (
                                        <img src={checkIconSvg} alt="check" className='w-4 h-4' />
                                    )}
                                </div>
                            ))}
                        </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
            </div>
        </div>
    )
}
