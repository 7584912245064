export const CheckIconGradient = ({
  ...props
}: Readonly<React.SVGProps<SVGSVGElement>>) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" fill="white"/>
      <path d="M13.7126 6.21163C13.8887 6.04399 14.123 5.95131 14.3661 5.95315C14.6092 5.95499 14.8421 6.0512 15.0156 6.22149C15.1892 6.39177 15.2897 6.62281 15.2962 6.86585C15.3026 7.10888 15.2144 7.34491 15.0501 7.52413L10.0626 13.7616C9.97681 13.854 9.87331 13.9281 9.75824 13.9796C9.64317 14.031 9.51891 14.0588 9.39289 14.0611C9.26686 14.0634 9.14166 14.0403 9.02477 13.9932C8.90788 13.946 8.8017 13.8758 8.71257 13.7866L5.40507 10.4791C5.31297 10.3933 5.23909 10.2898 5.18785 10.1748C5.13661 10.0598 5.10906 9.93566 5.10684 9.80978C5.10461 9.6839 5.12777 9.55887 5.17492 9.44213C5.22207 9.3254 5.29225 9.21935 5.38128 9.13033C5.4703 9.04131 5.57634 8.97113 5.69308 8.92398C5.80981 8.87682 5.93485 8.85367 6.06073 8.85589C6.18661 8.85811 6.31075 8.88566 6.42575 8.9369C6.54075 8.98814 6.64425 9.06202 6.73007 9.15413L9.34758 11.7704L13.6888 6.23913C13.6966 6.22951 13.7037 6.22032 13.7126 6.21163Z" fill="url(#paint0_linear_3886_23380)"/>
      <defs>
      <linearGradient id="paint0_linear_3886_23380" x1="10.2016" y1="5.95312" x2="10.2016" y2="14.0613" gradientUnits="userSpaceOnUse">
      <stop stopColor="#0072FF"/>
      <stop offset="1" stopColor="#00C6FF"/>
      </linearGradient>
      </defs>
    </svg>
  )
}