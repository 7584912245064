import {
  E164Number
} from 'libphonenumber-js/core';
import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-number-input';

import type { MembersProps } from "..";

interface Step1Props {
  emailIsInvalid: boolean;
  emailExistis: boolean;
  adminPhone: E164Number | undefined;
  handleAdminPhone: (value: E164Number | undefined) => void;
  nameCouncilRef: React.RefObject<HTMLInputElement>;
  adminNameRef: React.RefObject<HTMLInputElement>;
  adminEmailRef: React.RefObject<HTMLInputElement>;
  azulfyCodeRef: React.RefObject<HTMLInputElement>;
  formDataNewCouncil: {
    azulfy_code?: string;
    name: string;
    admin_email: string;
    admin_phone?: string;
    admin_name: string;
    license_id: string;
    members: MembersProps[];
  };
  setAzulfyCode: (value: React.SetStateAction<string>) => void;
  setNameCouncil: (value: React.SetStateAction<string>) => void;
  setNameAdmin: (value: React.SetStateAction<string>) => void;
  setEmailAdmin: (value: React.SetStateAction<string>) => void;
}

export default function Step1({
  emailIsInvalid,
  emailExistis,
  nameCouncilRef,
  adminEmailRef,
  azulfyCodeRef,
  formDataNewCouncil,
  adminNameRef,
  setAzulfyCode,
  setNameCouncil,
  setNameAdmin,
  setEmailAdmin,
  adminPhone,
  handleAdminPhone
}: Step1Props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full flex flex-col justify-center items-center gap-12 max-w-xl mx-auto">
        <div className="w-full flex flex-col gap-8">
          <p className="text-azulfy-blue font-comfortaa text-2xl font-bold text-center">
            {t("internal_azulfy_code")}*
          </p>
          <input
            type="text"
            ref={azulfyCodeRef}
            onChange={(e) => {
              setAzulfyCode(e.target.value);
            }}
            defaultValue={formDataNewCouncil.azulfy_code}
            placeholder={t("internal_azulfy_code")}
            className="w-full rounded-lg border border-gray-200 focus:outline-none py-3 px-4"
          />
        </div>
        <div className="w-full flex flex-col gap-8">
          <p className="text-azulfy-blue font-comfortaa text-2xl font-bold text-center">
            {t("choose_a_name_for_the_council")}
          </p>
          <input
            type="text"
            ref={nameCouncilRef}
            onChange={(e) => setNameCouncil(e.target.value)}
            defaultValue={formDataNewCouncil.name}
            placeholder="Ex.: Camera Oeiras"
            className="w-full rounded-lg border border-gray-200 focus:outline-none py-3 px-4"
          />
        </div>
        <div className="w-full flex flex-col gap-4">
          <p className="text-azulfy-rich-black text-sm font-comfortaa font-normal text-center">
            {t(
              "choose_a_name_and_email_address_for_those_who_will_manage_the_board"
            )}
          </p>
          <input
            type="text"
            ref={adminNameRef}
            onChange={(e) => setNameAdmin(e.target.value)}
            defaultValue={formDataNewCouncil.admin_name}
            placeholder={t("name")}
            className="w-full rounded-lg border border-gray-200 focus:outline-none py-3 px-4"
          />
          <input
            type="text"
            ref={adminEmailRef}
            onChange={(e) => setEmailAdmin(e.target.value)}
            defaultValue={formDataNewCouncil.admin_email}
            placeholder={t("email_address")}
            className="w-full rounded-lg border border-gray-200 focus:outline-none py-3 px-4"
          />
          <PhoneInput
            type="tel"
            name="phone"
            id="phone"
            value={adminPhone}
            onChange={(value) => {
              handleAdminPhone(value)
            }}
            placeholder={t('phone_number')}
            className="w-full border border-gray-300 rounded-lg px-4 py-2 [&_*:focus]:outline-none [&_*]:text-base"
          />
        </div>
        {emailIsInvalid && (
          <p className="text-error text-sm font-comfortaa font-normal text-center">
            {t("please_fill_in_a_valid_email_address")}
          </p>
        )}
        {emailExistis && (
          <p className="text-error text-sm font-comfortaa font-normal text-center">
            {t("unable_to_invite_this_user_this_user_has_already_been_invited")}
          </p>
        )}
      </div>
    </>
  );
}
