export const useGetUserPermissions = () => {
    const user = JSON.parse(localStorage.getItem('azulfyAuthUser') || '{}')
    return user.permissions || []
}

const Permission = ({ children, permissions}: {children: JSX.Element, permissions: string[]}) => {
    const userPermissions = useGetUserPermissions()

    if(userPermissions.some((permission: string) => permissions.includes(permission))) {
        return children
    }

    return null
}

export default Permission