import { BsChevronLeft } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";

interface HeaderCouncilProps {
    title?: string;
    navigateTo?: string;
}

export default function HeaderCouncil({ title, navigateTo }: HeaderCouncilProps) {
    const navigate = useNavigate();
    return (
        <>
            <header
                className="flex items-center w-full gap-2 py-1 lg:mb-5 capitalize"
            >
                <Link to={navigateTo!} onClick={() => navigate(-1)} className={`hidden md:block`}>
                    <BsChevronLeft size={20} className="text-azulfy-blue" />
                </Link>
                <p className="text-azulfy-blue text-xl font-bold font-comfortaa">
                    {title}
                </p>
            </header>
        </>
    )
}