import { zodResolver } from "@hookform/resolvers/zod";
import { LinearButton } from "components/LinearButton";
import { useUser } from "hooks/useUser";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "utils/toast";
import * as z from "zod";
import HeaderAccount from "../components/HeaderAccount";

const profileFormSchema = z.object({
  name: z.string(),
  email: z.string(),
});

type profileFormInputs = z.infer<typeof profileFormSchema>;

export default function Profile() {
  const { t } = useTranslation();
  const { authUser, updateUser } = useUser();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<profileFormInputs>({
    resolver: zodResolver(profileFormSchema),
  });

  useEffect(() => {
    setValue("name", authUser?.user?.name || "");
    setValue("email", authUser?.user?.email || "");
  }, []);

  async function handleUpdateUser(data: profileFormInputs) {
    // if(data.name === "") return
    const response = await updateUser({ name: data.name });
    if (response) {
      toast({
        label: "Success",
        message: response?.message || "Profile updated successfully",
        type: "success",
      });
    }
  }

  return (
    <main className="w-full">
      <HeaderAccount title={t("profile")} navigateTo="/account" />
      <form
        action=""
        onSubmit={handleSubmit(handleUpdateUser)}
        className="w-full max-w-2xl mx-auto flex flex-col gap-5 font-comfortaa"
      >
        <div>
          <label htmlFor="" className="font-normal text-xs text-gray-500">
            {t("name")}
          </label>
          <input
            {...register("name")}
            type="text"
            name="name"
            id="name"
            required
            placeholder={t("name")}
            className="w-full px-3 py-2 text-azulfy-rich-black border-b border-backgray focus:outline-none"
          />
        </div>
        <div>
          <label htmlFor="" className="font-normal text-xs text-gray-500">
            {t("email")}
          </label>
          <input
            {...register("email")}
            type="text"
            name="email"
            id="email"
            disabled
            placeholder={t("email")}
            className="w-full px-3 py-2 text-azulfy-rich-black border-b border-backgray focus:outline-none bg-transparent"
          />
        </div>
        <LinearButton
          type="submit"
          text={t("submit")}
          disabled={isSubmitting}
        />
      </form>
    </main>
  );
}
