import * as Popover from "@radix-ui/react-popover";
import Map, { MapboxGeoJSONPolygon } from "components/MapComponent";
import GeoInputRow from "components/MapComponent/internals/GeoInputRow";
import DeleteAllPopOver from "components/MapComponent/internals/addOns/components/DeleteAllPopOver";
import MapBasicButtons from "components/MapComponent/internals/addOns/components/MapBasicButtons";
import MapNotification from "components/MapComponent/internals/addOns/components/MapNotification";
import { polygonBuilder } from "components/MapComponent/internals/helpers";
import { t } from "i18next";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { BsThreeDots } from "react-icons/bs";

interface IGeographicalAreaMapProps {
  geoGraphicalArea: MapboxGeoJSONPolygon | undefined;
  setGeoGraphicalArea: Dispatch<
    SetStateAction<MapboxGeoJSONPolygon | undefined>
  >;
}
export const GeographicalAreaMap = ({
  geoGraphicalArea,
  setGeoGraphicalArea,
}: IGeographicalAreaMapProps) => {
  const [showNotification, setShowNotification] = useState(false);

  const mapRef = useRef<mapboxgl.Map | null>(null);
  const drawRef = useRef<MapboxDraw | null>(null);

  const polygonsLimit = 1;
  const polygonCount = geoGraphicalArea ? 1 : 0;

  const onUpdatePolygon = (feature: MapboxGeoJSONPolygon) => {
    setGeoGraphicalArea(undefined);
    setGeoGraphicalArea(() => feature);
  };

  const onAddPolygon = (feature: MapboxGeoJSONPolygon) => {
    setGeoGraphicalArea(() => undefined);
    setGeoGraphicalArea(() => feature);
  };

  useEffect(() => {
    if (!mapRef.current || !drawRef.current) return;

    mapRef.current.on("draw.update", (event) => {
      // To enable Point or Linestring change this rule
      if (event.features[0].geometry.type !== "Polygon") return;

      onUpdatePolygon?.(event.features[0]);
    });

    mapRef.current.on("draw.delete", () => {
      setGeoGraphicalArea(undefined);
    });

    mapRef.current.on("draw.create", (event) => {
      setShowNotification(false);
      // To enable Point or Linestring change this rule
      if (event.features[0].geometry.type !== "Polygon") return;

      onAddPolygon?.(event.features[0]);

      if (polygonsLimit > polygonCount) {
        drawRef.current?.deleteAll();
        drawRef.current?.set(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          polygonBuilder({
            id: event.features[0].id,
            coordinates: event.features[0].geometry.coordinates,
            properties: event.features[0].properties,
          })
        );
      }
    });
  }, []);

  const onConfirmRowDelete = (index: number) => {
    // Start and end have points, so count one more point than index
    if (
      geoGraphicalArea?.geometry?.coordinates?.[0]?.length &&
      geoGraphicalArea?.geometry?.coordinates?.[0]?.length <= 4
    ) {
      setGeoGraphicalArea(() => undefined);
      drawRef.current?.deleteAll();
      return;
    }

    if (geoGraphicalArea?.geometry?.coordinates?.[0]?.[index]) {
      const previousValue = geoGraphicalArea;
      previousValue.geometry.coordinates[0].splice(index, 1);
      setGeoGraphicalArea(() => {
        return {
          ...previousValue,
        };
      });
      drawRef.current?.delete(previousValue.id);
      drawRef.current?.set(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        polygonBuilder({
          id: previousValue.id,
          coordinates: previousValue.geometry.coordinates,
          properties: previousValue.properties,
        })
      );
    }
  };

  const onConfirmRowUpdate = (index: number, updatedCoordinate: number[]) => {
    const previousValue = geoGraphicalArea;
    if (previousValue?.geometry?.coordinates?.[0]?.[index]) {
      previousValue.geometry.coordinates[0][index] = updatedCoordinate;
      previousValue.geometry.coordinates[0][
        previousValue.geometry.coordinates[0].length - 1
      ] = updatedCoordinate;

      setGeoGraphicalArea(previousValue);
      drawRef.current?.delete(previousValue.id);
      drawRef.current?.set(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        polygonBuilder({
          id: previousValue.id,
          coordinates: previousValue.geometry.coordinates,
        })
      );
    }
  };

  return (
    <div className="mapWrapper">
      <Map showSearchBar mapRef={mapRef} drawRef={drawRef}>
        <MapBasicButtons canDraw={true} drawRef={drawRef} mapRef={mapRef} />
        <MapNotification
          showNotification={showNotification}
          setShowNotification={setShowNotification}
        />
      </Map>
      <div className="py-12">
        <span className="text-xl font-bold text-azulfy-blue">
          {t("gps_coordinates")}
        </span>

        <div className="flex justify-between">
          <p className="text-azulfy-rich-black">
            {t("click_on_the_map_to_choose_the_location_for_analysis")}
          </p>
          {geoGraphicalArea && (
            <Popover.Root>
              <Popover.Trigger asChild>
                <button className="hover:opacity-70">
                  <BsThreeDots size={24} color="#0072FF" />
                </button>
              </Popover.Trigger>
              <DeleteAllPopOver
                onConfirmDeleteAll={() => {
                  setGeoGraphicalArea(undefined);
                  drawRef.current?.deleteAll();
                }}
              />
            </Popover.Root>
          )}
        </div>

        {geoGraphicalArea && (
          <div className="flex items-center mt-8">
            <div className="flex-1">
              <span className="text-base font-bold text-azulfy-blue pl-28">
                {t("Latitude")}
              </span>
            </div>
            <div className="flex-1">
              <span className="text-base font-bold text-azulfy-blue pl-28">
                {t("Longitude")}
              </span>
            </div>
          </div>
        )}
        <ul className="flex flex-col gap-4 m-0 list-none">
          {geoGraphicalArea?.geometry.coordinates[0]
            .slice(0, geoGraphicalArea?.geometry.coordinates[0].length - 1)
            .map((coordinate, index) => {
              return (
                <li key={index}>
                  <GeoInputRow
                    index={index}
                    coordinate={coordinate}
                    onConfirmUpdate={onConfirmRowUpdate}
                    onConfirmDelete={onConfirmRowDelete}
                  />
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
