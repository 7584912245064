import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { AzulfyAuthUser } from "hooks/useUser";

import enTranslations from "../locale/en.json";
import ptTranslations from "../locale/pt.json";

const initI18n = (language: string) => {
  i18n.use(initReactI18next).init({
    lng: language,
    resources: {
      en: enTranslations,
      pt: ptTranslations,
    },
    interpolation: {
      escapeValue: false
    }
  });
};

if (localStorage.getItem("azulfyAuthUser")) {
  try {
    const authUser = localStorage.getItem("azulfyAuthUser")
      ? (JSON.parse(localStorage.getItem("azulfyAuthUser")!) as AzulfyAuthUser)
      : null;
    if (authUser && authUser?.user?.language) {
      initI18n(authUser?.user?.language);
    } else {
      initI18n("en");
    }
  } catch (error) {
    initI18n("en");
  }
} else {
  initI18n("en");
}
