import { create } from "zustand";
import { api } from "services/axios";
import { showErrorMessage } from "../utils/auxFunctions";
import { FilterProps, IndicatorsProps } from "./useFilters";

interface IndicatorsHiProps {
  label: string;
    date: string;
    sum_area: number;
    sum_intensity: number;
}

export interface IndicatorsHistoryProps {
  data: IndicatorsHiProps[],
  lt: number,
  gt: number,
  indicator: {
    name: string,
    description: string,
  }
}

interface useIndicatorsHistoryProps {
  showGraph: boolean;
  setShowGraph: (showGraph: boolean) => void;
  indicatorsHistory: IndicatorsHistoryProps | null;
  setIndicatorsHistory: (indicatorsHistory: IndicatorsHistoryProps | null) => void;
  getIndicatorsHistory: (workspaceUuid: string, params?: string) => Promise<IndicatorsHistoryProps>;
  setWhenSelected: (when: { startDate: Date; endDate: Date; label: string } | null) => void;
  dateSelected: { startDate: Date; endDate: Date; label: string } | null;
  setWhereSelected: (where: FilterProps | null) => void;
  setWhatSelected: (what: IndicatorsProps | null) => void;
  whereSelected: FilterProps | null;
  whatSelected: IndicatorsProps | null;
  whenSelected: { startDate: Date; endDate: Date; label: string } | null;
}

export const useIndicatorsHistory = create<useIndicatorsHistoryProps>((set) => ({
  indicatorsHistory: null,
  showGraph: false,
  setShowGraph: (showGraph: boolean) => set({ showGraph }),
  setIndicatorsHistory: (indicatorsHistory: IndicatorsHistoryProps | null) => set({ indicatorsHistory }),
  getIndicatorsHistory: async (workspaceUuid: string, params?: string) => {
    try {
      const { data } = await api.get(`/workspace/${workspaceUuid}/indicator-history` + (params ? params : ""));
      set({ indicatorsHistory: data.content.data });
      return data.content.data.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  dateSelected: null,
  setWhenSelected: (when: { startDate: Date; endDate: Date; label: string } | null) => set({ whenSelected: when }),
  setWhereSelected: (where: FilterProps | null) => set({ whereSelected: where }),
  setWhatSelected: (what: IndicatorsProps | null) => set({ whatSelected: what }),
  whereSelected: null,
  whatSelected: null,
  whenSelected: null,
}));
