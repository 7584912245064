import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  AiOutlinePlus,
  AiOutlineSend,
  AiOutlineClose,
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineOrderedList,
} from "react-icons/ai";
import { BsCameraVideo, BsFiletypePdf, BsImage } from "react-icons/bs";

import { Editor, EditorState, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

interface MessageBoxDesktopProps {
  handleSendMessage(messageHTML: string): Promise<void>;
  blockStyleFn: (
    contentBlock: any
  ) => "" | "text-center" | "text-left" | "list-decimal" | "list-disc";
  attachmentsInput: React.RefObject<HTMLInputElement>;
  attachments: File[];
  setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
  clear: boolean;
  setClear: React.Dispatch<React.SetStateAction<boolean>>;
  isSubbmitting: boolean;
}

export default function MessageBoxDesktop({
  attachments,
  handleSendMessage,
  blockStyleFn,
  attachmentsInput,
  setAttachments,
  clear,
  setClear,
  isSubbmitting,
}: MessageBoxDesktopProps) {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const editorRef = useRef<any>(null);

  const { t } = useTranslation();
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);

  function updateSendButtonStatus(editorState: EditorState) {
    const content = editorState.getCurrentContent();
    const contentText = content.getPlainText().trim();

    setIsSendButtonDisabled(contentText === "");
  }

  function handleKeyCommand(command: string, editorState: EditorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  }

  useEffect(() => {
    if (clear) {
      setEditorState(EditorState.createEmpty());
      setAttachments([]);
      setClear(false);
    }
    updateSendButtonStatus(editorState);
  }, [clear, editorState]);

  useEffect(() => {
    if(attachments?.length > 0){
      setIsSendButtonDisabled(false)
    } else {
      setIsSendButtonDisabled(true)
    }
  }, [attachments]);

  return (
    <div className="hidden md:flex flex-col mt-auto gap-3 bg-grey-600 px-6 py-3 w-full max-w-full">
      {attachments?.length > 0 && (
        <div className="flex items-center flex-wrap gap-x-3 gap-y-2 justify-start my-3">
          {attachments?.map((file, index) => {
            let fileIcon;

            if (file.type.startsWith("image/")) {
              fileIcon = <BsImage size={20} color="#00C6FF" />;
            } else if (file.type === "application/pdf") {
              fileIcon = <BsFiletypePdf size={20} color="#00C6FF" />;
            } else if (file.type.startsWith("video/")) {
              fileIcon = <BsCameraVideo size={20} color="#00C6FF" />;
            } else {
              fileIcon = <BsImage size={16} />;
            }

            return (
              <span
                key={index}
                className="w-12 h-12 p-2 rounded-md flex items-center justify-center bg-[#fff] relative"
              >
                {fileIcon}
                <button
                  onClick={() =>
                    setAttachments(attachments.filter((_, i) => i !== index))
                  }
                  className="w-[15px] h-[15px] absolute top-[-5px] right-[-5px] bg-azulfy-blue rounded-full py-1 flex items-center justify-center hover:bg-azulfy-blue-hover active:bg-azulfy-blue-pressed"
                >
                  <AiOutlineClose size={11} color="#fff" />
                </button>
              </span>
            );
          })}
        </div>
      )}
      <div className="flex items-center justify-between gap-3 w-full">
        <div className="flex items-center gap-3 max-lg:hidden">
          <button
            disabled={isSubbmitting}
            onClick={() => attachmentsInput.current?.click()}
            className="w-8 h-8 rounded-full p-1 flex items-center justify-center bg-Azulfy-Linear-Gradient hover:bg-Azulfy-Linear-Gradient-hover active:bg-Azulfy-Linear-Gradient-pressed"
          >
            <AiOutlinePlus size={20} color="#fff" />
          </button>
          <button
            disabled={isSubbmitting}
            onClick={(e) => {
              e.preventDefault();
              setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
            }}
            className="hover:opacity-70"
          >
            <AiOutlineBold size={28} color="#061425" />
          </button>
          <button
            disabled={isSubbmitting}
            onClick={(e) => {
              e.preventDefault();
              setEditorState(
                RichUtils.toggleInlineStyle(editorState, "ITALIC")
              );
            }}
            className="hover:opacity-70"
          >
            <AiOutlineItalic size={28} color="#061425" />
          </button>
          <button
            disabled={isSubbmitting}
            onClick={(e) => {
              e.preventDefault();
              setEditorState(
                RichUtils.toggleBlockType(editorState, "ordered-list-item")
              );
            }}
            className="hover:opacity-70"
          >
            <AiOutlineOrderedList size={28} color="#061425" />
          </button>
        </div>
        <div className="flex flex-1 flex-col gap-2">
          <div className="flex items-center justify-between w-full gap-3">
            <div
              className="flex-1 bg-white p-2 rounded-md font-raleway max-w-full overflow-auto max-h-[100px]"
              onClick={focus}
            >
              <Editor
                placeholder={t("write_a_message")}
                ref={editorRef}
                editorState={editorState}
                onChange={setEditorState}
                handleKeyCommand={handleKeyCommand}
                blockStyleFn={blockStyleFn}
              />
            </div>
            <button
              onClick={() =>
                handleSendMessage(stateToHTML(editorState.getCurrentContent()))
              }
              disabled={isSendButtonDisabled || isSubbmitting}
              className={`w-8 h-8 rounded-full p-1 flex items-center justify-center ${
                isSendButtonDisabled
                  ? "opacity-50 cursor-not-allowed bg-azulfy-rich-black"
                  : "hover:bg-Azulfy-Linear-Gradient-hover bg-Azulfy-Linear-Gradient active:bg-Azulfy-Linear-Gradient-pressed"
              }`}
            >
              <AiOutlineSend size={20} color="#fff" />
            </button>
          </div>
          <div className="hidden items-center gap-5 max-lg:flex my-2">
            <button
              disabled={isSubbmitting}
              onClick={() => attachmentsInput.current?.click()}
              className="w-8 h-8 rounded-full p-1 flex items-center justify-center bg-Azulfy-Linear-Gradient hover:bg-Azulfy-Linear-Gradient-hover active:bg-Azulfy-Linear-Gradient-pressed"
            >
              <AiOutlinePlus size={20} color="#fff" />
            </button>
            <button
              disabled={isSubbmitting}
              onClick={(e) => {
                e.preventDefault();
                setEditorState(
                  RichUtils.toggleInlineStyle(editorState, "BOLD")
                );
              }}
              className="hover:opacity-70"
            >
              <AiOutlineBold size={28} color="#061425" />
            </button>
            <button
              disabled={isSubbmitting}
              onClick={(e) => {
                e.preventDefault();
                setEditorState(
                  RichUtils.toggleInlineStyle(editorState, "ITALIC")
                );
              }}
              className="hover:opacity-70"
            >
              <AiOutlineItalic size={28} color="#061425" />
            </button>
            <button
              disabled={isSubbmitting}
              onClick={(e) => {
                e.preventDefault();
                setEditorState(
                  RichUtils.toggleBlockType(editorState, "ordered-list-item")
                );
              }}
              className="hover:opacity-70"
            >
              <AiOutlineOrderedList size={28} color="#061425" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
