import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_API_KEY || "";

import {
  HTMLAttributes,
  MutableRefObject,
  useEffect,
  useRef,
  useState
} from "react";
import "./style.css";

import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { coordinatesGeocoder } from "components/MapComponent/internals/coordinatesGeoCodes.ts";
import { defaultDrawStyles } from "components/MapComponent/internals/defaultDrawStyles.ts";
import classNames from "components/MapComponent/internals/helpers";
import { useUser } from "hooks/useUser";
export type PolygonTypes = "GeographicalPoint" | "PointsOfAnalysis";

export const MAPBOX_CONSTANTS = {
  LATITUDE_INDEX: 1,
  LONGITUDE_INDEX: 0,
};

export interface MapboxGeoJSONPolygon {
  id: string;
  type: string;
  properties: Properties;
  geometry: Geometry;
}


export interface MapBoxFeaturePoint {
  id: string;
  type: string;
  properties: Record<string, unknown>; 
  geometry: {
    coordinates: [number, number];
    type: string;
  };
}
export interface Properties {
  type?: PolygonTypes;
  name?: string;
  urlLiveCam?: string;
  points?: MapBoxFeaturePoint[];
}

interface Geometry {
  coordinates: number[][][];
  type: string;
}

interface IMapComponentProps {
  children?: React.ReactNode | React.ReactNode[];
  mapRef: MutableRefObject<mapboxgl.Map | null>;
  drawRef: MutableRefObject<MapboxDraw | null>;
  showNotification?: boolean;
  showSearchBar?: boolean;
}

const Map = ({
  mapRef,
  drawRef,
  children,
  showSearchBar,
}: IMapComponentProps) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const { authUser } = useUser();

  const [lat, setLat] = useState<number>(authUser?.workspaces.lat_center || 38.7077507);
  const [long, setLong] = useState<number>(authUser?.workspaces.lng_center || -9.1365919);
  const [zoom, setZoom] = useState(10);


  useEffect(() => {
    if (mapRef.current || !mapContainer.current) return;

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      // Select which mapbox-gl-draw control buttons to add to the map.
      controls: {
        polygon: true,
        trash: true,
      },
      styles: defaultDrawStyles,
      // Set mapbox-gl-draw to draw by default.
      // The user does not have to click the polygon control button first.
      defaultMode: "draw_polygon",
    });

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [long, lat],
      zoom: zoom,
    })
      .addControl(draw);
    draw.changeMode("simple_select");

    if (showSearchBar) {
      const searchBar = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        placeholder: "Search...",
        localGeocoder: coordinatesGeocoder as never,
      });

      map.addControl(searchBar, "top-left");
    }

    mapRef.current = map;
    drawRef.current = draw;
  }, []);

  useEffect(() => {
    if (!mapRef.current) return;

    mapRef.current.on("move", () => {
      setLong(Number(mapRef.current?.getCenter().lng.toFixed(4)) ?? 4);
      setLat(Number(mapRef.current?.getCenter().lat.toFixed(4)) ?? 1);
      setZoom(Number(mapRef.current?.getZoom().toFixed(2)) ?? 12);
    });
  }, []);

  return (
    <div className="relative">
      <div
        ref={mapContainer}
        // This value of height (546px) need to be also changed in the style.css .mapboxgl-canvas class
        className="relative left-0 top-0 w-full h-full min-h-[646px]"
      >
        {children}
      </div>
    </div>
  );
};

interface IMapItemProps extends HTMLAttributes<HTMLDivElement> {}

const MapItem = ({ ...props }: IMapItemProps) => {
  return (
    <div {...props} className={classNames("absolute z-10", props.className)} />
  );
};

Map.Item = MapItem;
export default Map;
