import { create } from "zustand";
import { api } from "services/axios";
import { showErrorMessage } from "../utils/auxFunctions";
import { MapBoxFeaturePoint } from "components/MapComponent";

interface Pagination {
  current_page: number;
  last_page: number;
  total: number;
  last_page_url: string | null;
}

export interface LiveCams {
  uuid: string;
  name: string;
  url: string;
  region_map_area: MapBoxFeaturePoint;
}

interface useLivesProps {
  liveCams: LiveCams[];
  liveCam: LiveCams;
  pagination: Pagination;
  setLiveCam: (liveCam: LiveCams) => void;
  getAllLivesCams: (workspaceUuid: string, params?: string) => Promise<LiveCams[]>;
  getLiveCamByUuid: (workspaceUuid: string, liveCamUuid: string) => Promise<LiveCams>;
  deleteLiveCam: (workspaceUuid: string, liveCamUuid: string) => Promise<void>;
}

export const useLives = create<useLivesProps>((set) => ({
  liveCams: [],
  liveCam: {} as LiveCams,
  pagination: {
    current_page: 1,
    last_page: 1,
    total: 1,
    last_page_url: null,
  },
  setLiveCam: (liveCam: LiveCams) => {
    set({ liveCam });
  },
  getAllLivesCams: async (workspaceUuid: string, params?: string) => {
    try {
      const { data } = await api.get(`/workspace/${workspaceUuid}/videos` + (params ? params : ""));
      set({ liveCams: data.content.data });
      set({
        pagination: {
          current_page: data.content.data.current_page,
          last_page: data.content.data.last_page,
          total: data.content.data.total,
          last_page_url: data.content.data.last_page_url,
        },
      });
      return data.content.data.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  getLiveCamByUuid: async (workspaceUuid: string, liveCamUuid: string) => {
    try {
      const { data } = await api.get(`/workspace/${workspaceUuid}/videos/${liveCamUuid}`);
      set({ liveCam: data.content.data });
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  deleteLiveCam: async (workspaceUuid: string, liveCamUuid: string) => {
    try {
      await api.delete(`/workspace/${workspaceUuid}/videos/${liveCamUuid}`);
    } catch (error: any) {
      showErrorMessage(error);
    }
  }
}));
