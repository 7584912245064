import { useEffect, useRef, useState } from "react";

import tutorialPhoto1 from "assets/tutorial-photo-1-min.webp";
import tutorialPhoto2 from "assets/tutorial-photo-2-min.webp";
import tutorialPhoto3 from "assets/tutorial-photo-3-min.webp";

interface TutorialMobileProps {
  handleCloseTutorial: () => void;
}

export default function TutorialMobile({
  handleCloseTutorial,
}: TutorialMobileProps) {
  const [tutorialStep, setTutorialStep] = useState<{
    step: number;
    img: string;
  }>({ step: 1, img: tutorialPhoto1 });
  const [startX, setStartX] = useState(0);
  const [isSliding, setIsSliding] = useState<"left" | "right" | null>(null);
  const [transitionLeft, setTransitionLeft] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState(5000);

  const buttonChangeImage1 = useRef<HTMLButtonElement>(null);
  const buttonChangeImage2 = useRef<HTMLButtonElement>(null);
  const buttonChangeImage3 = useRef<HTMLButtonElement>(null);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (isSliding == "right") {
      if (tutorialStep.step === 3) buttonChangeImage2.current?.click();
      if (tutorialStep.step === 2) buttonChangeImage1.current?.click();

      setIsSliding(null);
    }

    if (isSliding == "left") {
      if (tutorialStep.step === 1) buttonChangeImage2.current?.click();
      if (tutorialStep.step === 2) buttonChangeImage3.current?.click();
      setIsSliding(null);
    }

    setStartX(0);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!startX) return;

    const currentX = e.touches[0].clientX;
    const deltaX = currentX - startX;

    if (deltaX > 50) {
      // You can adjust the threshold for what constitutes a right swipe
      setIsSliding("right");
    }

    if (deltaX < -50) {
      setIsSliding("left");
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (tutorialStep.step === 1) buttonChangeImage2.current?.click();
      if (tutorialStep.step === 2) buttonChangeImage3.current?.click();
      if (tutorialStep.step === 3) buttonChangeImage1.current?.click();

      setTimeRemaining(5000);
    }, timeRemaining);

    return () => {
      clearTimeout(timer);
    };
  }, [tutorialStep, timeRemaining]);

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      className="relative h-full"
    >
      {/* <TransitionGroup className={"w-full"}>
        <CSSTransition
          key={tutorialStep.step}
          timeout={500}
          classNames="messageout"
        >
          <img
            className="object-fit w-full md:h-[75vh] max-md:h-[65vh]"
            src={tutorialStep.img}
            alt=""
          />
        </CSSTransition>
      </TransitionGroup> */}
      <div className="w-full md:h-[75vh] max-md:h-[65vh] overflow-hidden relative">
        <img
          className={`object-fit w-full h-full transition-all duration-1000 top-0 right-0 absolute ${
            tutorialStep.step === 1
              ? "translate-x-0 opacity-100"
              : "translate-x-[-1000px] opacity-0"
          } `}
          src={tutorialPhoto1}
          alt=""
        />
        <img
          className={`object-fit w-full h-full transition-all duration-1000 top-0 right-0 absolute ${
            tutorialStep.step === 2
              ? "translate-x-0 opacity-100"
              : transitionLeft
              ? "translate-x-[1000px] opacity-0"
              : "translate-x-[-1000px] opacity-0"
          } `}
          src={tutorialPhoto2}
          alt=""
        />
        <img
          className={`object-fit w-full h-full transition-all duration-1000 top-0 right-0 absolute ${
            tutorialStep.step === 3
              ? "translate-x-0 opacity-100"
              : "translate-x-[1000px] opacity-0"
          } `}
          src={tutorialPhoto3}
          alt=""
        />
      </div>
      <div className="md:h-[30vh] max-md:h-[40vh] bg-Azulfy-Linear-Gradient rounded-t-[20px] w-full absolute bottom-0 right-0 flex flex-col gap-8 items-center justify-center">
        <div className="flex items-center gap-[5px]">
          <button
            ref={buttonChangeImage1}
            onClick={() => {
              if (tutorialStep.step === 1) return;
              setTransitionLeft(true);
              setTutorialStep({ step: 1, img: tutorialPhoto1 });
              setTimeRemaining(5000);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <path
                d="M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4Z"
                fill={`${tutorialStep.step === 1 ? "#061425" : "#fff"}`}
              />
            </svg>
          </button>
          <button
            ref={buttonChangeImage2}
            onClick={() => {
              if (tutorialStep.step === 2) return;
              if (tutorialStep.step === 1) setTransitionLeft(true);
              if (tutorialStep.step === 3) setTransitionLeft(false);
              setTutorialStep({ step: 2, img: tutorialPhoto2 });
              setTimeRemaining(5000);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <path
                d="M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4Z"
                fill={`${tutorialStep.step === 2 ? "#061425" : "#fff"}`}
              />
            </svg>
          </button>
          <button
            ref={buttonChangeImage3}
            onClick={() => {
              if (tutorialStep.step === 3) return;
              setTransitionLeft(false);
              setTutorialStep({ step: 3, img: tutorialPhoto3 });
              setTimeRemaining(5000);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <path
                d="M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4Z"
                fill={`${tutorialStep.step === 3 ? "#061425" : "#fff"}`}
              />
            </svg>
          </button>
        </div>
        <p className="md:w-[390px] max-md:w-[270px] font-comfortaa text-white font-medium text-center">
          {tutorialStep.step === 1 &&
            "Get information on the quality of the Air, Land, Rivers and Oceans."}
          {tutorialStep.step === 2 &&
            "This information is provided by satellite images and a brief qualitative report"}
          {tutorialStep.step === 3 &&
            "Alerts are managed and updated in real time via chat."}
        </p>

        <button
          onClick={handleCloseTutorial}
          className="bg-white px-4 py-3 text-center text-azulfy-rich-black font-bold font-comfortaa rounded-[10px] shadow-lg w-[280px]"
        >
          Get Started
        </button>
      </div>
    </div>
  );
}
