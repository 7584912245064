import MapboxDraw from "@mapbox/mapbox-gl-draw";
import staticMode from '@mapbox/mapbox-gl-draw-static-mode';
import * as Dialog from "@radix-ui/react-dialog";
import GenericModal from "components/GenericModal";
import { defaultDrawStyles } from "components/MapComponent/internals/defaultDrawStyles";
import { t } from "i18next";
import { LngLatLike } from "mapbox-gl";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Map, { MapboxGeoJSONPolygon } from "../MapComponent";
import GeoInputRow from "../MapComponent/internals/GeoInputRow";
import MapBasicButtons from "../MapComponent/internals/addOns/components/MapBasicButtons";
import { polygonBuilder } from "../MapComponent/internals/helpers";

interface IGeographicalAreaMapProps {
  geoArea: MapboxGeoJSONPolygon | undefined;
  pointsOfAnalysisPolygons: MapboxGeoJSONPolygon[] | undefined;
  setPointsOfAnalysisPolygons: Dispatch<
    SetStateAction<MapboxGeoJSONPolygon[] | undefined>
  >;
}

export const PointsOfAnalysisMap = ({
  geoArea,
  pointsOfAnalysisPolygons,
  setPointsOfAnalysisPolygons,
}: IGeographicalAreaMapProps) => {
  const [createNewPointOfAnalysisProps, setCreateNewPointOfAnalysisProps] =
    useState<{
      showModal: boolean;
      nameInputValue: string;
    }>({
      showModal: false,
      nameInputValue: "",
    });

  const [showNotification, setShowNotification] = useState(false);

  const mapRef = useRef<mapboxgl.Map | null>(null);
  const drawRef = useRef<MapboxDraw | null>(null);
  const [counter, setCounter] = useState(0)

  const onUpdatePolygon = (feature: MapboxGeoJSONPolygon) => {
    if (!pointsOfAnalysisPolygons) return;
    const requestedIndex = pointsOfAnalysisPolygons?.findIndex(
      (polygon) => polygon.id === feature.id
    );
    if (requestedIndex === -1) return;

    const previousValue = pointsOfAnalysisPolygons;
    previousValue[requestedIndex].geometry.coordinates =
      feature.geometry.coordinates;

    setPointsOfAnalysisPolygons([...previousValue]);
  };

  const [
    tempCreateNewPointOfAnalysisProps,
    setTempCreateNewPointOfAnalysisProps,
  ] = useState<MapboxGeoJSONPolygon | undefined>();

  const onAddPolygon = (feature: MapboxGeoJSONPolygon) => {
    setTempCreateNewPointOfAnalysisProps(feature);
    setCreateNewPointOfAnalysisProps({
      nameInputValue: "",
      showModal: true,
    });
  };

  const confirmPolygonCreation = () => {
    if (!tempCreateNewPointOfAnalysisProps) return;

    setPointsOfAnalysisPolygons((prev) => [
      ...(prev ?? []),
      {
        ...tempCreateNewPointOfAnalysisProps,
        properties: {
          name: createNewPointOfAnalysisProps.nameInputValue,
          type: "PointsOfAnalysis",
        },
      },
    ]);

    setTempCreateNewPointOfAnalysisProps(undefined);
    setCreateNewPointOfAnalysisProps({
      nameInputValue: "",
      showModal: false,
    });
  };

  useEffect(() => {
    if (!mapRef.current || !drawRef.current) return;

    mapRef.current.on("load", () => {
      if (!geoArea?.geometry?.coordinates[0]) return;

      const geoAreaPointsFeatures = geoArea.geometry.coordinates[0].map(
        (point) => ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: point,
          },
        })
      );

      mapRef.current?.addSource("national-park", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: geoArea.geometry.coordinates,
              },
            } as never,
            ...(geoAreaPointsFeatures as never),
          ],
        },
      });

      mapRef.current?.addLayer({
        id: "park-boundary",
        type: "fill",
        source: "national-park",
        paint: {
          "fill-color": "#888888",
          "fill-opacity": 0,
        },
        filter: ["==", "$type", "Polygon"],
      });

      mapRef.current?.addLayer({
        id: "outline",
        type: "line",
        source: "national-park",
        layout: {},
        paint: {
          "line-color": "#0072FF",
          "line-width": 2,
        },
      });

      mapRef.current?.addLayer({
        id: "park-volcanoes",
        type: "circle",
        source: "national-park",
        paint: {
          "circle-radius": 8,
          "circle-color": "#0072FF",
        },
        filter: ["==", "$type", "Point"],
      });

      if(pointsOfAnalysisPolygons){
        //foreach polygon in pointsOfAnalysisPolygons enable draw 
        pointsOfAnalysisPolygons.forEach((polygon) => {
          drawRef.current?.add({
            type: "FeatureCollection",
            features: [{
              ...polygon,
              id: polygon.id
            }] as never,
            
          });
        })
      }

      // pointsOfAnalysisPolygons?.forEach((polygon) => {
      //   mapRef.current?.addSource(polygon.id, {
      //     type: "geojson",
      //     data: {
      //       type: "FeatureCollection",
      //       features: [polygon] as never,
      //     },
      //   });

      //   mapRef.current?.addLayer({
      //     id: polygon.id,
      //     type: "fill",
      //     source: polygon.id,
      //     paint: {
      //       "fill-color": "#0072FF", // Cor de preenchimento do polígono
      //       "fill-opacity": 0.5, // Opacidade do preenchimento
      //     },
      //   });
      // });

    });

    mapRef.current.on("draw.create", (event) => {
      setShowNotification(false);
      // To enable Point or Linestring change this rule
      if (event.features[0].geometry.type !== "Polygon") return;
      onAddPolygon?.(event.features[0]);
    });
  }, [pointsOfAnalysisPolygons, mapRef, drawRef, geoArea]);

  useEffect(() => {
    if (mapRef.current && geoArea && geoArea.geometry) {
      const coordinates = geoArea.geometry.coordinates[0]; // Primeiro anel de coordenadas do polígono
      const center = [
        coordinates.reduce((sum, coord) => sum + coord[0], 0) / coordinates.length, // Média das longitudes
        coordinates.reduce((sum, coord) => sum + coord[1], 0) / coordinates.length, // Média das latitudes
      ];
      const zoom = 11; // Nível de zoom desejado

      mapRef.current.setCenter(center as LngLatLike);
      mapRef.current.setZoom(zoom);
    }
  }, [geoArea, mapRef]);

  useEffect(() => {
    if (!mapRef.current || !drawRef.current || !pointsOfAnalysisPolygons || counter > 0) return

    mapRef.current?.removeControl(drawRef.current as unknown as mapboxgl.Control);

    const letters = pointsOfAnalysisPolygons?.map((item, index) => ({
      id: `custom-point-${index}`,
      filter: ["==", "id", item.id],
      type: "symbol",
      layout: {
        "text-field": String.fromCharCode(65 + index),
        "text-size": 16,
        "text-anchor": "center",
        "text-justify": "auto",
      },
      paint: {
        "text-color": "#0072FF",
      },
    }))

    const modes = MapboxDraw.modes;
    
    modes.static = staticMode;

    const newDraw = new MapboxDraw({
        displayControlsDefault: false,
        styles: [...defaultDrawStyles, ...letters! ],
        modes: modes
    });

    mapRef.current?.addControl(newDraw);

    drawRef.current = newDraw
    drawRef.current.changeMode("static");
    setCounter(prev => prev + 1)
  }, [counter, pointsOfAnalysisPolygons]);

  useEffect(() => {
    if (!mapRef.current || !drawRef.current) return;

    mapRef.current?.on("draw.update", (event) => {
      // To enable Point or Linestring change this rule
      if (event.features[0].geometry.type !== "Polygon") return;

      onUpdatePolygon?.(event.features[0]);
    });

    mapRef.current?.on("draw.delete", (event) => {
      if (event.features[0].geometry.type !== "Polygon") return;

      onDeletePolygon?.(event.features[0]);
    });
  }, [pointsOfAnalysisPolygons, mapRef, drawRef]);

  const onDeletePolygon = (feature: MapboxGeoJSONPolygon) => {
    if (!pointsOfAnalysisPolygons) return;

    setPointsOfAnalysisPolygons(
      pointsOfAnalysisPolygons.filter((polygon) => polygon.id !== feature.id)
    );
  };

  const onConfirmRowDeleteRowCoordinate = (rowIndex: number, index: number) => {
    const selectedPolygon = pointsOfAnalysisPolygons?.[rowIndex];

    // Start and end have points, so count one more point than index
    if (
      selectedPolygon &&
      selectedPolygon?.geometry?.coordinates?.[0]?.length &&
      selectedPolygon?.geometry?.coordinates?.[0]?.length <= 4
    ) {
      setPointsOfAnalysisPolygons(
        pointsOfAnalysisPolygons.filter((_, i) => i !== rowIndex)
      );
      drawRef.current?.delete(selectedPolygon.id);
      return;
    }
    if (
      selectedPolygon &&
      selectedPolygon?.geometry?.coordinates?.[0]?.[index]
    ) {
      const previousValue = pointsOfAnalysisPolygons[rowIndex];
      previousValue.geometry.coordinates[0].splice(index, 1);
      setPointsOfAnalysisPolygons(
        pointsOfAnalysisPolygons.map((polygon, i) => {
          if (i === rowIndex) {
            return {
              ...previousValue,
            };
          }
          return polygon;
        })
      );

      drawRef.current?.delete(previousValue.id);
      drawRef.current?.set(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        polygonBuilder({
          id: previousValue.id,
          coordinates: previousValue.geometry.coordinates,
          properties: previousValue.properties,
        })
      );
    }
  };

  const onConfirmRowCoordinateUpdate = (
    rowIndex: number,
    coordinateIndex: number,
    updatedCoordinate: number[]
  ) => {
    const previousValue = pointsOfAnalysisPolygons?.[rowIndex];
    if (previousValue?.geometry?.coordinates?.[0]?.[coordinateIndex]) {
      previousValue.geometry.coordinates[0][coordinateIndex] =
        updatedCoordinate;

      previousValue.geometry.coordinates[0][
        previousValue.geometry.coordinates[0].length - 1
      ] = updatedCoordinate;

      setPointsOfAnalysisPolygons(
        pointsOfAnalysisPolygons?.map((polygon, i) => {
          if (i === rowIndex) {
            return {
              ...previousValue,
            };
          }

          return polygon;
        })
      );
      drawRef.current?.delete(previousValue.id);
      drawRef.current?.set(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        polygonBuilder({
          id: previousValue.id,
          coordinates: previousValue.geometry.coordinates,
          properties: previousValue.properties,
        })
      );
    }
  };

  const onConfirmNameUpdate = (editIndex: number, name: string) => {
    setPointsOfAnalysisPolygons(
      pointsOfAnalysisPolygons?.map((polygon, index) => {
        if (index === editIndex) {
          return {
            ...polygon,
            properties: {
              ...polygon.properties,
              name: name,
            },
          };
        }
        return polygon;
      })
    );
  };

  return (
    <div className="mapWrapper">
      <Map
        showNotification={showNotification}
        mapRef={mapRef}
        drawRef={drawRef}
      >
        <MapBasicButtons canDraw={false} drawRef={drawRef} mapRef={mapRef} />
        {/* <MapNotification
          setShowNotification={setShowNotification}
          showNotification={showNotification}
        /> */}
      </Map>
      <Dialog.Root open={createNewPointOfAnalysisProps.showModal}>
        <GenericModal
          title={"Creating Region"}
          description={"Please type a name for the created region."}
          handleConfirm={confirmPolygonCreation}
        >
          <input
            value={createNewPointOfAnalysisProps.nameInputValue}
            onChange={(e) => {
              setCreateNewPointOfAnalysisProps({
                ...createNewPointOfAnalysisProps,
                nameInputValue: e.target.value,
              });
            }}
            type="text"
            placeholder="Name"
            className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none"
          />
        </GenericModal>
      </Dialog.Root>
      <div className="py-12">
        {pointsOfAnalysisPolygons && (
          <span className="text-xl font-bold text-azulfy-blue">
            {t('gps_coordinates')}
          </span>
        )}

        <ul className="flex flex-col flex-1 w-full gap-12 m-0 list-none ">
          {pointsOfAnalysisPolygons?.map((polygon, index) => {
            return (
              <PointOfAnalysisRow
                key={index}
                index={index}
                polygon={polygon}
                onConfirmNameUpdate={onConfirmNameUpdate}
                onConfirmRowDeleteRowCoordinate={
                  onConfirmRowDeleteRowCoordinate
                }
                onConfirmRowCoordinateUpdate={onConfirmRowCoordinateUpdate}
                pointsOfAnalysisPolygons={pointsOfAnalysisPolygons} // Passando pointsOfAnalysisPolygons como uma prop
                onDeletePolygon={onDeletePolygon}
                mapRef={mapRef}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const PointOfAnalysisRow = ({
  index,
  polygon,
  // onConfirmNameUpdate,
  onConfirmRowDeleteRowCoordinate,
  onConfirmRowCoordinateUpdate,
  // pointsOfAnalysisPolygons,
  // onDeletePolygon,
  // mapRef,
}: {
  index: number;
  polygon: MapboxGeoJSONPolygon;
  onConfirmNameUpdate: (index: number, name: string) => void;
  onConfirmRowDeleteRowCoordinate: (rowIndex: number, index: number) => void;
  onConfirmRowCoordinateUpdate: (
    rowIndex: number,
    coordinateIndex: number,
    coordinate: number[]
  ) => void;
  pointsOfAnalysisPolygons: MapboxGeoJSONPolygon[] | undefined;
  onDeletePolygon: (polygon: MapboxGeoJSONPolygon) => void;
  mapRef: React.RefObject<mapboxgl.Map>;
}) => {
  const [editNameInput, setEditNameInput] = useState(
    polygon?.properties?.name ?? ""
  );
  const [editOption,] = useState<undefined | "edit" | "delete">(
    undefined
  );

  useEffect(() => {
    setEditNameInput(polygon?.properties?.name ?? "");
  }, [polygon.properties.name]);

  return (
    <div className="flex flex-col items-start gap-5">
      <div className="flex flex-col flex-1 w-full">
        {index === 0 && (
          <div className="flex-1 mb-3">
            <span className="text-base font-bold text-azulfy-blue">{t('name')}</span>
          </div>
        )}
        <div className="flex items-center">
          <div className="flex items-end flex-1 gap-2">
            <span className="pb-2.5 text-base font-bold text-azulfy-blue">
              {String.fromCharCode(65 + index)}
            </span>

            <input
              disabled={!(editOption === "edit")}
              value={editNameInput}
              onChange={(e) => {
                setEditNameInput(e.target.value);
              }}
              type="text"
              placeholder="Ex.: Camera Oeiras"
              className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none"
            />
          </div>
        </div>
      </div>

      <ul className="flex flex-col flex-1 gap-4 p-0 m-0 list-none">
        {polygon?.geometry.coordinates[0]
          .slice(0, polygon?.geometry.coordinates[0].length - 1)
          .map((coordinate, coordsIndex) => {
            return (
              <li key={coordsIndex} className="flex flex-col">
                {index === 0 && coordsIndex === 0 && (
                  <div className="flex mb-3 gap-[22px]">
                    <div className="flex-1">
                      <span className="text-base font-bold text-azulfy-blue">
                        Latitude
                      </span>
                    </div>

                    <div className="flex-1">
                      <span className="text-base font-bold text-azulfy-blue">
                        Longitude
                      </span>
                    </div>
                  </div>
                )}
                <GeoInputRow
                  index={coordsIndex}
                  indexNumber={coordsIndex + 1}
                  coordinate={coordinate}
                  onConfirmUpdate={(coordinateIndex, coordinate) => {
                    onConfirmRowCoordinateUpdate(
                      index,
                      coordinateIndex,
                      coordinate
                    );
                  }}
                  onConfirmDelete={(coordinateIndex) => {
                    onConfirmRowDeleteRowCoordinate(index, coordinateIndex);
                  }}
                />
              </li>
            );
          })}
      </ul>
    </div>
  );
};
