import { ManagersAdminProps } from "hooks/useWorkspaces";
import { useState } from "react";
import { Checkbox } from "rsuite";

type MemberAdminItemProps = {
  manager: ManagersAdminProps
  handleManagerCheckboxChange: (manager: string, checked: boolean) => void
  handleDangerousManagerCheckboxChange: (manager: string, checked: boolean) => void
}

export const MemberAdminItem = ({ manager, handleManagerCheckboxChange, handleDangerousManagerCheckboxChange }: MemberAdminItemProps) => {
  const [checkbox, setCheckbox] = useState(manager.is_dangerous_alerts_manager)

  return (
    <div
      className="col-span-full grid grid-cols-3 items-center gap-x-3 border-b border-backgray py-3 w-full"
      >
      <div className='flex items-center gap-4 font-comfortaa'>
          <span className="w-8 h-8 rounded-full bg-azulfy-rich-black flex items-center justify-center min-w-[32px] text-white text-sm">
            {manager.initials}
          </span>

          <div className="flex flex-col gap-0 break-all">
            <p className='text-base font-bold text-azulfy-rich-black line-clamp-1'>{manager.name}</p>
            <span className='text-xs font-normal text-azulfy-rich-black line-clamp-1'>{manager.email}</span>
            <span className='text-xs font-normal text-azulfy-rich-black line-clamp-1'>{manager.phone}</span>
          </div>
      </div>

      <div className="justify-self-center">
          <Checkbox
            defaultChecked={manager.is_manager}
            onChange={(_, checked) => {
              handleManagerCheckboxChange(manager.uuid, checked)
              setCheckbox(checked)

              if (checked !== checkbox) {
                handleDangerousManagerCheckboxChange(manager.uuid, checked)
              }
            }}
          />
      </div>

      <div className="justify-self-center">
          <Checkbox
            checked={checkbox}
            onChange={(_, checked) => {
              handleDangerousManagerCheckboxChange(manager.uuid, checked)
              setCheckbox(checked)
            }}
          />
      </div>
    </div>
  )
}