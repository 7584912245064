import dayjs from "dayjs";
import { useUser } from "hooks/useUser";
import { formatDate } from "utils/auxFunctions";

interface DayCardProps {
  calendarIndex: number;
  day: moment.Moment;
  selectedDate: {
    startDate: Date;
    endDate: Date;
    label: string;
  } | null;
  setSelectedDate: React.Dispatch<
    React.SetStateAction<{
      startDate: Date;
      endDate: Date;
      label: string;
    } | null>
  >;
}

export default function DayCard({
  calendarIndex,
  day,
  selectedDate,
  setSelectedDate,
}: DayCardProps) {
  const { authUser } = useUser();
  function handleSelectDate() {
    if (selectedDate && selectedDate?.startDate && selectedDate?.endDate) {
      if (
        selectedDate?.startDate.getTime() === selectedDate?.endDate.getTime()
      ) {
        if (day.toDate().getTime() > selectedDate?.startDate?.getTime()) {
          const newSelectedDate = {
            startDate: selectedDate?.startDate,
            endDate: day.toDate(),
            label:
              formatDate(
                selectedDate?.startDate,
                authUser?.user?.language || "en"
              ) +
              " - " +
              formatDate(day.toDate(), authUser?.user?.language || "en"),
          };
          setSelectedDate(newSelectedDate);
        }
      } else {
        setSelectedDate({
          startDate: day.toDate(),
          endDate: day.toDate(),
          label:
            formatDate(day.toDate(), authUser?.user?.language || "en") +
            " - " +
            formatDate(day.toDate(), authUser?.user?.language || "en"),
        });
      }
    } else {
      setSelectedDate({
        startDate: day.toDate(),
        endDate: day.toDate(),
        label:
          formatDate(day.toDate(), authUser?.user?.language || "en") +
          " - " +
          formatDate(day.toDate(), authUser?.user?.language || "en"),
      });
    }
  }

  return (
    <>
      {calendarIndex === 0 && Number(day.format("DD").toString()) > 7 ? (
        <div className="flex items-center justify-center w-[40px] h-[40px] text-azulfy-rich-black text-sm font-medium text-center opacity-40">
          {day.format("DD").toString()}
        </div>
      ) : (
        <>
          {calendarIndex > 3 && Number(day.format("DD").toString()) < 20 ? (
            <div className="flex items-center justify-center w-[40px] h-[40px] text-azulfy-rich-black text-sm font-medium text-center opacity-40">
              {day.format("DD").toString()}
            </div>
          ) : (
            <div className="relative w-[40px] h-[40px]">
              {selectedDate?.startDate &&
                selectedDate?.endDate &&
                day.toDate().getTime() > selectedDate?.startDate.getTime() &&
                day.toDate().getTime() < selectedDate?.endDate.getTime() && (
                  <span className="absolute bg-azulfy-blue bg-opacity-20 w-[44px] h-[40px]" />
                )}
              {selectedDate?.startDate &&
                selectedDate?.endDate &&
                dayjs(selectedDate?.startDate).format("DD-MM-YYYY") ===
                  day.format("DD-MM-YYYY") && (
                  <div>
                    <span className="absolute bg-azulfy-blue bg-opacity-20 left-[15px] w-[29px] h-[40px]" />
                    <span className="absolute bg-azulfy-blue w-[40px] h-[40px] rounded-full" />
                  </div>
                )}
              {selectedDate?.startDate &&
                selectedDate?.endDate &&
                dayjs(selectedDate?.endDate).format("DD-MM-YYYY") ===
                  day.format("DD-MM-YYYY") && (
                  <div>
                    <span className="absolute bg-azulfy-blue bg-opacity-20 right-[15px] w-[25px] h-[40px]" />
                    <span className="absolute bg-azulfy-blue w-[40px] h-[40px] rounded-full" />
                  </div>
                )}
              <button
                onClick={handleSelectDate}
                className={`relative flex flex-col items-center justify-center  text-azulfy-blue text-sm w-full h-full ${
                  selectedDate &&
                  dayjs(selectedDate?.startDate).format("DD-MM-YYYY") ===
                    day.format("DD-MM-YYYY")
                    ? "rounded-full text-white"
                    : ""
                }
            ${
              selectedDate &&
              dayjs(selectedDate?.endDate).format("DD-MM-YYYY") ===
                day.format("DD-MM-YYYY")
                ? "rounded-full text-white"
                : ""
            }
            `}
              >
                <p className="z-20">{day.format("DD").toString()}</p>
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
}
