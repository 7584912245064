import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from 'utils/toast';
// import { uploadToS3 } from 'utils/awsS3';
// import { retrieveImgS3 } from 'utils/auxFunctions';
import { api } from 'services/axios';
import Loading from 'components/Loading';
import { LinearButton } from 'components/LinearButton';
import HeaderAccount from '../components/HeaderAccount';
import { Uploader } from 'rsuite';
import { BsCamera } from 'react-icons/bs';
import { FileType } from 'rsuite/esm/Uploader';

export default function ReportProblem() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disabledButtonSend, setDisabledButtonSend] = useState(true);
  const [description, setDescription] = useState<string>('');
  const [files, setFiles] = useState<FileType[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    setDisabledButtonSend(files.length === 0 || description === '');
  }, [files, description]);

  const handleFileChange = async (filesList: FileType[]) => {
    let imageCount = 0;
    let videoCount = 0;
    let isFileTooLarge = false;

    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];

    const filesToCheck = await Promise.all(filesList.map(async (file) => {
      if (file.blobFile instanceof Blob) {
        return new File([file.blobFile], file.name || "", { type: file.blobFile.type });
      }
      return file.blobFile;
    }));

    if (filesToCheck.length > 6) {
      toast({
        label: t('error'),
        message: t('you_can_only_upload_6_photos_or_a_video'),
        type: 'error',
      });
      setDisabledButtonSend(true);
      return;
    }

    filesToCheck.forEach((file) => {
      const fileName = file?.name.toLowerCase();
      const fileExtension = fileName?.substring(fileName.lastIndexOf('.'));

      if (imageExtensions.includes(fileExtension!)) {
        imageCount++;
      } else if (fileExtension === '.mp4') {
        videoCount++;
      }

      if (file!.size > 102 * 1024 * 1024) {
        isFileTooLarge = true;
      }
    });

    if (isFileTooLarge) {
      toast({
        label: t('error'),
        message: t('the_file_is_larger_than_102mb'),
        type: 'error',
      });
      setDisabledButtonSend(true);
      return;
    }

    if (imageCount > 6 || videoCount > 1) {
      toast({
        label: t('error'),
        message: t('you_can_only_upload_6_photos_or_a_video'),
        type: 'error',
      });
      setDisabledButtonSend(true);
      return;
    }

    setFiles(filesList);
    setDisabledButtonSend(filesList.length === 0 || description === '');
  };

  async function handleSendFeedback() {
    try {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append('description', description);

      for (let i = 0; i < files.length; i++) {
        const file = files[i].blobFile as File;
        formData.append('files[]', file);
      }

      const response = await api.post('/reports', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        toast({
          label: t('success'),
          message: t('report_sent_successfully'),
          type: 'success',
        });
        setIsSubmitting(false);
        setDescription('');
        setFiles([]);
      } else {
        toast({
          label: t('error'),
          message: t('an_error_occurred_while_sending_the_report'),
          type: 'error',
        });
      }
    } catch (error) {
      toast({
        label: t('error'),
        message: t('an_error_occurred_while_sending_the_report'),
        type: 'error',
      });
    }
  }

  return (
    <>
      {isSubmitting ? (
        <Loading />
      ) : (
        <div className="fw-full">
          <HeaderAccount title={t("give_us_your_feedback")} navigateTo="/account" />
          <div className="flex flex-col gap-5 w-full max-w-2xl mx-auto">
            {/* <h1 className="text-xl text-azulfy-rich-black font-semibold">{t('tell_us_your_feedback')}</h1> */}
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={t('write_a_report')}
              className="text-sm text-black-100 font-semibold px-4 py-2 h-[145px] outline-none border border-azulfy-rich-black border-opacity-70 rounded-md resize-none"
            />
            <p className="text-xs">{`${t('add_a_screenshot_or_a_screen_recording_that_shows_us_what_is_happening')} (${t('max_images_6_or_max_video_1')}).`}</p>
            <div className="flex items-center justify-center gap-5">
              <Uploader
                multiple
                listType="picture"
                action=""
                autoUpload={false}
                accept="image/*,video/*"
                onChange={handleFileChange}
              >
                <button>
                  <BsCamera size={30} />
                </button>
              </Uploader>
            </div>
            <span className="flex items-center justify-center w-full mt-5">
              <LinearButton
                disabled={disabledButtonSend}
                onClick={handleSendFeedback}
                text={t('submit')}
              />
            </span>
          </div>
        </div>
      )}
    </>
  );
}
