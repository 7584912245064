import { zodResolver } from "@hookform/resolvers/zod";
import { LinearButton } from "components/LinearButton";
import { useUser } from "hooks/useUser";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "utils/toast";
import * as z from "zod";
import HeaderAccount from "../components/HeaderAccount";

const changePasswordFormSchema = z.object({
  password: z.string(),
  password_confirmation: z.string(),
});

type changePasswordFormInputs = z.infer<typeof changePasswordFormSchema>;

export default function index() {
  const { t } = useTranslation();
  const { updateUser } = useUser();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<changePasswordFormInputs>({
    resolver: zodResolver(changePasswordFormSchema),
  });

  async function handleUpdatePassword(data: changePasswordFormInputs) {
    // if(data.name === "") return
    const response = await updateUser({
      password: data.password,
      password_confirmation: data.password_confirmation,
    });
    if (response) {
      toast({
        label: "Success",
        message: response?.message || "Profile updated successfully",
        type: "success",
      });
      setValue("password", "");
      setValue("password_confirmation", "");
    }
  }

  return (
    <>
      <main>
        <HeaderAccount
          title={t("change_your_password")}
          navigateTo="/account"
        />
        <form
          action=""
          onSubmit={handleSubmit(handleUpdatePassword)}
          className="w-full max-w-2xl mx-auto flex flex-col gap-5 font-comfortaa"
        >
          <div>
            <label htmlFor="" className="font-normal text-xs text-gray-500">
              {t("new_password")}
            </label>
            <input
              {...register("password")}
              type="password"
              name="password"
              id="password"
              required
              className="w-full px-3 py-2 text-azulfy-rich-black border-b border-backgray focus:outline-none"
            />
          </div>
          <div>
            <label htmlFor="" className="font-normal text-xs text-gray-500">
              {t("confirm_new_password")}
            </label>
            <input
              {...register("password_confirmation")}
              type="password"
              name="password_confirmation"
              id="password_confirmation"
              required
              className="w-full px-3 py-2 text-azulfy-rich-black border-b border-backgray focus:outline-none bg-transparent"
            />
          </div>
          <LinearButton
            type="submit"
            text={t("change_password")}
            disabled={isSubmitting}
          />
        </form>
      </main>
    </>
  );
}
