import { useUser } from "hooks/useUser";
import { Navigate, Outlet } from "react-router-dom";

export default function PublicRoutes() {
  const { verifyUser, authUser } = useUser();
  const redirectTo = verifyUser();

  if (redirectTo === "feed") return <Navigate to={`${authUser?.roles === "super_admin" ? "/home" : `/workspace/${authUser?.workspaces.uuid}/home`}`} />;
  if (redirectTo === "create-profile") return <Navigate to="/create-profile" />;

  return <Outlet />;
}
