import { useEffect, useState } from "react";

import { Attachment } from "hooks/useAlerts";

import {
  BsChevronLeft,
  BsChevronRight,
  BsFiletypePdf,
  BsX,
} from "react-icons/bs";
// import { BiRightArrowAlt, BiLeftArrowAlt } from "react-icons/bi";
// import { Document, Page, pdfjs } from "react-pdf";
import * as Dialog from "@radix-ui/react-dialog";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
// import { AiOutlineExport } from "react-icons/ai";

//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface ImagePreviewModalProps {
  attachments: Attachment[];
  index: number;
}

export default function ImagePreviewModal({
  attachments,
  index,
}: ImagePreviewModalProps) {
  const [currentIndex, setCurrentIndex] = useState(index);
  const [media, setMedia] = useState(attachments[index]);
  // const [pageNumber, setPageNumber] = useState(1);
  // const [numPages, setNumPages] = useState(0);
  // const pdfLinkRef = useRef<HTMLAnchorElement | null>(null);

  const handlNextImage = (e: any) => {
    if (e.key === "ArrowRight" && currentIndex < attachments.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    }
    if (e.key === "ArrowLeft" && currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  useEffect(() => {
    setMedia(attachments[currentIndex]);
  }, [currentIndex]);

  // useEffect(() => {
  //   if (getFileType(media.file_name) === "pdf") {
  //     const url = media.file_name;
  //     const loadingTask = pdfjs.getDocument(url);
  //     loadingTask.promise.then((pdf) => {
  //       setNumPages(pdf.numPages);
  //     });
  //   }
  // }, [media]);

  // const handlePageChange = (newPageNumber: number) => {
  //   setPageNumber(newPageNumber);
  // };

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          inset: 0,
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: "50",
        }}
      />
      <Dialog.Content
        forceMount
        onKeyDown={handlNextImage}
        className={
          "z-[100] wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed flex flex-col items-center gap-5 rounded-lg font-comfortaa w-[70vw] md:w-[30vw] max-h-[90vh]"
        }
      >
        <Dialog.Close
          className={
            "z-[101] absolute top-2 right-2 rounded-full bg-error hover:opacity-70"
          }
        >
          <BsX color="#fff" size={20} />
        </Dialog.Close>
        <div className="w-full h-full ">
          {media.file_type === "image" && (
            <img
              className="w-full h-full object-contain max-h-full"
              src={media.file_name}
            />
          )}
          {media.file_type === "video" && (
            <video className="w-full h-full object-cover max-h-[90vh]" controls>
              <source
                src={media.file_name}
                type="video/mp4"
                className="w-full"
              />
              Seu navegador não suporta o elemento de vídeo.
            </video>
          )}
          {media.file_type === "pdf" && (
            // <div className="w-full h-full max-h-full relative rounded-md font-comfortaa">
            //   <Document
            //     file={media.file_name}
            //     className={"flex justify-center relative"}
            //   >
            //     <Page pageNumber={pageNumber} />
            //   </Document>
            //   <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 flex">
            //     <button
            //       onClick={() => handlePageChange(pageNumber - 1)}
            //       disabled={pageNumber <= 1}
            //       className={`bg-azulfy-blue hover:bg-azulfy-blue-hover text-white transition-all w-8 h-8 rounded-l-md flex justify-center items-center ${
            //         pageNumber <= 1 ? "opacity-40 cursor-not-allowed" : ""
            //       }`}
            //     >
            //       <BiLeftArrowAlt size={20} />
            //     </button>
            //     <span className="bg-gray-200 transition-all flex justify-center items-center px-2">
            //       {pageNumber} de {numPages}
            //     </span>
            //     <button
            //       onClick={() => handlePageChange(pageNumber + 1)}
            //       disabled={pageNumber >= numPages}
            //       className={`bg-azulfy-blue hover:bg-azulfy-blue-hover   text-white transition-all w-8 h-8 rounded-r-md flex justify-center items-center ${
            //         pageNumber >= numPages
            //           ? "opacity-40 cursor-not-allowed"
            //           : ""
            //       }`}
            //     >
            //       <BiRightArrowAlt size={20} />
            //     </button>

            //     <a
            //       href={media.file_name}
            //       target="_blank"
            //       className="hover:no-underline flex items-center ml-3 bg-azulfy-blue hover:bg-azulfy-blue-hover p-1 rounded-md absolute left-32 mt-[2px]"
            //     >
            //       <AiOutlineExport size={20} className="text-white" />
            //     </a>
            //   </div>
            // </div>
            <div className="w-full h-full min-w-[30vw] min-h-[70vh] max-h-full relative rounded-md font-comfortaa bg-gray-200 flex items-center justify-center">
              <a
                href={media.file_name}
                target="_blank"
                className="hover:opacity-70"
              >
                <BsFiletypePdf className="text-5xl text-azulfy-blue" />
              </a>
            </div>
          )}
          {currentIndex < attachments.length - 1 && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setCurrentIndex((prev) => prev + 1);
              }}
              className="z-[101] absolute top-[50%] -translate-y-[50%] right-4 rounded-full bg-azulfy-blue p-1 flex items-center justify-center hover:opacity-70"
            >
              <BsChevronRight size={24} color="#fff" />
            </button>
          )}

          {currentIndex > 0 && (
            <button
              onClick={() => setCurrentIndex((prev) => prev - 1)}
              className="z-[101] absolute top-[50%] -translate-y-[50%] left-4 rounded-full bg-azulfy-blue p-1 flex items-center justify-center hover:opacity-70"
            >
              <BsChevronLeft size={24} color="#fff" />
            </button>
          )}
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
