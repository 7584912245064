const classNames = (...classes: any) => classes.filter(Boolean).join(" ");
export default classNames;

export const polygonBuilder = ({
  id,
  coordinates,
  properties,
}: {
  id?: string;
  coordinates?: unknown;
  properties?: unknown;
}) => ({
  type: "FeatureCollection",
  features: [
    {
      id: id,
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: coordinates,
      },
      properties: properties ?? {},
    },
  ],
});
