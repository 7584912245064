import { useEffect, useState } from "react";

import ListAlerts from "./components/ListAlerts";
import NoAlerts from "./components/NoAlerts";
import ShowConversation from "./components/ShowConversation";
import { useSearchParams } from "react-router-dom";
import { useAlerts } from "hooks/useAlerts";
import { useFilters } from "hooks/useFilters";

export default function AlertPage() {
  const [selectedAlert, setSelectedAlert] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { getAllFilters } = useFilters();

  const { alerts, setAlert, getAlertMessages } = useAlerts();

  const [searchParams] = useSearchParams();
  const alertUuid = searchParams.get("alert");

  async function getFilters() {
    await getAllFilters();
  }

  useEffect(() => {
    getFilters();
  }, []);

  async function getAllAlertMessages() {
    setIsLoading(true);
    if (alertUuid) {
      await getAlertMessages(
        alertUuid,
        "?=page=1&sortBy=created_at&sortDirection=desc&take=20"
      );
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (alertUuid) {
      setSelectedAlert(alertUuid);
      getAllAlertMessages();
      const alert = alerts.find((alert) => alert.uuid === alertUuid);
      if (alert) setAlert(alert);
    } else {
      setSelectedAlert(null);
    }
  }, [alerts]);

  return (
    <>
      {/* MOBILE */}
      <div className="hidden max-md:block w-full h-full">
        {selectedAlert ? (
          <ShowConversation
            setSelectedAlert={setSelectedAlert}
            selectedAlert={selectedAlert}
            isLoading={isLoading}
          />
        ) : (
          <ListAlerts
            selectedAlert={selectedAlert}
            setSelectedAlert={setSelectedAlert}
          />
        )}
      </div>
      {/* WEB */}
      <div className="hidden md:grid grid-cols-8 rounded-lg shadow-xl border border-grey-400 border-opacity-30">
        <div className="col-span-2 max-xl:col-span-3 border-r border-[#EDEDED] h-[80vh] max-h-[80vh]">
          <ListAlerts
            selectedAlert={selectedAlert}
            setSelectedAlert={setSelectedAlert}
          />
        </div>
        <div className="col-span-6 max-xl:col-span-5 h-[80vh] max-h-[80vh]">
          {selectedAlert ? (
            <ShowConversation
              setSelectedAlert={setSelectedAlert}
              selectedAlert={selectedAlert}
              isLoading={isLoading}
            />
          ) : (
            <NoAlerts />
          )}
        </div>
      </div>
    </>
  );
}
