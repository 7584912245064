import Map from "components/MapComponent";
import { useEffect } from "react";
import { Trans } from "react-i18next";
import { BsBoundingBoxCircles, BsX } from "react-icons/bs";
import classNames from "../../../helpers";

const MapNotification = ({
  showNotification,
  setShowNotification,
}: {
  showNotification: boolean;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (setShowNotification) setShowNotification(true);
    }, 500);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);
  
  return (
    <Map.Item
      className={classNames(
        "transition-all ease-in-out duration-500",
        showNotification
          ? "top-4 right-4 opacity-100 max-h-full max-w-full"
          : "opacity-0 max-h-0 max-w-0 -right-full top-4"
      )}
    >
      <div className="bg-azulfy-rich-black pl-2 pr-11 py-3 rounded-lg flex w-[340px] items-baseline text-white font-raleway gap-3 relative">
        <div className="flex-1">
          <span>
            <Trans
              i18nKey="move_the_map_to_find_the_desired_area_and_draw_to_mark_it"
              components={{ icon: <BsBoundingBoxCircles className="w-4 h-4 mb-[2px] mx-[2px] inline-flex text-white" /> }}
            />
          </span>
        </div>

        <button
          className="absolute right-4 top-3"
          onClick={() => {
            if (setShowNotification) {
              setShowNotification(false);
            }
          }}
        >
          <BsX className="w-4 h-4 text-white" />
        </button>
      </div>
    </Map.Item>
  );
};

export default MapNotification;
