import { useEffect, useRef, useState } from "react";

import {
  AiOutlinePlus,
  AiOutlineSend,
  AiOutlineClose,
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineOrderedList,
} from "react-icons/ai";
import { BsCameraVideo, BsFiletypePdf, BsImage } from "react-icons/bs";

import { Editor, EditorState, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

import { useTranslation } from "react-i18next";

interface MessageBoxMobileProps {
  handleSendMessage(messageHTML: string): Promise<void>;
  blockStyleFn: (
    contentBlock: any
  ) => "" | "text-center" | "text-left" | "list-decimal" | "list-disc";
  attachmentsInput: React.RefObject<HTMLInputElement>;
  attachments: File[];
  setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
  clear: boolean;
  setClear: React.Dispatch<React.SetStateAction<boolean>>;
  isSubbmitting: boolean;
}

export default function MessageBoxMobile({
  handleSendMessage,
  blockStyleFn,
  attachmentsInput,
  attachments,
  setAttachments,
  clear,
  setClear,
  isSubbmitting,
}: MessageBoxMobileProps) {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const editorRef = useRef<any>(null);

  const { t } = useTranslation();
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);

  function handleKeyCommand(command: string, editorState: EditorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  }

  function updateSendButtonStatus(editorState: EditorState) {
    const content = editorState.getCurrentContent();
    const contentText = content.getPlainText().trim();

    setIsSendButtonDisabled(contentText === "");
  }

  useEffect(() => {
    if (clear) {
      setEditorState(EditorState.createEmpty());
      setAttachments([]);
      setClear(false);
    }
    updateSendButtonStatus(editorState);
  }, [clear, editorState]);

  useEffect(() => {
    if(attachments?.length > 0){
      setIsSendButtonDisabled(false)
    } else {
      setIsSendButtonDisabled(true)
    }
  }, [attachments]);

  return (
    <div className="hidden max-md:flex flex-col mt-auto gap-[10px] py-[10px] border-t border-azulfy-rich-black border-opacity-30">
      {/* <span className="flex items-center justify-center w-full">
        <button>
          <svg
            width="31"
            height="3"
            viewBox="0 0 31 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect opacity="0.5" width="31" height="3" rx="1.5" fill="#061425" />
          </svg>
        </button>
      </span> */}
      <div className="flex flex-col px-4" onClick={focus}>
        <div className="flex-1 bg-white p-2 rounded-md font-raleway max-w-full overflow-auto max-h-[100px] border">
          <Editor
            placeholder={t("write_a_message")}
            ref={editorRef}
            editorState={editorState}
            onChange={setEditorState}
            handleKeyCommand={handleKeyCommand}
            blockStyleFn={blockStyleFn}
          />
        </div>
        <div className="flex gap-3 my-5">
          {attachments?.map((file, index) => {
            let fileIcon;

            if (file.type.startsWith("image/")) {
              fileIcon = <BsImage size={20} color="#00C6FF" />;
            } else if (file.type === "application/pdf") {
              fileIcon = <BsFiletypePdf size={20} color="#00C6FF" />;
            } else if (file.type.startsWith("video/")) {
              fileIcon = <BsCameraVideo size={20} color="#00C6FF" />;
            } else {
              fileIcon = <BsImage size={16} />;
            }

            return (
              <span
                key={index}
                className="w-12 h-12 p-2 rounded-md flex items-center justify-center bg-[#fff] relative"
              >
                {fileIcon}
                <button
                  onClick={() =>
                    setAttachments(attachments.filter((_, i) => i !== index))
                  }
                  className="w-[15px] h-[15px] absolute top-[-5px] right-[-5px] bg-azulfy-blue rounded-full py-1 flex items-center justify-center hover:bg-azulfy-blue-hover active:bg-azulfy-blue-pressed"
                >
                  <AiOutlineClose size={11} color="#fff" />
                </button>
              </span>
            );
          })}
        </div>
        <div className="flex items-center justify-between mt-5">
          <button
            disabled={isSubbmitting}
            onClick={() => attachmentsInput.current?.click()}
            className="w-8 h-8 rounded-full p-1 flex items-center justify-center bg-Azulfy-Linear-Gradient hover:bg-Azulfy-Linear-Gradient-hover active:bg-Azulfy-Linear-Gradient-pressed"
          >
            <AiOutlinePlus size={20} color="#fff" />
          </button>

          <div className="flex items-center gap-[10px] ml-5 mr-auto">
            <button
              disabled={isSubbmitting}
              onClick={(e) => {
                e.preventDefault();
                setEditorState(
                  RichUtils.toggleInlineStyle(editorState, "BOLD")
                );
              }}
              className="hover:opacity-70"
            >
              <AiOutlineBold size={28} color="#061425" />
            </button>
            <button
              disabled={isSubbmitting}
              onClick={(e) => {
                e.preventDefault();
                setEditorState(
                  RichUtils.toggleInlineStyle(editorState, "ITALIC")
                );
              }}
              className="hover:opacity-70"
            >
              <AiOutlineItalic size={28} color="#061425" />
            </button>
            <button
              disabled={isSubbmitting}
              onClick={(e) => {
                e.preventDefault();
                setEditorState(
                  RichUtils.toggleBlockType(editorState, "ordered-list-item")
                );
              }}
              className="hover:opacity-70"
            >
              <AiOutlineOrderedList size={28} color="#061425" />
            </button>
          </div>

          <button
            onClick={() =>
              handleSendMessage(stateToHTML(editorState.getCurrentContent()))
            }
            disabled={isSendButtonDisabled || isSubbmitting}
            className={`w-8 h-8 rounded-full p-1 flex items-center justify-center ${
              isSendButtonDisabled
                ? "opacity-50 cursor-not-allowed bg-azulfy-rich-black"
                : "hover:bg-Azulfy-Linear-Gradient-hover bg-Azulfy-Linear-Gradient active:bg-Azulfy-Linear-Gradient-pressed"
            }`}
          >
            <AiOutlineSend size={20} color="#fff" />
          </button>
        </div>
      </div>
    </div>
  );
}
