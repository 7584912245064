import { useRef } from "react";
import { useTranslation } from "react-i18next";

import * as Dialog from "@radix-ui/react-dialog";

import successIlustration from "../../assets/success.svg"

interface SuccessDeleteCouncilModalProps {
  councilName: string;
  handleSuccess: () => void;
}

export default function SuccessDeleteCouncilModal({
  councilName,
  handleSuccess,
}: SuccessDeleteCouncilModalProps) {
  const { t } = useTranslation();
  const closeButton = useRef<HTMLButtonElement>(null);

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          inset: 0,
          background: "rgba(0, 0, 0, 0.75)",
          zIndex: "16",
        }}
      />
      <Dialog.Content
        forceMount
        className={
          "z-40 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white px-12 py-8 rounded-lg max-md:w-[90vw] font-comfortaa"
        }
      >
        <Dialog.Close ref={closeButton} className={"hidden"}></Dialog.Close>
        <div className="flex flex-col items-center gap-5 max-w-[240px]">
          <img src={successIlustration} className="w-[7.5rem] h-[7.5rem]" alt="delete" />
          <div
            className="flex flex-col gap-1"
          >
            <h1
              className="text-azulfy-blue font-bold text-base text-center"
            >
              {t("success")}!
            </h1>
            <span
              className="text-base font-raleway text-center text-azulfy-rich-black"
            >
              {" "} "{councilName}"{" "}{t("council_has_been_deleted")}
            </span>
          </div>
          <div className="flex flex-col items-center justify-center gap-4 w-full">
            <button
              onClick={() => {
                handleSuccess();
                closeButton.current?.click();
              }}
              className="py-2 px-3 w-full shadow-md rounded-[10px] text-white text-center font-comfortaa text-base  max-md:!text-base font-bold bg-azulfy-blue hover:opacity-80 transition-all active:scale-[0.98] focus-visible:outline-none flex items-center justify-center gap-3"
            >
              {t("continue")}
            </button>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
