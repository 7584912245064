import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as z from "zod";

import { useUser } from "hooks/useUser";
import { zodResolver } from "@hookform/resolvers/zod";

import { InputLogin } from "components/InputLogin";
import { LinearButton } from "components/LinearButton";

import loginLogo from "assets/login-logo.png";

import { toast } from "utils/toast";

const forgetPasswordSchema = z.object({
  email: z.string(),
});

type forgetPasswordInputs = z.infer<typeof forgetPasswordSchema>;

export default function ForgotPassword() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<forgetPasswordInputs>({
    resolver: zodResolver(forgetPasswordSchema),
  });

  const { forgetPassword } = useUser();

  async function handleForgetPassword(data: forgetPasswordInputs) {
    const { email } = data;
    const response = await forgetPassword({ email });
    if (response) {
      toast({
        label: "Success",
        message: response?.message || "Email sent successfully",
        type: "success",
      });
      setValue("email", "");
    }
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen text-azulfy-rich-black font-comfortaa px-10 max-md:pb-20">
      <img
        className="w-[100px] max-md:w-[75px]"
        src={loginLogo}
        alt="Azulfy logo"
      />
      <span className="font-raleway text-lg block max-md:hidden mt-3">
        Let’s Azulfy the world together.
      </span>
      <form
        onSubmit={handleSubmit(handleForgetPassword)}
        className="flex flex-col gap-12 mt-24 max-md:mt-12"
      >
        <div className="flex flex-col">
          <h1 className="text-5xl max-md:text-3xl text-azulfy-rich-black mb-2 text-center">
            Let’s find your account
          </h1>
          <span className="text-center font-raleway text-xl max-md:text-xs">
            We'll email you a code to reset your password.
          </span>
        </div>

        <InputLogin
          {...register("email")}
          required
          icon="email"
          type="email"
          placeholder="E-mail"
        />
        <LinearButton disabled={isSubmitting} type="submit" text="Reset" />
      </form>
      <Link
        to={"/"}
        className="text-xl max-md:text-base font-bold text-azulfy-rich-black text-center mt-7 hover:underline"
      >
        Return to Login
      </Link>
    </div>
  );
}
