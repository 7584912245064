import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useHeader } from "hooks/useHeader";
import { useUser } from "hooks/useUser";

import MessageBox from "./MessageBox";
import logoAzulfy from "../../../../assets/logo-blue.svg";

import Loading from "components/Loading";
import { BsChevronLeft, BsPeopleFill } from "react-icons/bs";

import dayjs from "dayjs";
import { useSupport } from "hooks/useSupport";
import OtherMessage from "./OtherMessage";
import UserMessage from "./UserMessage";
import Permission from "utils/permission";

interface ShowConversationProps {
  selectedSupport: string | null;
  setSelectedSupport: (uuid: string | null) => void;
}

export default function ShowConversation({
  selectedSupport,
}: // setSelectedSupport,
  // isLoading,
  ShowConversationProps) {
  const [isFetching, setIsFetching] = useState(false);
  const { t } = useTranslation();
  const listSupportMessageDiv = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    getSupportMessages,
    supportMessages,
    support,
    unreadMessages,
    supportMessagesPagination,
    setSupport,
    supports,
  } = useSupport();
  const { authUser } = useUser();
  const { windowSize, setShowHeader } = useHeader();

  const size = windowSize();

  const navigate = useNavigate();

  function getQueryParams() {
    const queryParams = new URLSearchParams(window.location.search);
    const workspaceUuid = queryParams.get("workspaceUuid");
    const supportUuid = queryParams.get("supportUuid");
    return { workspaceUuid, supportUuid };
  }

  function getWorkspaceAndSupportUuidFromURL() {
    const { workspaceUuid, supportUuid } = getQueryParams();

    if (!workspaceUuid || !supportUuid) {
      const urlParts = window.location.pathname.split("/");
      const workspaceUuidIndex = urlParts.indexOf("workspace");
      const supportUuidIndex = urlParts.indexOf("support");

      if (workspaceUuidIndex !== -1 && supportUuidIndex !== -1) {
        const extractedWorkspaceUuid = urlParts[workspaceUuidIndex + 1];
        const extractedSupportUuid = urlParts[supportUuidIndex + 1];
        return {
          workspaceUuid: extractedWorkspaceUuid,
          supportUuid: extractedSupportUuid,
        };
      }
    }

    return { workspaceUuid, supportUuid };
  }

  const { workspaceUuid, supportUuid } = getWorkspaceAndSupportUuidFromURL();

  async function getAllSupportMessages() {
    setIsLoading(true);
    if (supportUuid) {
      await getSupportMessages(
        workspaceUuid!,
        supportUuid!,
        "?=page=1&sortBy=created_at&sortDirection=desc&take=10"
      );
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getAllSupportMessages();
    const support = supports.find(
      (support) => support.support?.uuid === supportUuid
    );
    if (support) setSupport(support);
  }, [location, supports, supportUuid]);

  useEffect(() => {
    if (size.width && size.width < 768) {
      setShowHeader(true);
    }
  }, [size]);

  useEffect(() => {
    if (!isLoading && listSupportMessageDiv?.current) {
      const scrollHeight = listSupportMessageDiv.current.scrollHeight;
      const clientHeight = listSupportMessageDiv.current.clientHeight;
      const maxScrollTop = scrollHeight - clientHeight;
      listSupportMessageDiv.current.scrollTop = maxScrollTop;
    }
  }, [isLoading, listSupportMessageDiv]);

  useEffect(() => {
    const onScroll = async () => {
      if (
        listSupportMessageDiv?.current &&
        listSupportMessageDiv?.current?.scrollTop === 0 &&
        supportUuid &&
        !isFetching &&
        supportMessagesPagination.current_page <
        supportMessagesPagination.last_page
      ) {
        setIsFetching(true);
        await getSupportMessages(
          workspaceUuid!,
          `${selectedSupport == undefined ? supportUuid : selectedSupport}`,
          `?sortBy=created_at&sortDirection=desc&page=${supportMessagesPagination.current_page + 1
          }&take=10`,
          true
        );
        listSupportMessageDiv.current.scrollTop += 60;
        setIsFetching(false);
      }
    };

    listSupportMessageDiv?.current?.addEventListener("scroll", onScroll);

    return () =>
      listSupportMessageDiv?.current?.removeEventListener("scroll", onScroll);
  }, [
    supportMessagesPagination,
    listSupportMessageDiv,
    isLoading,
    supportUuid,
    selectedSupport,
    isFetching,
  ]);

  const scrollToBottom = () => {
    if (listSupportMessageDiv.current) {
      listSupportMessageDiv.current.scrollTop = listSupportMessageDiv.current.scrollHeight;
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center h-full w-full">
          <Loading />
        </div>
      ) : (
        <div className={`flex flex-col max-md:py-5 h-full`}>
          <header className="flex flex-col px-6 py-4 gap-4 border-b border-[#EDEDED]">
            {authUser?.roles === "super_admin" ? (
              <div className="flex items-center justify-between max-md:mb-4">
                <div className="flex items-center gap-[10px]">
                  <button onClick={() => navigate(`/support`)}>
                    <BsChevronLeft size={20} className="text-azulfy-blue" />
                  </button>
                  <BsPeopleFill size={20} className="text-azulfy-blue" />
                  <h2 className="text-azulfy-blue max-md:text-azulfy-rich-black font-bold text-base">
                    {support?.name}
                  </h2>
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-between max-md:mb-4">
                <div className="flex items-center gap-[10px]">
                  <img src={logoAzulfy} alt="logo-azulfy" className="w-8" />
                  <h2 className="text-azulfy-rich-black font-bold text-base">
                    Azulfy
                  </h2>
                </div>
              </div>
            )}
          </header>
          <main
            ref={listSupportMessageDiv}
            className="flex flex-col gap-5 p-6 overflow-y-auto h-full"
          >
            {isFetching && (
              <div className="flex items-center justify-center mt-[-20px]">
                <Loading />
              </div>
            )}
            {supportMessages?.length > 0 &&
              supportMessages?.map((message) => {
                const isUserMessage =
                  message.user?.uuid === authUser?.user?.uuid;
                const sendAt = dayjs(message.created_at).format(
                  "DD/MM/YYYY HH:mm"
                );
                if (!isUserMessage) {
                  return (
                    <OtherMessage
                      key={message.uuid}
                      message={message}
                      sendAt={sendAt}
                    />
                  );
                } else {
                  return (
                    <UserMessage
                      key={message.uuid}
                      message={message}
                      sendAt={sendAt}
                    />
                  );
                }
              })}

            {unreadMessages?.length > 0 && (
              <div className="flex items-center w-full gap-[10px]">
                <span className="h-[1px] w-full bg-azulfy-blue" />
                <p className="min-w-[130px] text-xs text-azulfy-blue text-center font-semibold font-raleway">
                  {t("new_messages")}
                </p>
                <span className="h-[1px] w-full bg-azulfy-blue" />
              </div>
            )}

            {unreadMessages?.length > 0 &&
              unreadMessages?.map((message) => {
                const isUserMessage =
                  message.user?.uuid === authUser?.user?.uuid;
                const sendAt = dayjs(message.created_at).format(
                  "DD/MM/YYYY HH:mm"
                );
                if (!isUserMessage) {
                  return (
                    <OtherMessage
                      key={message.uuid}
                      message={message}
                      sendAt={sendAt}
                    />
                  );
                } else {
                  return (
                    <UserMessage
                      key={message.uuid}
                      message={message}
                      sendAt={sendAt}
                    />
                  );
                }
              })}
          </main>
          <Permission permissions={['create message customer support']}>
            <>
              {!support.deleted_at && <MessageBox scrollToBottom={scrollToBottom} />}
            </>
          </Permission>
        </div>
      )}
    </>
  );
}
