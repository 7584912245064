import { useLives } from "hooks/useLives";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { MapboxGeoJSONPolygon } from "components/MapComponent";
import { useWorkspaces } from "hooks/useWorkspaces";
import { PointsOfAnalysisMap } from "./PointsOfAnalysisMap";
import { api } from "services/axios";
import { toast } from "utils/toast";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import { BsChevronLeft } from "react-icons/bs";

export default function EditLiveCam() {
    const { t } = useTranslation();
    const { liveCam, getLiveCamByUuid, deleteLiveCam } = useLives();
    const { getWorkspacesByUuid, workspace } = useWorkspaces();
    const [isLoading, setIsLoading] = useState(true);
    const [geoGraphicalArea, setGeoGraphicalArea] = useState<
        MapboxGeoJSONPolygon | undefined
    >();
    const [pointsOfAnalysisPolygons, setPointsOfAnalysisPolygons] = useState<
        MapboxGeoJSONPolygon[] | undefined
    >();

    const navigate = useNavigate();

    const { uuidWorkspace, uuidLiveCam } = useParams<{ uuidWorkspace: string, uuidLiveCam: string }>()

    const handleGetWorkspacesByUuid = async () => {
        await getWorkspacesByUuid(uuidWorkspace!)
            .finally(() => {
                setIsLoading(false);
            })
    };

    useEffect(() => {
        handleGetWorkspacesByUuid();
    }, []);

    useEffect(() => {
        setGeoGraphicalArea(workspace?.region_map_area);
    }, [workspace?.region_map_area]);

    useEffect(() => {
        if (workspace && workspace.area_points?.length > 0) {
            const extractedPolygons = workspace.area_points.map(
                (item) => item.region_map_area
            );
            setPointsOfAnalysisPolygons(extractedPolygons);
        }
    }, [workspace]);

    async function getLiveCam() {
        await getLiveCamByUuid(uuidWorkspace!, uuidLiveCam!)
    }

    useEffect(() => {
        getLiveCam()
    }, [uuidWorkspace, uuidLiveCam])

    async function handleDeleteLiveCam() {
        await deleteLiveCam(uuidWorkspace!, uuidLiveCam!)
        navigate(`/workspace/${uuidWorkspace}/live-cams`)
        toast({
            label: t('success'),
            message: t('live_cam_deleted_successfully'),
            type: "success",
        })
    }

    return (
        <main className="pb-8 flex flex-col gap-3">
            {isLoading ? (
                <div className="flex justify-center items-center h-[400px]">
                    <Loading />
                </div>
            ) : (
                <>
                    <header
                        className="flex items-center w-full gap-2 py-1 lg:mb-5 capitalize"
                    >
                        <Link to={`/workspace/${uuidWorkspace}`} className={`hidden md:block`}>
                            <BsChevronLeft size={20} className="text-azulfy-blue" />
                        </Link>
                        <p className="text-azulfy-blue text-xl font-bold font-comfortaa">
                            {t('live_cams')}
                        </p>
                    </header>
                    <div className="w-full mb-8 max-w-3xl mx-auto">
                        <ReactPlayer url={liveCam?.url} controls width="100%" />
                    </div>
                    <PointsOfAnalysisMap
                        type="edit"
                        tempPointsOfAnalysis={liveCam}
                        geoArea={geoGraphicalArea}
                        pointsOfAnalysisPolygons={pointsOfAnalysisPolygons}
                        setPointsOfAnalysisPolygons={setPointsOfAnalysisPolygons}
                        onCreateNewWorkspaceVideo={() => {

                        }}
                        onUpdateWorkspaceVideo={async (data) => {
                            try {
                                const response = await api.put(
                                    `/workspace/${uuidWorkspace}/videos/${liveCam?.uuid}`,
                                    {
                                        workspace_id: uuidWorkspace,
                                        name: data?.properties?.name,
                                        url: data?.properties?.urlLiveCam,
                                        region_map_area: JSON.stringify(data),
                                    },
                                    {
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                    }
                                );
                                toast({
                                    label: t('success'),
                                    message: t('live_cam_updated_successfully'),
                                    type: "success",
                                })
                                // @ts-ignore
                                const createdLiveCamUid = response?.data.content?.data?.uuid
                                navigate(`/workspace/${uuidWorkspace}/live-cams`)
                            } catch (error: unknown) {
                                console.error(error);
                            }
                        }}
                        onDeleteLiveCam={() => {
                            handleDeleteLiveCam()
                        }}
                    />
                </>
            )}

        </main>
    )
}
