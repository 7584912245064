import { useTranslation } from 'react-i18next';
import approvalProcessSvg from '../../assets/approval-process.svg'
import { LinearButton } from 'components/LinearButton';
import { useUser } from 'hooks/useUser';

export default function ApprovalProcess() {
    const { t } = useTranslation();
    const { logout } = useUser();

    return (
        <main className='w-full h-screen flex flex-col justify-center items-center font-comfortaa gap-12 px-8'>
            <img src={approvalProcessSvg} alt="Approval Process" className='md:w-96 w-60' />
            <div className='flex flex-col gap-2 items-center text-center'>
                <h1 className='text-azulfy-blue md:text-3xl text-xl font-normal'>
                    {t('your_account_is_in_the_approval_process')}
                </h1>
                <h3 className='text-azulfy-rich-black md:text-base text-sm font-normal'>
                    {t('wait_for_it_to_be_approved_before_you_can_log_in')}
                </h3>
            </div>
            <div className='max-w-xs w-full'>
                <LinearButton text={t('return_to_Login')} onClick={logout} />
            </div>
        </main>
    )
}
