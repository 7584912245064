export const types = [
  { value: 1, label: "Air" },
  { value: 2, label: "Soil" },
  { value: 3, label: "Water" },
];

export const status = [
  { value: 0, label: "closed" },
  { value: 1, label: "new" },
];

export const category = [
  { value: 1, label: "river" },
  { value: 2, label: "sea" },
  { value: 3, label: "water" },
  { value: 4, label: "air" },
];

export const indicator = [
  { value: 1, label: "Cyanobacteria" },
  { value: 2, label: "Faecals+" },
];
