import * as Dialog from "@radix-ui/react-dialog";
import * as Popover from "@radix-ui/react-popover";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import { useTranslation } from "react-i18next";
import {
  BsTrashFill
} from "react-icons/bs";

export default function DeleteAllPopOver({onConfirmDeleteAll}: { onConfirmDeleteAll: () => void }) {
    const { t } = useTranslation();

    return (
      <Popover.Portal>
        <Popover.Content
          align="end"
        >
          <div className="flex flex-col items-center justify-center w-full gap-3">
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <button
                  className="px-3 py-2 rounded-lg shadow-md flex items-center gap-[5px] bg-white text-error hover:opacity-70">
                  <BsTrashFill size={16}/>

                  <span className="font-medium">
                    {t('delete_all')}
                  </span>
                </button>
              </Dialog.Trigger>

              <ConfirmDeleteModal
                title={t('remove_all_analysis_points')}
                description={''}
                handleDelete={onConfirmDeleteAll}
              />
            </Dialog.Root>
          </div>
        </Popover.Content>
      </Popover.Portal>
    );
  }
  