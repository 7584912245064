import { useTranslation } from "react-i18next";
import { AiOutlinePlus, AiOutlineSend } from "react-icons/ai";

export default function MessageBoxClosed() {
    const { t } = useTranslation();
    return (
        <div className="hidden md:flex flex-col mt-auto gap-3 bg-grey-600 px-6 py-3 w-full max-w-full">
            <div className="flex items-center justify-between gap-3 w-full">
                <button
                    disabled
                    className="w-8 h-8 rounded-full p-1 flex items-center justify-center bg-Azulfy-Linear-Gradient opacity-20"
                >
                    <AiOutlinePlus size={20} color="#fff" />
                </button>
                <p className="text-xs text-azulfy-rich-black opacity-50">{t("alert_closed_no_new_messages_allowed")}</p>
                <button
                    disabled
                    className="w-8 h-8 rounded-full p-1 flex items-center justify-center bg-Azulfy-Linear-Gradient opacity-20"
                >
                    <AiOutlineSend size={20} color="#fff" />
                </button>
            </div>
        </div>
    )
}
