import { create } from "zustand";

import { api } from "services/axios";

import { toast } from "utils/toast";
import { showErrorMessage } from "../utils/auxFunctions";

import axios from "axios";
import i18n from "i18next";

interface License {
  uuid: string;
  name: string;
  members: number;
  color: string;
}

export interface User {
  uuid: string;
  name: string;
  email: string;
  language: string;
  password?: string;
  password_confirmation?: string;
  password_expires_at?: string;
  initials?: string;
  accepted_invite?: number;
}

export interface Workspaces {
  uuid: string;
  name: string;
  admin_email: string;
  admin_name: string;
  license_type: string;
  license: License;
  lat_center: number;
  lng_center: number;
  total_users?: number;
  pivot: {
    user_id: number;
    workspace_id: number;
  };
  total_alerts_managers_ids?: number;
}

export interface Support {
  uuid: string;
  workspace_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface AzulfyAuthUser {
  user: User | null;
  token: string | null;
  roles: string;
  permissions: string[];
  isAuthenticated: boolean;
  passwordExpired: boolean;
  workspaces: Workspaces;
  support: Support | null;
  error?: number;
}

interface useUserProps {
  authUser: AzulfyAuthUser | null;
  login: (dataToPost: {
    email: string;
    password: string;
  }) => Promise<AzulfyAuthUser | null>;
  forgetPassword: (dataToPost: { email: string }) => Promise<any>;
  verifyUser: () => "create-profile" | "feed" | "login" | "pending" | "invite-request";
  resetPassword: (dataToPost: {
    email: string;
    password: string;
    password_confirmation: string;
    token: string;
  }) => Promise<any>;
  updateUser: (partial: Partial<User>) => Promise<any>;
  renewPassword: (dataToPost: {
    password: string;
    password_confirmation: string;
  }) => Promise<any>;
  logout: () => void;
}

export const useUser = create<useUserProps>((set, get) => ({
  authUser: localStorage.getItem("azulfyAuthUser")
    ? JSON.parse(localStorage.getItem("azulfyAuthUser")!)
    : null,

  login: async (dataToPost: { email: string; password: string }) => {
    try {
      const { data } = await api.post("/auth/login", dataToPost);
      if (data?.content?.data) {
        const user = {
          uuid: data.content.data?.user?.uuid || "",
          name: data.content.data?.user?.name || "",
          email: data.content.data?.user?.email || "",
          language: data.content.data?.user?.language || "",
          initials: data.content.data?.user?.initials || "",
          password_expires_at:
            data.content.data?.user?.password_expires_at || "",
          accepted_invites: data.content.data?.user?.accepted_invites || 0,
        } as User;
        const azulfyAuthUser = {
          user,
          token: data.content.data?.token || "",
          roles: data.content.data?.roles || "",
          workspaces: data.content.data?.workspaces || {},
          support: data.content.data?.support || null,
          permissions: data.content.data?.permissions || [],
          isAuthenticated: true,
          passwordExpired: false,
          error: data.content.data?.error || 0,
        } as AzulfyAuthUser;
        localStorage.setItem("azulfyAuthUser", JSON.stringify(azulfyAuthUser));
        set({ authUser: azulfyAuthUser });
        api.defaults.headers.common["Authorization"] =
          "Bearer " + data.content.data?.token;
        i18n.changeLanguage(data.content.data?.user?.language || "pt");
        return azulfyAuthUser;
      }
      return null;
    } catch (error: any) {
      if (error?.response?.status === 422) {
        toast({
          label: "Error",
          message: "Invalid email or password",
          type: "error",
        });
        return null;
      }
      showErrorMessage(error);
      return null;
    }
  },

  forgetPassword: async (dataToPost: { email: string }) => {
    try {
      const { data } = await api.post("/auth/forget-password", dataToPost);
      return data.content;
    } catch (error: any) {
      showErrorMessage(error);
      return null;
    }
  },

  verifyUser: () => {
    const authUser = localStorage.getItem("azulfyAuthUser")
      ? (JSON.parse(localStorage.getItem("azulfyAuthUser")!) as AzulfyAuthUser)
      : null;

    if (authUser && authUser?.isAuthenticated) {
      if (authUser.error === 161) {
        return "invite-request";
      } else if (!authUser?.user?.name) {
        return "create-profile";
      } else {
        return "feed";
      }
    }

    return "login";
  },

  resetPassword: async (dataToPost: {
    email: string;
    password: string;
    password_confirmation: string;
    token: string;
  }) => {
    try {
      const { data } = await api.post("/auth/reset-password", dataToPost);
      return data.content;
    } catch (error: any) {
      showErrorMessage(error);
      return null;
    }
  },

  renewPassword: async (dataToPost: {
    password: string;
    password_confirmation: string;
  }) => {
    try {
      const userAuth = get().authUser;

      if (!userAuth) {
        return;
      }

      const { password, password_confirmation } = dataToPost;

      const { data } = await api.post(`/password/expired`, {
        password,
        password_confirmation,
      });
      return data.content;
    } catch (error: any) {
      showErrorMessage(error);
      return null;
    }
  },

  updateUser: async (partial: Partial<User>) => {
    try {
      const userAuth = get().authUser;

      if (!userAuth) {
        return;
      }

      const { data } = await api.put(`/users/${userAuth.user?.uuid}`, partial);
      userAuth.user = data.content.data;
      localStorage.setItem("azulfyAuthUser", JSON.stringify(userAuth));
      set({ authUser: userAuth });
      return data.content;
    } catch (error: any) {
      showErrorMessage(error);
      return;
    }
  },

  logout: () => {
    try {
      const baseUrl = import.meta.env.VITE_API_BASEPATH
      axios.post(`${baseUrl}/auth/logout`, null, {
        headers: {
          Authorization: "Bearer " + get().authUser?.token,
        },
      });
      const azulfyAuthUser = {
        user: null,
        token: "",
        roles: "",
        workspaces: {} as Workspaces,
        permissions: [],
        isAuthenticated: false,
        passwordExpired: false,
        support: null,
      } as AzulfyAuthUser;
      localStorage.setItem("azulfyAuthUser", JSON.stringify(azulfyAuthUser));
      set({ authUser: azulfyAuthUser });
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
}));