import Map from "components/MapComponent";
import { MutableRefObject, useEffect, useState } from "react";
import classNames from "../../../helpers";
import RasterTypeImgPreview2 from "./raster-imgs/previews/raster-type-2.png";
import RasterTypeImgPreview3 from "./raster-imgs/previews/raster-type-3.png";
import { Alert } from "hooks/useAlerts";
import { useFilters } from "hooks/useFilters";

const MapRasterFilter = ({
  mapRef,
  alerts,
}: {
  mapRef: MutableRefObject<mapboxgl.Map | null>;
  alerts: Alert[] | null;
}) => {
  const [darkV11Enabled, setDarkV11Enabled] = useState(true);
  const [showRasterPreviewFilter, setShowRasterPreviewFilter] = useState(false);

  const { setMapStyle } = useFilters();

  //@ts-ignore
  const filteredAlertsWithImage = alerts?.filter((alert) => {
    if (alert.alert_images.length > 0) {
      return true;
    }
    return false;
  });

  useEffect(() => {
    if (!mapRef.current) return;
    if (filteredAlertsWithImage && filteredAlertsWithImage.length === 0) {
      setTimeout(() => {
        if (mapRef.current) {
          const map = mapRef.current;

          map.getStyle().layers?.forEach((layer) => {
            if (layer.id.endsWith("-layer")) {
              map.removeLayer(`${layer.id}`);
            }
          });

          Object.keys(map.getStyle().sources || {}).forEach((source) => {
            if (source.endsWith('-source')) {
              map.removeSource(`${source}`);
            }
          });
        }
      }, 100)
    }
  }, [filteredAlertsWithImage, mapRef]);

  useEffect(() => {
    if (filteredAlertsWithImage && filteredAlertsWithImage.length > 0 && !darkV11Enabled) {
      filteredAlertsWithImage?.forEach((alert) => {
        alert.alert_images.forEach((img) => {
          const bl = img.geo_coordinates.bl;
          const br = img.geo_coordinates.br;
          const tl = img.geo_coordinates.tl;
          const tr = img.geo_coordinates.tr;

          if (mapRef.current?.getLayer(`${img.id}-layer`)) {
            mapRef.current?.removeLayer(`${img.id}-layer`);
          }
          if (mapRef.current?.getSource(`${img.id}-source`)) {
            mapRef.current?.removeSource(`${img.id}-source`);
          }

          mapRef.current?.addSource(`${img.id}-source`, {
            type: "image",
            url: img.url,
            coordinates: [
              [tl[0], tl[1]],
              [tr[0], tr[1]],
              [br[0], br[1]],
              [bl[0], bl[1]],
            ],
          });

          mapRef.current?.addLayer({
            id: `${img.id}-layer`,
            type: "raster",
            source: `${img.id}-source`,
          })
        })
      })
    }
  }, [mapRef, filteredAlertsWithImage, darkV11Enabled]);

  return (
    <Map.Item
      className={classNames(
        "flex flex-row-reverse items-end select-none bottom-14 md:bottom-4 right-4",
        showRasterPreviewFilter && "gap-5"
      )}
    >
      <button
        title="Filter"
        className="grid w-12 h-12 bg-white shadow-lg rounded-xl place-items-center"
        onClick={() => setShowRasterPreviewFilter(!showRasterPreviewFilter)}
      >
        <MapIconSvg />
      </button>
      <div
        className={classNames(
          "flex p-0 m-0  gap-5  transition-all duration-500  ease-in-out max-w-0 max-h-0 overflow-hidden list-none ",
          showRasterPreviewFilter && "!h-fit !w-fit  max-w-none max-h-none"
        )}
      >
        <RasterPreview
          previewImg={RasterTypeImgPreview2}
          onClick={() => {
            if (darkV11Enabled) {
              setMapStyle('dark');
              mapRef.current?.setStyle('mapbox://styles/mapbox/dark-v11');

              mapRef.current?.on('style.load', () => {
                filteredAlertsWithImage?.forEach((alert) => {
                  alert.alert_images.forEach((img) => {
                    const bl = img.geo_coordinates.bl;
                    const br = img.geo_coordinates.br;
                    const tl = img.geo_coordinates.tl;
                    const tr = img.geo_coordinates.tr;

                    if (mapRef.current?.getLayer(`${img.id}-layer`)) {
                      mapRef.current?.removeLayer(`${img.id}-layer`);
                    }
                    if (mapRef.current?.getSource(`${img.id}-source`)) {
                      mapRef.current?.removeSource(`${img.id}-source`);
                    }

                    mapRef.current?.addSource(`${img.id}-source`, {
                      type: "image",
                      url: img.url,
                      coordinates: [
                        [tl[0], tl[1]],
                        [tr[0], tr[1]],
                        [br[0], br[1]],
                        [bl[0], bl[1]],
                      ],
                    });

                    mapRef.current?.addLayer({
                      id: `${img.id}-layer`,
                      type: "raster",
                      source: `${img.id}-source`,
                    })
                  })
                })
              });
              setShowRasterPreviewFilter(false);
              setDarkV11Enabled(false);
            }
          }}
        />

        <RasterPreview
          previewImg={RasterTypeImgPreview3}
          onClick={() => {
            setMapStyle('light');
            mapRef.current?.setStyle('mapbox://styles/mapbox/streets-v12');
            //remove source and layer
            setTimeout(() => {
              filteredAlertsWithImage?.forEach((alert) => {
                alert.alert_images.forEach((img) => {
                  mapRef.current?.removeLayer(`${img.id}-layer`);
                  mapRef.current?.removeSource(`${img.id}-source`);
                })
              })
            }, 100);
            setShowRasterPreviewFilter(false);
            setDarkV11Enabled(true);
          }}
        />
      </div>
    </Map.Item>
  );
};
export default MapRasterFilter;

interface IRasterPreviewProps extends React.HTMLAttributes<HTMLButtonElement> {
  previewImg: string;
}
const RasterPreview = ({ previewImg, ...props }: IRasterPreviewProps) => {
  return (
    <button
      {...props}
      className="border border-[#B7B7B5] rounded-md w-[78px] h-16 overflow-hidden outline-none"
    >
      <img
        draggable={false}
        src={previewImg}
        alt="raster image type 1"
        className="w-full h-full"
      />
    </button>
  );
};

const MapIconSvg = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.181641 4.15933C0.181641 3.93855 0.296073 3.73355 0.483995 3.61767L5.96305 0.239066C6.21744 0.0822029 6.54528 0.265203 6.54528 0.564063V17.8371C6.54528 18.0579 6.43084 18.2629 6.24292 18.3788L2.12239 20.9197C1.27444 21.4426 0.181641 20.8326 0.181641 19.8364V4.15933ZM15.4544 4.15933C15.4544 3.93855 15.5688 3.73355 15.7567 3.61767L19.8773 1.07678C20.7252 0.553906 21.818 1.16391 21.818 2.16011V17.4819C21.818 17.9235 21.5891 18.3335 21.2133 18.5652L16.0366 21.7574C15.7822 21.9143 15.4544 21.7313 15.4544 21.4324V4.15933ZM13.8793 3.61767C14.0672 3.73355 14.1816 3.93855 14.1816 4.15933V21.4324C14.1816 21.7313 13.8538 21.9143 13.5994 21.7574L8.12036 18.3788C7.93244 18.2629 7.818 18.0579 7.818 17.8371V0.564063C7.818 0.265203 8.14584 0.0822029 8.40023 0.239066L13.8793 3.61767Z"
        fill="#0072FF"
      />
    </svg>
  );
};
