import { LinearButton } from "components/LinearButton";
import Loading from "components/Loading";
// import { SearchInput } from "components/SearchInput";
import { useLicenses } from "hooks/useLicenses";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronRight } from "react-icons/bs";

import { useNavigate } from "react-router-dom";

export default function ListLicenses() {
  const [isLoading, setIsLoading] = useState(true);
  // const [isSearching, setIsSearching] = useState(false);
  // const [search, setSearch] = useState<string>("");
  //   const [page, setPage] = useState<{
  //     current: number;
  //     next: any[];
  //     previous: any[];
  //   }>();

  const { t } = useTranslation();
  //   const { getAllWorkspaces, workspaces, pagination } = useWorkspaces();
  const { getAllLicenses, licenses } = useLicenses();
  const navigate = useNavigate();

  async function getLicenses() {
    // await getAllLicenses(
    //   "?sortBy=created_at&sortDirection=desc&page=1&take=10"
    // );
    await getAllLicenses();
    setIsLoading(false);
  }

  useEffect(() => {
    getLicenses();
  }, []);

  //   useEffect(() => {
  //     var current = 0;
  //     var next = [];
  //     var previous = [];
  //     const MAX_PAGE = 2;
  //     if (pagination) {
  //       for (
  //         let i = pagination.current_page;
  //         i <= pagination.current_page + MAX_PAGE;
  //         i++
  //       ) {
  //         if (i > pagination.last_page) break;
  //         if (pagination.current_page === i) {
  //           current = i;
  //         }
  //         if (pagination.current_page < i) {
  //           next.push(i);
  //         }
  //       }
  //       for (
  //         let i = pagination.current_page;
  //         i >= pagination.current_page - MAX_PAGE;
  //         i--
  //       ) {
  //         if (i <= 0) break;
  //         if (pagination.current_page > i) {
  //           previous.unshift(i);
  //         }
  //       }
  //       if (pagination.current_page > MAX_PAGE + 1) {
  //         previous.unshift("...");
  //         previous.unshift(1);
  //       }
  //       if (pagination.last_page - pagination.current_page > MAX_PAGE + 1) {
  //         next.push("...");
  //         next.push(pagination.last_page);
  //       }
  //     }
  //     setPage({ current: current, next: next, previous: previous });
  //   }, [pagination]);

  //   async function handleSelectPage(selectedPage: number) {
  //     setIsLoading(true);
  //     await getAllLicenses(
  //       `?sortBy=created_at&sortDirection=desc&page=${selectedPage}&take=10`
  //     );
  //     setIsLoading(false);
  //   }

  // useEffect(() => {
  //     const delayDebounce = setTimeout(async () => {
  //         if (!isSearching) return;
  //         const currentPage = pagination.current_page;
  //         if (search) {
  //             await getAllLicenses(
  //                 `?sortBy=created_at&sortDirection=desc&page=${currentPage}&take=10&search=${search}`
  //             );
  //         } else {
  //             await getAllLicenses(
  //                 `?sortBy=created_at&sortDirection=desc&page=${currentPage}&take=10`
  //             );
  //         }
  //         setIsSearching(false);
  //     }, 300);
  //     return () => clearTimeout(delayDebounce);
  // }, [search, isSearching, pagination]);

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-[400px]">
          <Loading />
        </div>
      ) : (
        <div className="flex flex-col gap-8 h-full relative">
          <header className="flex max-md:flex-col gap-y-3 items-center justify-between max-md:justify-center">
            <h1 className="text-azulfy-blue text-xl max-md:text-base font-bold">
              {t("manage_licenses")}
            </h1>

            {/* <SearchInput
                            placeholder={`${t("search_license")}...`}
                            onChange={(e) => {
                                setIsSearching(true);
                                setSearch(e.target.value);
                            }}
                            value={search}
                            showSearchIcon
                            classNamesDiv="w-[300px]"
                        /> */}
          </header>

          <div className="flex max-md:justify-center">
            <h2 className="text-azulfy-blue md:text-2xl text-base font-bold">{t("licenses")}</h2>
          </div>

          <main className="w-full max-h-[600px] overflow-y-auto">
            <table className="text-azulfy-rich-black font-bold font-comfortaa w-full">
              <thead>
                <tr className="border-b border-grey-700 border-opacity-20">
                  <th className="text-start py-2 px-1 md:text-base text-xs">
                    {t("license")}
                  </th>

                  <th className="text-start py-2 px-1 md:text-base text-xs">
                    {t("number_of_members")}
                  </th>
                </tr>
              </thead>

              <tbody>
                {licenses?.map((license) => {
                  return (
                    <tr
                      key={license?.uuid}
                      className="border-b border-grey-700 border-opacity-20 hover:opacity-70 font-normal cursor-pointer"
                      onClick={() => {
                        navigate(`/licenses/${license?.uuid}`);
                      }}
                    >
                      <td className="py-5 px-1 text-sm md:text-base break-all capitalize">
                        {license?.name}
                      </td>

                      <td className="py-5 px-1 text-sm md:text-base break-all">
                        {license?.members}
                      </td>

                      <td className="w-4">
                        <button className="hover:opacity-70">
                          <BsChevronRight
                            size={16}
                            className="text-azulfy-rich-black "
                          />
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </main>

          <div className="flex flex-col ml-auto gap-3 pb-6">
            {/* {licenses?.length > 0 && (
              <footer className="flex text-base text-azulfy-rich-black justify-center items-center gap-3 py-2">
                {pagination && (
                  <>
                    {page?.previous && page?.previous?.length > 0 && (
                      <button
                        disabled={page?.previous?.length === 0}
                        onClick={() =>
                          handleSelectPage((page?.current || 2) - 1)
                        }
                        className="font-raleway hover:opacity-70 disabled:opacity-50"
                      >
                        {t("prev")}
                      </button>
                    )}

                    {page?.previous.map((page) => {
                      return (
                        <button
                          onClick={() => handleSelectPage(page)}
                          className="border border-[#F1F1F1] flex items-center justify-center rounded-md font-raleway w-8 h-8 hover:opacity-70"
                          key={page}
                        >
                          {page}
                        </button>
                      );
                    })}
                    <button
                      className={`hidden ${page?.previous && page?.previous?.length > 0 && `!flex`
                        } ${page?.next && page?.next?.length > 0 && `!flex`
                        } bg-azulfy-blue hover:bg-azulfy-blue-hover active:bg-azulfy-blue-pressed text-white flex items-center justify-center rounded-md font-raleway w-8 h-8`}
                    >
                      {page?.current}
                    </button>
                    {page?.next.map((page) => {
                      if (typeof page === "string") {
                        return (
                          <span
                            className="px-[10px]"
                            key={page}
                          >
                            {page}
                          </span>
                        );
                      } else {
                        return (
                          <button
                            onClick={() => handleSelectPage(page)}
                            className="border border-[#F1F1F1] flex items-center justify-center rounded-md font-raleway w-8 h-8 hover:opacity-70"
                            key={page}
                          >
                            {page}
                          </button>
                        );
                      }
                    })}
                    {page?.next && page?.next?.length > 0 && (
                      <button
                        disabled={page?.next?.length === 0}
                        onClick={() =>
                          handleSelectPage((page?.current || 0) + 1)
                        }
                        className="font-raleway hover:opacity-70 disabled:opacity-50"
                      >
                        {t('next')}
                      </button>
                    )}
                  </>
                )}
              </footer>
            )} */}
            <span className="md:w-[300px] max-md:flex-1 max-md:w-full">
              <LinearButton
                textSize="16"
                text={`+ ${t("new_license")}`}
                onClick={() => {
                  navigate("/licenses/create");
                }}
              />
            </span>
          </div>
        </div>
      )}
    </>
  );
}
