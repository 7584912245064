import * as Dialog from "@radix-ui/react-dialog";

import type { AlertMessage } from "hooks/useAlerts";

import { BsCameraVideo, BsFiletypePdf, BsImage } from "react-icons/bs";
import ImagePreviewModal from "../AttachmentModal";

interface OtherMessageProps {
  message: AlertMessage;
  sendAt: string;
}

export default function OtherMessage({ message, sendAt }: OtherMessageProps) {
  return (
    <div key={message.uuid} className="flex flex-col w-full">
      <div className="flex items-start w-full gap-3">
        <span className="w-8 h-8 rounded-full bg-azulfy-rich-black flex items-center justify-center min-w-[32px] text-white">
          {message?.user?.initials}
        </span>
        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-5 justify-between">
            <h2 className="font-raleway text-azulfy-rich-black text-sm font-bold">
              {message?.user?.name}
            </h2>
            <span className="font-raleway text-xs text-azulfy-rich-black">
              {sendAt}
            </span>
          </div>
          <div className="bg-[#EDEDED] rounded-lg p-3 flex flex-col gap-3">
            <span
              className="text-azulfy-rich-black text-sm font-raleway"
              dangerouslySetInnerHTML={{
                __html: message?.message,
              }}
            />
            {message?.attachments && message?.attachments.length > 0 && (
              <div key={message.uuid} className="flex flex-wrap gap-x-[10px] gap-y-[10px] items-center">
                {message?.attachments.map((attachment, index) => {
                  const fileName = attachment.file_name || "";
                  let icon;

                  if (
                    fileName.endsWith(".jpg") ||
                    fileName.endsWith(".png") ||
                    fileName.endsWith(".jpeg")
                  ) {
                    icon = <BsImage size={20} color="#00C6FF" />;
                  } else if (fileName.endsWith(".pdf")) {
                    icon = <BsFiletypePdf size={20} color="#00C6FF" />;
                  } else if (
                    fileName.endsWith(".mp4") ||
                    fileName.endsWith(".avi") ||
                    fileName.endsWith(".mov")
                  ) {
                    icon = <BsCameraVideo size={20} color="#00C6FF" />;
                  } else {
                    icon = <BsImage size={20} color="#00C6FF" />;
                  }
                  return (
                    <Dialog.Root key={attachment.uuid}>
                      <Dialog.Trigger asChild>
                        <button className="bg-white w-12 h-12 flex items-center justify-center">
                          {icon}
                        </button>
                      </Dialog.Trigger>
                      <ImagePreviewModal
                        attachments={message?.attachments}
                        index={index}
                      />
                    </Dialog.Root>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
