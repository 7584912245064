import { Progress } from "rsuite";

import { useTranslation } from "react-i18next";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { classNames } from "utils";

interface ProgressHeaderProps {
  step: number;
  unableNext: boolean;
  handlePrevious: () => void;
  handleNext: () => void;
  handleCreateNewWorkspace(): Promise<void>;
  className?: string
}

export default function ProgressHeader({
  step,
  unableNext,
  handlePrevious,
  handleNext,
  handleCreateNewWorkspace,
  className
}: ProgressHeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  return (
    <header className={classNames(className, "md:pb-16 pb-8")}>
      <div className="flex justify-between items-center w-full">
        <button
          className="hover:opacity-80"
          onClick={step === 16 ? () => navigate(`/home`) : handlePrevious}
        >
          <BsChevronLeft size={20} className="text-azulfy-blue" />
        </button>
        <span className="font-comfortaa text-base font-bold text-azulfy-blue">
          {step === 16 ? "1" : step === 32 ? "2" : step === 48 ? "3" : step === 64 ? "4" : step === 80 ? "5" : "6"}
          /6
        </span>
        {step === 96 ? (
          <button
            className={`font-comfortaa text-xl text-azulfy-blue font-bold hover:opacity-80`}
            onClick={handleCreateNewWorkspace}
          >
            {t("finish")}
          </button>
        ) : (
          <button
            className={`font-comfortaa text-xl text-azulfy-blue font-bold ${
              unableNext ? "opacity-30" : "hover:opacity-80"
            }`}
            onClick={handleNext}
            disabled={unableNext}
          >
            {t("next")}
          </button>
        )}
      </div>
      <Progress.Line percent={step} showInfo={false} strokeColor="#0072FF" />
    </header>
  );
}
