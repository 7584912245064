import * as Dialog from "@radix-ui/react-dialog";

import type { AlertMessage } from "hooks/useAlerts";
import { useTranslation } from "react-i18next";

import Loading from "components/Loading";
import { BsCameraVideo, BsFiletypePdf, BsImage } from "react-icons/bs";
import ImagePreviewModal from "../AttachmentModal";

interface UserMessageProps {
  message: AlertMessage;
  sendAt: string;
}

export default function UserMessage({ message, sendAt }: UserMessageProps) {
  const { t } = useTranslation();
  return (
    <div
      key={message.uuid}
      className={`flex items-start justify-end w-full gap-3 ${
        message?.isTemporary && "opacity-50"
      }`}
    >
      <div className={`flex flex-col gap-3`}>
        <div className="flex items-center gap-5 justify-between">
          {message?.isTemporary && (
            <div className="flex items-center justify-center w-5 h-5">
              <Loading />
            </div>
          )}
          <span className="font-raleway text-xs text-azulfy-blue">
            {sendAt}
          </span>
          <h2 className="font-raleway text-azulfy-blue text-sm font-bold">
            {t("you")}
          </h2>
        </div>
        <div className="bg-[#3B95FF] rounded-lg p-3 flex flex-col gap-3">
          <span
            className="text-white text-sm font-raleway"
            dangerouslySetInnerHTML={{
              __html: message?.message,
            }}
          />
          {message?.attachments && message?.attachments.length > 0 && (
            <div key={message?.uuid} className="flex flex-wrap gap-x-[10px] gap-y-[10px] items-center">
              {message?.attachments.map((attachment, index) => {
                let icon;

                if (attachment.file_type === 'image') {
                  icon = <BsImage size={20} color="#00C6FF" />;
                } else if (attachment.file_type === 'pdf') {
                  icon = <BsFiletypePdf size={20} color="#00C6FF" />;
                } else if (attachment.file_type === 'video') {
                  icon = <BsCameraVideo size={20} color="#00C6FF" />;
                } else {
                  icon = <BsImage size={20} color="#00C6FF" />;
                }
                
                if (attachment?.isTemporary) {
                  return (
                    <button className="bg-white w-12 h-12 flex items-center justify-center">
                      {icon}
                    </button>
                  );
                } else {
                  return (
                    <Dialog.Root key={attachment.uuid}>
                      <Dialog.Trigger asChild>
                        <button className="bg-white w-12 h-12 flex items-center justify-center">
                          {icon}
                        </button>
                      </Dialog.Trigger>
                      <ImagePreviewModal
                        attachments={message?.attachments}
                        index={index}
                      />
                    </Dialog.Root>
                  );
                }
              })}
            </div>
          )}
        </div>
      </div>
      <span className="w-8 h-8 rounded-full bg-[#3B95FF] flex items-center justify-center min-w-[32px] text-white">
        {message?.user?.initials}
      </span>
    </div>
  );
}
