import { useUser } from "hooks/useUser";
import { Navigate } from "react-router-dom";

export default function RedirectTo() {
  const { verifyUser, authUser } = useUser();
  const redirectTo = verifyUser();

  if (redirectTo === "login") return <Navigate to="/login" />;
  else if (redirectTo === "create-profile")
    return <Navigate to="/create-profile" />;

  return <Navigate to={`${authUser?.roles === "super_admin" ? "/home" : `/workspace/${authUser?.workspaces.uuid}/home`}`} />;
}
