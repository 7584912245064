import { create } from "zustand";
import { api } from "services/axios";
import { showErrorMessage } from "../utils/auxFunctions";
import type { AzulfyAuthUser, User } from "./useUser";

import { v4 as uuid } from "uuid";
import { Workspace } from "./useWorkspaces";

interface Pagination {
  current_page: number;
  last_page: number;
  total: number;
  last_page_url: string | null;
}

interface AlertImageProps {
  alert_id: number;
  algorithm_type: string;
  geo_coordinates: any;
  id: number;
  url: string;
}

export interface Alert {
  uuid: string;
  name: string;
  description: string;
  status: {
    name: {
      en: string;
      pt: string;
    }
    uuid: string;
    type: string;
  };
  indicator: number;
  indicators: {
    description: string;
    filter_id: number;
    id: number;
    name: string;
    uuid: string;
    image: string;
  }
  alert_datetime: string;
  alert_data: {
    date: Date;
    time: Date;
    unit: string;
    alert: string;
    value: number;
    source: string;
    // lat: number;
    location: string;
    // lon: number;
    source_url: string;
  };
  severity: number;
  lat: string;
  lng: string;
  messages_new: number;
  category: number;
  total_unread_messages: number;
  deleted_at?: string;
  satellite: {
    description: string;
    name: string;
    id: number;
  }
  alert_images: AlertImageProps[];
}

export interface Attachment {
  uuid: string;
  file_name: string;
  file_type: string;
  created_at: Date;
  updated_at: Date;
  isTemporary?: boolean;
}

export interface AlertMessage {
  uuid: string;
  message: string;
  created_at: Date;
  updated_at: Date;
  user: User;
  read: number;
  attachments: Attachment[];
  isTemporary?: boolean;
  type?: string;
}

export interface membersWorkspaceByTypeProps {
  user: {
    uuid: string;
    name: string;
    initials: string;
  };
  selected: boolean;
}

interface useAlertsProps {
  alerts: Alert[];
  alert: Alert;
  alertSelectedHome: string | null;
  alertStatus: Alert;
  alertMessages: AlertMessage[];
  unreadMessages: AlertMessage[];
  alertsFiltered: Alert[] | null;
  alertsPagination: Pagination;
  alertMessagesPagination: Pagination;
  membersWorkspaceByTypeManager: membersWorkspaceByTypeProps[];
  membersWorkspaceByTypeObserver: membersWorkspaceByTypeProps[];
  selectedAlert: string | null;
  setSelectedAlert: (alert: string | null) => void;
  setAlertsFiltered: (alerts: Alert[] | null) => void;
  setAlertSelectedHome: (alert: string | null) => void;
  getAlerts: (params?: string, concat?: boolean) => Promise<Alert[] | null>;
  getAlertMessages: (
    alertUuid: string,
    params?: string,
    concat?: boolean
  ) => Promise<any>;
  getAlertByUuid: (alertUuid: string) => Promise<Alert | null>;
  setAlert: (alert: Alert) => void;
  setAlerts: (alerts: Alert[]) => void;
  deleteAlert: (alertUuid: string) => Promise<any>;
  createNewAlertMessage: (
    alertUuid: string,
    obtToPost: { message: string; attachments: File[] }
  ) => Promise<any>;
  updateAlert: (alertUuid: string, partial: Partial<Alert>) => Promise<any>;
  updateAlertStatus: (workspaceUuid: string, alertUuid: string, jsonData: object) => Promise<any>;
  getAllMembersofWorkspaceByTypeManager: (
    workspaceUuid: string,
    alertUuid: string,
    type: string,
    params?: string
  ) => Promise<any>;
  getAllMembersofWorkspaceByTypeObserver: (
    workspaceUuid: string,
    alertUuid: string,
    type: string,
    params?: string
  ) => Promise<any>;
  updateAlertManager: (
    workspaceUuid: string,
    alertUuid: string,
    jsonData: object
  ) => Promise<Workspace>;
  updateAlertObserver: (
    workspaceUuid: string,
    alertUuid: string,
    jsonData: object
  ) => Promise<any>;
}

export const useAlerts = create<useAlertsProps>((set, get) => ({
  alerts: [],
  alertMessages: [],
  unreadMessages: [],
  membersWorkspaceByTypeManager: [] as membersWorkspaceByTypeProps[],
  membersWorkspaceByTypeObserver: [] as membersWorkspaceByTypeProps[],
  alertsPagination: {
    current_page: 1,
    last_page: 1,
    total: 1,
    last_page_url: null,
  },
  alertMessagesPagination: {
    current_page: 1,
    last_page: 1,
    total: 1,
    last_page_url: null,
  },
  alert: {} as Alert,
  alertSelectedHome: null,
  alertStatus: {} as Alert,
  alertsFiltered: null,
  selectedAlert: null,
  setSelectedAlert(alert) {
    set({ selectedAlert: alert });
  },
  setAlertsFiltered(alerts: Alert[] | null) {
    set({ alertsFiltered: alerts });
  },
  setAlertSelectedHome(alert) {
    set({ alertSelectedHome: alert });
  },

  getAlerts: async (params?: string, concat?: boolean) => {
    const authUser = localStorage.getItem("azulfyAuthUser")
      ? (JSON.parse(localStorage.getItem("azulfyAuthUser")!) as AzulfyAuthUser)
      : null;

    if (!authUser) return;

    try {
      const { data } = await api.get(
        `/workspace/${authUser?.workspaces.uuid}/alerts` +
          (params ? params : "")
      );

      if (concat) {
        set({ alerts: [...get().alerts, ...data.content.data.data] });
      } else {
        set({ alerts: data.content.data.data });
      }
      set({
        alertsPagination: {
          current_page: data.content.data.current_page,
          last_page: data.content.data.last_page,
          total: data.content.data.total,
          last_page_url: data.content.data.last_page_url,
        },
      });
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
      return null;
    }
  },

  getAlertMessages: async (
    alertUuid: string,
    params?: string,
    concat?: boolean
  ) => {
    const authUser = localStorage.getItem("azulfyAuthUser")
      ? (JSON.parse(localStorage.getItem("azulfyAuthUser")!) as AzulfyAuthUser)
      : null;

    if (!authUser) return;

    try {
      const { data } = await api.get(
        `/workspace/${authUser?.workspaces.uuid}/alerts/${alertUuid}/messages` +
          (params ? params : "")
      );
      if (!data?.content?.data) return;
      const messages = data.content.data.read.data;
      if (concat) {
        if (get().unreadMessages?.length > 0) {
          set({
            unreadMessages: [],
          });
        }
        set({
          alertMessages: [...messages.reverse(), ...get().alertMessages],
        });
      } else {
        set({ alertMessages: messages.reverse() });
        set({ unreadMessages: data.content.data.unread.data });
      }
      set({
        alertMessagesPagination: {
          current_page: data.content.data.read.current_page,
          last_page: data.content.data.read.last_page,
          total: data.content.data.read.total,
          last_page_url: data.content.data.read.last_page_url,
        },
      });
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
      return null;
    }
  },

  getAlertByUuid: async (alertUuid: string) => {
    const authUser = localStorage.getItem("azulfyAuthUser")
      ? (JSON.parse(localStorage.getItem("azulfyAuthUser")!) as AzulfyAuthUser)
      : null;

    if (!authUser) return;

    try {
      const { data } = await api.get(
        `/workspace/${authUser?.workspaces.uuid}/alerts/${alertUuid}`
      );
      set({ alert: data.content.data });
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
      return null;
    }
  },

  setAlert: (alert: Alert) => {
    set({ alert });
  },

  setAlerts: (alerts: Alert[]) => {
    set({ alerts });
  },

  deleteAlert: async (alertUuid: string) => {
    const authUser = localStorage.getItem("azulfyAuthUser")
      ? (JSON.parse(localStorage.getItem("azulfyAuthUser")!) as AzulfyAuthUser)
      : null;

    if (!authUser) return;

    try {
      const { data } = await api.delete(
        `/workspace/${authUser?.workspaces.uuid}/alerts/${alertUuid}`
      );
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
      return null;
    }
  },

  createNewAlertMessage: async (
    alertUuid: string,
    obtToPost: { message: string; attachments: File[] }
  ) => {
    const authUser = localStorage.getItem("azulfyAuthUser")
      ? (JSON.parse(localStorage.getItem("azulfyAuthUser")!) as AzulfyAuthUser)
      : null;

    if (!authUser) return;

    try {
      const tempUuid = uuid();
      const newAlertMessages = get().alertMessages;
      set({ unreadMessages: [] });
      if (obtToPost.message && obtToPost.attachments) {
        const temporaryAttachments = [] as Attachment[];
        obtToPost.attachments.map((attachment) => {
          temporaryAttachments.push({
            uuid: uuid(),
            file_name: attachment.name,
            file_type: attachment.type,
            created_at: new Date(),
            updated_at: new Date(),
            isTemporary: true,
          });
        });
        const newMessage: AlertMessage = {
          uuid: tempUuid,
          message: obtToPost.message,
          attachments: temporaryAttachments,
          created_at: new Date(),
          updated_at: new Date(),
          read: 1,
          type: "new_message",
          user: authUser.user!,
          isTemporary: true,
        };
        newAlertMessages.push(newMessage);
        set({ alertMessages: newAlertMessages });
      }
      const { data } = await api.post(
        `/workspace/${authUser?.workspaces.uuid}/alerts/${alertUuid}/messages`,
        obtToPost,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (data?.content?.data) {
        const index = newAlertMessages.findIndex(
          (message) => message.uuid === tempUuid
        );
        if (index >= 0) {
          newAlertMessages[index] = data.content.data;
          set({ alertMessages: newAlertMessages });
        } else {
          newAlertMessages.push(data.content.data);
          set({ alertMessages: newAlertMessages });
        }
      }
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
      return null;
    }
  },

  updateAlert: async (alertUuid: string, partial: Partial<Alert>) => {
    const authUser = localStorage.getItem("azulfyAuthUser")
      ? (JSON.parse(localStorage.getItem("azulfyAuthUser")!) as AzulfyAuthUser)
      : null;

    if (!authUser) return;

    try {
      const { data } = await api.put(
        `/workspace/${authUser?.workspaces.uuid}/alerts/${alertUuid}`,
        partial
      );
      if (data?.content) {
        const newAlerts = get().alerts;
        const newAlert = get().alert;
        const index = newAlerts.findIndex((alert) => alert.uuid === alertUuid);

        if (newAlert) set({ alert: { ...newAlert, ...partial } });
        if (index >= 0) {
          newAlerts[index] = { ...newAlerts[index], ...partial };
          set({ alerts: newAlerts });
        }
        return data.content.data;
      }
      return null;
    } catch (error: any) {
      showErrorMessage(error);
      return null;
    }
  },
  updateAlertStatus: async (workspaceUuid: string, alertUuid: string, jsonData: object) => {
    try {
      const requestBody = JSON.stringify(jsonData);
      const { data } = await api.put(
        `/workspace/${workspaceUuid}/alerts/${alertUuid}/status`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return data.content.data;
    } catch (error: any) {
      console.error(error);
    }
  },
  getAllMembersofWorkspaceByTypeManager: async (
    workspaceUuid: string,
    alertUuid: string,
    type: string,
    params?: string
  ) => {
    try {
      const { data } = await api.get(
        `/workspace/${workspaceUuid}/alerts/${alertUuid}/${type}/users` + (params ? params : "")
      );
      set({ membersWorkspaceByTypeManager: data.content.data });
      return data.content.data.data;
    } catch (error: any) {
      console.error(error);
    }
  },
  getAllMembersofWorkspaceByTypeObserver: async (
    workspaceUuid: string,
    alertUuid: string,
    type: string,
    params?: string
  ) => {
    try {
      const { data } = await api.get(
        `/workspace/${workspaceUuid}/alerts/${alertUuid}/${type}/users` + (params ? params : "")
      );
      set({ membersWorkspaceByTypeObserver: data.content.data });
      return data.content.data.data;
    } catch (error: any) {
      console.error(error);
    }
  },
  updateAlertManager: async (
    workspaceUuid: string,
    alertUuid: string,
    jsonData: object
  ) => {
    try {
      const requestBody = JSON.stringify(jsonData);
      const { data } = await api.put(
        `/workspace/${workspaceUuid}/alert/${alertUuid}/users-manager`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return data.content.data;
    } catch (error: any) {
      console.error(error);
    }
  },
  updateAlertObserver: async (
    workspaceUuid: string,
    alertUuid: string,
    jsonData: object
  ) => {
    try {
      const requestBody = JSON.stringify(jsonData);
      const { data } = await api.put(
        `/workspace/${workspaceUuid}/alert/${alertUuid}/users-observers`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return data;
    } catch (error: any) {
      console.error(error);
    }
  },
}));
