import { useRef } from "react";
import { useTranslation } from "react-i18next";

import * as Dialog from "@radix-ui/react-dialog";

interface ConfirmDeleteModalProps {
  title: string;
  name: string;
  email: string;
  role: string;
  handleDelete: () => void;
}

export default function ConfirmDeleteMemberModal({
  title,
  name,
  email,
  role,
  handleDelete,
}: ConfirmDeleteModalProps) {
  const { t } = useTranslation();
  const closeButton = useRef<HTMLButtonElement>(null);

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          inset: 0,
          background: "rgba(0, 0, 0, 0.75)",
          zIndex: "16",
        }}
      />
      <Dialog.Content
        forceMount
        className={
          "z-40 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white px-12 py-8 rounded-lg font-comfortaa"
        }
      >
        <Dialog.Close ref={closeButton} className={"hidden"}></Dialog.Close>
        <div className="flex flex-col gap-5">
          <h1 className="text-azulfy-blue font-bold text-base">{title}</h1>
          <div className="flex flex-col items-center">
            <p className="text-sm text-azulfy-rich-black font-raleway flex gap-1 flex-col md:flex-row text-center">
              {t('name')}: <span className="font-bold">{name}</span>
            </p>
            <p className="text-sm text-azulfy-rich-black font-raleway flex gap-1 flex-col md:flex-row text-center">
              {t('email')}: <span className="font-bold">{email}</span>
            </p>
            <p className="text-sm text-azulfy-rich-black font-raleway flex gap-1 flex-col md:flex-row text-center">
              {t('role')}: <span className="font-bold">{role}</span>
            </p>
          </div>
          <div className="flex items-center justify-center gap-4">
            <button
              onClick={() => closeButton.current?.click()}
              className="flex items-center justify-center py-2 px-3 rounded-md bg-primary-200 font-bold bg-opacity-20 hover:opacity-70 text-azulfy-rich-black"
            >
              {t("cancel")}
            </button>
            <button
              onClick={handleDelete}
              className="flex items-center justify-center text-white font-bold py-2 px-3 rounded-md bg-Azulfy-Linear-Gradient hover:bg-Azulfy-Linear-Gradient-hover active:bg-Azulfy-Linear-Gradient-pressed"
            >
              {t("yes_delete")}
            </button>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
