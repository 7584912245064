import loginLogo from "assets/login-logo.png";
import { InputLogin } from "components/InputLogin";
import { LinearButton } from "components/LinearButton";

export default function CreateProfile() {
  return (
    <div className="flex flex-col justify-center items-center h-screen text-azulfy-rich-black font-comfortaa px-10 max-md:pb-20">
      <img
        className="w-[100px] max-md:w-[75px]"
        src={loginLogo}
        alt="Azulfy logo"
      />
      <span className="font-raleway text-lg block max-md:hidden mt-3">
        Let’s Azulfy the world together.
      </span>
      <div className="flex flex-col gap-12 mt-12">
        <div className="flex flex-col">
          <h1 className="text-5xl max-md:text-3xl text-azulfy-rich-black mb-2 text-center md:w-[570px]">
            Welcome!
          </h1>
          <span className="text-center font-raleway text-xl max-md:text-xs">
            Let’s create your profile
          </span>
        </div>

        <div className="flex flex-col gap-5">
          <InputLogin type="text" placeholder="Name" />
        </div>
        <LinearButton text="Continue" />
      </div>
    </div>
  );
}
