import ToastContainer from "components/Toast/ToastContainer";
import { RouterProvider } from "react-router-dom";
import { routes } from "routes/app.routes";

function App() {
  return (
    <>
      <ToastContainer />
      <RouterProvider router={routes} />
    </>
  );
}

export default App;
