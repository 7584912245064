import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { toast } from "utils/toast";

import MessageBoxMobile from "./Mobile";
import MessageBoxDesktop from "./Desktop";
import { useSupport } from "hooks/useSupport";

interface MessageBoxProps {
  scrollToBottom: () => void;
}

const blockStyleFn = (contentBlock: any) => {
  const type = contentBlock.getType();
  if (type === "center") {
    return "text-center";
  }
  if (type === "left") {
    return "text-left";
  } else if (type === "ordered-list-item") {
    return "list-decimal";
  } else if (type === "unordered-list-item") {
    return "list-disc";
  }
  return "";
};

export default function MessageBox({ scrollToBottom }: MessageBoxProps) {
  const [attachments, setAttachments] = useState<File[]>([]);
  const [isSubbmitting, setIsSubmitting] = useState(false);
  const [clear, setClear] = useState(false);

  const { t } = useTranslation();
  const { createNewSupportMessage } = useSupport();

  const attachmentsInput = useRef<HTMLInputElement>(null);

  async function handleUploadImages(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files) {
      if (attachments.length + files?.length > 10) {
        toast({
          label: t("error"),
          message: t("max_10_attachments"),
          type: "error",
        });
        return;
      }

      const onlyImagesVideosOrPdf = Array.from(files).filter(
        (file) =>
          (file.type.includes("image") ||
            file.type.includes("video") ||
            file.type.includes("pdf")) &&
          file.size < 102400 * 1024
      );

      if (onlyImagesVideosOrPdf.length !== files.length) {
        toast({
          label: t("error"),
          message: t("only_images_videos_or_pdf"),
          type: "error",
        });
      }

      setAttachments([...attachments, ...onlyImagesVideosOrPdf]);
    }
  }

  function getQueryParams() {
    const queryParams = new URLSearchParams(window.location.search);
    const workspaceUuid = queryParams.get("workspaceUuid");
    const supportUuid = queryParams.get("supportUuid");
    return { workspaceUuid, supportUuid };
  }

  function getWorkspaceAndSupportUuidFromURL() {
    const { workspaceUuid, supportUuid } = getQueryParams();

    if (!workspaceUuid || !supportUuid) {
      const urlParts = window.location.pathname.split("/");
      const workspaceUuidIndex = urlParts.indexOf("workspace");
      const supportUuidIndex = urlParts.indexOf("support");

      if (workspaceUuidIndex !== -1 && supportUuidIndex !== -1) {
        const extractedWorkspaceUuid = urlParts[workspaceUuidIndex + 1];
        const extractedSupportUuid = urlParts[supportUuidIndex + 1];
        return { workspaceUuid: extractedWorkspaceUuid, supportUuid: extractedSupportUuid };
      }
    }

    return { workspaceUuid, supportUuid };
  }

  const { workspaceUuid, supportUuid } = getWorkspaceAndSupportUuidFromURL();

  async function handleSendMessage(messageHTML: string) {
    if (messageHTML || attachments.length > 0) {
      setIsSubmitting(true);
      setClear(true);
      await createNewSupportMessage(workspaceUuid!, supportUuid!, {
        message: messageHTML,
        attachments,
      });
      scrollToBottom();
      setIsSubmitting(false);
    } else {
      toast({
        label: t("error"),
        message: t("message_is_empty"),
        type: "error",
      });
    }
  }

  useEffect(() => {
    setClear(true);
  }, [alert]);

  return (
    <>
      {/* MOBILE */}
      <MessageBoxMobile
        blockStyleFn={blockStyleFn}
        handleSendMessage={handleSendMessage}
        attachmentsInput={attachmentsInput}
        attachments={attachments}
        setAttachments={setAttachments}
        clear={clear}
        setClear={setClear}
        isSubbmitting={isSubbmitting}
      />

      {/* DESKTOP */}
      <MessageBoxDesktop
        blockStyleFn={blockStyleFn}
        handleSendMessage={handleSendMessage}
        attachmentsInput={attachmentsInput}
        attachments={attachments}
        setAttachments={setAttachments}
        clear={clear}
        setClear={setClear}
        isSubbmitting={isSubbmitting}
      />
      <input
        id="choose-file"
        className="hidden"
        ref={attachmentsInput}
        accept="image/*, video/*, .pdf"
        multiple
        onChange={(e) => handleUploadImages(e)}
        type="file"
      />
    </>
  );
}
