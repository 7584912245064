import { LinearButton } from "components/LinearButton";
import Loading from "components/Loading";
import { useLives } from "hooks/useLives";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCameraVideo, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import Permission from "utils/permission";

export default function LiveCams() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { uuidWorkspace } = useParams<{ uuidWorkspace: string }>()
    const { liveCams, getAllLivesCams } = useLives();

    const hasPermissionEditLiveCam = Permission({
        permissions: ['edit workspace video'],
        children: <></>,
    })

    async function handleGetAllLivesCams(workspaceUuid: string) {
        setIsLoading(true);
        await getAllLivesCams(workspaceUuid);
        setIsLoading(false);
    }

    useEffect(() => {
        handleGetAllLivesCams(uuidWorkspace!);
    }, [uuidWorkspace])

    return (
        <main>
            <header
                className="flex items-center w-full gap-2 py-1 lg:mb-5 capitalize"
            >
                <Link to={`/workspace/${uuidWorkspace}`} className={`hidden md:block`}>
                    <BsChevronLeft size={20} className="text-azulfy-blue" />
                </Link>
                <p className="text-azulfy-blue text-xl font-bold font-comfortaa">
                    {t('live_cams')}
                </p>
            </header>
            {isLoading ? (
                <div className="flex justify-center items-center h-[400px]">
                    <Loading />
                </div>
            ) : (
                <div className="flex flex-col">
                    <Permission permissions={['create workspace video']}>
                        <div className="md:w-60 w-full mb-6">
                            <LinearButton text={`+ ${t('create_a_new_live_cam')}`} textSize="16" onClick={() => navigate(`/workspace/${uuidWorkspace}/live-cams/create-live-cam`)} />
                        </div>
                    </Permission>
                    {liveCams?.map((liveCam) => (
                        <Link key={liveCam.uuid} to={hasPermissionEditLiveCam ? `/workspace/${uuidWorkspace}/live-cams/${liveCam.uuid}/edit-live-cam` : ``} className="hover:no-underline">
                            <div className={`flex items-center justify-between py-3 border-b border-backgray ${!hasPermissionEditLiveCam ? 'cursor-not-allowed' : 'hover:cursor-pointer hover:opacity-80'}`}>
                                <div className="flex items-center gap-3">
                                    <div className="bg-azulfy-blue rounded-full p-1">
                                        <BsCameraVideo size={20} className="text-white" />
                                    </div>
                                    <p className="font-raleway capitalize text-base font-normal text-azulfy-rich-black">
                                        {liveCam.name}
                                    </p>
                                </div>
                                <BsChevronRight size={12} className="text-azulfy-rich-black" />
                            </div>
                        </Link>
                    ))}
                </div>
            )}

        </main>
    )
}
