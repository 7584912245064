import { useTranslation } from "react-i18next";
import pageNotFoundImg from "../../assets/page-not-found.svg";
import Header from "components/Header";

export default function PageNotFound() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center gap-5  h-screen w-screen overflow-x-hidden">
      <Header />
      <img src={pageNotFoundImg} alt="Page not found" className="w-72" />
      <h1 className="text-3xl text-azulfy-blue font-comfortaa font-bold">
        {t('404_error')}!
      </h1>
      <h3 className="text-lg text-azulfy-rich-black font-raleway">
        {t('page_not_found')}.
      </h3>
    </div>
  );
}
