import { BsChevronRight, BsFillFileEarmarkTextFill, BsFillFlagFill, BsLockFill, BsPersonFill, BsQuestionCircleFill, BsShieldFillCheck } from "react-icons/bs";
import { HiTranslate } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUser } from "hooks/useUser";

export default function Account() {
    const { t } = useTranslation();
    const { authUser } = useUser();
    return (
        <>
            <main className="w-full flex flex-col font-comfortaa">
                <Link
                    to={authUser?.roles === "super_admin" ? "/account/profile" : `/workspace/${authUser?.workspaces?.uuid}/account/profile`}
                    className="flex justify-between items-center py-3 border-b-[0.1px] border-solid border-backgray"
                >
                    <span className="flex gap-4 items-center font-normal text-base text-azulfy-rich-black hover:opacity-80">
                        <BsPersonFill size={24} className="text-azulfy-blue" />
                        {t('profile')}
                    </span>
                    <BsChevronRight size={12} className="text-azulfy-rich-black" />
                </Link>
                <Link
                    to={authUser?.roles === "super_admin" ? "/account/change-password" : `/workspace/${authUser?.workspaces?.uuid}/account/change-password`}
                    className="flex justify-between items-center py-3 border-b-[0.1px] border-solid border-backgray"
                >
                    <span className="flex gap-4 items-center font-normal text-base text-azulfy-rich-black hover:opacity-80">
                        <BsLockFill size={24} className="text-azulfy-blue" />
                        {t('change_your_password')}
                    </span>
                    <BsChevronRight size={12} className="text-azulfy-rich-black" />
                </Link>
                <Link
                    to={authUser?.roles === "super_admin" ? "/account/language" : `/workspace/${authUser?.workspaces?.uuid}/account/language`}
                    className="flex justify-between items-center py-3 border-b-[0.1px] border-solid border-backgray"
                >
                    <span className="flex gap-4 items-center font-normal text-base text-azulfy-rich-black hover:opacity-80">
                        <HiTranslate size={24} className="text-azulfy-blue" />
                        {t('language')}
                    </span>
                    <BsChevronRight size={12} className="text-azulfy-rich-black" />
                </Link>
                <Link
                    to="#"
                    className="flex justify-between items-center py-3 border-b-[0.1px] border-solid border-backgray"
                >
                    <span className="flex gap-4 items-center font-normal text-base text-azulfy-rich-black hover:opacity-80">
                        <BsQuestionCircleFill size={24} className="text-azulfy-blue" />
                        {t('faq')}
                    </span>
                    <BsChevronRight size={12} className="text-azulfy-rich-black" />
                </Link>
                <Link
                    to={authUser?.roles === "super_admin" ? "/account/report-problem" : `/workspace/${authUser?.workspaces?.uuid}/account/report-problem`}
                    className="flex justify-between items-center py-3 border-b-[0.1px] border-solid border-backgray"
                >
                    <span className="flex gap-4 items-center font-normal text-base text-azulfy-rich-black hover:opacity-80">
                        <BsFillFlagFill size={24} className="text-azulfy-blue" />
                        {t('give_us_your_feedback')}
                    </span>
                    <BsChevronRight size={12} className="text-azulfy-rich-black" />
                </Link>
                <Link
                    to={"/term-of-service"}
                    target="_blank"
                    className="flex justify-between items-center py-3 border-b-[0.1px] border-solid border-backgray"
                >
                    <span className="flex gap-4 items-center font-normal text-base text-azulfy-rich-black hover:opacity-80">
                        <BsFillFileEarmarkTextFill size={24} className="text-azulfy-blue" />
                        {t('terms_of_service')}
                    </span>
                    <BsChevronRight size={12} className="text-azulfy-rich-black" />
                </Link>
                <Link
                    to="/privacy-policy"
                    target="_blank"
                    className="flex justify-between items-center py-3 border-b-[0.1px] border-solid border-backgray"
                >
                    <span className="flex gap-4 items-center font-normal text-base text-azulfy-rich-black hover:opacity-80">
                        <BsShieldFillCheck size={24} className="text-azulfy-blue" />
                        {t('privacy_policy')}
                    </span>
                    <BsChevronRight size={12} className="text-azulfy-rich-black" />
                </Link>
            </main>
        </>
    )
}