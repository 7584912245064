import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ShowConversation from "./components/ShowConversation";
import { useSupport } from "hooks/useSupport";
import ListSupports from "./components/ListSupports";
import NoSupports from "./components/NoSupports";
import { useUser } from "hooks/useUser";

export default function SupportPage() {
  const { authUser } = useUser();
  const [selectedSupport, setSelectedSupport] = useState<string | null>(null);
  const [, setIsLoading] = useState(false);

  const { supports, setSupport, getSupportMessages } = useSupport();

  const [searchParams] = useSearchParams();
  const supportUuid = searchParams.get("supportUuid");
  const workspaceUuid = searchParams.get("workspaceUuid");

  const location = useLocation();

  async function getAllSupportMessages() {
    setIsLoading(true);
    if (supportUuid) {
      await getSupportMessages(
        workspaceUuid!,
        supportUuid,
        "?=page=1&sortBy=created_at&sortDirection=desc&take=10"
      );
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (supportUuid) {
      setSelectedSupport(supportUuid);
      getAllSupportMessages();
      const support = supports.find((support) => support.support?.uuid === supportUuid);
      if (support) setSupport(support);
    } else {
      setSelectedSupport(null);
    }
  }, [location, supports, supportUuid]);

  return (
    <>
      {/* MOBILE */}
      {authUser?.roles === "super_admin" ? (
        <div className="hidden max-md:block w-full h-full">
          {selectedSupport ? (
            <ShowConversation
              setSelectedSupport={setSelectedSupport}
              selectedSupport={selectedSupport}
            />
          ) : (
            <ListSupports
              selectedSupport={selectedSupport}
              setSelectedSupport={setSelectedSupport}
            />
          )}
        </div>
      ) : (
        <div className="hidden max-md:block w-full h-full">
          {selectedSupport ? (
            <ShowConversation
              setSelectedSupport={setSelectedSupport}
              selectedSupport={selectedSupport}
            />
          ) : (
            <ShowConversation
              setSelectedSupport={setSelectedSupport}
              selectedSupport={selectedSupport}
            />
          )}
        </div>
      )}

      {/* WEB */}
      {authUser?.roles === "super_admin" ? (
        <div className="hidden md:grid grid-cols-8 rounded-lg shadow-xl border border-grey-400 border-opacity-30">
          <div className="col-span-2 max-xl:col-span-3 border-r border-[#EDEDED] h-[80vh] max-h-[80vh]">
            <ListSupports
              selectedSupport={selectedSupport}
              setSelectedSupport={setSelectedSupport}
            />
          </div>
          <div className={`col-span-6 max-xl:col-span-5  h-[80vh] max-h-[80vh]`}>
            {selectedSupport ? (
              <ShowConversation
                setSelectedSupport={setSelectedSupport}
                selectedSupport={selectedSupport}
              />
            ) : (
              <NoSupports />
            )}
          </div>
        </div>
      ) : (
        <div className="hidden md:grid grid-cols-8 rounded-lg shadow-xl border border-grey-400 border-opacity-30">
          <div className={`col-span-8 max-xl:col-span-8 h-[80vh] max-h-[80vh]`}>
            <ShowConversation
              setSelectedSupport={setSelectedSupport}
              selectedSupport={selectedSupport}
            />
          </div>
        </div>
      )}
    </>
  );
}
