import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useUser } from "hooks/useUser";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

import { endOfMonth, subDays, subMonths } from "date-fns";
import moment from "moment";
import { formatDate } from "utils/auxFunctions";
import MonthCard from "./MonthCard";

export const monthsEn = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const monthsPt = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export function calendarBuild(value: moment.Moment) {
  const startDay = value.clone().startOf("month").startOf("isoWeek");
  const endDay = value.clone().endOf("month").endOf("isoWeek");
  const day = startDay.clone().subtract(1, "day");

  const calendar = [];

  while (day.isBefore(endDay, "day")) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => day.add(1, "day").clone())
    );
  }

  return calendar;
}

interface DatePickerProps {
  selectedDate: {
    startDate: Date;
    endDate: Date;
    label: string;
  } | null;
  setSelectedDate: React.Dispatch<
    React.SetStateAction<{
      startDate: Date;
      endDate: Date;
      label: string;
    } | null>
  >;
  closeButton?: React.RefObject<HTMLButtonElement>;
  handleApply: () => void;
  handleClear: () => void;
}

export default function DatePicker({
  selectedDate,
  setSelectedDate,
  closeButton,
  handleApply,
  handleClear,
}: DatePickerProps) {
  const { t } = useTranslation();
  const { authUser } = useUser();
  const newDate = new Date();
  const [currentYear, setCurrentYear] = useState(newDate.getFullYear());
  const [currentMonthIndex, setCurrentMonthIndex] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (selectedDate) {
      const date = new Date(selectedDate.endDate);

      setCurrentMonthIndex(date.getMonth());
      
      return
    }
    
    const today = new Date();
    const monthIndex = today.getMonth();
    setCurrentMonthIndex(monthIndex);
  }, [selectedDate]);

  function changeMonth(direction: "prev" | "next") {
    const newMonthIndex =
      direction === "prev"
        ? (currentMonthIndex! + 11) % 12
        : (currentMonthIndex! + 1) % 12;
    const newYear =
      direction === "prev" && currentMonthIndex === 0
        ? (currentYear - 1).toString()
        : direction === "next" && currentMonthIndex === 11
          ? (currentYear + 1).toString()
          : currentYear.toString();

    setCurrentMonthIndex(newMonthIndex);
    setCurrentYear(parseInt(newYear, 10));
  }

  function handleSelectPeriod(period: "weekly" | "monthly") {
    const nowDt = new Date();
    nowDt.setHours(0, 0, 0, 0);
    if (period === "weekly") {
      const initialDate = subDays(nowDt, 6);
      const endDate = nowDt;

      //ir para o mês da semana selecionada
      const selectedMonth = initialDate.getMonth();
      const selectedYear = initialDate.getFullYear();

      setCurrentMonthIndex(selectedMonth);
      setCurrentYear(selectedYear);


      setSelectedDate({
        startDate: initialDate,
        endDate: endDate,
        label: `${formatDate(
          initialDate,
          authUser?.user?.language || "en"
        )} - ${formatDate(endDate, authUser?.user?.language || "en")}`,
      });
    } else {
      const lastMonth = subMonths(nowDt, 1);
      const initialDate = subMonths(nowDt, 1);
      initialDate.setDate(1);
      const lastDayOfLastMonth = endOfMonth(lastMonth);
      lastDayOfLastMonth.setHours(0, 0, 0, 0);

      const newMonthIndex = lastMonth.getMonth();
      const newYear = lastMonth.getFullYear();

      setCurrentMonthIndex(newMonthIndex);
      setCurrentYear(newYear);

      setSelectedDate({
        startDate: initialDate,
        endDate: lastDayOfLastMonth,
        label: `${formatDate(
          initialDate,
          authUser?.user?.language || "en"
        )} - ${formatDate(
          lastDayOfLastMonth,
          authUser?.user?.language || "en"
        )}`,
      });
    }
  }

  return (
    <>
      {currentMonthIndex !== null ? (
        <div className="w-[340px] max-h-[480px] flex flex-col items-center bg-white rounded-md font-comfortaa">
          <div className="flex items-center justify-center w-full gap-5 p-4 mb-2">
            <button
              onClick={() => {
                changeMonth("prev");
              }}
            >
              <BsChevronLeft className="text-lg text-azulfy-blue hover:text-azulfy-blue-hover active:text-azulfy-blue-pressed" />
            </button>
            <span className="text-sm font-bold text-azulfy-blue w-[150px] text-center">
              {authUser?.user?.language === "en"
                ? monthsEn[currentMonthIndex]
                : monthsPt[currentMonthIndex]}{" "}
              {currentYear}
            </span>
            <button
              onClick={() => {
                changeMonth("next");
              }}
            >
              <BsChevronRight className="text-lg text-azulfy-blue hover:text-azulfy-blue-hover active:text-azulfy-blue-pressed" />
            </button>
          </div>
          <div className="h-full px-4">
            <MonthCard
              month={monthsEn[currentMonthIndex]}
              currentYear={currentYear}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </div>

          <div className="flex flex-wrap items-center justify-center gap-5 bg-grey-600 py-3 w-full mt-5">
            <button
              onClick={() => handleSelectPeriod("weekly")}
              className="px-[10px] py-3 flex items-center justify-center text-white bg-azulfy-rich-black rounded-[10px] min-w-[130px] shadow-md"
            >
              {t("last_week")}
            </button>
            <button
              onClick={() => handleSelectPeriod("monthly")}
              className="px-[10px] py-3 flex items-center justify-center text-white bg-azulfy-rich-black rounded-[10px] min-w-[130px] shadow-md"
            >
              {t("last_month")}
            </button>
            <button
              onClick={() => {
                handleClear();
              }}
              className="px-[10px] py-3 flex items-center justify-center text-azulfy-rich-black min-w-[130px]"
            >
              {t("clear")}
            </button>
            <button
              onClick={() => {
                handleApply();
                if (closeButton?.current) {
                  closeButton.current.click();
                }
              }}
              className="px-[10px] py-3 min-w-[130px] rounded-[10px] shadow-md text-white bg-Azulfy-Linear-Gradient hover:bg-Azulfy-Linear-Gradient-hover transition-all active:scale-[0.98] active:bg-Azulfy-Linear-Gradient-pressed"
            >
              {t("apply")}
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}
