/* import { useAuth } from '@hooks/auth/useAuth' */
import { useToast } from "hooks/useToast";
import { useUser } from "hooks/useUser";
import { useCallback, useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { api } from "services/axios";

export default function ProtectedRoutes() {
  const { verifyUser } = useUser();
  const redirectTo = verifyUser();

  const navigate = useNavigate();

  const { setShowToast } = useToast();

  // const goToRedirectRoute = useCallback(() => {
  //   setShowToast(false);
  //   navigate("/redirect");
  // }, []);

  const handleRedirectToLogin = useCallback(() => {
    setShowToast(false);
    navigate("/login");
  }, []);

  useEffect(() => {

    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return new Promise((resolve, reject) => {
          if (
            error?.response?.data?.content?.error === 401 ||
            error?.response?.data?.content?.error === 469
          ) {
            if (
              error?.response?.config?.url &&
              error.response.config.url.includes("auth")
            ) {
              reject(error);
              return;
            }
            const azulfyAuthUser = {
              user: null,
              token: "",
              roles: [],
              workspaces: [],
              permissions: [],
              isAuthenticated: false,
              passwordExpired: false,
              support: null,
            };
            localStorage.setItem(
              "azulfyAuthUser",
              JSON.stringify(azulfyAuthUser)
            );

            // goToRedirectRoute();

            // setTimeout(handleRedirectToLogin, 1000);
            handleRedirectToLogin();

            reject(error);
            return;
          }
          if (error?.response?.data?.content?.error === 100) {
            window.location.href = "/password-expired";
          }
          reject(error);
          resolve(error);
          return;
        });
      }
    );
  }, [api]);

  if (redirectTo === "login") return <Navigate to="/login" />;
  if (redirectTo === "create-profile") return <Navigate to="/create-profile" />;

  return <Outlet />;
}
