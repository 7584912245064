import { useLives } from "hooks/useLives";
import HeaderCouncil from "pages/Council/components/HeaderCouncil";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";

export default function ReadLiveCam() {
    const { liveCam, getLiveCamByUuid } = useLives();
    const { uuidWorkspace, uuidLiveCam } = useParams<{ uuidWorkspace: string, uuidLiveCam: string }>()

    async function getLiveCam(){
        await getLiveCamByUuid(uuidWorkspace!, uuidLiveCam!)
    }
    
    useEffect(() => {
        getLiveCam()
    }, [uuidWorkspace, uuidLiveCam])

    return (
        <main className="pb-8">
            <HeaderCouncil title={liveCam?.name} />
            <div className="mt-3 w-full max-w-3xl mx-auto">
                <ReactPlayer url={liveCam?.url} controls />
            </div>
        </main>
    )
}
