import { useEffect, useState } from "react";
import { MAPBOX_CONSTANTS } from "..";

interface IGeoInputProps {
  coordinate: number[];
  index: number;
  onConfirmUpdate: (index: number, coordinate: number[]) => void;
  onConfirmDelete: (index: number) => void;
  indexNumber?: number;
}

const GeoInputRow = ({
  // index,
  indexNumber,
  coordinate,
  // onConfirmUpdate,
  // onConfirmDelete,
}: IGeoInputProps) => {
  const [editOption, ] = useState<undefined | "edit" | "delete">(
    undefined
  );
  const [inputValues, setInputValues] = useState({
    lat: coordinate[MAPBOX_CONSTANTS.LATITUDE_INDEX],
    lng: coordinate[MAPBOX_CONSTANTS.LONGITUDE_INDEX],
  });

  // const handleCancelEdition = () => {
  //   setInputValues({
  //     lat: coordinate[MAPBOX_CONSTANTS.LATITUDE_INDEX],
  //     lng: coordinate[MAPBOX_CONSTANTS.LONGITUDE_INDEX],
  //   });
  //   setEditOption(undefined);
  // };

  useEffect(() => {
    setInputValues({
      lat: coordinate[MAPBOX_CONSTANTS.LATITUDE_INDEX],
      lng: coordinate[MAPBOX_CONSTANTS.LONGITUDE_INDEX],
    });
  }, [coordinate]);
  return (
    <div className="flex items-center gap-[22px]">
      <div className="flex items-center flex-1 gap-3">
        {indexNumber && (
          <span className="text-base font-bold text-azulfy-blue">
            {indexNumber}
          </span>
        )}
        <input
          value={inputValues.lat}
          onChange={(e) => {
            setInputValues({
              ...inputValues,
              lat: Number(e.target.value),
            });
          }}
          disabled={!(editOption && editOption === "edit")}
          type="number"
          placeholder="Ex.: Camera Oeiras"
          className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none"
        />
      </div>

      <div className="flex-1">
        <input
          value={inputValues.lng}
          disabled={!(editOption && editOption === "edit")}
          type="number"
          onChange={(e) => {
            setInputValues({
              ...inputValues,
              lng: Number(e.target.value),
            });
          }}
          placeholder="Ex.: Camera Oeiras"
          className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none"
        />
      </div>
    </div>
  );
};
export default GeoInputRow;
