import { Progress } from "rsuite";

import { BsChevronLeft } from "react-icons/bs";
import { useTranslation } from "react-i18next";

interface ProgressHeaderProps {
  step: number;
  unableNext: boolean;
  handlePrevious: () => void;
  handleNext: () => void;
  handleUpdateWorkspace(): Promise<void>;
}

export default function ProgressHeader({
  step,
  unableNext,
  handlePrevious,
  handleNext,
  handleUpdateWorkspace,
}: ProgressHeaderProps) {
  const { t } = useTranslation();
  return (
    <header className="md:mb-16 mb-8 mt-[112px] max-md:mt-[80px]">
      <div className="flex justify-between items-center w-full">
        <button
          className={`hover:opacity-80 ${step === 25 ? "invisible" : "block"}`}
          onClick={handlePrevious}
        >
          <BsChevronLeft size={20} className="text-azulfy-blue" />
        </button>
        <span className="font-comfortaa text-base font-bold text-azulfy-blue">
          {step === 25 ? "1" : step === 50 ? "2" : step === 75 ? "3" : "4"}
          /4
        </span>
        {step === 100 ? (
          <button
            className={`font-comfortaa text-xl text-azulfy-blue font-bold hover:opacity-80`}
            onClick={handleUpdateWorkspace}
          >
            {t("request")}
          </button>
        ) : (
          <button
            className={`font-comfortaa text-xl text-azulfy-blue font-bold ${
              unableNext ? "opacity-30" : "hover:opacity-80"
            }`}
            onClick={handleNext}
            disabled={unableNext}
          >
            {t("next")}
          </button>
        )}
      </div>
      <Progress.Line percent={step} showInfo={false} strokeColor="#0072FF" />
    </header>
  );
}
