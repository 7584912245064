import * as Dialog from "@radix-ui/react-dialog";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import { useTranslation } from "react-i18next";
import {
  BsTrashFill
} from "react-icons/bs";

type DeleteAllDialogProps = {
  onConfirmDeleteAll: () => void
}

export default function DeleteAllDialog({onConfirmDeleteAll}: DeleteAllDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <button
          className="px-3 py-2 rounded-lg shadow-md flex items-center gap-[5px] bg-white text-azulfy-rich-black hover:opacity-70">
          <BsTrashFill size={16}/>
          
          <span className="font-medium">
            {t('delete_all')}
          </span>
        </button>
      </Dialog.Trigger>

      <ConfirmDeleteModal
        title={t('remove_all_analysis_points')}
        description={''}
        handleDelete={onConfirmDeleteAll}
      />
    </Dialog.Root>
  );
}
  