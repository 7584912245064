import { useTranslation } from "react-i18next";

export default function NoAlerts() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="141"
        viewBox="0 0 200 141"
        fill="none"
      >
        <path
          d="M162 0.328125H42C36.4772 0.328125 32 4.80528 32 10.3281V130.328C32 135.851 36.4772 140.328 42 140.328H162C167.523 140.328 172 135.851 172 130.328V10.3281C172 4.80528 167.523 0.328125 162 0.328125Z"
          fill="#282A74"
          fillOpacity="0.1"
        />
        <g filter="url(#filter0_d_828_12163)">
          <path
            d="M146 10.3281H11C8.23858 10.3281 6 12.5667 6 15.3281V40.3281C6 43.0896 8.23858 45.3281 11 45.3281H146C148.761 45.3281 151 43.0896 151 40.3281V15.3281C151 12.5667 148.761 10.3281 146 10.3281Z"
            fill="white"
          />
        </g>
        <path
          d="M75 18.3281H49C47.3431 18.3281 46 19.6713 46 21.3281C46 22.985 47.3431 24.3281 49 24.3281H75C76.6569 24.3281 78 22.985 78 21.3281C78 19.6713 76.6569 18.3281 75 18.3281Z"
          fill="#B4DAFF"
        />
        <path
          d="M93 31.3281H49C47.3431 31.3281 46 32.6713 46 34.3281C46 35.985 47.3431 37.3281 49 37.3281H93C94.6569 37.3281 96 35.985 96 34.3281C96 32.6713 94.6569 31.3281 93 31.3281Z"
          fill="#DEE9FC"
        />
        <path
          d="M33 13.3281H14C11.2386 13.3281 9 15.5667 9 18.3281V37.3281C9 40.0895 11.2386 42.3281 14 42.3281H33C35.7614 42.3281 38 40.0895 38 37.3281V18.3281C38 15.5667 35.7614 13.3281 33 13.3281Z"
          fill="#0072FF"
        />
        <path
          d="M21.222 31.1081H23.222L23.244 30.3381C23.244 29.3261 23.882 29.0621 24.784 28.7761C25.6888 28.5188 26.4852 27.9738 27.0528 27.2236C27.6204 26.4734 27.9283 25.5588 27.93 24.6181C27.9498 24.0438 27.8502 23.4716 27.6374 22.9377C27.4246 22.4039 27.1033 21.92 26.6939 21.5168C26.2844 21.1135 25.7957 20.7997 25.2586 20.595C24.7216 20.3904 24.148 20.2996 23.574 20.3281C22.9674 20.3149 22.3644 20.425 21.8017 20.6518C21.2389 20.8786 20.7281 21.2174 20.3002 21.6475C19.8723 22.0777 19.5363 22.5903 19.3124 23.1542C19.0886 23.7181 18.9816 24.3216 18.998 24.9281H21.066C21.0581 24.5933 21.1172 24.2602 21.2397 23.9484C21.3623 23.6367 21.5458 23.3525 21.7796 23.1126C22.0133 22.8727 22.2926 22.6818 22.6011 22.5512C22.9096 22.4207 23.241 22.353 23.576 22.3521C23.8781 22.3346 24.1804 22.3823 24.4623 22.4921C24.7443 22.6018 24.9994 22.7709 25.2102 22.988C25.421 23.2051 25.5826 23.465 25.684 23.75C25.7855 24.0351 25.8243 24.3387 25.798 24.6401C25.7847 25.128 25.615 25.5987 25.314 25.9829C25.013 26.3671 24.5965 26.6445 24.126 26.7741L23.4 27.0621C22.6892 27.2334 22.0686 27.6653 21.6612 28.2724C21.2538 28.8795 21.0891 29.6175 21.2 30.3401L21.222 31.1081ZM22.212 35.8621C22.4078 35.8691 22.6031 35.8367 22.7862 35.7669C22.9693 35.6971 23.1366 35.5913 23.2781 35.4558C23.4196 35.3202 23.5325 35.1576 23.6101 34.9777C23.6877 34.7978 23.7285 34.6041 23.73 34.4081C23.7282 34.2123 23.6873 34.0189 23.6095 33.8392C23.5318 33.6595 23.4189 33.4972 23.2774 33.3618C23.1359 33.2265 22.9687 33.1209 22.7857 33.0512C22.6027 32.9815 22.4076 32.9492 22.212 32.9561C22.0163 32.9492 21.8212 32.9815 21.6382 33.0512C21.4552 33.1209 21.2881 33.2265 21.1466 33.3618C21.0051 33.4972 20.8921 33.6595 20.8144 33.8392C20.7367 34.0189 20.6957 34.2123 20.694 34.4081C20.6955 34.6041 20.7362 34.7978 20.8138 34.9777C20.8914 35.1576 21.0044 35.3202 21.1459 35.4558C21.2874 35.5913 21.4547 35.6971 21.6378 35.7669C21.8209 35.8367 22.0161 35.8691 22.212 35.8621Z"
          fill="white"
        />
        <g filter="url(#filter1_d_828_12163)">
          <path
            d="M54 53.3281H189C190.326 53.3281 191.598 53.8549 192.536 54.7926C193.473 55.7303 194 57.002 194 58.3281V83.3281C194 84.6542 193.473 85.926 192.536 86.8637C191.598 87.8013 190.326 88.3281 189 88.3281H54C52.6739 88.3281 51.4021 87.8013 50.4645 86.8637C49.5268 85.926 49 84.6542 49 83.3281V58.3281C49 57.002 49.5268 55.7303 50.4645 54.7926C51.4021 53.8549 52.6739 53.3281 54 53.3281V53.3281Z"
            fill="white"
          />
        </g>
        <path
          d="M118 61.3281H92C90.3431 61.3281 89 62.6713 89 64.3281C89 65.985 90.3431 67.3281 92 67.3281H118C119.657 67.3281 121 65.985 121 64.3281C121 62.6713 119.657 61.3281 118 61.3281Z"
          fill="#B4DAFF"
        />
        <path
          d="M136 74.3281H92C90.3431 74.3281 89 75.6713 89 77.3281C89 78.985 90.3431 80.3281 92 80.3281H136C137.657 80.3281 139 78.985 139 77.3281C139 75.6713 137.657 74.3281 136 74.3281Z"
          fill="#DEE9FC"
        />
        <path
          d="M76 56.3281H57C54.2386 56.3281 52 58.5667 52 61.3281V80.3281C52 83.0895 54.2386 85.3281 57 85.3281H76C78.7614 85.3281 81 83.0895 81 80.3281V61.3281C81 58.5667 78.7614 56.3281 76 56.3281Z"
          fill="#0072FF"
        />
        <path
          d="M64.222 74.1081H66.222L66.244 73.3381C66.244 72.3261 66.882 72.0621 67.784 71.7761C68.6888 71.5188 69.4852 70.9738 70.0528 70.2236C70.6204 69.4734 70.9283 68.5588 70.93 67.6181C70.9498 67.0438 70.8502 66.4716 70.6374 65.9377C70.4246 65.4039 70.1033 64.92 69.6939 64.5168C69.2844 64.1135 68.7957 63.7997 68.2586 63.595C67.7216 63.3904 67.148 63.2996 66.574 63.3281C65.9674 63.3149 65.3644 63.425 64.8017 63.6518C64.2389 63.8786 63.7281 64.2174 63.3002 64.6475C62.8723 65.0777 62.5363 65.5903 62.3124 66.1542C62.0886 66.7181 61.9816 67.3216 61.998 67.9281H64.066C64.0581 67.5933 64.1172 67.2602 64.2397 66.9484C64.3623 66.6367 64.5458 66.3525 64.7796 66.1126C65.0133 65.8727 65.2926 65.6818 65.6011 65.5512C65.9096 65.4207 66.241 65.353 66.576 65.3521C66.8781 65.3346 67.1804 65.3823 67.4623 65.4921C67.7443 65.6018 67.9994 65.7709 68.2102 65.988C68.421 66.2051 68.5826 66.465 68.684 66.75C68.7855 67.0351 68.8243 67.3387 68.798 67.6401C68.7847 68.128 68.615 68.5987 68.314 68.9829C68.013 69.3671 67.5965 69.6445 67.126 69.7741L66.4 70.0621C65.6892 70.2334 65.0686 70.6653 64.6612 71.2724C64.2538 71.8795 64.0891 72.6175 64.2 73.3401L64.222 74.1081ZM65.212 78.8621C65.4078 78.8691 65.6031 78.8367 65.7862 78.7669C65.9693 78.6971 66.1366 78.5913 66.2781 78.4558C66.4196 78.3202 66.5325 78.1576 66.6101 77.9777C66.6877 77.7978 66.7285 77.6041 66.73 77.4081C66.7282 77.2123 66.6873 77.0189 66.6095 76.8392C66.5318 76.6595 66.4189 76.4972 66.2774 76.3618C66.1359 76.2265 65.9687 76.1209 65.7857 76.0512C65.6027 75.9815 65.4076 75.9492 65.212 75.9561C65.0163 75.9492 64.8212 75.9815 64.6382 76.0512C64.4552 76.1209 64.2881 76.2265 64.1466 76.3618C64.0051 76.4972 63.8921 76.6595 63.8144 76.8392C63.7367 77.0189 63.6957 77.2123 63.694 77.4081C63.6955 77.6041 63.7362 77.7978 63.8138 77.9777C63.8914 78.1576 64.0044 78.3202 64.1459 78.4558C64.2874 78.5913 64.4547 78.6971 64.6378 78.7669C64.8209 78.8367 65.0161 78.8691 65.212 78.8621Z"
          fill="white"
        />
        <g filter="url(#filter2_d_828_12163)">
          <path
            d="M11 96.3281H146C147.326 96.3281 148.598 96.8549 149.536 97.7926C150.473 98.7303 151 100.002 151 101.328V126.328C151 127.654 150.473 128.926 149.536 129.864C148.598 130.801 147.326 131.328 146 131.328H11C9.67392 131.328 8.40215 130.801 7.46447 129.864C6.52678 128.926 6 127.654 6 126.328V101.328C6 100.002 6.52678 98.7303 7.46447 97.7926C8.40215 96.8549 9.67392 96.3281 11 96.3281V96.3281Z"
            fill="white"
          />
        </g>
        <path
          d="M75 104.328H49C47.3431 104.328 46 105.671 46 107.328C46 108.985 47.3431 110.328 49 110.328H75C76.6569 110.328 78 108.985 78 107.328C78 105.671 76.6569 104.328 75 104.328Z"
          fill="#B4DAFF"
        />
        <path
          d="M93 117.328H49C47.3431 117.328 46 118.671 46 120.328C46 121.985 47.3431 123.328 49 123.328H93C94.6569 123.328 96 121.985 96 120.328C96 118.671 94.6569 117.328 93 117.328Z"
          fill="#DEE9FC"
        />
        <path
          d="M33 99.3281H14C11.2386 99.3281 9 101.567 9 104.328V123.328C9 126.09 11.2386 128.328 14 128.328H33C35.7614 128.328 38 126.09 38 123.328V104.328C38 101.567 35.7614 99.3281 33 99.3281Z"
          fill="#0072FF"
        />
        <path
          d="M21.222 117.108H23.222L23.244 116.338C23.244 115.326 23.882 115.062 24.784 114.776C25.6888 114.519 26.4852 113.974 27.0528 113.224C27.6204 112.473 27.9283 111.559 27.93 110.618C27.9498 110.044 27.8502 109.472 27.6374 108.938C27.4246 108.404 27.1033 107.92 26.6939 107.517C26.2844 107.114 25.7957 106.8 25.2586 106.595C24.7216 106.39 24.148 106.3 23.574 106.328C22.9674 106.315 22.3644 106.425 21.8017 106.652C21.2389 106.879 20.7281 107.217 20.3002 107.648C19.8723 108.078 19.5363 108.59 19.3124 109.154C19.0886 109.718 18.9816 110.322 18.998 110.928H21.066C21.0581 110.593 21.1172 110.26 21.2397 109.948C21.3623 109.637 21.5458 109.352 21.7796 109.113C22.0133 108.873 22.2926 108.682 22.6011 108.551C22.9096 108.421 23.241 108.353 23.576 108.352C23.8781 108.335 24.1804 108.382 24.4623 108.492C24.7443 108.602 24.9994 108.771 25.2102 108.988C25.421 109.205 25.5826 109.465 25.684 109.75C25.7855 110.035 25.8243 110.339 25.798 110.64C25.7847 111.128 25.615 111.599 25.314 111.983C25.013 112.367 24.5965 112.644 24.126 112.774L23.4 113.062C22.6892 113.233 22.0686 113.665 21.6612 114.272C21.2538 114.88 21.0891 115.617 21.2 116.34L21.222 117.108ZM22.212 121.862C22.4078 121.869 22.6031 121.837 22.7862 121.767C22.9693 121.697 23.1366 121.591 23.2781 121.456C23.4196 121.32 23.5325 121.158 23.6101 120.978C23.6877 120.798 23.7285 120.604 23.73 120.408C23.7282 120.212 23.6873 120.019 23.6095 119.839C23.5318 119.659 23.4189 119.497 23.2774 119.362C23.1359 119.226 22.9687 119.121 22.7857 119.051C22.6027 118.981 22.4076 118.949 22.212 118.956C22.0163 118.949 21.8212 118.981 21.6382 119.051C21.4552 119.121 21.2881 119.226 21.1466 119.362C21.0051 119.497 20.8921 119.659 20.8144 119.839C20.7367 120.019 20.6957 120.212 20.694 120.408C20.6955 120.604 20.7362 120.798 20.8138 120.978C20.8914 121.158 21.0044 121.32 21.1459 121.456C21.2874 121.591 21.4547 121.697 21.6378 121.767C21.8209 121.837 22.0161 121.869 22.212 121.862Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d_828_12163"
            x="0"
            y="7.32812"
            width="157"
            height="47"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_828_12163"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_828_12163"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_828_12163"
            x="43"
            y="50.3281"
            width="157"
            height="47"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_828_12163"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_828_12163"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_828_12163"
            x="0"
            y="93.3281"
            width="157"
            height="47"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_828_12163"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_828_12163"
              result="shape"
            />
          </filter>
        </defs>
      </svg>

      <h3 className="font-comfortaa font-semibold text-azulfy-rich-black">
        {t("no_alerts")}
      </h3>
      <span className="font-comfortaa text-xs">{t("no_alerts_founded")}</span>
    </div>
  );
}
