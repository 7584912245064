import { create } from "zustand";
import { api } from "services/axios";
import { showErrorMessage } from "../utils/auxFunctions";

interface Pagination {
  current_page: number;
  last_page: number;
  total: number;
  last_page_url: string | null;
}

interface PermissionsProps {
  name: string;
  uuid: string;
}

interface Permission {
  services: PermissionsProps[];
  filters: PermissionsProps[];
  indicators: PermissionsProps[];
  admin_role: PermissionsProps[];
  technicians_role: PermissionsProps[];
  external_service_provider_role: PermissionsProps[];
}

interface LicensePermissions {
  uuid: string;
  permissions: PermissionsProps;
}

interface LicenseProps {
  name: string;
  uuid: string;
  color: string;
  members: number;
  services: PermissionsProps[];
  filters: PermissionsProps[];
  indicators: PermissionsProps[];
  license_permissions_admin: LicensePermissions[];
  license_permissions_technician: LicensePermissions[];
  license_permissions_external_service_provider: LicensePermissions[];
}

export interface License {
  license: LicenseProps;
  permissions: Permission;
  uuid: string;
  name: string;
  members: number;
  color: string;
  services?: string[];
  filters?: string[];
  indicators?: string[];
  admin_role?: string[];
  technicians_role?: string[];
  external_service_provider_role?: string[];
}

interface useLicensesProps {
  licenses: License[];
  license: License;
  pagination: Pagination;
  permissions: Permission;
  getAllPermissions: (params?: string) => Promise<Permission>;
  getAllLicenses: (params?: string) => Promise<License[]>;
  getLicenseByUuid: (licenseUuid: string) => Promise<License>;
  createNewLicense: (dataToPost: object) => Promise<License>;
  updateLicense: (licenseUuid: string,  partial: Partial<License>) => Promise<License>;
  deleteLicense: (licenseUuid: string) => Promise<void>;
}

export const useLicenses = create<useLicensesProps>((set) => ({
  licenses: [],
  license: {} as License,
  pagination: {
    current_page: 1,
    last_page: 1,
    total: 1,
    last_page_url: null,
  },
  permissions: {} as Permission,
  getAllPermissions: async (params?: string) => {
    try {
      const { data } = await api.get(`/permissions` + (params ? params : ""));
      set({ permissions: data.content.data });
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  getAllLicenses: async (params?: string) => {
    try {
      const { data } = await api.get(`/license` + (params ? params : ""));
      set({ licenses: data.content.data });
      set({
        pagination: {
          current_page: data.content.data.current_page,
          last_page: data.content.data.last_page,
          total: data.content.data.total,
          last_page_url: data.content.data.last_page_url,
        },
      });
      return data.content.data.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  getLicenseByUuid: async (licenseUuid: string) => {
    try {
      const { data } = await api.get(`/license/${licenseUuid}`);
      set({ license: data.content.data });
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  createNewLicense: async (dataToPost: object) => {
    try {
      const { data } = await api.post(`/license`, dataToPost);
      set({ license: data.content.data });
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  updateLicense: async (licenseUuid: string, partial: Partial<License>) => {
    try {
      const { data } = await api.put(`/license/${licenseUuid}`, partial);
      set({ license: {} as License });
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  deleteLicense: async (licenseUuid: string) => {
    try {
      await api.delete(`/license/${licenseUuid}`);
    } catch (error: any) {
      showErrorMessage(error);
    }
  }
}));
