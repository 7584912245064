import Header from "components/Header"
import { useTranslation } from "react-i18next"

export default function PrivacyPolicy() {
    const { t } = useTranslation()
    return (
        <main className="pb-10 container mx-auto lg:max-w-4xl xl:max-w-7xl">
            <Header position="absolute" />
            <div className="mt-[112px] max-md:mt-[80px] font-comfortaa px-4 lg:px-0">
                <header
                    className="flex items-center w-full justify-center py-1 mb-8"
                >
                    <p className="text-azulfy-blue text-xl font-bold font-comfortaa">
                        {t('privacy_policy')}
                    </p>
                </header>
                <p className="text-azulfy-rich-black text-sm text-right mb-4">{t('updated_on')} 26,12,2023</p>
                <h2 className="text-xl font-bold text-azulfy-blue mb-3">
                    {t('welcome_to_our_privacy_policy.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('welcome_to_our_privacy_policy.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('welcome_to_our_privacy_policy.p2')}
                </p>
                <ul className="text-azulfy-rich-black text-sm">
                    <li>
                        {t('welcome_to_our_privacy_policy.p2_1')}
                    </li>
                    <li>
                        {t('welcome_to_our_privacy_policy.p2_2')}
                    </li>
                    <ul className="text-azulfy-rich-black text-sm mt-0">
                        <li>
                            {t('welcome_to_our_privacy_policy.p2_2_1')}
                        </li>
                        <li>
                            {t('welcome_to_our_privacy_policy.p2_2_2')}
                        </li>
                    </ul>
                    <li>
                        {t('welcome_to_our_privacy_policy.p2_3')}
                    </li>
                    <li>
                        {t('welcome_to_our_privacy_policy.p2_4')}
                    </li>
                </ul>
                <p className="text-azulfy-rich-black text-sm">
                    {t('welcome_to_our_privacy_policy.p3')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('welcome_to_our_privacy_policy.p4')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('definitions.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('definitions.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('definitions.p2')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('definitions.p3')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('definitions.p4')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('definitions.p5')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('definitions.p6')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('definitions.p7')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('definitions.p8')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('legal_framework.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('legal_framework.p1')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('who_is_responsible_for_this_platform.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('who_is_responsible_for_this_platform.p1')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('who_is_responsible_for_the_processing_of_your_personal_data.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('who_is_responsible_for_the_processing_of_your_personal_data.p1')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('what_personal_data_do_we_collect.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('what_personal_data_do_we_collect.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('what_personal_data_do_we_collect.p2')}
                </p>
                <ul className="text-azulfy-rich-black text-sm mt-0">
                    <li>
                        {t('what_personal_data_do_we_collect.p2_1')}
                    </li>
                    <li>
                        {t('what_personal_data_do_we_collect.p2_2')}
                    </li>
                    <li>
                        {t('what_personal_data_do_we_collect.p2_3')}
                    </li>
                    <li>
                        {t('what_personal_data_do_we_collect.p2_4')}
                    </li>
                    <li>
                        {t('what_personal_data_do_we_collect.p2_5')}
                    </li>
                </ul>
                <p className="text-azulfy-rich-black text-sm">
                    {t('what_personal_data_do_we_collect.p3')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('what_personal_data_do_we_collect.p4')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('what_personal_data_do_we_collect.p5')}
                </p>
                <ul className="text-azulfy-rich-black text-sm mt-0">
                    <li>
                        {t('what_personal_data_do_we_collect.p5_1')}
                    </li>
                    <li>
                        {t('what_personal_data_do_we_collect.p5_2')}
                    </li>
                    <li>
                        {t('what_personal_data_do_we_collect.p5_3')}
                    </li>
                    <li>
                        {t('what_personal_data_do_we_collect.p5_4')}
                    </li>
                    <li>
                        {t('what_personal_data_do_we_collect.p5_5')}
                    </li>
                    <li>
                        {t('what_personal_data_do_we_collect.p5_6')}
                    </li>
                </ul>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('how_do_we_collect_your_personal_data.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('how_do_we_collect_your_personal_data.p1')}
                </p>
                <ul className="text-azulfy-rich-black text-sm mt-0">
                    <li>
                        {t('how_do_we_collect_your_personal_data.p1_1')}
                    </li>
                    <li>
                        {t('how_do_we_collect_your_personal_data.p1_2')}
                    </li>
                    <li>
                        {t('how_do_we_collect_your_personal_data.p1_3')}
                    </li>
                    <li>
                        {t('how_do_we_collect_your_personal_data.p1_4')}
                    </li>
                </ul>
                <p className="text-azulfy-rich-black text-sm">
                    {t('how_do_we_collect_your_personal_data.p2')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('how_do_we_collect_your_personal_data.p3')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('what_are_the_purposes_of_the_processing_of_your_data.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('what_are_the_purposes_of_the_processing_of_your_data.p1')}
                </p>
                <ul className="text-azulfy-rich-black text-sm mt-0">
                    <li>
                        {t('what_are_the_purposes_of_the_processing_of_your_data.p1_1')}
                    </li>
                    <li>
                        {t('what_are_the_purposes_of_the_processing_of_your_data.p1_2')}
                    </li>
                    <li>
                        {t('what_are_the_purposes_of_the_processing_of_your_data.p1_3')}
                    </li>
                    <li>
                        {t('what_are_the_purposes_of_the_processing_of_your_data.p1_4')}
                    </li>
                    <li>
                        {t('what_are_the_purposes_of_the_processing_of_your_data.p1_5')}
                    </li>
                    <li>
                        {t('what_are_the_purposes_of_the_processing_of_your_data.p1_6')}
                    </li>
                </ul>
                <p className="text-azulfy-rich-black text-sm">
                    {t('what_are_the_purposes_of_the_processing_of_your_data.p2')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('how_do_we_handle_personal_data_of_children.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('how_do_we_handle_personal_data_of_children.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('how_do_we_handle_personal_data_of_children.p2')}
                </p>
                <ul className="text-azulfy-rich-black text-sm mt-0">
                    <li>
                        {t('how_do_we_handle_personal_data_of_children.p2_1')}
                    </li>
                </ul>
                <p className="text-azulfy-rich-black text-sm">
                    {t('how_do_we_handle_personal_data_of_children.p3')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('how_do_we_handle_personal_data_of_children.p4')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('cookies.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('cookies.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('cookies.p2')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('cookies.p3')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('cookies.p4')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('strictly_necessary_cookies.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('strictly_necessary_cookies.p1')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('performance_cookies.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('performance_cookies.p1')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('functional_cookies.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('functional_cookies.p1')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('targeting_cookies.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('targeting_cookies.p1')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('who_do_we_share_your_data_with.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('who_do_we_share_your_data_with.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('who_do_we_share_your_data_with.p2')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('who_do_we_share_your_data_with.p3')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('who_do_we_share_your_data_with.p4')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('what_happens_if_the_Platform_is_sold_to_another_company.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('what_happens_if_the_Platform_is_sold_to_another_company.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('what_happens_if_the_Platform_is_sold_to_another_company.p2')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('for_how_long_will_we_retain_retention_period_your_personal_data.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('for_how_long_will_we_retain_retention_period_your_personal_data.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('for_how_long_will_we_retain_retention_period_your_personal_data.p2')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('for_how_long_will_we_retain_retention_period_your_personal_data.p3')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('what_are_your_rights.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('what_are_your_rights.p1')}
                </p>
                <ul className="text-azulfy-rich-black text-sm mt-0">
                    <li>
                        {t('what_are_your_rights.p1_1')}
                    </li>
                    <li>
                        {t('what_are_your_rights.p1_2')}
                    </li>
                    <li>
                        {t('what_are_your_rights.p1_3')}
                    </li>
                    <li>
                        {t('what_are_your_rights.p1_4')}
                    </li>
                    <li>
                        {t('what_are_your_rights.p1_5')}
                    </li>
                    <li>
                        {t('what_are_your_rights.p1_6')}
                    </li>
                    <li>
                        {t('what_are_your_rights.p1_7')}
                    </li>
                    <li>
                        {t('what_are_your_rights.p1_8')}
                    </li>
                    <li>
                        {t('what_are_your_rights.p1_9')}
                    </li>
                    <li>
                        {t('what_are_your_rights.p1_10')}
                    </li>
                </ul>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('automated_decisions.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('automated_decisions.p1')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('how_do_we_respect_the_security_of_your_data.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('how_do_we_respect_the_security_of_your_data.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('how_do_we_respect_the_security_of_your_data.p2')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('changes_to_this_privacy_policy.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('changes_to_this_privacy_policy.p1')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('contact_us.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('contact_us.p1')}
                </p>
            </div>
        </main>
    )
}
