import * as Popover from "@radix-ui/react-popover";
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsChevronRight, BsSearch } from 'react-icons/bs';
import { useParams, useSearchParams } from 'react-router-dom';

import { useAlerts } from 'hooks/useAlerts';
import { toast } from 'utils/toast';
import checkIconSvg from '../../../../../assets/tabler-icon-check.svg';

export default function ManagerAlert() {
    const { t } = useTranslation();
    const { getAllMembersofWorkspaceByTypeManager, membersWorkspaceByTypeManager, updateAlertManager, getAllMembersofWorkspaceByTypeObserver, getAlerts } = useAlerts();
    const { uuidWorkspace } = useParams<{ uuidWorkspace: string }>();
    const [searchParams,] = useSearchParams();
    const alertUuid = searchParams.get("alert")

    const closeButton = useRef<HTMLButtonElement>(null);
    const searchManagerInputRef = useRef<HTMLInputElement>(null)

    const [managerSelected, setManagerSelected] = useState({
        name: "",
        initials: "",
    })
    const [, setOriginalManagerSelected] = useState({
        name: "",
        initials: "",
    });

    async function handleSearchManager() {
        if (searchManagerInputRef.current) {
            await getAllMembersofWorkspaceByTypeManager(uuidWorkspace!, alertUuid!, "manager", `?search=${searchManagerInputRef.current.value}`)
        }
    }

    async function handleUpdateManager(managerUuid: string) {
        await updateAlertManager(uuidWorkspace!, alertUuid!, { user_id: managerUuid })
            .then(() => {
                closeButton.current?.click()

                getAllMembersofWorkspaceByTypeManager(uuidWorkspace!, alertUuid!, "manager")
                getAllMembersofWorkspaceByTypeObserver(uuidWorkspace!, alertUuid!, "observer")
                getAlerts()
                
                toast({
                    label: t('success'),
                    message: t('manager_updated_successfully'),
                    type: "success",
                })
            })
            .catch((error) => {
                console.error('Error updating role:', error);
            })

    }
    useEffect(() => {
        const managerName = membersWorkspaceByTypeManager?.find((member) => member.selected === true)?.user.name
        const managerInitials = membersWorkspaceByTypeManager?.find((member) => member.selected === true)?.user.initials
        if (!searchManagerInputRef.current || searchManagerInputRef.current.value === "") {
            setManagerSelected({
                name: managerName!,
                initials: managerInitials!,
            });
            setOriginalManagerSelected({
                name: managerName!,
                initials: managerInitials!,
            });
        }
    }, [membersWorkspaceByTypeManager, searchManagerInputRef])

    return (
        <div className='flex flex-col gap-1 w-full'>
            <p className='text-sm text-azulfy-rich-black font-raleway'>
                {t("manager")}
            </p>
            <div className='flex flex-col gap-1 w-full'>
                <Popover.Root onOpenChange={ async () => {
                    if(searchManagerInputRef?.current){
                        searchManagerInputRef.current!.value = ""
                    }
                    await getAllMembersofWorkspaceByTypeManager(uuidWorkspace!, alertUuid!, "manager")
                }}>
                    <Popover.Trigger asChild>
                        <div className='flex items-center justify-between py-2 px-3 rounded-md shadow-md bg-white cursor-pointer hover:opacity-70 min-h-[44px]'>
                            <div className='flex items-center gap-1 font-comfortaa'>
                                {managerSelected.initials && (
                                    <span className="w-7 h-7 rounded-full bg-azulfy-rich-black flex items-center justify-center min-w-[28px] text-white text-[10px]">
                                        {managerSelected.initials}
                                    </span>
                                )}
                                <p className='text-xs font-bold text-azulfy-rich-black select-none line-clamp-1'>{managerSelected.name}</p>
                            </div>
                            <BsChevronRight className='text-azulfy-rich-black' size={12} />
                        </div>
                    </Popover.Trigger>
                    <Popover.Portal>
                        <Popover.Content
                            sideOffset={5}
                            align="start"
                            onCloseAutoFocus={ async () => {
                                if(searchManagerInputRef?.current){
                                    searchManagerInputRef.current!.value = ""
                                }
                                await getAllMembersofWorkspaceByTypeManager(uuidWorkspace!, alertUuid!, "manager")
                            }}
                            className="bg-white shadow-lg py-2 px-3 rounded-md border border-grey-400 border-opacity-30 w-[var(--radix-popover-trigger-width)] max-h-60 divide-y divide-slate-200 overflow-y-scroll overflow-x-hidden"
                        >
                            <Popover.Close ref={closeButton} hidden />
                            <div className='mb-4 flex items-center justify-between bg-grey-600 py-2 px-3 rounded-lg border border-grey-400 sticky top-0 z-10'>
                                <input
                                    type="text"
                                    name="Search"
                                    id="search"
                                    ref={searchManagerInputRef}
                                    onChange={handleSearchManager}
                                    placeholder={`${t("search")}...`}
                                    className='active:outline-none focus-visible:outline-none bg-transparent'
                                />
                                <BsSearch size={16} />
                            </div>
                            {membersWorkspaceByTypeManager.map((member) => (
                                <div
                                    key={member.user.uuid}
                                    onClick={() => {
                                        handleUpdateManager(member.user.uuid)
                                    }}
                                    className="flex items-center justify-between py-2 cursor-pointer hover:opacity-70"
                                >
                                    <div className='flex items-center gap-1 font-comfortaa break-all'>
                                        <span className="w-7 h-7 rounded-full bg-azulfy-rich-black flex items-center justify-center min-w-[28px] text-white text-[10px]">
                                            {member.user.initials}
                                        </span>

                                        <p className='text-xs font-bold text-azulfy-rich-black select-none line-clamp-1'>{member.user.name}</p>
                                    </div>

                                    {member.selected && (
                                        <img src={checkIconSvg} alt="check" className='w-4 h-4' />
                                    )}
                                </div>
                            ))}
                        </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
            </div>
        </div>
    )
}
