import * as Dialog from "@radix-ui/react-dialog";
import * as Popover from "@radix-ui/react-popover";
import { CheckIconGradient } from "assets/icons/CheckIconGradient";
import ConfirmStatusModal from "components/ConfirmStatusModal";
import Loading from "components/Loading";
import { useWorkspaces } from "hooks/useWorkspaces";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronDown, BsChevronLeft } from "react-icons/bs";
import { LuAlertCircle, LuCheckCircle2, LuXCircle } from "react-icons/lu";
import { useNavigate, useParams } from "react-router-dom";
import { MapboxGeoJSONPolygon } from "./MapComponent";
import { PointsOfAnalysisMap } from "./PointsOfAnalysisMap";

export default function ManageStatus() {
  const { t } = useTranslation();
  const { uuid } = useParams<{ uuid: string }>();
  const { getWorkspacesByUuid, workspace, updateWorkspaceStatus } = useWorkspaces();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState('');
  const [tempSelectedStatus, setTempSelectedStatus] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [pointsOfAnalysisPolygons, setPointsOfAnalysisPolygons] = useState<
    MapboxGeoJSONPolygon[] | undefined
  >();
  const [geoGraphicalArea, setGeoGraphicalArea] = useState<
    MapboxGeoJSONPolygon | undefined
  >();

  const handleGetWorkspacesByUuid = async () => {
    const data = await getWorkspacesByUuid(uuid!);
    setSelectedStatus(data.status)
    setIsLoading(false);
  }

  const handleStatusChange = (newStatus: string) => {
    setIsDialogOpen(true);
    // setSelectedStatus(newStatus);
    setTempSelectedStatus(newStatus);
  };

  const statusOptions = [
    { label: t('pending_approval'), value: 'pending', icon: <LuAlertCircle color="#F3C947" /> },
    { label: t('aproved'), value: 'approved', icon: <LuCheckCircle2 color="#27AE60" /> },
    { label: t('denied'), value: 'rejected', icon: <LuXCircle color="#CD0C0C" /> },
  ];

  useEffect(() => {
    handleGetWorkspacesByUuid();
  }, [])

  useEffect(() => {
    setGeoGraphicalArea(undefined);
    setPointsOfAnalysisPolygons(undefined);
  }, [workspace])

  useEffect(() => {
    setGeoGraphicalArea(workspace?.region_map_area == null ? workspace?.region_map_area_pending : workspace.status === "pending" ? workspace?.region_map_area_pending : workspace?.region_map_area);
  }, [workspace?.region_map_area, workspace?.region_map_area_pending, workspace?.status])

  useEffect(() => {
    if (workspace && workspace.area_points?.length > 0) {
      const extractedPolygons = workspace.area_points
        .filter(item => workspace.status === "pending" && item?.region_map_area_pending !== null || workspace.status !== "pending" && item?.region_map_area !== null)
        .map(workspace.status === "pending" ? item => item.region_map_area_pending : item => item.region_map_area);

      setPointsOfAnalysisPolygons(extractedPolygons);
    }
  }, [workspace]);

  async function handleUpdateWorkspaceStatus() {
    await updateWorkspaceStatus(uuid!, { status: tempSelectedStatus });
    setSelectedStatus(tempSelectedStatus);
    setIsDialogOpen(false);
    setTempSelectedStatus('');
    navigate(`/workspace/${uuid}`);
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div>
            <div className="w-full flex flex-wrap justify-between pb-5">
              <div className="flex gap-1 md:gap-5 items-center">
                <button
                  className="hover:opacity-80"
                  onClick={() => navigate(-1)}
                >
                  <BsChevronLeft className="w-4 h-4 md:w-5 md:h-5 text-azulfy-blue" />
                </button>

                <p className="text-azulfy-blue text-base md:text-xl font-comfortaa font-bold">
                  {t("manage_status")}
                </p>
              </div>

              <Popover.Root>
                <div className="flex max-md:items-center justify-between md:flex-col w-full md:max-w-[245px] gap-[2px]">
                  <span className="text-xs">{t('status')}</span>

                  <Popover.Trigger asChild>
                    <div className="cursor-pointer rounded-md px-3 h-10 flex gap-2 items-center justify-between shadow-sm shadow-black-100/30"
                    >
                      <div className='flex gap-1 whitespace-nowrap text-xs md:text-sm items-center font-comfortaa'>
                        {selectedStatus ? (
                          <>
                            {statusOptions.find(option => option.value === selectedStatus)?.icon}

                            {statusOptions.find(option => option.value === selectedStatus)?.label} 
                          </>
                        ) 
                        : 'Select Status'}
                      </div>

                      <BsChevronDown className="w-3 h-3" />
                    </div>
                  </Popover.Trigger>
                </div>

                <Popover.Content
                  sideOffset={3}
                  align="end"
                  className="w-[--radix-popover-trigger-width] flex flex-col rounded-md shadow-md bg-white border border-gray-300 shadow-black-100/25"
                >
                  {statusOptions.map((option) => (
                    <Popover.Close>
                      <div
                        key={option.value}
                        className='px-3 flex gap-1 items-center justify-between text-xs md:text-sm hover:opacity-80 hover:cursor-pointer h-11'
                        onClick={() => handleStatusChange(option.value)}
                      >
                        <div className="flex items-center gap-1">
                          <span>
                            {option.icon}
                          </span>

                          <span>
                            {option.label}
                          </span>
                        </div>

                        {option.value === selectedStatus && (
                          <CheckIconGradient />
                        )}
                      </div>
                    </Popover.Close>
                  ))}
                </Popover.Content>
              </Popover.Root>
            </div>

            <PointsOfAnalysisMap
              geoArea={geoGraphicalArea}
              pointsOfAnalysisPolygons={pointsOfAnalysisPolygons}
              setPointsOfAnalysisPolygons={setPointsOfAnalysisPolygons}
            />
          </div>

          <Dialog.Root open={isDialogOpen}>
            <ConfirmStatusModal
              title={t('confirm_status_change')}
              description={`${t('change_status_to')} ${tempSelectedStatus ? statusOptions.find(option => option.value === tempSelectedStatus)?.label : ''}`}
              handleConfirm={() => {
                handleUpdateWorkspaceStatus();
              }}
              handleCancel={() => {
                setIsDialogOpen(false)
                setTempSelectedStatus('');
              }}
            >
            </ConfirmStatusModal>
          </Dialog.Root>
        </>
      )}

    </>
  )
}
