import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { useIndicatorsHistory } from 'hooks/useIndicatorsHistory';
import { useTranslation } from 'react-i18next';

export const ChartComponent: React.FC = () => {
    const { indicatorsHistory } = useIndicatorsHistory();
    const { t } = useTranslation();

    const areaChartRef = useRef<HTMLCanvasElement | null>(null);
    const intensityChartRef = useRef<HTMLCanvasElement | null>(null);
    const areaChartInstance = useRef<Chart | null>(null);
    const intensityChartInstance = useRef<Chart | null>(null);

    useEffect(() => {
        if (areaChartRef.current && indicatorsHistory?.data) {
            const ctx = areaChartRef.current.getContext('2d');
            if (ctx) {
                if (areaChartInstance.current) {
                    areaChartInstance.current.destroy();
                }

                const labels = indicatorsHistory.data.map(item => item.label);
                const sumAreaData = indicatorsHistory.data.map(item => item.sum_area);

                areaChartInstance.current = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: labels,
                        datasets: [{
                            label: t('area', { defaultValue: 'Area' }),
                            data: sumAreaData,
                            backgroundColor: 'rgba(153, 199, 255, 1)',
                            borderColor: 'rgba(153, 199, 255, 1)',
                            borderWidth: 1,
                            type: 'bar'
                        }]
                    },
                    options: {
                        scales: {
                            y: {
                                stacked: true
                            }
                        },
                        plugins: {
                            legend: {
                                onClick: () => {}
                            }
                        }
                    }
                });
            }
        }

        if (intensityChartRef.current && indicatorsHistory?.data) {
            const ctx = intensityChartRef.current.getContext('2d');
            if (ctx) {
                if (intensityChartInstance.current) {
                    intensityChartInstance.current.destroy();
                }

                const labels = indicatorsHistory.data.map(item => item.label);
                const sumIntensityData = indicatorsHistory.data.map(item => item.sum_intensity);

                intensityChartInstance.current = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: labels,
                        datasets: [{
                            label: t('intensity', { defaultValue: 'Intensity' }),
                            data: sumIntensityData,
                            backgroundColor: 'rgba(0, 114, 255, 1)',
                            borderColor: 'rgba(0, 114, 255, 1)',
                            borderWidth: 2,
                        }]
                    },
                    options: {
                        scales: {
                            y: {
                                stacked: true
                            }
                        },
                        plugins: {
                            legend: {
                                onClick: () => {}
                            }
                        }
                    }
                });
            }
        }

        return () => {
            if (areaChartInstance.current) {
                areaChartInstance.current.destroy();
            }
            if (intensityChartInstance.current) {
                intensityChartInstance.current.destroy();
            }
        };
    }, [indicatorsHistory, t]);

    return (
        <section>
            <h3 className="text-xl font-bold font-comfortaa text-azulfy-blue">
                {t("indicator_history", { defaultValue: 'Indicator History' })}
            </h3>
            <p className="font-raleway font-semibold text-sm text-azulfy-rich-black mt-2">
                {indicatorsHistory?.indicator?.name} {t('indicator', { defaultValue: 'Indicator' })}
            </p>
            <p className="font-raleway font-normal text-sm text-azulfy-rich-black mb-6">
                {indicatorsHistory?.indicator?.description}
            </p>
            <div className="flex flex-col gap-12 px-3 pt-2">
                <div>
                    <h4 className="text-lg font-bold">{t('area', { defaultValue: 'Area' })}</h4>
                    <canvas ref={areaChartRef} width="400" height="250"></canvas>
                </div>
                <div>
                    <h4 className="text-lg font-bold">{t('intensity', { defaultValue: 'Intensity' })}</h4>
                    <canvas ref={intensityChartRef} width="400" height="250"></canvas>
                </div>
            </div>
        </section>
    );
};
