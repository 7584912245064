import Loading from "components/Loading";
import { useLicenses } from "hooks/useLicenses";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, SelectPicker } from "rsuite";

interface Step2Props {
    isLicenseSelected: boolean;
    setIsLicenseSelected: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Step2({ setIsLicenseSelected }: Step2Props) {
    const { t } = useTranslation();
    const { getAllLicenses, licenses, getLicenseByUuid, license } = useLicenses();
    const [isLoading, setIsLoading] = useState(true);

    const [selectedLicense, setSelectedLicense] = useState<string | null>(null);

    const [selectedServices, setSelectedServices] = useState<string[]>([]);
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
    const [selectedIndicators, setSelectedIndicators] = useState<string[]>([]);
    const [selectedRolesAdmin, setSelectedRolesAdmin] = useState<string[]>([]);
    const [selectedRolesTechnician, setSelectedRolesTechnician] = useState<string[]>([]);
    const [selectedRolesExternal, setSelectedRolesExternal] = useState<string[]>([]);

    const [, setFormDataNewCouncil] = useState({
        code_azulfy: "",
        name: "",
        admin_email: "",
        admin_name: "",
        license_id: "",
        region_map_area: {},
        region_map_area_points: [],
        members: [],
    });

    const licensesOptions = licenses.map((license) => {
        return {
            value: license?.uuid,
            label: license?.name
        }
    })

    async function getLicenses() {
        await getAllLicenses();
        setIsLoading(false);
    }

    function getSelectedLicenseData() {
        const storedData = localStorage.getItem("new-council");
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            if (parsedData && parsedData.license_id) {
                setSelectedLicense(parsedData.license_id);
            }
        }
    }

    useEffect(() => {
        getLicenses();
        getSelectedLicenseData();
    }, []);

    useEffect(() => {
        setIsLicenseSelected(selectedLicense !== null);
    }, [selectedLicense, setIsLicenseSelected]);

    useEffect(() => {
        if (license && license.license && license.permissions.services) {
            const licenseServiceUUIDs = license.license.services.map((service) => service.uuid);

            const selectedServices = license.permissions.services
                .filter((service) => licenseServiceUUIDs.includes(service.uuid))
                .map((service) => service.uuid);

            setSelectedServices(selectedServices);
        }
        if (license && license.license && license.permissions.filters) {
            const licenseFiltersUUIDs = license.license.filters.map((filter) => filter.uuid);

            const selectedFilters = license.permissions.filters
                .filter((filter) => licenseFiltersUUIDs.includes(filter.uuid))
                .map((filter) => filter.uuid);

            setSelectedFilters(selectedFilters);
        }
        if (license && license.license && license.permissions.indicators) {
            const licenseIndicatorsUUIDs = license.license.indicators.map((indicator) => indicator.uuid);

            const selectedIndicators = license.permissions.indicators
                .filter((indicator) => licenseIndicatorsUUIDs.includes(indicator.uuid))
                .map((indicator) => indicator.uuid);

            setSelectedIndicators(selectedIndicators);
        }
        if (license && license.license && license.permissions.admin_role) {
            const licenseAdminsUUIDs = license.license.license_permissions_admin.map((admin) => admin.uuid);

            const selectedAdmins = license.permissions.admin_role
                .filter((admin) => licenseAdminsUUIDs.includes(admin.uuid))
                .map((admin) => admin.uuid);

            setSelectedRolesAdmin(selectedAdmins);
        }
        if (license && license.license && license.permissions.technicians_role) {
            const licenseTechnicianUUIDs = license.license.license_permissions_technician.map((technician) => technician.uuid);

            const selectedTechnicians = license.permissions.technicians_role
                .filter((technician) => licenseTechnicianUUIDs.includes(technician.uuid))
                .map((technician) => technician.uuid);

            setSelectedRolesTechnician(selectedTechnicians);
        }
        if (license && license.license && license.permissions.external_service_provider_role) {
            const licenseExternalUUIDs = license.license.license_permissions_external_service_provider.map((external) => external.uuid);

            const selectedTechnicians = license.permissions.external_service_provider_role
                .filter((external) => licenseExternalUUIDs.includes(external.uuid))
                .map((external) => external.uuid);

            setSelectedRolesExternal(selectedTechnicians);
        }
    }, [license]);

    async function getLicenseSelected(uuid: string) {
        await getLicenseByUuid(uuid);
        setSelectedLicense(uuid);

        setFormDataNewCouncil((prevFormData) => ({
            ...prevFormData,
            license_id: uuid,
        }));

        const storedData = localStorage.getItem("new-council");
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            localStorage.setItem(
                "new-council",
                JSON.stringify({
                    ...parsedData,
                    license_id: uuid,
                })
            );
        }

        const selectedLicenseLabel = licenses.find(license => license.uuid === uuid)?.name || '';
        const maxMembers = licenses.find(license => license.uuid === uuid)?.members || 0;

        localStorage.setItem("maxMembers", maxMembers.toString());
        localStorage.setItem("selectedLicenseLabel", selectedLicenseLabel);
    }

    return (
        <main>
            <header className="w-full">
                <h3 className="text-azulfy-blue font-comfortaa text-xl font-bold">
                    {t('choose_a_License')}
                </h3>
            </header>
            {isLoading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <main className="flex flex-col justify-center items-center gap-12 w-full max-w-5xl mx-auto pb-10">
                        <div className="flex flex-col items-center gap-2 w-full max-w-xs mt-5">
                            <label htmlFor="" className="font-bold text-base text-azulfy-blue">
                                {t("license")}*
                            </label>
                            <SelectPicker
                                data={licensesOptions}
                                searchable={false}
                                style={{ width: '100%', fontSize: '14px', color: '#061425' }}
                                cleanable={false}
                                size="lg"
                                // defaultValue={selectedLicense !== null ? selectedLicense : undefined}
                                value={selectedLicense !== null ? selectedLicense : undefined}
                                // menuStyle={{ color: '#061425' }}
                                className="text-azulfy-rich-black"
                                onChange={(value) => {
                                    getLicenseSelected(value as string)
                                }}
                            />
                        </div>
                        <div className="w-full flex flex-col gap-10 font-comfortaa mt-8">
                            {selectedLicense && (
                                <div className="flex flex-col justify-center lg:items-center items-start gap-2">
                                    <h4 className="text-azulfy-blue font-bold text-xl">
                                        {t('number_of_members')}
                                    </h4>
                                    <p className="text-azulfy-rich-black text-base">
                                        {selectedLicense && licenses.find(license => license.uuid === selectedLicense)?.members}
                                    </p>
                                </div>
                            )}
                            <div className="flex flex-col justify-center lg:items-center items-start gap-2">
                                {license && license.permissions && license.permissions.services.length > 0 && (
                                    <h4 className="text-azulfy-blue font-bold text-xl">
                                        {t('services')}
                                    </h4>
                                )}
                                <div className="flex lg:flex-row flex-col gap-2">
                                    {license && license.permissions && license.permissions.services && (
                                        <>
                                            {license.permissions.services.map((service) => (
                                                <div key={service.uuid} className="flex items-center">
                                                    <Checkbox
                                                        key={service.uuid}
                                                        name={service.name}
                                                        id={service.name}
                                                        disabled
                                                        checked={selectedServices.includes(service.uuid)}
                                                    />
                                                    <label htmlFor={service.name} className="text-azulfy-rich-black text-base cursor-not-allowed opacity-80 text-center">{service.name}</label>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col justify-center lg:items-center items-start gap-2">
                                {license && license.permissions && license.permissions.filters.length > 0 && (
                                    <h4 className="text-azulfy-blue font-bold text-xl">
                                        {t('filters')}
                                    </h4>
                                )}
                                <div className="flex lg:flex-row flex-col gap-2">
                                    {license && license.permissions && license.permissions.filters && (
                                        <>
                                            {license.permissions.filters.map((filter) => (
                                                <div key={filter.uuid} className="flex items-center">
                                                    <Checkbox
                                                        key={filter.uuid}
                                                        name={filter.name}
                                                        id={filter.name}
                                                        disabled
                                                        checked={selectedFilters.includes(filter.uuid)}
                                                    />
                                                    <label htmlFor={filter.name} className="text-azulfy-rich-black text-base cursor-not-allowed opacity-80 text-center">{filter.name}</label>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col justify-center lg:items-center items-start gap-2">
                                {license && license.permissions && license.permissions.indicators.length > 0 && (
                                    <h4 className="text-azulfy-blue font-bold text-xl">
                                        {t('indicators')}
                                    </h4>
                                )}
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                                    {license && license.permissions && license.permissions.indicators && (
                                        <>
                                            {license.permissions.indicators.map((indicator) => (
                                                <div key={indicator.uuid} className="flex items-center">
                                                    <Checkbox
                                                        key={indicator.uuid}
                                                        name={indicator.name}
                                                        id={indicator.name}
                                                        disabled
                                                        checked={selectedIndicators.includes(indicator.uuid)}
                                                    />
                                                    <label htmlFor={indicator.name} className="text-azulfy-rich-black text-base cursor-not-allowed opacity-80 text-center">{indicator.name}</label>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col justify-center lg:items-center items-start gap-2">
                                {license && license.permissions && license.permissions.admin_role.length > 0 && (
                                    <h4 className="text-azulfy-blue font-bold text-xl">
                                        {t('roles')}
                                    </h4>
                                )}
                                <div className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                    <div>
                                        {license && license.permissions && license.permissions.admin_role.length > 0 && (

                                            <h5 className="text-azulfy-rich-black text-base font-bold">
                                                {t('admin')}
                                            </h5>
                                        )}
                                        {license && license.permissions && license.permissions.admin_role && (
                                            <>
                                                {license.permissions.admin_role.map((admin) => (
                                                    <div key={admin.uuid} className="flex items-center">
                                                        <Checkbox
                                                            key={admin.uuid}
                                                            name={admin.name}
                                                            id={admin.name + 'admin'}
                                                            disabled
                                                            checked={selectedRolesAdmin.includes(admin.uuid)}
                                                        />
                                                        <label htmlFor={admin.name + 'admin'} className="text-azulfy-rich-black text-base cursor-not-allowed opacity-80 text-center capitalize">{admin.name}</label>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        {license && license.permissions && license.permissions.technicians_role.length > 0 && (
                                            <h5 className="text-azulfy-rich-black text-base font-bold">
                                                {t('technicians')}
                                            </h5>
                                        )}
                                        {license && license.permissions && license.permissions.technicians_role && (
                                            <>
                                                {license.permissions.technicians_role.map((technician) => (
                                                    <div key={technician.uuid} className="flex items-center">
                                                        <Checkbox
                                                            key={technician.uuid}
                                                            name={technician.name}
                                                            id={technician.name + 'technician'}
                                                            disabled
                                                            checked={selectedRolesTechnician.includes(technician.uuid)}
                                                        />
                                                        <label htmlFor={technician.name + 'technician'} className="text-azulfy-rich-black text-base cursor-not-allowed opacity-80 text-center capitalize">{technician.name}</label>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        {license && license.permissions && license.permissions.external_service_provider_role.length > 0 && (
                                            <h5 className="text-azulfy-rich-black text-base font-bold">
                                                {t('external_service_provider')}
                                            </h5>
                                        )}
                                        {license && license.permissions && license.permissions.external_service_provider_role && (
                                            <>
                                                {license.permissions.external_service_provider_role.map((external) => (
                                                    <div key={external.uuid} className="flex items-center">
                                                        <Checkbox
                                                            key={external.uuid}
                                                            name={external.name}
                                                            id={external.name + 'external'}
                                                            disabled
                                                            checked={selectedRolesExternal.includes(external.uuid)}
                                                        />
                                                        <label htmlFor={external.name + 'external'} className="text-azulfy-rich-black text-base cursor-not-allowed opacity-80 text-center capitalize">{external.name}</label>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )}

        </main>
    )
}
