import Loading from "components/Loading";
import { useWorkspaces } from "hooks/useWorkspaces";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronLeft } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { MemberAdminItem } from "../components/MemberAdminItem";

export default function AlertNotifications() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedManagers, setSelectedManagers] = useState<string[]>([]);
    const [selectedDangerousManagers, setSelectedDangerousManagers] = useState<string[]>([]);
    const { getWorkspacesByUuid, workspace, getAllManagersAdmin, allManagersAdmin, updateManagerAdmin, updateDangerousManagerAdmin } = useWorkspaces();
    const { uuid } = useParams<{ uuid: string }>()

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([getWorkspacesByUuid(uuid!), getAllManagersAdmin(uuid!)]);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [uuid]);

    useEffect(() => {
        if (allManagersAdmin) {
            const initialSelectedManagers = allManagersAdmin.filter(manager => manager.is_manager).map(manager => manager.uuid);
            setSelectedManagers(initialSelectedManagers);
        }
    }, [allManagersAdmin]);

    useEffect(() => {
        if (allManagersAdmin) {
            const initialSelectedDangerousManagers = allManagersAdmin.filter(manager => manager.is_dangerous_alerts_manager).map(manager => manager.uuid);
            setSelectedDangerousManagers(initialSelectedDangerousManagers);
        }
    }, [allManagersAdmin]);

    const handleManagerCheckboxChange = async (manager: string, checked: boolean) => {
        const updatedManagers = checked
            ? [...selectedManagers, manager]
            : selectedManagers.filter(id => id !== manager);

        setSelectedManagers(updatedManagers);

        try {
            await updateManagerAdmin(uuid!, { alerts_managers_ids: updatedManagers });
            await getWorkspacesByUuid(uuid!);
        } catch (error) {
            console.error("Error updating manager:", error);
        }
    };

    const handleDangerousManagerCheckboxChange = async (manager: string, checked: boolean) => {
        const updatedDangerousManagers = checked
            ? [...selectedDangerousManagers, manager]
            : selectedDangerousManagers.filter(id => id !== manager);

        setSelectedDangerousManagers(updatedDangerousManagers);

        try {
            await updateDangerousManagerAdmin(uuid!, { dangerous_alerts_managers_ids: updatedDangerousManagers });
            await getWorkspacesByUuid(uuid!);
        } catch (error) {
            console.error("Error updating manager:", error);
        }
    };

    return (
        <>
            {isLoading ? (
                <>
                    <Loading />
                </>
            ) : (
                <div className="w-full flex flex-col gap-5">
                    <header
                        className="flex items-center w-full justify-between py-1 lg:mb-5 capitalize"
                    >
                        <div className="flex items-center gap-2">
                            <Link to={`/workspace/${uuid}`} className={`hidden md:block`}>
                                <BsChevronLeft size={20} className="text-azulfy-blue" />
                            </Link>
                            <p className="text-azulfy-blue text-base md:text-xl font-bold font-comfortaa">
                                {t('new_alert_notifications')}
                            </p>
                        </div>
                        <p className="text-[#808080] text-sm font-normal font-comfortaa">
                            {workspace.total_alerts_managers_ids}
                        </p>
                    </header>

                    <main className="grid grid-cols-3 gap-x-3">
                        <p className="text-azulfy-rich-black text-xs lg:text-base font-bold font-comfortaa opacity-50">
                            {t('admin_name')}
                        </p>

                        <span className="text-azulfy-rich-black justify-self-center text-xs lg:text-base font-bold font-comfortaa opacity-50">
                            {t('notify_when_new_alerts')}
                        </span>

                        <span className="text-azulfy-rich-black justify-self-center text-xs lg:text-base font-bold font-comfortaa opacity-50">
                            {t('notify_danger_alerts')}
                        </span>

                        {allManagersAdmin?.map((manager) => (
                            <MemberAdminItem
                                key={manager.uuid}
                                manager={manager}
                                handleManagerCheckboxChange={handleManagerCheckboxChange}
                                handleDangerousManagerCheckboxChange={handleDangerousManagerCheckboxChange}
                            />
                        ))}
                    </main>
                </div>
            )}
        </>
    )
}
