import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-number-input';

import { LinearButton } from "components/LinearButton";
import { OptionsProps } from "components/ListMembers";
import {
  E164Number
} from 'libphonenumber-js/core';
import { MembersProps } from "..";

import { SelectPicker } from "rsuite";

interface Step1Props {
  emailIsInvalid: boolean;
  emailExistis: boolean;
  unabledInvite: boolean;
  nameAdmin: string;
  phoneMember: E164Number | undefined;
  handlePhoneMember: (value: E164Number | undefined) => void;
  emailAdmin: string;
  rolesOptions: OptionsProps[];
  roleSelected: string | null;
  nameMemberRef: React.RefObject<HTMLInputElement>;
  emailMemberRef: React.RefObject<HTMLInputElement>;
  setUnabledInvite: (value: React.SetStateAction<boolean>) => void;
  setRoleSelected: (value: React.SetStateAction<string | null>) => void;
  handleInviteMember: () => Promise<void>;
  getInitials(name: string | undefined): string;
  updateMemberRole: (uuid: string, newRole: string) => void;
  undoMember: (index: number) => void;
  formDataNewCouncil: {
    members: MembersProps[];
  };
  numberOfMembersAdded: number;
  limitOfMembers: number;
  unableAddMoreMembers: boolean;
}

export default function Step1({
  emailIsInvalid,
  emailExistis,
  unabledInvite,
  rolesOptions,
  roleSelected,
  nameMemberRef,
  emailMemberRef,
  nameAdmin,
  emailAdmin,
  formDataNewCouncil,
  setUnabledInvite,
  setRoleSelected,
  handleInviteMember,
  updateMemberRole,
  undoMember,
  getInitials,
  numberOfMembersAdded,
  limitOfMembers,
  unableAddMoreMembers,
  phoneMember,
  handlePhoneMember
}: Step1Props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full flex flex-col justify-center items-center gap-8 max-w-xl mx-auto pb-20">
        {unableAddMoreMembers ? (
          <>
            <p className="text-azulfy-blue font-comfortaa text-xl font-bold text-center">
              {t("maximum_members_reached")}
            </p>
          </>
        ) : (
          <>
            <div className="flex flex-col gap-1">
              <p className="text-azulfy-blue font-comfortaa text-2xl font-bold text-center">
                {t("invite_those_who_will_work_on_this_council")}
              </p>
            </div>
            <div className="w-full flex flex-col gap-4">
              <input
                type="text"
                ref={nameMemberRef}
                onChange={(value) => {
                  if (value.target.value === "") {
                    setUnabledInvite(true);
                  } else {
                    setUnabledInvite(false);
                  }
                }}
                placeholder={t("name")}
                className="w-full rounded-lg border border-gray-200 focus:outline-none py-3 px-4"
              />
              <input
                type="email"
                ref={emailMemberRef}
                onChange={(value) => {
                  if (value.target.value === "") {
                    setUnabledInvite(true);
                  } else {
                    setUnabledInvite(false);
                  }
                }}
                placeholder={t("email_address")}
                className="w-full rounded-lg border border-gray-200 focus:outline-none py-3 px-4"
              />
              <SelectPicker
                data={rolesOptions}
                searchable={false}
                style={{
                  width: "100%",
                  fontSize: "14px",
                  color: "#061425",
                }}
                cleanable={false}
                placeholder={t("select_a_role")}
                className="text-azulfy-rich-black"
                size="lg"
                value={roleSelected}
                placement="auto"
                onSelect={(value) => setRoleSelected(value)}
              />
              <PhoneInput
                type="tel"
                name="phone"
                id="phone"
                value={phoneMember}
                onChange={(value) => {
                  handlePhoneMember(value)
                }}
                placeholder={t('phone_number')}
                className="w-full border border-gray-300 rounded-lg px-4 py-2 [&_*:focus]:outline-none [&_*]:text-base"
              />
            </div>
            <LinearButton
              text={`${t("invite")}`}
              onClick={handleInviteMember}
              disabled={unabledInvite}
            />
            {emailExistis && (
              <p className="text-error text-sm font-comfortaa font-normal text-center">
                {t("unable_to_invite_this_user_this_user_has_already_been_invited")}
              </p>
            )}
            {emailIsInvalid && (
              <p className="text-error text-sm font-comfortaa font-normal text-center">
                {t("please_fill_in_a_valid_email_address")}
              </p>
            )}
          </>
        )}

        <div>
          <p className="text-azulfy-blue font-comfortaa text-2xl font-bold text-center">
            {t("council")} - {numberOfMembersAdded}/{limitOfMembers} {t("members")}
          </p>
        </div>
        <div className="w-full flex flex-col gap-4 justify-start">
          <div className="flex flex-col gap-4">
            <p className="text-azulfy-blue font-comfortaa font-bold text-xl">
              {t("admin")}
            </p>
            <div className="flex items-center justify-between">
              <div className="flex gap-4 items-center">
                <div className="flex items-center justify-center bg-azulfy-rich-black w-8 h-8 rounded-full">
                  <span className="font-comfortaa text-xs font-normal text-white">
                    {getInitials(nameAdmin)}
                  </span>
                </div>
                <div className="flex flex-col font-comfortaa">
                  <h3 className="text-base font-bold text-azulfy-rich-black">
                    {nameAdmin}
                  </h3>
                  <p className="text-xs font-normal text-azulfy-rich-black">
                    {emailAdmin}
                  </p>
                </div>
              </div>
              <div className="font-comfortaa flex gap-5">
                <SelectPicker
                  data={rolesOptions}
                  searchable={false}
                  style={{
                    width: "8rem",
                    fontSize: "14px",
                    color: "#061425",
                  }}
                  cleanable={false}
                  className="text-azulfy-rich-black"
                  value={"admin"}
                  disabled
                />
                <button
                  className={`text-sm font-bold text-error opacity-50`}
                  disabled
                >
                  {t("undo")}
                </button>
              </div>
            </div>
            {formDataNewCouncil.members
              .filter((member) => member.role === "admin")
              .map((adminMember, index) => (
                <div key={index} className="flex items-center justify-between">
                  <div className="flex gap-4 items-center">
                    <div className="flex items-center justify-center bg-azulfy-rich-black w-8 h-8 rounded-full">
                      <span className="font-comfortaa text-xs font-normal text-white">
                        {getInitials(adminMember.name)}
                      </span>
                    </div>
                    <div className="flex flex-col font-comfortaa">
                      <h3 className="text-base font-bold text-azulfy-rich-black">
                        {adminMember.name}
                      </h3>
                      <p className="text-xs font-normal text-azulfy-rich-black">
                        {adminMember.email}
                      </p>
                      <p className="text-xs font-normal text-azulfy-rich-black">
                        {adminMember.phone}
                      </p>
                    </div>
                  </div>
                  <div className="font-comfortaa flex gap-5">
                    <SelectPicker
                      data={rolesOptions}
                      searchable={false}
                      style={{
                        width: "8rem",
                        fontSize: "14px",
                        color: "#061425",
                      }}
                      cleanable={false}
                      className="text-azulfy-rich-black"
                      value={adminMember.role}
                      placement="auto"
                      onSelect={(value) =>
                        updateMemberRole(adminMember.uuid, value)
                      }
                    />
                    <button
                      className={`text-sm font-bold text-error hover:opacity-80`}
                      onClick={() => undoMember(index)}
                    >
                      {t("undo")}
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <div className="flex flex-col gap-4">
            {formDataNewCouncil.members.filter(
              (member) => member.role === "technicians"
            ).length > 0 && (
                <p className="text-azulfy-blue font-comfortaa font-bold text-xl">
                  {t("technicians")}
                </p>
              )}
            {formDataNewCouncil.members
              .filter((member) => member.role === "technicians")
              .map((adminMember, index) => (
                <div
                  key={index + 10}
                  className="flex items-center justify-between"
                >
                  <div className="flex gap-4 items-center">
                    <div className="flex items-center justify-center bg-azulfy-rich-black w-8 h-8 rounded-full">
                      <span className="font-comfortaa text-xs font-normal text-white">
                        {getInitials(adminMember.name)}
                      </span>
                    </div>
                    <div className="flex flex-col font-comfortaa">
                      <h3 className="text-base font-bold text-azulfy-rich-black">
                        {adminMember.name}
                      </h3>
                      <p className="text-xs font-normal text-azulfy-rich-black">
                        {adminMember.email}
                      </p>
                      <p className="text-xs font-normal text-azulfy-rich-black">
                        {adminMember.phone}
                      </p>
                    </div>
                  </div>
                  <div className="font-comfortaa flex gap-5">
                    <SelectPicker
                      data={rolesOptions}
                      searchable={false}
                      style={{
                        width: "8rem",
                        fontSize: "14px",
                        color: "#061425",
                      }}
                      cleanable={false}
                      className="text-azulfy-rich-black"
                      value={adminMember.role}
                      placement="auto"
                      onSelect={(value) =>
                        updateMemberRole(adminMember.uuid, value)
                      }
                    />
                    <button
                      className={`text-sm font-bold text-error hover:opacity-80`}
                      onClick={() => undoMember(index)}
                    >
                      {t("undo")}
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <div className="flex flex-col gap-4">
            {formDataNewCouncil.members.filter(
              (member) => member.role === "external_service_provider"
            ).length > 0 && (
                <p className="text-azulfy-blue font-comfortaa font-bold text-xl">
                  {t("external_service_provider")}
                </p>
              )}
            {formDataNewCouncil.members
              .filter((member) => member.role === "external_service_provider")
              .map((adminMember, index) => (
                <div
                  key={index + 1}
                  className="flex items-center justify-between"
                >
                  <div className="flex gap-4 items-center">
                    <div className="flex items-center justify-center bg-azulfy-rich-black w-8 h-8 rounded-full">
                      <span className="font-comfortaa text-xs font-normal text-white">
                        {getInitials(adminMember.name)}
                      </span>
                    </div>
                    <div className="flex flex-col font-comfortaa">
                      <h3 className="text-base font-bold text-azulfy-rich-black">
                        {adminMember.name}
                      </h3>
                      <p className="text-xs font-normal text-azulfy-rich-black">
                        {adminMember.email}
                      </p>
                      <p className="text-xs font-normal text-azulfy-rich-black">
                        {adminMember.phone}
                      </p>
                    </div>
                  </div>
                  <div className="font-comfortaa flex gap-5">
                    <SelectPicker
                      data={rolesOptions}
                      searchable={false}
                      style={{
                        width: "8rem",
                        fontSize: "14px",
                        color: "#061425",
                      }}
                      cleanable={false}
                      className="text-azulfy-rich-black"
                      value={adminMember.role}
                      placement="auto"
                      onSelect={(value) =>
                        updateMemberRole(adminMember.uuid, value)
                      }
                    />
                    <button
                      className={`text-sm font-bold text-error hover:opacity-80`}
                      onClick={() => undoMember(index)}
                    >
                      {t("undo")}
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
