import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";

import {
  Forget,
  Login,
  Reset,
  CreateProfile,
  PasswordExpired,
  Account,
  Profile,
  Language,
  ChangePassword,
  ReportProblem,
  CouncilInfo,
  CouncilMembers,
  AlertPage,
  SupportPage,
  NewCouncil,
  EditCouncil,
  EditGeograficalArea,
  PageNotFound,
  Home,
  ApprovalProcess,
  Live,
  LiveCams,
  CreateLiveCam,
  ReadLiveCam,
  EditLiveCam,
  ListLicenses,
  CreateLicense,
  EditLicense,
  InviteRequest,
  ManageStatus,
  TermsServices,
  PrivacyPolicy,
  AlertNotifications,
} from "./routes";
import DefaultLayout from "layouts/DefaultLayout";
import RedirectTo from "pages";
import PublicRoutes from "./PublicRoutes";
import Loading from "components/Loading";

export const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Outlet />}>
      {/* Redirect */}
      <Route index element={<RedirectTo />} />
      <Route path="term-of-service" element={<TermsServices />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route
        path="redirect"
        element={
          <div className="flex flex-col h-screen w-full justify-center items-center">
            <Loading />
          </div>
        }
      />

      {/* Public routes */}
      <Route element={<PublicRoutes />}>
        <Route path="login" element={<Login />} />
        <Route path="forget-password" element={<Forget />} />
        <Route path="reset-password" element={<Reset />} />
        <Route path="create-profile" element={<CreateProfile />} />
      </Route>

      {/* Private routes */}
      <Route element={<ProtectedRoutes />}>
        {/* Home */}
        <Route
          path=":workspacePath?/:uuidWorkspace?/home"
          element={<DefaultLayout />}
        >
          <Route index element={<Home />} />
        </Route>

        {/* Password Expired */}
        <Route path="password-expired" element={<PasswordExpired />} />

        {/* Account */}
        <Route
          path=":workspacePath?/:uuidWorkspace?/account"
          element={<DefaultLayout />}
        >
          <Route index element={<Account />} />
          <Route path="profile" element={<Profile />} />
          <Route path="language" element={<Language />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="report-problem" element={<ReportProblem />} />
        </Route>

        {/* Council */}
        <Route path="workspace" element={<DefaultLayout />}>
          {/* <Route index element={<Account />} /> */}
          <Route path="new-workspace" element={<NewCouncil />} />
          <Route path=":uuid">
            <Route index element={<CouncilInfo />} />
            <Route path="members" element={<CouncilMembers />} />
            <Route path="edit-workspace" element={<EditCouncil />} />
            <Route
              path="alert-notifications"
              element={<AlertNotifications />}
            />
            <Route
              path="edit-geografical-area"
              element={<EditGeograficalArea />}
            />
            <Route path="manage-status" element={<ManageStatus />} />
          </Route>
        </Route>

        {/* Alert */}
        <Route
          path="workspace/:uuidWorkspace/alerts"
          element={<DefaultLayout />}
        >
          <Route index element={<AlertPage />} />
        </Route>

        {/* Support */}
        <Route
          path="workspace/:uuidWorkspace/support/:uuidSupport"
          element={<DefaultLayout />}
        >
          <Route index element={<SupportPage />} />
        </Route>

        <Route
          path=":workspacePath?/:uuidWorkspace?/support"
          element={<DefaultLayout />}
        >
          <Route index element={<SupportPage />} />
        </Route>

        {/* Live */}
        <Route path="workspace/:uuidWorkspace/live" element={<DefaultLayout />}>
          <Route index element={<Live />} />
        </Route>

        <Route
          path="workspace/:uuidWorkspace/live-cams"
          element={<DefaultLayout />}
        >
          <Route index element={<LiveCams />} />
          <Route path="create-live-cam" element={<CreateLiveCam />} />
          <Route path=":uuidLiveCam" element={<ReadLiveCam />} />
          <Route path=":uuidLiveCam/edit-live-cam" element={<EditLiveCam />} />
        </Route>

        {/* Licenses */}
        <Route path="licenses" element={<DefaultLayout />}>
          <Route index element={<ListLicenses />} />
          <Route path="create" element={<CreateLicense />} />
          <Route path=":uuidLicense" element={<EditLicense />} />
        </Route>

        <Route path="invite-request" element={<InviteRequest />} />

        <Route path="approval-process" element={<ApprovalProcess />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Route>
  )
);
