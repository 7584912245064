import { MapboxGeoJSONPolygon } from "components/MapComponent";
import { api } from "services/axios";
import { create } from "zustand";
import { showErrorMessage } from "../utils/auxFunctions";

interface Pagination {
  current_page: number;
  last_page: number;
  total: number;
  last_page_url: string | null;
}

interface RolesOptionsProps {
  uuid: string;
  name: string;
  email: string;
  language: string;
  password_expires_at: string;
  role_name: string;
  pivot: {
    workspace_id: number;
    user_id: number;
  };
  role: string;
  user?: {
    uuid: string;
    name: string;
    email: string;
    phone?: string;
    initials: string;
    language: string;
    password_expires_at: string;
  };
}

export interface RolesProps {
  admin: RolesOptionsProps[];
  technicians: RolesOptionsProps[];
  external_service_provider: RolesOptionsProps[];
}

interface AreaPoints {
  name: string;
  region_map_area: MapboxGeoJSONPolygon;
  region_map_area_pending: MapboxGeoJSONPolygon;
  uuid?: string;
}

interface License {
  color: string;
  deleted_at: string;
  members: number;
  name: string;
  uuid: string;
}

export interface ManagersAdminProps {
  uuid: string;
  name: string;
  email: string;
  phone: string;
  accepted_invite: boolean;
  language: string;
  password_expires_at: string;
  is_manager: boolean;
  is_dangerous_alerts_manager: boolean;
  initials: string;
}

export interface Workspace {
  uuid: string;
  name: string;
  admin_email: string;
  admin_name: string;
  license_id: string;
  license: License;
  users?: RolesProps;
  total_users?: number;
  identifier?: number;
  total_unread_messages?: number;
  support?: {
    uuid: string;
  };
  deleted_at?: string;
  total_area_points: number;
  status: string;
  code_azulfy: string;
  region_map_area: MapboxGeoJSONPolygon;
  region_map_area_pending: MapboxGeoJSONPolygon;
  area_points: AreaPoints[];
  region_map_area_points?: AreaPoints[];
  total_alerts_managers_ids?: number;
}

interface useWorkspacesProps {
  workspaces: Workspace[];
  workspace: Workspace;
  memberWorkspace: RolesProps;
  allManagersAdmin: ManagersAdminProps[];
  pagination: Pagination;
  getAllWorkspaces: (params?: string) => Promise<Workspace[]>;
  getWorkspacesByUuid: (uuid: string) => Promise<Workspace>;
  getAllMembersOfWorkspace: (uuid: string, params?: string) => Promise<any>;
  getAllManagersAdmin: (workspaceUuid: string) => Promise<any>
  updateManagerAdmin: (workspaceUuid: string, jsonData: object) => Promise<any>;
  updateDangerousManagerAdmin: (workspaceUuid: string, jsonData: object) => Promise<any>;
  createNewMemberOfWorkspace: (
    workspaceUuid: string,
    jsonData: object
  ) => Promise<Workspace>;
  updateRoleMemberOfWorkspace: (
    uuid: string,
    uuidMember: string,
    jsonData: object
  ) => Promise<Workspace>;
  editMemberOfWorkspace: (
    uuidMember: string,
    jsonData: object
  ) => Promise<Workspace>;
  deleteMemberOfWorkspace: (
    uuid: string,
    uuidMember: string
  ) => Promise<Workspace>;
  createNewWorkspace: (dataToPost: object) => Promise<Workspace>;
  checkIfEmailExists: (email: string) => Promise<boolean>;
  updateWorkspace: (
    uuidWorkspace: string,
    partial: Partial<Workspace>
  ) => Promise<Workspace>;
  updateWorkspaceInfo: (
    uuidWorkspace: string,
    partial: Partial<Workspace>
  ) => Promise<Workspace>;
  updateWorkspaceStatus: (
    uuidWorkspace: string,
    partial: Partial<Workspace>
  ) => Promise<Workspace>;
  deleteWorkspace: (uuid: string) => Promise<Workspace>;
}

export const useWorkspaces = create<useWorkspacesProps>((set) => ({
  workspaces: [],
  workspace: {} as Workspace,
  memberWorkspace: {} as RolesProps,
  allManagersAdmin: [] as ManagersAdminProps[],
  pagination: {
    current_page: 1,
    last_page: 1,
    total: 1,
    last_page_url: null,
  },

  getAllWorkspaces: async (params?: string) => {
    try {
      const { data } = await api.get("/workspace" + (params ? params : ""));
      set({ workspaces: data.content.data.data });
      set({
        pagination: {
          current_page: data.content.data.current_page,
          last_page: data.content.data.last_page,
          total: data.content.data.total,
          last_page_url: data.content.data.last_page_url,
        },
      });
      return data.content.data.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  getWorkspacesByUuid: async (uuid: string) => {
    try {
      const { data } = await api.get(`/workspace/${uuid}`);
      set({ workspace: data.content.data });
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  getAllMembersOfWorkspace: async (uuid: string, params?: string) => {
    try {
      const { data } = await api.get(
        `/workspace/${uuid}/members` + (params ? params : "")
      );
      set({ memberWorkspace: data.content.data.data });
      return data.content.data.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  getAllManagersAdmin: async (workspaceUuid: string) => {
    try {
      const { data } = await api.get(`/workspace/${workspaceUuid}/manager-admin`);
      set({ allManagersAdmin: data.content.data });
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  updateManagerAdmin: async (
    workspaceUuid: string,
    jsonData: object
  ) => {
    try {
      const requestBody = JSON.stringify(jsonData);
      const { data } = await api.put(
        `/workspace/${workspaceUuid}/manager-admin`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  updateDangerousManagerAdmin: async (
    workspaceUuid: string,
    jsonData: object
  ) => {
    try {
      const requestBody = JSON.stringify(jsonData);

      const { data } = await api.put(
        `/workspace/${workspaceUuid}/dangerous-alerts`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return data.content.data;
    } catch (error: unknown) {
      showErrorMessage(error);

      return Promise.reject(error)
    }
  },
  createNewMemberOfWorkspace: async (
    workspaceUuid: string,
    jsonData: object
  ) => {
    try {
      const requestBody = JSON.stringify(jsonData);
      const { data } = await api.post(
        `/workspace/${workspaceUuid}/members`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);

      return Promise.reject(error);
    }
  },
  updateRoleMemberOfWorkspace: async (
    uuid: string,
    uuidMember: string,
    jsonData: object
  ) => {
    try {
      const requestBody = JSON.stringify(jsonData);
      const { data } = await api.put(
        `/workspace/${uuid}/members/${uuidMember}`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return data.content.data;
    } catch (error: any) {
      throw error;
    }
  },
  editMemberOfWorkspace: async (
    uuidMember: string,
    jsonData: object
  ) => {
    try {
      const requestBody = JSON.stringify(jsonData);

      const { data } = await api.put(
        `/users/${uuidMember}`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return data.content.data;
    } catch (error: unknown) {
      showErrorMessage(error);
    }
  },
  deleteMemberOfWorkspace: async (uuid: string, uuidMember: string) => {
    try {
      const { data } = await api.delete(
        `/workspace/${uuid}/members/${uuidMember}`
      );
      return data.content.data;
    } catch (error: any) {
      throw error;
    }
  },
  createNewWorkspace: async (dataToPost: object) => {
    try {
      const { data } = await api.post("/workspace", dataToPost, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data.content.data;
    } catch (error: any) {
      console.error(error);
    }
  },
  checkIfEmailExists: async (email: string) => {
    try {
      const { data } = await api.post(
        `/checkEmail`,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (data.headerCode === 200 && data.content.status) {
        return true;
      } else if (data.headerCode === 400 && !data.content.status) {
        return false;
      } else {
        return false;
      }
    } catch (error: any) {
      return false;
    }
  },
  updateWorkspace: async (
    uuidWorkspace: string,
    partial: Partial<Workspace>
  ) => {
    try {
      const { data } = await api.put(`/workspace/${uuidWorkspace}`, partial);
      set({ workspace: data.content.data });
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  updateWorkspaceInfo: async (
    uuidWorkspace: string,
    partial: Partial<Workspace>
  ) => {
    try {
      const { data } = await api.put(`/workspace/${uuidWorkspace}/info`, partial);
      set({ workspace: data.content.data });
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  updateWorkspaceStatus: async (
    uuidWorkspace: string,
    partial: Partial<Workspace>
  ) => {
    try {
      const { data } = await api.post(
        `/workspace/${uuidWorkspace}/status`,
        partial
      );
      // set({ workspace: data.content.data });
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  deleteWorkspace: async (uuid: string) => {
    try {
      const { data } = await api.delete(`/workspace/${uuid}`);
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
}));
