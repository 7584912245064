import { Alert, useAlerts } from "hooks/useAlerts";
import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useUser } from "hooks/useUser";
import { BiWater } from "react-icons/bi";
import { severityColors } from "utils/severityColors";
import { TbSatellite, TbWind } from "react-icons/tb";
import { GiGroundSprout } from "react-icons/gi";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { Modal } from "rsuite";
interface AboutAlertModalProps {
    alert: Alert;
}

export default function AboutAlertModal({ alert }: AboutAlertModalProps) {
    const { t } = useTranslation();
    const { authUser } = useUser();
    const { setAlertSelectedHome } = useAlerts();

    return (
        <>
            <Modal.Header>
                <Modal.Title>
                    <div className="flex flex-col items-start gap-3">
                        <p className="font-semibold text-lg text-azulfy-blue font-raleway">
                            {alert?.name}
                        </p>
                        <Link
                            to={`/workspace/${authUser?.workspaces?.uuid}/alerts?alert=${alert?.uuid}`}
                            onClick={() => setAlertSelectedHome(null)}
                            className="flex items-center gap-1 hover:no-underline hover:text-white text-white font-bold font-comfortaa rounded-[10px] px-4 py-2 text-xs bg-Azulfy-Linear-Gradient hover:bg-Azulfy-Linear-Gradient-hover transition-all active:scale-[0.98] active:bg-Azulfy-Linear-Gradient-pressed"
                        >
                            {t("open_chat_alert")}
                            <BsChevronRight size={10} />
                        </Link>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="flex flex-col gap-2 font-raleway w-full">
                    <div className="flex flex-col gap-1 rounded-[20px] shadow">
                        <div className="flex flex-col gap-2 font-raleway mt-2">
                            <div className="flex items-center justify-start gap-2">
                                <div className="flex items-center gap-1">
                                    <span
                                        className={`rounded-full`}
                                    >
                                        {alert?.indicators.filter_id === 1 && (
                                            <BiWater
                                                color={severityColors.find((color) => color.value === alert.severity)?.label}
                                                size={18}
                                            />
                                        )}
                                        {alert?.indicators.filter_id === 2 && (
                                            <TbWind
                                                color={severityColors.find((color) => color.value === alert.severity)?.label}
                                                size={18}
                                            />
                                        )}

                                        {alert?.indicators.filter_id === 3 && (
                                            <GiGroundSprout
                                                color={severityColors.find((color) => color.value === alert.severity)?.label}
                                                size={18}
                                            />
                                        )}
                                    </span>
                                    <p className="font-semibold text-sm text-azulfy-rich-black">{alert?.indicators?.name}</p>
                                </div>
                                <span className="font-normal text-sm text-grey-800">
                                    {new Date(alert?.alert_datetime).toLocaleDateString("pt-BR", { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false }).replace(',', " ")}
                                </span>
                            </div>
                            <div className="flex items-center justify-start gap-2">
                                <div className="flex items-center gap-1">
                                    <span
                                        className={`rounded-full`}
                                    >
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 5.25073V5.25823M12 11.2507V11.2582M8.121 7.37164C8.54065 6.95212 8.82646 6.41757 8.94229 5.8356C9.05812 5.25364 8.99876 4.65039 8.77173 4.10216C8.54469 3.55393 8.16017 3.08534 7.66681 2.75565C7.17344 2.42597 6.59338 2.25 6 2.25C5.40662 2.25 4.82656 2.42597 4.3332 2.75565C3.83983 3.08534 3.45531 3.55393 3.22828 4.10216C3.00124 4.65039 2.94188 5.25364 3.05771 5.8356C3.17354 6.41757 3.45935 6.95212 3.879 7.37164L6 9.49339L8.121 7.37164ZM14.121 13.3716C14.5406 12.9521 14.8265 12.4176 14.9423 11.8356C15.0581 11.2536 14.9988 10.6504 14.7717 10.1022C14.5447 9.55393 14.1602 9.08534 13.6668 8.75565C13.1734 8.42597 12.5934 8.25 12 8.25C11.4066 8.25 10.8266 8.42597 10.3332 8.75565C9.83983 9.08534 9.45531 9.55393 9.22828 10.1022C9.00124 10.6504 8.94188 11.2536 9.05771 11.8356C9.17354 12.4176 9.45935 12.9521 9.879 13.3716L12 15.4934L14.121 13.3716Z" stroke="#061425" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                    <p className="font-semibold text-sm text-azulfy-rich-black">{t('location_coordinates')}</p>
                                </div>
                                <span className="font-normal text-sm text-grey-800">
                                    {alert?.lat} , {alert?.lng}
                                </span>
                            </div>
                        </div>
                        <hr className="my-6" />
                        <div>
                            <p className="font-semibold text-lg text-azulfy-blue font-raleway">{t("satellite")}</p>
                            <div className="mt-3">
                                <p className="font-semibold text-sm text-azulfy-rich-black flex items-center gap-0.5">
                                    <TbSatellite className="text-azulfy-rich-black" size={18} />
                                    {t("name")}
                                </p>
                                <span className="font-normal text-sm text-grey-800 font-raleway">{alert?.satellite?.name}</span>
                                <p className="font-normal text-sm text-grey-800 mt-1">{alert?.satellite?.description}</p>
                            </div>
                        </div>
                        <hr className="my-6" />
                        <div>
                            <p className="font-semibold text-lg text-azulfy-blue font-raleway">{alert?.indicators?.name}</p>
                            <div className="mt-3">
                                <p className="font-semibold text-sm text-azulfy-rich-black flex items-center gap-0.5">
                                    <HiOutlineInformationCircle className="text-azulfy-rich-black" size={18} />
                                    {t("overview")}
                                </p>
                                <p className="font-normal text-sm text-grey-800 mt-1">{alert?.indicators?.description}</p>
                                <img className="mt-4 rounded-xl w-full h-60 object-cover" src={alert?.indicators?.image} alt={alert?.indicators?.name} />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </>
    );
}
