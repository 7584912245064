import ListMembers from "../../../components/ListMembers";

export default function index() {

  return (
    <>
      <main>
        <div className="w-full mx-auto">
          <ListMembers showButtonAddMembers />
        </div>
      </main>
    </>
  )
}
