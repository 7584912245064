import * as Dialog from "@radix-ui/react-dialog";
import Loading from "components/Loading";
import { useLicenses } from "hooks/useLicenses";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronLeft } from "react-icons/bs";

import ConfirmDeleteLiveCamModal from "components/ConfirmDeleteLiveCamModal";
import { LinearButton } from "components/LinearButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Checkbox, Input, InputNumber } from "rsuite";
import { toast } from "utils/toast";

export default function EditLicense() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { uuidLicense } = useParams<{ uuidLicense: string }>();
  const { getLicenseByUuid, license, updateLicense, deleteLicense } = useLicenses();
  const [disabledButton, setDisabledButton] = useState(true);
  const navigate = useNavigate();
  const [showColorPicker, setShowColorPicker] = useState(false);

  const [nameLicense, setNameLicense] = useState< string | null >(null);
  const [numberMembers, setNumberMembers] = useState<number | null>(null);
  const [colorLicense, setColorLicense] = useState<string | null>(null);

  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [selectedIndicators, setSelectedIndicators] = useState<string[]>([]);
  const [selectedRolesAdmin, setSelectedRolesAdmin] = useState<string[]>([]);
  const [selectedRolesTechnician, setSelectedRolesTechnician] = useState<string[]>([]);
  const [selectedRolesExternal, setSelectedRolesExternal] = useState<string[]>([]);

  const colorPickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getLicense();
  }, [uuidLicense]);

  async function getLicense() {
    await getLicenseByUuid(uuidLicense!)
    .then((response) => {
      setNameLicense(response?.license?.name);
      setNumberMembers(response?.license?.members);
      setColorLicense(response?.license?.color);
    })
    setIsLoading(false);
  }

  useEffect(() => {
    if (nameLicense !== "" && numberMembers !== 0) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  })

  

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target as Node)) {
        setShowColorPicker(false);
      }
    }

    if (showColorPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showColorPicker]);

  const handleServiceCheckboxChange = (uuid: string) => {
    setSelectedServices((prevSelected) =>
      prevSelected.includes(uuid)
        ? prevSelected.filter((id) => id !== uuid)
        : [...prevSelected, uuid]
    );
  };

  const handleFilterCheckboxChange = (uuid: string) => {
    setSelectedFilters((prevSelected) =>
      prevSelected.includes(uuid)
        ? prevSelected.filter((id) => id !== uuid)
        : [...prevSelected, uuid]
    );
  };

  const handleIndicatorCheckboxChange = (uuid: string) => {
    setSelectedIndicators((prevSelected) =>
      prevSelected.includes(uuid)
        ? prevSelected.filter((id) => id !== uuid)
        : [...prevSelected, uuid]
    );
  };

  const handleRoleAdminCheckboxChange = (uuid: string) => {
    setSelectedRolesAdmin((prevSelected) =>
      prevSelected.includes(uuid)
        ? prevSelected.filter((id) => id !== uuid)
        : [...prevSelected, uuid]
    );
  };

  const handleRoleTechnicianCheckboxChange = (uuid: string) => {
    setSelectedRolesTechnician((prevSelected) =>
      prevSelected.includes(uuid)
        ? prevSelected.filter((id) => id !== uuid)
        : [...prevSelected, uuid]
    );
  };

  const handleRoleExternalCheckboxChange = (uuid: string) => {
    setSelectedRolesExternal((prevSelected) =>
      prevSelected.includes(uuid)
        ? prevSelected.filter((id) => id !== uuid)
        : [...prevSelected, uuid]
    );
  }

  useEffect(() => {
    if (license && license.license && license.permissions.services) {
      const licenseServiceUUIDs = license.license.services.map((service) => service.uuid);

      const selectedServices = license.permissions.services
        .filter((service) => licenseServiceUUIDs.includes(service.uuid))
        .map((service) => service.uuid);

      setSelectedServices(selectedServices);
    }
    if (license && license.license && license.permissions.filters) {
      const licenseFiltersUUIDs = license.license.filters.map((filter) => filter.uuid);

      const selectedFilters = license.permissions.filters
        .filter((filter) => licenseFiltersUUIDs.includes(filter.uuid))
        .map((filter) => filter.uuid);

      setSelectedFilters(selectedFilters);
    }
    if (license && license.license && license.permissions.indicators) {
      const licenseIndicatorsUUIDs = license.license.indicators.map((indicator) => indicator.uuid);

      const selectedIndicators = license.permissions.indicators
        .filter((indicator) => licenseIndicatorsUUIDs.includes(indicator.uuid))
        .map((indicator) => indicator.uuid);

      setSelectedIndicators(selectedIndicators);
    }
    if (license && license.license && license.permissions.admin_role) {
      const licenseAdminsUUIDs = license.license.license_permissions_admin.map((admin) => admin.uuid);

      const selectedAdmins = license.permissions.admin_role
        .filter((admin) => licenseAdminsUUIDs.includes(admin.uuid))
        .map((admin) => admin.uuid);

      setSelectedRolesAdmin(selectedAdmins);
    }
    if (license && license.license && license.permissions.technicians_role) {
      const licenseTechnicianUUIDs = license.license.license_permissions_technician.map((technician) => technician.uuid);

      const selectedTechnicians = license.permissions.technicians_role
        .filter((technician) => licenseTechnicianUUIDs.includes(technician.uuid))
        .map((technician) => technician.uuid);

      setSelectedRolesTechnician(selectedTechnicians);
    }
    if (license && license.license && license.permissions.external_service_provider_role) {
      const licenseExternalUUIDs = license.license.license_permissions_external_service_provider.map((external) => external.uuid);

      const selectedTechnicians = license.permissions.external_service_provider_role
        .filter((external) => licenseExternalUUIDs.includes(external.uuid))
        .map((external) => external.uuid);

      setSelectedRolesExternal(selectedTechnicians);
    }
  }, [license]);

  async function handleUpdateLicense() {
    const response = await updateLicense(uuidLicense!, {
      name: nameLicense ? nameLicense : license?.license?.name,
      members: numberMembers ? numberMembers : license?.license?.members,
      color: colorLicense ? colorLicense : license?.license?.color,
      services: selectedServices,
      filters: selectedFilters,
      indicators: selectedIndicators,
      admin_role: selectedRolesAdmin,
      technicians_role: selectedRolesTechnician,
      external_service_provider_role: selectedRolesExternal
    })
    if (response) {
      toast({
        label: t('success'),
        message: t('license_updated_successfully'),
        type: "success",
      });
      navigate(`/licenses`)
      setNameLicense(null);
      setNumberMembers(0);
      setColorLicense('#0072FF');
      setSelectedServices([])
      setSelectedFilters([])
      setSelectedIndicators([])
      setSelectedRolesAdmin([])
      setSelectedRolesTechnician([])
      setSelectedRolesExternal([])
    }
  }

  async function handleDeleteLicense() {
    await deleteLicense(uuidLicense!)
    toast({
      label: t('success'),
      message: t('license_deleted_successfully'),
      type: "success",
    });
    navigate(`/licenses`)
  }

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-[400px]">
          <Loading />
        </div>
      ) : (
        <div className="flex flex-col gap-8 ">
          <header className="flex max-md:flex-col gap-y-3 items-center max-md:justify-center">
            <Link to='/licenses' className="flex items-center gap-3 hover:no-underline">
              <BsChevronLeft size={20} className="text-azulfy-blue" />
            </Link>
            
            <h1 className="text-azulfy-blue text-xl max-md:text-base font-bold">
              {t('manage_license')}
            </h1>
          </header>

          <main className="flex flex-col gap-12 w-full max-w-5xl mx-auto pb-10">
            <div className="flex lg:flex-row flex-col gap-5">
              <div className="flex flex-col gap-2">
                <label htmlFor="" className="font-bold text-base text-azulfy-blue ">
                  {t("license_name")}
                </label>

                <Input
                  size="lg"
                  placeholder={t("name")}
                  defaultValue={license?.license?.name}
                  onChange={(e) => setNameLicense(e)}
                  className="w-full rounded-lg border border-gray-200 focus:outline-none py-3 px-4 text-azulfy-rich-black"

                />
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="" className="font-bold text-base text-azulfy-blue ">
                  {t("number_of_members")}*
                </label>
                <InputNumber
                  size="lg"
                  placeholder={t("number_of_members")}
                  defaultValue={license?.license?.members}
                  onChange={(value) => setNumberMembers(value as number)}
                  min={0}
                  className="w-full rounded-lg border border-gray-200 focus:outline-none py-[0.20rem] px-1 text-azulfy-rich-black"
                />
              </div>
            </div>
            <div className="w-full flex flex-col gap-10 font-comfortaa mt-8">
              <div className="flex flex-col gap-2">
                <h4 className="text-azulfy-blue font-bold text-xl">
                  {t('services')}
                </h4>
                <div className="flex lg:flex-row flex-col gap-2">
                  {license && license.permissions && license.permissions.services && (
                    <>
                      {license.permissions.services.map((service) => (
                        <div key={service.uuid} className="flex items-center">
                          <Checkbox
                            key={service.uuid}
                            name={service.name}
                            id={service.name}
                            onChange={() => handleServiceCheckboxChange(service.uuid)}
                            checked={selectedServices.includes(service.uuid)}

                          />
                          <label htmlFor={service.name} className="text-azulfy-rich-black text-base cursor-pointer text-center">{service.name}</label>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="text-azulfy-blue font-bold text-xl">
                  {t('filters')}
                </h4>
                <div className="flex lg:flex-row flex-col gap-2">
                  {license && license.permissions && license.permissions.filters && (
                    <>
                      {license.permissions.filters.map((filter) => (
                        <div key={filter.uuid} className="flex items-center">
                          <Checkbox
                            key={filter.uuid}
                            name={filter.name}
                            id={filter.name}
                            onChange={() => handleFilterCheckboxChange(filter.uuid)}
                            checked={selectedFilters.includes(filter.uuid)}
                          />
                          <label htmlFor={filter.name} className="text-azulfy-rich-black text-base cursor-pointer text-center">{filter.name}</label>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="text-azulfy-blue font-bold text-xl">
                  {t('indicators')}
                </h4>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                  {license && license.permissions && license.permissions.indicators && (
                    <>
                      {license.permissions.indicators.map((indicator) => (
                        <div key={indicator.uuid} className="flex items-center">
                          <Checkbox
                            key={indicator.uuid}
                            name={indicator.name}
                            id={indicator.name}
                            onChange={() => handleIndicatorCheckboxChange(indicator.uuid)}
                            checked={selectedIndicators.includes(indicator.uuid)}
                          />
                          <label htmlFor={indicator.name} className="text-azulfy-rich-black text-base cursor-pointer text-start">{indicator.name}</label>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="text-azulfy-blue font-bold text-xl">
                  {t('roles')}
                </h4>
                <div className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                  <div>
                    <h5 className="text-azulfy-rich-black text-base font-bold">
                      {t('admin')}
                    </h5>
                    {license && license.permissions && license.permissions.admin_role && (
                      <>
                        {license.permissions.admin_role.map((admin) => (
                          <div key={admin.uuid} className="flex items-center">
                            <Checkbox
                              key={admin.uuid}
                              name={admin.name}
                              id={admin.name + 'admin'}
                              onChange={() => handleRoleAdminCheckboxChange(admin.uuid)}
                              checked={selectedRolesAdmin.includes(admin.uuid)}
                            />
                            <label htmlFor={admin.name + 'admin'} className="text-azulfy-rich-black text-base cursor-pointer text-start capitalize">{admin.name}</label>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  <div>
                    <h5 className="text-azulfy-rich-black text-base font-bold">
                      {t('technicians')}
                    </h5>
                    {license && license.permissions && license.permissions.technicians_role && (
                      <>
                        {license.permissions.technicians_role.map((technician) => (
                          <div key={technician.uuid} className="flex items-center">
                            <Checkbox
                              key={technician.uuid}
                              name={technician.name}
                              id={technician.name + 'technician'}
                              onChange={() => handleRoleTechnicianCheckboxChange(technician.uuid)}
                              checked={selectedRolesTechnician.includes(technician.uuid)}
                            />
                            <label htmlFor={technician.name + 'technician'} className="text-azulfy-rich-black text-base cursor-pointer text-start capitalize">{technician.name}</label>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  <div>
                    <h5 className="text-azulfy-rich-black text-base font-bold">
                      {t('external_service_provider')}
                    </h5>
                    {license && license.permissions && license.permissions.external_service_provider_role && (
                      <>
                        {license.permissions.external_service_provider_role.map((external) => (
                          <div key={external.uuid} className="flex items-center">
                            <Checkbox
                              key={external.uuid}
                              name={external.name}
                              id={external.name + 'external'}
                              onChange={() => handleRoleExternalCheckboxChange(external.uuid)}
                              checked={selectedRolesExternal.includes(external.uuid)}
                            />
                            <label htmlFor={external.name + 'external'} className="text-azulfy-rich-black text-base cursor-pointer text-start capitalize">{external.name}</label>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full max-w-xl mx-auto flex gap-4">
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <button
                    className="px-4 py-3 w-full shadow-md rounded-[10px] text-white text-center font-comfortaa text-xl max-md:!text-base font-bold bg-error hover:opacity-80 transition-all active:scale-[0.98]"
                  >
                    {t("delete_license")}
                  </button>
                </Dialog.Trigger>
                <ConfirmDeleteLiveCamModal
                  title={t("are_you_sure_you_want_to_delete_this_license")}
                  handleDelete={handleDeleteLicense}
                />
              </Dialog.Root>
              <LinearButton text={t('save')} onClick={handleUpdateLicense} disabled={disabledButton} />
            </div>
          </main>
        </div>
      )}
    </>
  );
}
