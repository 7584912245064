import Map from "components/MapComponent";
import { MutableRefObject } from "react";
import { BsBoundingBoxCircles, BsZoomIn, BsZoomOut } from "react-icons/bs";

const MapBasicButtons = ({
  mapRef,
  canDraw,
  drawRef,
}: {
  mapRef: MutableRefObject<mapboxgl.Map | null>;
  canDraw: boolean;
  drawRef: MutableRefObject<MapboxDraw | null>;
}) => {
  return (
    <Map.Item className="right-4 bottom-4">
      <ul className="flex flex-col p-2.5 border border-grey-400 bg-grey-600 rounded-lg gap-3 !m-0 list-none">
        {canDraw && (
          <li>
            <button
              title={"Draw"}
              className="border-b"
              onClick={() => {
                if (!canDraw) return;
                drawRef.current?.changeMode("draw_polygon");
              }}
            >
              <BsBoundingBoxCircles className="w-4 h-4 text-azulfy-rich-black" />
            </button>
            <hr className="h-[1px] w-full bg-grey-400 " />
          </li>
        )}

        <li>
          <button
            title={"Zoom in"}
            className="border-b"
            onClick={() => mapRef?.current?.zoomIn()}
          >
            <BsZoomIn className="w-4 h-4 text-azulfy-rich-black" />
          </button>
          <hr className="h-[1px] w-full bg-grey-400 " />
        </li>

        <li>
          <button
            title={"Zoom out"}
            className="border-b"
            onClick={() => mapRef?.current?.zoomOut()}
          >
            <BsZoomOut className="w-4 h-4 text-azulfy-rich-black" />
          </button>
        </li>
      </ul>
    </Map.Item>
  );
};

export default MapBasicButtons;
