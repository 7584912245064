import { api } from "services/axios";
import { v4 as uuid } from "uuid";
import { create } from "zustand";
import { showErrorMessage } from "../utils/auxFunctions";
import type { AzulfyAuthUser, User } from "./useUser";
import { Workspace } from "./useWorkspaces";

interface Pagination {
  current_page: number;
  last_page: number;
  total: number;
  last_page_url: string | null;
}

export interface Support {
  uuid: string;
  name: string;
  type: string;
  status: string;
  indicator: string;
  alert_datetime: string;
  messages_new: number;
  category: string;
  total_unread_messages: number;
  deleted_at?: string;
}

export interface Attachment {
  uuid: string;
  file_name: string;
  file_type: string;
  created_at: Date;
  updated_at: Date;
  isTemporary?: boolean;
}

export interface SupportMessage {
  uuid: string;
  message: string;
  created_at: Date;
  updated_at: Date;
  user: User;
  read: number;
  attachments: Attachment[];
  isTemporary?: boolean;
}

interface useSupportProps {
  supports: Workspace[];
  support: Workspace;
  supportMessages: SupportMessage[];
  unreadMessages: SupportMessage[];
  supportsPagination: Pagination;
  supportMessagesPagination: Pagination;
  getSupports: (params?: string, concat?: boolean) => Promise<Workspace[] | null>;
  getSupportMessages: (
    workspaceUuid: string,
    supportUuid: string,
    params?: string,
    concat?: boolean
  ) => Promise<any>;
  setSupport: (support: Workspace) => void;
  setSupports: (supports: Workspace[]) => void;
  deleteAlert: (alertUuid: string) => Promise<any>;
  createNewSupportMessage: (
    workspaceUuid: string,
    supporttUuid: string,
    obtToPost: { message: string; attachments: File[] }
  ) => Promise<any>;
  updateAlert: (alertUuid: string, partial: Partial<Support>) => Promise<any>;
}

export const useSupport = create<useSupportProps>((set, get) => ({
  supports: [],
  supportMessages: [],
  unreadMessages: [],
  supportsPagination: {
    current_page: 1,
    last_page: 1,
    total: 1,
    last_page_url: null,
  },
  supportMessagesPagination: {
    current_page: 1,
    last_page: 1,
    total: 1,
    last_page_url: null,
  },
  support: {} as Workspace,
  getSupports: async (params?: string, concat?: boolean) => {
    const authUser = localStorage.getItem("azulfyAuthUser")
      ? (JSON.parse(localStorage.getItem("azulfyAuthUser")!) as AzulfyAuthUser)
      : null;

    if (!authUser) return;

    try {
      const { data } = await api.get(
        `/workspace` +
          (params ? params : "")
      );

      if (concat) {
        set({ supports: [...get().supports, ...data.content.data.data] });
      } else {
        set({ supports: data.content.data.data });
      }
      set({
        supportsPagination: {
          current_page: data.content.data.current_page,
          last_page: data.content.data.last_page,
          total: data.content.data.total,
          last_page_url: data.content.data.last_page_url,
        },
      });
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
      return null;
    }
  },

  getSupportMessages: async (
    workspaceUuid: string,
    supportUuid: string,
    params?: string,
    concat?: boolean
  ) => {
    const authUser = localStorage.getItem("azulfyAuthUser")
      ? (JSON.parse(localStorage.getItem("azulfyAuthUser")!) as AzulfyAuthUser)
      : null;

    if (!authUser) return;

    try {
      const { data } = await api.get(
        `/workspace/${workspaceUuid}/support/${supportUuid}/messages` +
          (params ? params : "")
      );
      if (!data?.content?.data) return;
      const messages = data.content.data.read.data;
      if (concat) {
        if (get().unreadMessages?.length > 0) {
          set({
            unreadMessages: [],
          });
        }
        set({
          supportMessages: [...messages.reverse(), ...get().supportMessages],
        });
      } else {
        set({ supportMessages: messages.reverse() });
        set({ unreadMessages: data.content.data.unread.data });
      }
      set({
        supportMessagesPagination: {
          current_page: data.content.data.read.current_page,
          last_page: data.content.data.read.last_page,
          total: data.content.data.read.total,
          last_page_url: data.content.data.read.last_page_url,
        },
      });
      return data.content.data.data;
    } catch (error: any) {
      showErrorMessage(error);
      return null;
    }
  },

  setSupport: (support: Workspace) => {
    set({ support });
  },

  setSupports: (supports: Workspace[]) => {
    set({ supports });
  },

  deleteAlert: async (alertUuid: string) => {
    const authUser = localStorage.getItem("azulfyAuthUser")
      ? (JSON.parse(localStorage.getItem("azulfyAuthUser")!) as AzulfyAuthUser)
      : null;

    if (!authUser) return;

    try {
      const { data } = await api.delete(
        `/workspace/${authUser?.workspaces.uuid}/alerts/${alertUuid}`
      );
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
      return null;
    }
  },

  createNewSupportMessage: async (
    workspaceUuid: string,
    supportUuid: string,
    obtToPost: { message: string; attachments: File[] }
  ) => {
    const authUser = localStorage.getItem("azulfyAuthUser")
      ? (JSON.parse(localStorage.getItem("azulfyAuthUser")!) as AzulfyAuthUser)
      : null;

    if (!authUser) return;

    try {
      const tempUuid = uuid();
      const newSupportMessages = get().supportMessages;
      set({ unreadMessages: [] });
      if (obtToPost.message && obtToPost.attachments) {
        const temporaryAttachments = [] as Attachment[];
        obtToPost.attachments.map((attachment) => {
          temporaryAttachments.push({
            uuid: uuid(),
            file_name: attachment.name,
            file_type: attachment.type,
            created_at: new Date(),
            updated_at: new Date(),
            isTemporary: true,
          });
        });
        const newMessage: SupportMessage = {
          uuid: tempUuid,
          message: obtToPost.message,
          attachments: temporaryAttachments,
          created_at: new Date(),
          updated_at: new Date(),
          read: 1,
          user: authUser.user!,
          isTemporary: true,
        };
        newSupportMessages.push(newMessage);
        set({ supportMessages: newSupportMessages });
      }
      const { data } = await api.post(
        `/workspace/${workspaceUuid}/support/${supportUuid}/messages`,
        obtToPost,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (data?.content?.data) {
        const index = newSupportMessages.findIndex(
          (message) => message.uuid === tempUuid
        );
        if (index >= 0) {
          newSupportMessages[index] = data.content.data;
          set({ supportMessages: newSupportMessages });
        } else {
          newSupportMessages.push(data.content.data);
          set({ supportMessages: newSupportMessages });
        }
      }
      return data.content.data;
    } catch (error: any) {
      showErrorMessage(error);
      return null;
    }
  },

  updateAlert: async (alertUuid: string, partial: Partial<Support>) => {
    const authUser = localStorage.getItem("azulfyAuthUser")
      ? (JSON.parse(localStorage.getItem("azulfyAuthUser")!) as AzulfyAuthUser)
      : null;

    if (!authUser) return;

    try {
      const { data } = await api.put(
        `/workspace/${authUser?.workspaces.uuid}/alerts/${alertUuid}`,
        partial
      );
      if (data?.content) {
        const newAlerts = get().supports;
        const newAlert = get().support;
        const index = newAlerts.findIndex((alert) => alert.uuid === alertUuid);

        if (newAlert) set({ support: { ...newAlert, ...partial } });
        if (index >= 0) {
          newAlerts[index] = { ...newAlerts[index], ...partial };
          set({ supports: newAlerts });
        }
        return data.content.data;
      }
      return null;
    } catch (error: any) {
      showErrorMessage(error);
      return null;
    }
  },
}));
