import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { Modal } from 'rsuite'
import { api } from 'services/axios'
import { showErrorMessage } from 'utils/auxFunctions'

import { LinearButton } from 'components/LinearButton'
import { useUser } from 'hooks/useUser'
import { BsDownload } from 'react-icons/bs'
import { toast } from 'utils/toast'
import buildingReportGif from '../../../../../assets/building-report.gif'
import sendEmailSvg from '../../../../../assets/send-email.svg'

export default function ReportPopOver() {
  const { t } = useTranslation()
  const { authUser } = useUser()
  const [showModal, setShowModal] = useState(false)
  const [showModalSendEmail, setShowModalSendEmail] = useState(false)

  const [searchParams] = useSearchParams()
  const alertUuid = searchParams.get('alert')

  const { uuidWorkspace } = useParams<{ uuidWorkspace: string }>()

  const handleDownload = async (isShowMessages: boolean) => {
    try {
      setShowModal(true)

      const response = await api.get(
        `workspace/${uuidWorkspace}/alerts/${alertUuid}/report-alert${
          isShowMessages ? '?isShowMessages=true' : ''
        }`
      )

      if (response.data.status === false) {
        toast({
          label: t('error'),
          message: t('no_attachments_found_in_this_alert'),
          type: 'error',
        })
      } else {
        setShowModal(false)
        setShowModalSendEmail(true)
      }
    } catch (error) {
      showErrorMessage(error)
    } finally {
      setShowModal(false)
    }
  }

  return (
    <>
      <Modal
        size='xs'
        onOpen={() => setShowModal(true)}
        onClose={() => setShowModal(false)}
        open={showModal}
        backdrop='static'
      >
        <Modal.Body className='flex flex-col gap-3 justify-center items-center'>
          <Modal.Title className='text-center text-lg'>
            {t('generating_a_report')}
          </Modal.Title>
          <img src={buildingReportGif} alt='building-report' className='w-28' />
        </Modal.Body>
      </Modal>

      {showModalSendEmail && (
        <Modal
          size='xs'
          onOpen={() => setShowModalSendEmail(true)}
          open={showModalSendEmail}
          backdrop='static'
        >
          <Modal.Body className='flex flex-col gap-3 justify-center items-center'>
            <div className='flex flex-col justify-center items-center gap-2'>
              <img src={sendEmailSvg} className='w-24 h-24' alt='send email' />
              <h1 className='text-azulfy-blue font-bold text-center text-base'>
                {t('report_generated_successfully')}:
              </h1>
              <span className='text-sm text-azulfy-rich-black font-raleway'>
                {`${authUser?.user?.email}`}
              </span>
              <div className='flex items-center mt-2 justify-end gap-4'>
                <LinearButton
                  onClick={() => {
                    setShowModalSendEmail(false)
                  }}
                  text={t('continue')}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <div className='flex-col hidden md:flex items-center justify-center w-full gap-3 text-azulfy-rich-black'>
        <button
          className='flex items-center gap-[10px] hover:opacity-70 rounded-md shadow-md px-3 py-2'
          onClick={() => handleDownload(false)}
        >
          <span>{t('data_summary')}</span>
          <BsDownload size={16} className='md:text-azulfy-rich-black' />
        </button>
        <button
          className='flex items-center gap-[10px] hover:opacity-70 rounded-md shadow-md px-3 py-2'
          onClick={() => handleDownload(true)}
        >
          <span>{t('all_data_with_comments')}</span>
          <BsDownload size={16} className='md:text-azulfy-rich-black' />
        </button>
      </div>
    </>
  )
}
