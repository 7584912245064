import { useRef } from "react";
import { useTranslation } from "react-i18next";

import * as Dialog from "@radix-ui/react-dialog";

import trashIlustration from "../../assets/trashIlustration.svg"
import { CgSpinner } from "react-icons/cg";

interface ConfirmDeleteCouncilModalProps {
  councilName: string;
  handleDelete: () => void;
  onCloseConfirmModal: () => void;
  loading: boolean;
}

export default function ConfirmDeleteCouncilModal({
  councilName,
  handleDelete,
  onCloseConfirmModal,
  loading,
}: ConfirmDeleteCouncilModalProps) {
  const { t } = useTranslation();
  const closeButton = useRef<HTMLButtonElement>(null);

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          inset: 0,
          background: "rgba(0, 0, 0, 0.75)",
          zIndex: "16",
        }}
      />
      <Dialog.Content
        forceMount
        className={
          "z-40 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white px-12 py-8 rounded-lg max-md:w-[90vw] font-comfortaa"
        }
      >
        <Dialog.Close ref={closeButton} className={"hidden"}></Dialog.Close>
        <div className="flex flex-col items-center gap-5 w-full md:max-w-[240px]">
          <img src={trashIlustration} className="w-[7.5rem] h-[7.5rem]" alt="delete" />
          <div
            className="flex flex-col gap-1"
          >
            <h1
              className="text-azulfy-rich-black font-bold text-base text-center"
            >
              <span>
                {t("are_you_sure_you_want_to_delete")}
              </span>
              {" "} "{councilName}"{" "}
              <span className="lowercase">
                {t("council")}?
              </span>
            </h1>
            <span
              className="text-base font-raleway text-center text-azulfy-rich-black"
            >
              {t("this_action_cannot_be_undone")}
            </span>
          </div>
          <div className="flex flex-col items-center justify-center gap-4 w-full">
            <button
              onClick={() => {
                handleDelete();
                // closeButton.current?.click();
              }}
              disabled={loading}
              className={`py-2 px-3 w-full shadow-md rounded-[10px] text-white text-center font-comfortaa text-base  max-md:!text-base font-bold bg-error hover:opacity-80 transition-all active:scale-[0.98] focus-visible:outline-none flex items-center justify-center gap-3 ${loading ? "cursor-not-allowed opacity-50 hover:opacity-50" : "cursor-pointer"}`}
            >
              {loading ? (
                <>
                  <CgSpinner className="animate-spin w-6 h-6 mx-auto text-white" />
                </>
              ) : (
                <>
                  {t("yes_delete_council")}
                </>

              )}
            </button>
            <button
              onClick={() => onCloseConfirmModal()}
              disabled={loading}
              className="flex items-center justify-center w-full py-2 px-3 rounded-[10px] bg-white font-comfortaa font-bold text-base bg-opacity-20 hover:opacity-70 text-azulfy-rich-black border border-azulfy-rich-black transition-all active:scale-[0.98] focus-visible:outline-none"
            >
              {t("keep_council")}
            </button>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
