import * as Popover from "@radix-ui/react-popover";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { useAlerts } from "hooks/useAlerts";
import { BsChevronRight } from "react-icons/bs";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "utils/toast";
import { getAlertStatuses } from "../../AlertStatuses";


export default function ChangeAlertStatusPopOver() {
  const { t } = useTranslation();
  const { updateAlertStatus, alert, getAlerts, getAlertByUuid } = useAlerts();
  const { uuidWorkspace } = useParams<{ uuidWorkspace: string }>();
  const [searchParams,] = useSearchParams();
  const alertUuid = searchParams.get("alert");

  const statuses = getAlertStatuses();

  const closeButton = useRef<HTMLButtonElement>(null);

  const activeIndex = statuses.findIndex((status) => status.value === alert?.status?.type);

  async function handleUpdateAlertStatus(statusType: string) {
    closeButton.current?.click();
    await updateAlertStatus(uuidWorkspace!, alertUuid!, { type: statusType })
    await getAlertByUuid(alertUuid!);
    await getAlerts();
    toast({
      label: t("success"),
      message: t("alert_updated_successfully"),
      type: "success",
    });
  }

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button
          className={`flex items-center gap-2 justify-between py-2 px-3 rounded-md shadow-md bg-white cursor-pointer hover:opacity-70 min-h-[44px]`}
        >
          <p className="text-xs font-bold text-azulfy-rich-black select-none line-clamp-1 text-start">
            {statuses.find((status) => status.value === alert?.status?.type)?.label}
          </p>
          <BsChevronRight className='text-azulfy-rich-black' size={12} />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          sideOffset={3}
          align="start"
          className="bg-white shadow-lg rounded-md border border-grey-400 border-opacity-30 w-full max-h-60 overflow-y-scroll overflow-x-hidden"
        >
          <Popover.Close ref={closeButton} hidden />
          <div className="flex flex-col text-azulfy-rich-black divide-y divide-slate-200 font-comfortaa w-[var(--radix-popover-trigger-width)]">
            {statuses.map((status, index) => (
              <button
                key={status.value}
                onClick={() => handleUpdateAlertStatus(status.value)}
                className={`hover:opacity-70 text-xs font-bold text-start text-azulfy-rich-black py-3 px-3 flex items-center justify-between gap-3 line-clamp-2 ${index < activeIndex ? "bg-[#D2D2D3] opacity-50" : ""}`}
              >
                {status.label}
                {alert?.status?.type === status.value && (
                  <span className="text-azulfy-blue text-[10px] whitespace-nowrap">{t("current")}</span>
                )}
              </button>
            ))}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
