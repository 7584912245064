import { useLicenses } from "hooks/useLicenses";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronLeft } from "react-icons/bs";


import { LinearButton } from "components/LinearButton";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox, Input, InputNumber } from "rsuite";
import { toast } from "utils/toast";

export default function CreateLicense() {
  const { t } = useTranslation();
  const { createNewLicense, getAllPermissions, permissions } = useLicenses();
  const navigate = useNavigate();
  const [disabledButton, setDisabledButton] = useState(true);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const [nameLicense, setNameLicense] = useState("");
  const [numberMembers, setNumberMembers] = useState(0);
  // const [colorLicense, setColorLicense] = useState("#0072FF");

  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [selectedIndicators, setSelectedIndicators] = useState<string[]>([]);
  const [selectedRolesAdmin, setSelectedRolesAdmin] = useState<string[]>([]);
  const [selectedRolesTechnician, setSelectedRolesTechnician] = useState<string[]>([]);
  const [selectedRolesExternal, setSelectedRolesExternal] = useState<string[]>([]);

  const colorPickerRef = useRef<HTMLDivElement>(null);

  async function getPermissions() {
    await getAllPermissions();
  }

  useEffect(() => {
    getPermissions();
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target as Node)) {
        setShowColorPicker(false);
      }
    }

    if (showColorPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showColorPicker]);

  useEffect(() => {
    if (nameLicense.trim() !== "" && numberMembers !== 0) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [nameLicense, numberMembers]);

  const handleServiceCheckboxChange = (uuid: string) => {
    setSelectedServices((prevSelected) =>
      prevSelected.includes(uuid)
        ? prevSelected.filter((id) => id !== uuid)
        : [...prevSelected, uuid]
    );
  };

  const handleFilterCheckboxChange = (uuid: string) => {
    setSelectedFilters((prevSelected) =>
      prevSelected.includes(uuid)
        ? prevSelected.filter((id) => id !== uuid)
        : [...prevSelected, uuid]
    );
  };

  const handleIndicatorCheckboxChange = (uuid: string) => {
    setSelectedIndicators((prevSelected) =>
      prevSelected.includes(uuid)
        ? prevSelected.filter((id) => id !== uuid)
        : [...prevSelected, uuid]
    );
  };

  const handleRoleAdminCheckboxChange = (uuid: string) => {
    setSelectedRolesAdmin((prevSelected) =>
      prevSelected.includes(uuid)
        ? prevSelected.filter((id) => id !== uuid)
        : [...prevSelected, uuid]
    );
  };

  const handleRoleTechnicianCheckboxChange = (uuid: string) => {
    setSelectedRolesTechnician((prevSelected) =>
      prevSelected.includes(uuid)
        ? prevSelected.filter((id) => id !== uuid)
        : [...prevSelected, uuid]
    );
  };

  const handleRoleExternalCheckboxChange = (uuid: string) => {
    setSelectedRolesExternal((prevSelected) =>
      prevSelected.includes(uuid)
        ? prevSelected.filter((id) => id !== uuid)
        : [...prevSelected, uuid]
    );
  }

  async function handleCreateLicense() {
    const response = await createNewLicense({
      name: nameLicense,
      members: numberMembers ? numberMembers : 0,
      color: '#0072FF',
      services: selectedServices,
      filters: selectedFilters,
      indicators: selectedIndicators,
      admin_role: selectedRolesAdmin,
      technicians_role: selectedRolesTechnician,
      external_service_provider_role: selectedRolesExternal
    })
    if (response) {
      toast({
        label: t('success'),
        message: t('license_created_successfully'),
        type: "success",
      });
      navigate(`/licenses`)
    }
    setNameLicense('');
    setNumberMembers(0);
    setSelectedServices([])
    setSelectedFilters([])
    setSelectedIndicators([])
    setSelectedRolesAdmin([])
    setSelectedRolesTechnician([])
    setSelectedRolesExternal([])
  }

  return (
    <>
      <div className="flex flex-col gap-8 ">
        <header className="flex max-md:flex-col gap-y-3 items-center justify-between max-md:justify-center">
          <Link to='/licenses' className="flex items-center gap-3 hover:no-underline">
            <BsChevronLeft size={20} className="text-azulfy-blue" />

            <h1 className="text-azulfy-blue text-xl max-md:text-base font-bold">
              {t('create_new_license')}
            </h1>
          </Link>
        </header>

        <main className="flex flex-col w-full max-w-5xl mx-auto pb-10">
          <div className="flex lg:flex-row flex-col mb-4 gap-5">
            <div className="flex flex-col gap-2">
              <label htmlFor="" className="font-bold text-base text-azulfy-blue ">
                {t("license_name")}
              </label>
              
              <Input
                size="lg"
                placeholder={t("name")}
                onChange={(e) => setNameLicense(e)}
                className="w-full rounded-lg border border-gray-200 focus:outline-none py-3 px-4 text-azulfy-rich-black"

              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="" className="font-bold text-base text-azulfy-blue ">
                {t("number_of_members")}*
              </label>
              <InputNumber
                size="lg"
                placeholder={t("number_of_members")}
                onChange={(value) => setNumberMembers(value as number)}
                min={0}
                className="w-full rounded-lg border border-gray-200 focus:outline-none min-h-[48px] [&_span]:flex [&_span]:flex-col [&_span]:justify-center [&_input]:!h-[48px] text-azulfy-rich-black"
              />
            </div>
            {/* <div className="flex flex-col gap-2">
              <label htmlFor="" className="font-bold text-base text-azulfy-blue ">
                {t("choose_a_color")}*
              </label>
              <div className="w-full relative" ref={colorPickerRef}>
                <button
                  onClick={() => setShowColorPicker(!showColorPicker)}
                  className="w-full flex items-center justify-between gap-4 rounded-lg border border-gray-200 focus:outline-none py-2 px-4 text-grey-200"
                >
                  <span
                    style={{ backgroundColor: colorLicense ? colorLicense : "#0072FF" }}
                    className="rounded-md text-white font-comfortaa font-bold px-2 py-1"
                  >
                    {colorLicense ? colorLicense : "#0072FF"}
                  </span>
                  <BsChevronDown size={16} className={`text-azulfy-rich-black ${showColorPicker ? ' rotate-180' : ''} `} />

                </button>
                {showColorPicker && (
                  <TwitterPicker
                    className="!absolute left-0 top-14"
                    triangle="hide"
                    onChange={(color) => {
                      setColorLicense(color.hex)
                    }}
                  />
                )}
              </div>
            </div> */}
          </div>
          <div className="w-full flex flex-col gap-10 font-comfortaa mt-8">
            <div className="flex flex-col gap-2">
              <h4 className="text-azulfy-blue font-bold text-xl">
                {t('services')}
              </h4>

              <div className="flex lg:flex-row flex-col gap-2">
                {permissions.services && (
                  <>
                    {permissions.services.map((service) => (
                      <div key={service.uuid} className="flex items-center">
                        <Checkbox
                          key={service.uuid}
                          name={service.name}
                          id={service.name}
                          onChange={() => handleServiceCheckboxChange(service.uuid)}
                          checked={selectedServices.includes(service.uuid)}
                        />

                        <label htmlFor={service.name} className="text-azulfy-rich-black text-base cursor-pointer">{service.name}</label>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <h4 className="text-azulfy-blue font-bold text-xl">
                {t('filters')}
              </h4>
              <div className="flex lg:flex-row flex-col gap-2">
                {permissions.filters && (
                  <>
                    {permissions.filters.map((filter) => (
                      <div key={filter.uuid} className="flex items-center">
                        <Checkbox
                          key={filter.uuid}
                          name={filter.name}
                          id={filter.name}
                          onChange={() => handleFilterCheckboxChange(filter.uuid)}
                          checked={selectedFilters.includes(filter.uuid)}
                        />
                        <label htmlFor={filter.name} className="text-azulfy-rich-black text-base cursor-pointer">{filter.name}</label>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <h4 className="text-azulfy-blue font-bold text-xl">
                {t('indicators')}
              </h4>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                {permissions.indicators && (
                  <>
                    {permissions.indicators.map((indicator) => (
                      <div key={indicator.uuid} className="flex items-center">
                        <Checkbox
                          key={indicator.uuid}
                          name={indicator.name}
                          id={indicator.name}
                          onChange={() => handleIndicatorCheckboxChange(indicator.uuid)}
                          checked={selectedIndicators.includes(indicator.uuid)}
                        />
                        <label htmlFor={indicator.name} className="text-azulfy-rich-black text-base cursor-pointer">{indicator.name}</label>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <h4 className="text-azulfy-blue font-bold text-xl">
                {t('roles')}
              </h4>
              <div className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                <div>
                  <h5 className="text-azulfy-rich-black text-base font-bold">
                    {t('admin')}
                  </h5>
                  {permissions.admin_role && (
                    <>
                      {permissions.admin_role.map((admin) => (
                        <div key={admin.uuid} className="flex items-center">
                          <Checkbox
                            key={admin.uuid}
                            name={admin.name}
                            id={admin.name + 'admin'}
                            onChange={() => handleRoleAdminCheckboxChange(admin.uuid)}
                            checked={selectedRolesAdmin.includes(admin.uuid)}
                          />
                          <label htmlFor={admin.name + 'admin'} className="text-azulfy-rich-black text-base cursor-pointer text-start capitalize">{admin.name}</label>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <div>
                  <h5 className="text-azulfy-rich-black text-base font-bold">
                    {t('technicians')}
                  </h5>
                  {permissions.technicians_role && (
                    <>
                      {permissions.technicians_role.map((technician) => (
                        <div key={technician.uuid} className="flex items-center">
                          <Checkbox
                            key={technician.uuid}
                            name={technician.name}
                            id={technician.name + 'technician'}
                            onChange={() => handleRoleTechnicianCheckboxChange(technician.uuid)}
                            checked={selectedRolesTechnician.includes(technician.uuid)}
                          />
                          <label htmlFor={technician.name + 'technician'} className="text-azulfy-rich-black text-base cursor-pointer text-start capitalize">{technician.name}</label>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <div>
                  <h5 className="text-azulfy-rich-black text-base font-bold">
                    {t('external_service_provider')}
                  </h5>
                  {permissions.external_service_provider_role && (
                    <>
                      {permissions.external_service_provider_role.map((external) => (
                        <div key={external.uuid} className="flex items-center">
                          <Checkbox
                            key={external.uuid}
                            name={external.name}
                            id={external.name + 'external'}
                            onChange={() => handleRoleExternalCheckboxChange(external.uuid)}
                            checked={selectedRolesExternal.includes(external.uuid)}
                          />
                          <label htmlFor={external.name + 'external'} className="text-azulfy-rich-black text-base cursor-pointer text-start capitalize">{external.name}</label>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full max-w-md mx-auto mt-20">
            <LinearButton text={t('create_license')} onClick={handleCreateLicense} disabled={disabledButton} />
          </div>
        </main>
      </div>
    </>
  );
}
