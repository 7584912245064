import { useRef } from "react";
import { useTranslation } from "react-i18next";

import * as Dialog from "@radix-ui/react-dialog";

interface ConfirmDeleteModalProps {
  title: string;
  description: string;
  handleConfirm?: () => void;
  handleCancel?: () => void;
  children?: React.ReactNode;
}

export default function ConfirmStatusModal({
  title,
  description,
  handleConfirm,
  handleCancel,
  children,
}: ConfirmDeleteModalProps) {
  const { t } = useTranslation();
  const closeButton = useRef<HTMLButtonElement>(null);

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          inset: 0,
          background: "rgba(0, 0, 0, 0.75)",
          zIndex: "16",
        }}
      />
      <Dialog.Content
        forceMount
        className={
          "z-40 wrounded-lg top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed bg-white px-12 py-8 rounded-lg font-comfortaa max-w-[588px]"
        }
      >
        <Dialog.Close ref={closeButton} className={"hidden"}></Dialog.Close>
        <div className="flex flex-col gap-5">
          <h1 className="text-base font-bold text-azulfy-blue">{title}</h1>
          <span className="text-sm text-azulfy-rich-black font-raleway">
            {description}
          </span>

          {children}
          <div className="flex items-center justify-end gap-4">
            <button
              onClick={() => {
                closeButton.current?.click();
                handleCancel?.();
              }}
              className="flex items-center justify-center px-3 py-2 rounded-md bg-primary-200 bg-opacity-20 hover:opacity-70 text-azulfy-rich-black"
            >
              {t("cancel")}
            </button>
            <button
              onClick={handleConfirm}
              className="flex items-center justify-center px-3 py-2 font-bold text-white rounded-md bg-Azulfy-Linear-Gradient hover:bg-Azulfy-Linear-Gradient-hover active:bg-Azulfy-Linear-Gradient-pressed"
            >
              {t("confirm")}
            </button>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
