import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { SearchInput } from "components/SearchInput";

import { BsChevronRight, BsPeopleFill } from "react-icons/bs";
import Loading from "components/Loading";
import { useHeader } from "hooks/useHeader";
import { useSupport } from "hooks/useSupport";
import { Workspace } from "hooks/useWorkspaces";

interface ListSupportsProps {
  selectedSupport: string | null;
  setSelectedSupport: (uuid: string | null) => void;
}

export default function ListSupports({ selectedSupport }: ListSupportsProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [search, setSearch] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const { t } = useTranslation();

  const { getSupports, supports, supportsPagination, setSupports } =
    useSupport();
  const { setShowHeader } = useHeader();

  const listAlertDiv = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  async function getAllSupports() {
    setIsLoading(true);
    await getSupports("?page=1&sortBy=updated_at&sortDirection=desc");
    setIsLoading(false);
  }

  useEffect(() => {
    setShowHeader(true);
  }, []);

  useEffect(() => {
    getAllSupports();
    setSearch("");
  }, []);

  async function handleSelectSupport(support: Workspace) {
    const alertIndex = supports.indexOf(support);
    if (alertIndex > -1) {
      supports[alertIndex].total_unread_messages = 0;
      setSupports([...supports]);
    }
    navigate(
      `/support/?supportUuid=${support.support?.uuid}&workspaceUuid=${support.uuid}`
    );
  }

  useEffect(() => {
    const delayDebounce = setTimeout(async () => {
      if (!isTyping) return;
      if (search) {
        await getSupports(
          `?page=1&sortBy=updated_at&sortDirection=desc&search=${search}`
        );
      } else {
        await getAllSupports();
      }
      setIsTyping(false);
    }, 300);
    return () => clearTimeout(delayDebounce);
  }, [search, isTyping]);

  useEffect(() => {
    const onScroll = async () => {
      if (
        listAlertDiv?.current &&
        listAlertDiv?.current?.scrollTop +
          listAlertDiv?.current?.clientHeight ===
          listAlertDiv?.current?.scrollHeight &&
        !isLoading &&
        supportsPagination.current_page < supportsPagination.last_page
      ) {
        setLoadingMore(true);
        getSupports(
          `?page=1&sortBy=updated_at&sortDirection=desc&page=${
            supportsPagination.current_page + 1
          }`,
          true
        );
        setLoadingMore(false);
      }
    };

    listAlertDiv?.current?.addEventListener("scroll", onScroll);

    return () => listAlertDiv?.current?.removeEventListener("scroll", onScroll);
  }, [supportsPagination, listAlertDiv, isLoading]);

  return (
    <div className="flex flex-col items-center py-4 gap-4 h-full max-h-full">
      <div className="hidden max-md:flex items-center justify-center w-full px-3 mb-2">
        <SearchInput
          placeholder={`${t("search")}...`}
          showSearchIcon
          value={search}
          onChange={(e) => {
            setIsTyping(true);
            setSearch(e.target.value);
          }}
          classNamesInput="max-md:text-sm"
          classNamesDiv="w-full px-4 py-3"
        />
      </div>
      <h3 className="font-bold text-azulfy-blue max-md:text-xl">
        {t("chats")}
      </h3>
      <div className="hidden md:flex items-center justify-center w-full px-3">
        <SearchInput
          placeholder={`${t("search")}...`}
          value={search}
          onChange={(e) => {
            setIsTyping(true);
            setSearch(e.target.value);
          }}
          showSearchIcon
          classNamesInput="max-lg:text-xs"
        />
      </div>

      <div
        ref={listAlertDiv}
        className="flex flex-col w-full text-azulfy-rich-black h-full overflow-y-auto"
      >
        {isLoading ? (
          <div className="flex h-full items-center justify-center">
            <Loading />
          </div>
        ) : (
          <>
            {supports &&
              supports?.map((support) => {
                const isSelected = selectedSupport === support.support?.uuid;
                return (
                  <button
                    onClick={() => handleSelectSupport(support)}
                    key={support.uuid}
                    className={`flex items-center justify-between w-full p-3 border-b border-grey-400 border-opacity-50 ${
                      isSelected ? "bg-azulfy-blue" : ""
                    }`}
                  >
                    <div className="flex items-center gap-[10px]">
                      <span
                        className={`p-2 rounded-full ${
                          isSelected ? "bg-white" : "bg-azulfy-blue"
                        }`}
                      >
                        <BsPeopleFill
                          color={isSelected ? "#0072FF" : "#fff"}
                          size={16}
                        />
                      </span>
                      <div className="flex flex-col items-center">
                        <h3
                          className={`text-sm max-lg:text-xs font-semibold ${
                            isSelected ? "text-white" : ""
                          }`}
                        >
                          {support.name}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center gap-1">
                      {support.total_unread_messages! > 0 && (
                        <span className="bg-[#FC6530] rounded-full w-4 h-4 max-lg:w-3 max-lg:h-3 text-white text-[8px] max-lg:text-[7px] font-semibold py-[3px] max-lg:py-[2px] px-[1px] text-center">
                          {support.total_unread_messages}
                        </span>
                      )}
                      <span className="hover:opacity-70">
                        <BsChevronRight
                          size={12}
                          className={"text-azulfy-rich-black"}
                        />
                      </span>
                    </div>
                  </button>
                );
              })}
          </>
        )}
        {loadingMore && (
          <div className="flex items-center justify-center py-2">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
}
