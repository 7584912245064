import { useRef } from "react";
import * as Popover from "@radix-ui/react-popover";

import { useAlerts } from "hooks/useAlerts";

import { TbWorldLatitude, TbWorldLongitude } from "react-icons/tb";
import ReportPopOver from "../ReportPopOver";
import ObserverAlert from "../ObserverAlert";

interface DeleteChatPopOverProps {
  setSelectedAlert: (uuid: string | null) => void;
}

export default function DeleteChatPopOver({ }: DeleteChatPopOverProps) {
  const closeButton = useRef<HTMLButtonElement>(null);

  const { alert } = useAlerts();

  return (
    <Popover.Portal>
      <Popover.Content
        sideOffset={5}
        align="end"
        className="bg-white p-4 shadow-lg rounded-md border border-grey-400 border-opacity-30"
      >
        <Popover.Close ref={closeButton} hidden />
        <div className="flex flex-col items-center justify-center w-full gap-3">
          <div className="flex md:flex-row flex-col items-center justify-between gap-5">
            <div className="flex items-center gap-[5px]">
              <TbWorldLatitude size={24} color="#00C6FF" />
              <p className="text-azulfy-blue font-raleway text-xs font-bold">
                Latitude
              </p>
              <span className="ml-[5px]">{alert?.lat || 0}</span>
            </div>
            <div className="flex items-center gap-[5px]">
              <TbWorldLongitude size={24} color="#00C6FF" />
              <p className="text-azulfy-blue font-raleway text-xs font-bold">
                Longitude
              </p>
              <span className="ml-[5px]">{alert?.lng || 0}</span>
            </div>
          </div>

          <div className="w-full block lg:hidden my-5">
            <ObserverAlert />
          </div>

          <ReportPopOver />
        </div>
      </Popover.Content>
    </Popover.Portal>
  );
}
