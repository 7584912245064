import React, { ButtonHTMLAttributes } from "react";
import { BsChevronRight } from "react-icons/bs";

interface ButtonFilterProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  textSize?: string;
}

export const ButtonFilter = React.forwardRef<
  HTMLButtonElement,
  Omit<ButtonFilterProps, "ref">
>(({ text, textSize, ...props }, ref) => (
  <button
    className="p-3 rounded-lg flex items-center gap-[5px] bg-azulfy-blue hover:bg-azulfy-blue-hover active:bg-azulfy-blue-pressed text-white text-xs font-bold font-comfortaa disabled:bg-grey-400"
    {...props}
    ref={ref}
  >
    <span>{text}</span>
    <BsChevronRight className="text-xs text-white" />
  </button>
));
