import { useTranslation } from "react-i18next"
import Header from "components/Header"

export default function TermsServices() {
    const { t } = useTranslation()
    return (
        <main className="pb-10 container mx-auto lg:max-w-4xl xl:max-w-7xl">
            <Header position="absolute" />
            <div className="mt-[112px] max-md:mt-[80px] font-comfortaa px-4 lg:px-0">
                <header
                    className="flex items-center w-full justify-center py-1 mb-8"
                >
                    <p className="text-azulfy-blue text-xl font-bold font-comfortaa">
                        {t('terms_of_service')}
                    </p>
                </header>
                <p className="text-azulfy-rich-black text-sm text-right mb-4">{t('updated_on')} 26,12,2023</p>
                <h2 className="text-xl font-bold text-azulfy-blue mb-3">
                    {t('welcome_to_azulfy.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('welcome_to_azulfy.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('welcome_to_azulfy.p2')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('welcome_to_azulfy.p3')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('welcome_to_azulfy.p4')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('welcome_to_azulfy.p5')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('welcome_to_azulfy.p6')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('welcome_to_azulfy.p7')}
                </p>
                <ul className="text-azulfy-rich-black text-sm">
                    <li>
                        {t('welcome_to_azulfy.p7_1')}
                    </li>
                    <li>
                        {t('welcome_to_azulfy.p7_2')}
                    </li>
                    <li>
                        {t('welcome_to_azulfy.p7_3')}
                    </li>
                </ul>
                <p className="text-azulfy-rich-black text-sm">
                    {t('welcome_to_azulfy.p8')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('welcome_to_azulfy.p9')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('about_us.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('about_us.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('about_us.p2')}
                </p>
                <ul className="text-azulfy-rich-black text-sm">
                    <li>
                        {t('about_us.p2_1')}
                    </li>
                    <li>
                        {t('about_us.p2_2')}
                    </li>
                    <li>
                        {t('about_us.p2_3')}
                    </li>
                </ul>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('registration.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('registration.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('registration.p2')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('registration.p3')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('registration.p4')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('registration.p5')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('registration.p6')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('membership_payment.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('membership_payment.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('membership_payment.p2')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('membership_payment.p3')}
                </p>
                <ul className="text-azulfy-rich-black text-sm">
                    <li>
                        {t('membership_payment.p3_1')}
                    </li>
                    <li>
                        {t('membership_payment.p3_2')}
                    </li>
                    <li>
                        {t('membership_payment.p3_3')}
                    </li>
                </ul>
                <p className="text-azulfy-rich-black text-sm">
                    {t('membership_payment.p4')}
                </p>
                <ul className="text-azulfy-rich-black text-sm">
                    <li>
                        {t('membership_payment.p4_1')}
                    </li>
                    <li>
                        {t('membership_payment.p4_2')}
                    </li>
                    <li>
                        {t('membership_payment.p4_3')}
                    </li>
                </ul>
                <p className="text-azulfy-rich-black text-sm">
                    {t('membership_payment.p5')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('membership_payment.p6')}
                </p>
                <ul className="text-azulfy-rich-black text-sm">
                    <li>
                        {t('membership_payment.p6_1')}
                    </li>
                    <li>
                        {t('membership_payment.p6_2')}
                    </li>
                    <li>
                        {t('membership_payment.p6_3')}
                    </li>
                    <li>
                        {t('membership_payment.p6_4')}
                    </li>
                </ul>
                <p className="text-azulfy-rich-black text-sm">
                    {t('membership_payment.p7')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('membership_payment.p8')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('membership_payment.p9')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('cancellation_policy.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('cancellation_policy.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('cancellation_policy.p2')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('duties_of_the_member.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('duties_of_the_member.p1')}
                </p>
                <ul className="text-azulfy-rich-black text-sm">
                    <li>
                        {t('duties_of_the_member.p1_1')}
                    </li>
                    <li>
                        {t('duties_of_the_member.p1_2')}
                    </li>
                    <li>
                        {t('duties_of_the_member.p1_3')}
                    </li>
                    <li>
                        {t('duties_of_the_member.p1_4')}
                    </li>
                    <li>
                        {t('duties_of_the_member.p1_5')}
                    </li>
                    <li>
                        {t('duties_of_the_member.p1_6')}
                    </li>
                </ul>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('intellectual_property.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('intellectual_property.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('intellectual_property.p2')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('intellectual_property.p3')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('intellectual_property.p4')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('availability_of_the_platform.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('availability_of_the_platform.p1')}
                </p>
                <ul className="text-azulfy-rich-black text-sm">
                    <li>
                        {t('availability_of_the_platform.p1_1')}
                    </li>
                    <li>
                        {t('availability_of_the_platform.p1_2')}
                    </li>
                    <li>
                        {t('availability_of_the_platform.p1_3')}
                    </li>
                    <li>
                        {t('availability_of_the_platform.p1_4')}
                    </li>
                    <li>
                        {t('availability_of_the_platform.p1_5')}
                    </li>
                </ul>
                <p className="text-azulfy-rich-black text-sm">
                    {t('availability_of_the_platform.p2')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('availability_of_the_platform.p3')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('warranties.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('warranties.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('warranties.p2')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('warranties.p3')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('limitation_of_liability.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('limitation_of_liability.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('limitation_of_liability.p2')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('security.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('security.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('security.p2')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('governing_law_and_jurisdiction.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('governing_law_and_jurisdiction.p1')}
                </p>
                <h2 className="text-xl font-bold text-azulfy-blue my-3">
                    {t('general_provisions.title')}
                </h2>
                <p className="text-azulfy-rich-black text-sm">
                    {t('general_provisions.p1')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('general_provisions.p2')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('general_provisions.p3')}
                </p>
                <p className="text-azulfy-rich-black text-sm">
                    {t('general_provisions.p4')}
                </p>
            </div>
        </main>
    )
}
