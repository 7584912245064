import { FeaturePoint } from "components/Home/HomeAdmin/HomeAdminMap";
import mapboxgl from "mapbox-gl";
import { MutableRefObject, useEffect } from "react";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import { useAlerts } from "hooks/useAlerts";
import { severityColors } from "utils/severityColors";
import { useIndicatorsHistory } from "hooks/useIndicatorsHistory";

const useMapMakers = ({
  mapRef,
  markRedirectUrl,
  features,
}: {
  mapRef: MutableRefObject<mapboxgl.Map | null>;
  markRedirectUrl?: string;
  features: FeaturePoint[];
}) => {
  const { uuidWorkspace } = useParams<{ uuidWorkspace: string }>();
  const { setAlertSelectedHome } = useAlerts();
  const { setIndicatorsHistory } = useIndicatorsHistory();

  function isValidCoordinates(lng: number, lat: number): boolean {
    return lng >= -180 && lng <= 180 && lat >= -90 && lat <= 90;
  }

  useEffect(() => {
    const markers = document.querySelectorAll(".marker");
    markers.forEach((marker) => marker.remove());

    for (const marker of features) {
      const el = document.createElement("div");
      const width = 32;
      const height = 32;
      el.className = "marker";
      el.style.cursor = "pointer";
      el.style.borderRadius = "50%";
      el.style.width = `${width}px`;
      el.style.height = `${height}px`;

      if (marker.properties.type === 55) {
        el.style.backgroundSize = "100%";
        el.style.backgroundImage = `url(/map-alerts/alert-${marker.properties.type}.svg)`;
        el.style.backgroundColor = `${
          severityColors.find((color) => color.value === marker.properties.type)
            ?.label
        }`;
        
        const dom = document.createElement("div");
        dom.innerHTML += `
          <p class="text-base font-semibold mb-3 text-azulfy-rich-black">${
            marker.properties.name
          }</p>  
          <div><a class="px-4 py-3 hover:font-bold font-bold hover:no-underline !no-underline block w-full shadow-md rounded-[10px] !text-white text-center font-comfortaa text-base bg-Azulfy-Linear-Gradient hover:bg-Azulfy-Linear-Gradient-hover transition-all active:scale-[0.98] active:bg-Azulfy-Linear-Gradient-pressed focus-visible:outline-none" href="${`/workspace/${uuidWorkspace}/live-cams/${marker.properties.uuid}`}">
          ${t("open_live_cam")}</a></div>
        `;
        const popup = new mapboxgl.Popup({ offset: 25 })
          .setDOMContent(dom)
          .addTo(mapRef.current as never);

        if(
          !isValidCoordinates(
            marker.geometry.coordinates[0],
            marker.geometry.coordinates[1]
          )
        ) {
          return
        }

        // Add markers to the map.
        new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates as never)
          .setPopup(popup)
          .addTo(mapRef.current as never);
      } else {
        el.style.backgroundImage = `url(/map-alerts/alert-${marker.properties.type}.png)`;
        el.style.backgroundSize = "75%";
        el.style.backgroundRepeat = "no-repeat";
        el.style.backgroundPosition = "center";
        el.style.backgroundColor = `${
          severityColors.find(
            (color) => color.value === marker.properties.severity
          )?.label
        }`;

        if (
          !isValidCoordinates(
            marker.geometry.coordinates[0],
            marker.geometry.coordinates[1]
          )
        ) {
          return
        }

        new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates as never)
          .addTo(mapRef.current as never)
          .getElement()
          .addEventListener("click", () => {
            const uuid = marker.properties.uuid;
            const element = document.querySelector(`[data-uuid="${uuid}"]`);

            if (element instanceof HTMLDivElement) {
              element.click();
              element.scrollIntoView({ behavior: "smooth" });
              setAlertSelectedHome(uuid);
              setIndicatorsHistory(null);
            }
          });
      }
    }
  }, [features, mapRef, markRedirectUrl]);
};

export default useMapMakers;
