import { BsCheckLg, BsFillPencilFill, BsTrashFill, BsX } from "react-icons/bs";
import { useEffect, useState } from "react";
import { t } from "i18next";
import { MAPBOX_CONSTANTS } from "..";

interface IGeoInputProps {
  coordinate: number[];
  index: number;
  onConfirmUpdate: (index: number, coordinate: number[]) => void;
  onConfirmDelete: (index: number) => void;
  indexNumber?: number;
  showIndex?: boolean;
}

const GeoInputRow = ({
  index,
  indexNumber,
  coordinate,
  onConfirmUpdate,
  onConfirmDelete,
  showIndex = true,
}: IGeoInputProps) => {
  const [editOption, setEditOption] = useState<undefined | "edit" | "delete">(
    undefined
  );
  const [inputValues, setInputValues] = useState({
    lat: coordinate[MAPBOX_CONSTANTS.LATITUDE_INDEX],
    lng: coordinate[MAPBOX_CONSTANTS.LONGITUDE_INDEX],
  });

  const handleCancelEdition = () => {
    setInputValues({
      lat: coordinate[MAPBOX_CONSTANTS.LATITUDE_INDEX],
      lng: coordinate[MAPBOX_CONSTANTS.LONGITUDE_INDEX],
    });
    setEditOption(undefined);
  };

  useEffect(() => {
    setInputValues({
      lat: coordinate[MAPBOX_CONSTANTS.LATITUDE_INDEX],
      lng: coordinate[MAPBOX_CONSTANTS.LONGITUDE_INDEX],
    });

  }, [coordinate]);
  return (
    <div className="flex items-center gap-[22px]">
      <div className="flex items-center flex-1 gap-3">
        {showIndex && (
          <>
            {indexNumber && (
              <span className="text-base font-bold text-azulfy-blue">
                {indexNumber}
              </span>
            )}
          </>
        )}
        <input
          value={inputValues.lat}
          onChange={(e) => {
            setInputValues({
              ...inputValues,
              lat: Number(e.target.value),
            });
          }}
          disabled={!(editOption && editOption === "edit")}
          type="number"
          placeholder="Ex.: Camera Oeiras"
          className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none"
        />
      </div>

      <div className="flex-1">
        <input
          value={inputValues.lng}
          disabled={!(editOption && editOption === "edit")}
          type="number"
          onChange={(e) => {
            setInputValues({
              ...inputValues,
              lng: Number(e.target.value),
            });
          }}
          placeholder="Ex.: Camera Oeiras"
          className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none"
        />
      </div>
      {editOption ? (
        <div className="flex items-center gap-5">
          <button
            onClick={handleCancelEdition}
            title={
              editOption === "edit" ? t("Cancel edit") : t("Cancel delete")
            }
            className="w-6 h-6 grid place-items-center shadow-lg rounded ring-[0.5px] ring-grey-400"
          >
            <BsX className="w-5 h-5 text-error" />
          </button>
          <button
            title={
              editOption === "edit" ? t("Confirm edit") : t("Confirm delete")
            }
            className="w-6 h-6 grid place-items-center shadow-lg rounded ring-[0.5px] ring-grey-400"
            onClick={() => {
              if (editOption === "edit") {
                // For one reason mapbox work with reversed lat/long values, so we need to inverse like bellow
                onConfirmUpdate(index, [inputValues.lng, inputValues.lat]);
              }
              if (editOption === "delete") {
                onConfirmDelete(index);
              }
              setEditOption(undefined);
            }}
          >
            <BsCheckLg className="w-4 h-4 text-success" />
          </button>
        </div>
      ) : (
        <div className="flex items-center gap-5">
          <button title={t("Edit")} onClick={() => setEditOption("edit")}>
            <BsFillPencilFill className="text-[#C0C4C8] md:w-6 w-4 md:h-6 h-4" />
          </button>
          <button title={t("Delete")} onClick={() => setEditOption("delete")}>
            <BsTrashFill className="text-[#C0C4C8] md:w-6 w-4 md:h-6 h-4" />
          </button>
        </div>
      )}
    </div>
  );
};
export default GeoInputRow;
