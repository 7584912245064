import { BsCameraVideo, BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export default function Live() {
    const navigate = useNavigate();
    return (
        <main>
            <header
                className="flex items-center w-full gap-4 py-1 lg:mb-5"
            >
                <button onClick={() => navigate(-1)} className={`hidden md:block`}>
                    <BsChevronLeft size={20} className="text-azulfy-blue" />
                </button>
                <BsCameraVideo size={24} className="text-azulfy-blue" />
                <p className="text-azulfy-blue text-xl font-bold font-comfortaa">
                    Praia da Fonte da Telha
                </p>
            </header>
            <div className="mt-3 w-full max-w-3xl mx-auto">
                <iframe
                    // width="560"
                    // height="315"
                    src="https://www.youtube.com/embed/MSPqMb6wDTk?si=BVINPxUAmLqikHug"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    className="w-full md:h-96 h-72"
                >
                </iframe>
            </div>
        </main>
    )
}
