import { Outlet } from "react-router-dom";

import { useHeader } from "hooks/useHeader";

import Header from "components/Header";
import Sidebar from "components/Sidebar";
import { useEffect } from "react";

export default function DefaultLayout() {
  const { showHeader, windowSize, setShowHeader } = useHeader();

  const size = windowSize();

  useEffect(() => {
    if (size.width && size.width >= 768) {
      setShowHeader(true);
    }
  }, [size]);

  return (
    <div className="flex flex-col items-center h-full w-screen overflow-x-hidden">
      <div className="w-[90%] max-lg:w-[95%] h-full">
        {showHeader && <Header />}
        <div
          className={`flex w-full h-full relative ${
            showHeader ? "mt-[112px]" : ""
          } `}
        >
          <Sidebar />
          <div
            className={`md:ml-[180px] w-full md:w-[calc(100%_-_180px)] ${
              showHeader ? "h-[calc(100vh-112px)]" : "h-[100vh]"
            }  font-comfortaa`}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
