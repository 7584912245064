import { create } from "zustand";
import { api } from "services/axios";
import { showErrorMessage } from "../utils/auxFunctions";

export interface IndicatorsProps {
  image: string;
  id: number;
  name: string;
  description: string;
  uuid: string;
  filter_id: number;
}

export interface FilterProps {
  uuid: string;
  id: number;
  name: string;
  indicators: IndicatorsProps[];
}

interface useLivesProps {
  filters: FilterProps[];
  getAllFilters: () => Promise<FilterProps[]>;
  mapStyle: 'light' | 'dark';
  setMapStyle: (mapStyle: 'light' | 'dark') => void;
}

export const useFilters = create<useLivesProps>((set) => ({
  filters: [],
  getAllFilters: async () => {
    try {
      const { data } = await api.get(`/filters`);
      set({ filters: data.content.data });
      return data.content.data.data;
    } catch (error: any) {
      showErrorMessage(error);
    }
  },
  mapStyle: 'light',
  setMapStyle: (mapStyle: 'light' | 'dark') => set({ mapStyle: mapStyle }),
}));
