import { useTranslation } from "react-i18next";
import { BsBellFill, BsCameraVideo, BsChevronLeft, BsChevronRight, BsCode, BsFillGeoAltFill, BsFillTagsFill, BsPeopleFill } from "react-icons/bs";
import { LuAlertCircle, LuCheckCircle2, LuXCircle } from "react-icons/lu";
import { Link, useNavigate, useParams } from "react-router-dom";
import ListMembers from "../../../components/ListMembers";

import Loading from "components/Loading";
import { useWorkspaces } from "hooks/useWorkspaces";
import { useEffect, useState } from "react";
import './style.css';

import { LinearButton } from "components/LinearButton";
import { MapboxGeoJSONPolygon } from "components/MapComponent";
import { useLives } from "hooks/useLives";
import { useUser } from "hooks/useUser";
import Permission from "utils/permission";
import { PointsOfAnalysisMap } from "./PointsOfAnalysisMap";

export default function index() {
    const { t } = useTranslation();
    const { authUser } = useUser();
    const navigate = useNavigate();
    const { getWorkspacesByUuid, workspace } = useWorkspaces();
    const { liveCams, getAllLivesCams } = useLives();
    const [isLoading, setIsLoading] = useState(true);
    const { uuid } = useParams<{ uuid: string }>();

    const statusOptions = {
        pending: {
            label: t('pending_approval'),
            icon: <LuAlertCircle className="min-w-[18px]" color="#F3C947" />,
        },
        approved: {
            label: t('aproved'),
            icon: <LuCheckCircle2 className="min-w-[18px]" color="#27AE60" />,
        },
        rejected: {
            label: t('denied'),
            icon: <LuXCircle className="min-w-[18px]" color="#CD0C0C" /> ,
        }
    }

    const [pointsOfAnalysisPolygons, setPointsOfAnalysisPolygons] = useState<
        MapboxGeoJSONPolygon[] | undefined
    >();
    const [geoGraphicalArea, setGeoGraphicalArea] = useState<
        MapboxGeoJSONPolygon | undefined
    >();

    const hasPermissionEditLiveCam = Permission({
        permissions: ['edit workspace video'],
        children: <></>,
    })

    const handleGetWorkspacesByUuid = async () => {
        await getWorkspacesByUuid(uuid!);
        setIsLoading(false);
    }

    useEffect(() => {
        handleGetWorkspacesByUuid();
    }, [])

    useEffect(() => {
        setGeoGraphicalArea(undefined);
        setPointsOfAnalysisPolygons(undefined);
    }, [workspace])

    useEffect(() => {
        setGeoGraphicalArea(workspace?.status === "pending" ? workspace?.region_map_area_pending : workspace?.region_map_area || workspace?.status !== "pending" ? workspace?.region_map_area : workspace?.region_map_area_pending);
    }, [workspace?.region_map_area, workspace?.region_map_area_pending, workspace?.status]);

    useEffect(() => {
        if (workspace && workspace.area_points?.length > 0) {
            const extractedPolygons = workspace.area_points
                .filter(item => workspace.status === "pending" && item?.region_map_area_pending !== null || workspace.status !== "pending" && item?.region_map_area !== null)
                .map(workspace.status === "pending" ? item => item.region_map_area_pending : item => item.region_map_area);
            setPointsOfAnalysisPolygons(extractedPolygons);
        }
    }, [workspace]);

    async function handleGetAllLivesCams(workspaceUuid: string) {
        setIsLoading(true);
        await getAllLivesCams(workspaceUuid);
        setIsLoading(false);
    }

    useEffect(() => {
        const hasPermission = Permission({
            permissions: ['view workspace video'],
            children: <></>,
        });
        if (hasPermission) {
            handleGetAllLivesCams(uuid!);
        }
    }, [uuid])

    const status = statusOptions[workspace.status as keyof typeof statusOptions]

    return (
        <>
            <main>
                <header
                    className="flex items-center w-full gap-2 py-1 lg:mb-5"
                >
                    {authUser?.roles === "super_admin" && (
                        <button onClick={() => navigate(`/home`)} className={`hidden md:block`}>
                            <BsChevronLeft size={20} className="text-azulfy-blue" />
                        </button>
                    )}
                    <p className="text-azulfy-blue text-xl font-bold font-comfortaa">
                        {t('council_info')}
                    </p>
                </header>
                {isLoading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-28 w-full max-w-5xl mx-auto pb-12">
                            <div className="flex flex-col gap-12">
                                <div className="flex flex-col">
                                    <div className="flex items-center justify-between">
                                        <p className="font-comfortaa text-base font-bold text-azulfy-blue">
                                            {t('client_info')}
                                        </p>
                                        <Link to={`/workspace/${uuid}/edit-workspace`} className="font-raleway text-sm font-normal text-azulfy-blue underline hover:opacity-80">
                                            {t('edit')}
                                        </Link>
                                    </div>
                                    <div className="py-3 flex items-center justify-between border-b border-backgray">
                                        <div className="flex gap-2">
                                            <BsPeopleFill size={20} className="text-azulfy-rich-black" />
                                            <p className="font-raleway text-sm font-normal text-azulfy-rich-black">
                                                {t('council')}
                                            </p>
                                        </div>
                                        <p className="font-raleway text-sm font-normal text-gray-500">
                                            {workspace?.name}
                                        </p>
                                    </div>
                                    <div className="py-3 flex items-center justify-between border-b border-backgray">
                                        <div className="flex gap-2">
                                            <BsFillTagsFill size={20} className="text-azulfy-rich-black" />
                                            <p className="font-raleway text-sm font-normal text-azulfy-rich-black">
                                                {t('license_type')}
                                            </p>
                                        </div>
                                        <p className="font-raleway text-sm font-normal text-gray-500">
                                            {t(workspace?.license?.name)}
                                        </p>
                                    </div>
                                    <div className="py-3 flex items-center justify-between border-b border-backgray">
                                        <div className="flex gap-2">
                                            <BsCode size={20} className="text-azulfy-rich-black" />
                                            <p className="font-raleway text-sm font-normal text-azulfy-rich-black">
                                                {t('internal_azulfy_code')}
                                            </p>
                                        </div>
                                        <p className="font-raleway text-sm font-normal text-gray-500">
                                            {t(workspace?.code_azulfy)}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-12 lg:hidden">
                                    <Permission permissions={['view workspace video']}>
                                        <div className="flex flex-col">
                                            <div className="flex items-center justify-between">
                                                <p className="font-comfortaa text-base font-bold text-azulfy-blue">
                                                    {t('live_cams')}
                                                </p>
                                            </div>
                                            {liveCams?.slice(0, 4).map((liveCam) => (
                                                <Link key={liveCam.uuid} to={hasPermissionEditLiveCam ? `/workspace/${uuid}/live-cams/${liveCam.uuid}/edit-live-cam` : ``} className="hover:no-underline">
                                                    <div className={`flex items-center justify-between py-3 border-b border-backgray ${!hasPermissionEditLiveCam ? 'cursor-not-allowed' : 'hover:cursor-pointer hover:opacity-80'}`}>
                                                        <div className="flex items-center gap-3">
                                                            <div className="bg-azulfy-blue rounded-full p-1">
                                                                <BsCameraVideo size={20} className="text-white" />
                                                            </div>
                                                            <p className="font-raleway capitalize text-base font-normal text-azulfy-rich-black">
                                                                {liveCam.name}
                                                            </p>
                                                        </div>
                                                        <BsChevronRight size={12} className="text-azulfy-rich-black" />
                                                    </div>
                                                </Link>
                                            ))}
                                            <div className="w-[90%] mx-auto mt-8">
                                                <LinearButton text={t('see_all_live_cams')} textSize="16" onClick={() => navigate(`/workspace/${uuid}/live-cams`)} />
                                            </div>
                                        </div>
                                    </Permission>
                                    <div className="flex flex-col">
                                        <div className="flex items-center justify-between">
                                            <p className="font-comfortaa text-base font-bold text-azulfy-blue">
                                                {t('new_alert_notifications')}
                                            </p>
                                            <Link to={`/workspace/${uuid}/alert-notifications`} className="font-raleway text-sm font-normal text-azulfy-blue underline hover:opacity-80">
                                                {t('edit')}
                                            </Link>
                                        </div>
                                        <div className="py-3 flex items-center justify-between border-b border-backgray">
                                            <div className="flex gap-2">
                                                <BsBellFill size={20} className="text-azulfy-rich-black" />
                                                <p className="font-raleway text-sm font-normal text-azulfy-rich-black">
                                                    {t('admins')}
                                                </p>
                                            </div>
                                            <p className="font-raleway text-sm font-normal text-gray-500">
                                                {workspace?.total_alerts_managers_ids}
                                            </p>
                                        </div>
                                    </div>

                                    <Permission permissions={['view members workspace']}>
                                        <div className="flex flex-col">
                                            <div className="flex items-center justify-between">
                                                <p className="font-comfortaa text-base font-bold text-azulfy-blue">
                                                    {t('members')}
                                                </p>

                                                <Permission permissions={['edit members workspace']}>
                                                    <Link to={`/workspace/${uuid}/members`} className="font-raleway text-sm font-normal text-azulfy-blue underline hover:opacity-80">
                                                        {t('edit')}
                                                    </Link>
                                                </Permission>
                                            </div>

                                            <div className="py-3 flex items-center justify-between border-b border-backgray">
                                                <div className="flex gap-2">
                                                    <BsPeopleFill size={20} className="text-azulfy-rich-black" />
                                                    <p className="font-raleway text-sm font-normal text-azulfy-rich-black">
                                                        {t('members')}
                                                    </p>
                                                </div>
                                                
                                                <p className="font-raleway text-sm font-normal text-gray-500">
                                                    {workspace?.total_users}/{workspace?.license?.members}
                                                </p>
                                            </div>
                                        </div>
                                    </Permission>
                                </div>
                                <div className="flex flex-col">
                                    <div className="flex items-center justify-between ">
                                        <p className="font-comfortaa text-base font-bold text-azulfy-blue">
                                            {t('geographycal_area')}
                                        </p>

                                        <div className="flex gap-[10px]">
                                            <Link to={authUser?.roles === "super_admin" ? `/workspace/${uuid}/manage-status` : ``} className={`hover:no-underline hover:text-azulfy-rich-black flex gap-[2px] px-3 text-xs h-[26px] items-center ${authUser?.roles === "super_admin" ? 'hover:opacity-80' : 'cursor-default'}`}>
                                                {status?.icon}
                                                
                                                <span>{status?.label}</span>
                                            </Link>

                                            <Link to={`/workspace/${uuid}/edit-geografical-area`} className="font-raleway text-sm font-normal text-azulfy-blue underline hover:opacity-80">
                                                {t('edit')}
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="py-3 flex items-center justify-between border-b border-backgray">
                                        <div className="flex gap-2">
                                            <BsFillGeoAltFill size={20} className="text-azulfy-rich-black" />

                                            <p className="font-raleway text-sm font-normal text-azulfy-rich-black">
                                                {t('locations')}
                                            </p>
                                        </div>

                                        <p className="font-raleway text-sm font-normal text-gray-500">
                                            {workspace.total_area_points}
                                        </p>
                                    </div>

                                    <div className="w-[90%] mx-auto mt-8">
                                        <LinearButton text={`+ ${t("add_new_location")}`} textSize="16" onClick={() => navigate(`/workspace/${uuid}/edit-geografical-area`)} />
                                    </div>
                                </div>

                                <div className="px-5 md:px-0">
                                    <PointsOfAnalysisMap
                                        geoArea={geoGraphicalArea}
                                        pointsOfAnalysisPolygons={pointsOfAnalysisPolygons}
                                        setPointsOfAnalysisPolygons={setPointsOfAnalysisPolygons}
                                    />
                                </div>

                            </div>
                            <div className="hidden lg:flex flex-col gap-12">
                                <Permission permissions={['view workspace video']}>
                                    <div className="flex flex-col">
                                        <div className="flex items-center justify-between">
                                            <p className="font-comfortaa text-base font-bold text-azulfy-blue">
                                                {t('live_cams')}
                                            </p>
                                        </div>
                                        {liveCams?.slice(0, 4).map((liveCam) => (
                                            <Link key={liveCam.uuid} to={hasPermissionEditLiveCam ? `/workspace/${uuid}/live-cams/${liveCam.uuid}/edit-live-cam` : ``} className="hover:no-underline">
                                                <div className={`flex items-center justify-between py-3 border-b border-backgray ${!hasPermissionEditLiveCam ? 'cursor-not-allowed' : 'hover:cursor-pointer hover:opacity-80'}`}>
                                                    <div className="flex items-center gap-3">
                                                        <div className="bg-azulfy-blue rounded-full p-1">
                                                            <BsCameraVideo size={20} className="text-white" />
                                                        </div>
                                                        <p className="font-raleway capitalize text-base font-normal text-azulfy-rich-black">
                                                            {liveCam.name}
                                                        </p>
                                                    </div>
                                                    <BsChevronRight size={12} className="text-azulfy-rich-black" />
                                                </div>
                                            </Link>
                                        ))}
                                        <div className="w-[90%] mx-auto mt-8">
                                            <LinearButton text={t('see_all_live_cams')} textSize="16" onClick={() => navigate(`/workspace/${uuid}/live-cams`)} />
                                        </div>
                                    </div>
                                </Permission>
                                <div className="flex flex-col">
                                    <div className="flex items-center justify-between">
                                        <p className="font-comfortaa text-base font-bold text-azulfy-blue">
                                            {t('new_alert_notifications')}
                                        </p>
                                        <Link to={`/workspace/${uuid}/alert-notifications`} className="font-raleway text-sm font-normal text-azulfy-blue underline hover:opacity-80">
                                            {t('edit')}
                                        </Link>
                                    </div>
                                    <div className="py-3 flex items-center justify-between border-b border-backgray">
                                        <div className="flex gap-2">
                                            <BsBellFill size={20} className="text-azulfy-rich-black" />
                                            <p className="font-raleway text-sm font-normal text-azulfy-rich-black">
                                                {t('admins')}
                                            </p>
                                        </div>
                                        <p className="font-raleway text-sm font-normal text-gray-500">
                                            {workspace?.total_alerts_managers_ids}
                                        </p>
                                    </div>
                                </div>
                                <Permission permissions={['view members workspace']}>
                                    <>
                                        <div className="flex flex-col">
                                            <div className="flex items-center justify-between">
                                                <p className="font-comfortaa text-base font-bold text-azulfy-blue">
                                                    {t('members')}
                                                </p>
                                                <Permission permissions={['edit members workspace']}>
                                                    <Link to={`/workspace/${uuid}/members`} className="font-raleway text-sm font-normal text-azulfy-blue underline hover:opacity-80">
                                                        {t('edit')}
                                                    </Link>
                                                </Permission>
                                            </div>
                                            <div className="py-3 flex items-center justify-between border-b border-backgray">
                                                <div className="flex gap-2">
                                                    <BsPeopleFill size={20} className="text-azulfy-rich-black" />
                                                    <p className="font-raleway text-sm font-normal text-azulfy-rich-black">
                                                        {t('members')}
                                                    </p>
                                                </div>
                                                <p className="font-raleway text-sm font-normal text-gray-500">
                                                    {workspace?.total_users}/{workspace?.license?.members}
                                                </p>
                                            </div>
                                        </div>

                                        <ListMembers showButtonAddMembers={false} alignLabel={false} />
                                    </>
                                </Permission>
                            </div>
                        </div>
                    </>
                )}

            </main>
        </>
    )
}
