import Map from "components/MapComponent";
import { MutableRefObject } from "react";
import { Trans } from "react-i18next";
import { BsBoundingBoxCircles, BsZoomIn, BsZoomOut } from "react-icons/bs";
import { Tooltip, Whisper } from "rsuite";

const MapBasicButtons = ({
  mapRef,
  canDraw,
  drawRef,
  drawType = "polygon",
}: {
  mapRef: MutableRefObject<mapboxgl.Map | null>;
  canDraw: boolean;
  drawRef: MutableRefObject<MapboxDraw | null>;
  drawType?: 'polygon' | 'point';
}) => {
  return (
    <Map.Item className="right-4 bottom-4">
      <ul className="flex flex-col p-0 border border-grey-400 bg-grey-600 rounded-lg !m-0 list-none">
        {canDraw && (
          <>
            {drawType === 'polygon' && (
              <li>
                <Whisper
                  trigger="hover"
                  placement='topEnd'
                  speaker={
                    <Tooltip arrow={false} className="bg-azulfy-rich-black font-normal text-xs text-center text-white font-raleway min-w-[340px] -ml-[10px] px-3 py-2 rounded-lg">
                      <Trans
                        i18nKey="draw_the_map_tootip"
                        components={{ icon: <BsBoundingBoxCircles className="w-4 h-4 mb-[2px] mx-[2px] inline-flex text-white" /> }}
                      />
                    </Tooltip>
                  }
                >
                  <button
                    title={"Draw"}
                    className="p-2.5"
                    onClick={() => {
                      if (!canDraw) return;
                      
                      drawRef.current?.changeMode("draw_polygon");
                    }}
                  >
                    <BsBoundingBoxCircles className="w-4 h-4 text-azulfy-rich-black" />
                  </button>
                </Whisper>
              </li>
            )}

            <hr className="h-[1px] inline-flex bg-gray-400 w-4 mx-auto" />

            {drawType === 'point' && (
              <li>
                <Whisper
                  trigger="hover"
                  placement='topEnd'
                  speaker={
                    <Tooltip arrow={false} className="bg-azulfy-rich-black font-normal text-xs text-center text-white font-raleway min-w-[320px] -ml-[10px] px-3 py-2 rounded-lg">
                      <Trans
                        i18nKey="draw_the_map_tootip"
                        components={{ icon: <BsBoundingBoxCircles className="w-4 h-4 mb-[2px] mx-[2px] inline-flex text-white" /> }}
                      />
                    </Tooltip>
                  }
                >
                  <button
                    title={"Draw Point"}
                    className="p-2.5"
                    onClick={() => {
                      drawRef.current?.changeMode("draw_point");
                    }}
                  >
                    <BsBoundingBoxCircles className="w-4 h-4 text-azulfy-rich-black" />
                  </button>
                </Whisper>
              </li>
            )}

            <hr className="h-[1px] inline-flex bg-gray-400 w-4 mx-auto" />
          </>
        )}

        <li>
          <button
            title={"Zoom in"}
            className="p-2.5"
            onClick={() => mapRef?.current?.zoomIn()}
          >
            <BsZoomIn className="w-4 h-4 text-azulfy-rich-black" />
          </button>
        </li>

        <hr className="h-[1px] inline-flex bg-gray-400 w-4 mx-auto" />

        <li>
          <button
            title={"Zoom out"}
            className="p-2.5"
            onClick={() => mapRef?.current?.zoomOut()}
          >
            <BsZoomOut className="w-4 h-4 text-azulfy-rich-black" />
          </button>
        </li>
      </ul>
    </Map.Item>
  );
};

export default MapBasicButtons;
