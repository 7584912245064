import { useEffect, useState } from "react";
import { create } from "zustand";

interface useHeaderProps {
  showHeader: boolean;
  setShowHeader: (showHeader: boolean) => void;
  windowSize: () => {
    width: number | undefined;
    height: number | undefined;
  };
}

export const useHeader = create<useHeaderProps>((set) => ({
  showHeader: true,

  setShowHeader: (showHeader: boolean) => {
    set({ showHeader });
  },

  windowSize: () => {
    const [windowSize, setWindowSize] = useState<{
      width: number | undefined;
      height: number | undefined;
    }>({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
  },
}));
