//@ts-ignore
import * as turf from "@turf/turf";

/*

* Given a query in the form "lng, lat" or "lat, lng"
* returns the matching geographic coordinate(s)
* as search results in carmen geo json format,
* https://github.com/mapbox/carmen/blob/master/carmen-geojson.md */
export const coordinatesGeocoder = function (query: string) {
  // Match anything which looks like
  // decimal degrees coordinate pair.
  const matches = query.match(
    /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i,
  )
  if (!matches) {
    return null
  }

  function coordinateFeature(lng: number, lat: number) {
    return {
      center: [lng, lat],
      geometry: {
        type: 'Point',
        coordinates: [lng, lat],
      },
      place_name: 'Lat: ' + lat + ' Lng: ' + lng,
      place_type: ['coordinate'],
      properties: {},
      type: 'Feature',
    }
  }

  const coord1 = Number(matches[1])
  const coord2 = Number(matches[2])
  const geocodes = []

  if (coord1 < -90 || coord1 > 90) {
    // must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2))
  }

  if (coord2 < -90 || coord2 > 90) {
    // must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1))
  }

  if (geocodes.length === 0) {
    // else could be either lng, lat or lat, lng
    geocodes.push(coordinateFeature(coord1, coord2))
    geocodes.push(coordinateFeature(coord2, coord1))
  }

  return geocodes
}

export function calculateDynamicZoom(bbox: turf.BBox): number {
  const [minX, minY, maxX, maxY] = bbox
  const width = maxX - minX
  const height = maxY - minY
  const tileSize = 256 // Size of a tile (256 pixels) in a standard map

  // Calculate the largest difference between the width and height of the bbox
  const maxDiff = Math.max(width, height)

  // Calculate the necessary zoom so that the largest dimension of the bbox occupies the map size (256 pixels)
  // Rounds up to ensure the entire polygon is visible
  const zoom = Math.ceil(Math.log2(tileSize / maxDiff))

  return zoom
}
