import { useTranslation } from "react-i18next";

interface AlertStatus {
  label: string;
  value: string;
}

export const getAlertStatuses = (): AlertStatus[] => {
  const { t } = useTranslation();
  return [
    { label: t("new"), value: "new" },
    { label: t("alert_analysis_by_admin"), value: "analysis" },
    { label: t("adding_each_team_member"), value: "addMember" },
    { label: t("analysis_of_satellite_parameters"), value: "analysisSatellite" },
    { label: t("in_situ_initial_verification_tests_analysis_of_results"), value: "initialAnalysisResults" },
    { label: t("define_corrective_action"), value: "defineAction" },
    { label: t("corrective_action_completed"), value: "defineCompleted" },
    { label: t("in_situ_final_verification_tests_analysis_of_results"), value: "FinalAnalysisResults" },
    { label: t("reopening_of_affected_area"), value: "affectedArea" },
    { label: t("team_leaders_final_note_report"), value: "finalReport" },
    { label: t("alert_closure"), value: "closed" }
  ];
};