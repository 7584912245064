import { BsChevronLeft } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";

interface HeaderAccountProps {
    title?: string;
    navigateTo?: string;
}

export default function HeaderAccount({ title }: HeaderAccountProps) {
    const navigate = useNavigate();
    return (
        <>
            <header
                className="flex items-center w-full justify-center md:justify-between py-1 mb-8"
            >
                <Link to={''} onClick={() => navigate(-1)} className="hidden md:block">
                    <BsChevronLeft size={20} className="text-azulfy-blue" />
                </Link>
                <p className="text-azulfy-blue text-xl font-bold font-comfortaa">
                    {title}
                </p>
                <p>
                    {''}
                </p>
            </header>
        </>
    )
}