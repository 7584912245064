import { useTranslation } from "react-i18next";
import HeaderCouncil from "../components/HeaderCouncil";
import { useEffect, useState } from "react";
import { useWorkspaces } from "hooks/useWorkspaces";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "components/Loading";
import { LinearButton } from "components/LinearButton";
// import { OptionsProps } from "components/ListMembers";
// import { SelectPicker } from "rsuite";
import { toast } from "utils/toast";
import { useLicenses } from "hooks/useLicenses";
import { SelectPicker } from "rsuite";
import { useUser } from "hooks/useUser";
import * as Dialog from "@radix-ui/react-dialog";
import { BsFillTrashFill } from "react-icons/bs";
import ConfirmDeleteCouncilModal from "components/ConfirmDeleteCouncilModal";
import SuccessDeleteCouncilModal from "components/SuccessDeleteCouncilModal";

export default function EditCouncil() {
    const { t } = useTranslation();
    const { authUser } = useUser();
    const { getWorkspacesByUuid, workspace, updateWorkspaceInfo, deleteWorkspace } = useWorkspaces();
    const { getAllLicenses, licenses } = useLicenses();
    const [isLoading, setIsLoading] = useState(true);
    const { uuid } = useParams<{ uuid: string }>()
    const [nameCouncil, setNameCouncil] = useState(workspace?.name);
    const [codeAzulfy, setCodeAzulfy] = useState(workspace?.code_azulfy);
    const [selectedLicense, setSelectedLicense] = useState('');
    const [showPopupSuccessDelete, setShowPopupSuccessDelete] = useState(false);
    const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState(false);
    const [loadingDeleteWorkspace, setLoadingDeleteWorkspace] = useState(false);

    const navigate = useNavigate();

    const licensesOptions = licenses.map((license) => {
        return {
            value: license?.uuid,
            label: license?.name
        }
    })

    async function getDetailsWorkspace() {
        await getWorkspacesByUuid(uuid!);
        setIsLoading(false);
    }

    async function getLicenses() {
        await getAllLicenses();
        setIsLoading(false);
    }

    useEffect(() => {
        getDetailsWorkspace()
    }, [uuid])

    useEffect(() => {
        getLicenses();
    }, []);

    async function handleUpdateWorkspace() {
        const response = await updateWorkspaceInfo(uuid!, { name: nameCouncil, license_id: selectedLicense, code_azulfy: codeAzulfy });
        if (response) {
            toast({
                label: t('success'),
                message: t('council_updated_successfully'),
                type: "success",
            });
            setNameCouncil('')
            setSelectedLicense('')
            setCodeAzulfy('')
        }
    }

    async function handleDeleteWorkspace() {
        setLoadingDeleteWorkspace(true)
        try {
            const response = await deleteWorkspace(uuid!);
            if (response) {
                setShowPopupConfirmDelete(false)
                setShowPopupSuccessDelete(true)
            }
        } catch (error) {
            toast({
                label: t('error'),
                message: t('an_error_occurred_while_deleting_the_council'),
                type: "error",
            });
        } finally {
            setLoadingDeleteWorkspace(false)
        }
    }

    return (
        <main>
            <HeaderCouncil title={t('edit_council')} />
            {isLoading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <div className="w-full max-w-xl mx-auto">
                        <div className="w-full flex flex-col gap-8">
                            <p
                                className="text-azulfy-blue font-comfortaa text-2xl font-bold text-center"
                            >
                                {t('council')}
                            </p>
                            <div>
                                <label htmlFor="" className="font-normal text-xs text-gray-500">
                                    {t("name")}
                                </label>
                                <input
                                    type="text"
                                    defaultValue={workspace?.name}
                                    onChange={(e) => setNameCouncil(e.target.value)}
                                    placeholder="Ex.: Camera Oeiras"
                                    className="w-full rounded-lg border border-gray-200 focus:outline-none py-3 px-4 text-azulfy-rich-black"
                                />
                            </div>
                            <div className="flex flex-col gap-1">
                                <label htmlFor="" className="font-normal text-xs text-gray-500">
                                    {t("license")}
                                </label>
                                <SelectPicker
                                    data={licensesOptions}
                                    searchable={false}
                                    style={{ width: '100%', fontSize: '14px', color: '#061425' }}
                                    cleanable={false}
                                    size="lg"
                                    disabled={authUser?.roles === "super_admin" ? false : true}
                                    // menuStyle={{ color: '#061425' }}
                                    className="text-azulfy-rich-black"
                                    defaultValue={workspace?.license?.uuid}
                                    onChange={(value) => setSelectedLicense(value as string)}
                                />
                                {/* <input
                                    type="text"
                                    defaultValue={workspace.license.name}
                                    disabled
                                    className="w-full rounded-lg border border-gray-200 focus:outline-none py-3 px-4 text-azulfy-rich-black"
                                /> */}
                            </div>
                            <div className="flex flex-col gap-1">
                                <label htmlFor="" className="font-normal text-xs text-gray-500">
                                    {t("internal_azulfy_code")}
                                </label>
                                <input
                                    type="text"
                                    defaultValue={workspace?.code_azulfy}
                                    onChange={(e) => setCodeAzulfy(e.target.value)}
                                    disabled={authUser?.roles === "super_admin" ? false : true}
                                    placeholder="123"
                                    className="w-full rounded-lg border border-gray-200 focus:outline-none py-3 px-4 text-azulfy-rich-black"
                                />
                            </div>
                            <div className="w-full flex justify-center">
                                <div
                                    className="flex flex-col justify-center items-center gap-5 max-w-sm w-full"
                                >
                                    <LinearButton
                                        text={t("save")}
                                        onClick={handleUpdateWorkspace}
                                    //   disabled={isSubmitting}
                                    />
                                    {authUser?.roles === "super_admin" && (
                                        <button
                                            onClick={() => setShowPopupConfirmDelete(true)}
                                            className="px-4 py-3 w-full shadow-md rounded-[10px] text-white text-center font-comfortaa text-xl  max-md:!text-base font-bold bg-error hover:opacity-80 transition-all active:scale-[0.98] focus-visible:outline-none flex items-center justify-center gap-3"
                                        >
                                            <BsFillTrashFill />
                                            {t("delete_this_council")}
                                        </button>
                                    )}
                                    <Dialog.Root
                                        open={showPopupConfirmDelete}
                                    >

                                        <ConfirmDeleteCouncilModal
                                            councilName={workspace?.name}
                                            loading={loadingDeleteWorkspace}
                                            handleDelete={(() => {
                                                handleDeleteWorkspace()
                                            })}
                                            onCloseConfirmModal={() => setShowPopupConfirmDelete(false)}
                                        />
                                    </Dialog.Root>
                                    <Dialog.Root
                                        open={showPopupSuccessDelete}
                                    >
                                        <SuccessDeleteCouncilModal
                                            councilName={workspace?.name}
                                            handleSuccess={(() => {
                                                navigate('/home')
                                                setShowPopupSuccessDelete(false)
                                            })}
                                        />
                                    </Dialog.Root>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

        </main>
    )
}
