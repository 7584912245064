import { useTranslation } from "react-i18next";

export default function NoSupports() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center w-full h-full bg-grey-600">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="222"
        height="257"
        viewBox="0 0 222 257"
        fill="none"
      >
        <path
          d="M111 242.193C172.304 242.193 222 192.634 222 131.5C222 70.366 172.304 20.8071 111 20.8071C49.6964 20.8071 0 70.366 0 131.5C0 192.634 49.6964 242.193 111 242.193Z"
          fill="#282A74"
          fillOpacity="0.1"
        />
        <g filter="url(#filter0_d_1359_9224)">
          <path
            d="M174.64 84.2712H47.36C43.2731 84.2712 39.96 87.5752 39.96 91.6508V246.621C39.96 250.696 43.2731 254 47.36 254H174.64C178.727 254 182.04 250.696 182.04 246.621V91.6508C182.04 87.5752 178.727 84.2712 174.64 84.2712Z"
            fill="white"
          />
        </g>
        <path
          d="M96.1993 106.41H57.7193C55.2672 106.41 53.2793 108.392 53.2793 110.838C53.2793 113.283 55.2672 115.265 57.7193 115.265H96.1993C98.6514 115.265 100.639 113.283 100.639 110.838C100.639 108.392 98.6514 106.41 96.1993 106.41Z"
          fill="#B4DAFF"
        />
        <path
          d="M122.839 125.597H57.7193C55.2672 125.597 53.2793 127.579 53.2793 130.024C53.2793 132.47 55.2672 134.452 57.7193 134.452H122.839C125.291 134.452 127.279 132.47 127.279 130.024C127.279 127.579 125.291 125.597 122.839 125.597Z"
          fill="#DFEAFB"
        />
        <path
          d="M96.1993 146.259H57.7193C55.2672 146.259 53.2793 148.241 53.2793 150.687C53.2793 153.132 55.2672 155.114 57.7193 155.114H96.1993C98.6514 155.114 100.639 153.132 100.639 150.687C100.639 148.241 98.6514 146.259 96.1993 146.259Z"
          fill="#B4DAFF"
        />
        <path
          d="M122.839 165.446H57.7193C55.2672 165.446 53.2793 167.428 53.2793 169.874C53.2793 172.319 55.2672 174.301 57.7193 174.301H122.839C125.291 174.301 127.279 172.319 127.279 169.874C127.279 167.428 125.291 165.446 122.839 165.446Z"
          fill="#DFEAFB"
        />
        <path
          d="M96.1993 186.108H57.7193C55.2672 186.108 53.2793 188.091 53.2793 190.536C53.2793 192.981 55.2672 194.964 57.7193 194.964H96.1993C98.6514 194.964 100.639 192.981 100.639 190.536C100.639 188.091 98.6514 186.108 96.1993 186.108Z"
          fill="#B4DAFF"
        />
        <path
          d="M122.839 205.295H57.7193C55.2672 205.295 53.2793 207.278 53.2793 209.723C53.2793 212.168 55.2672 214.151 57.7193 214.151H122.839C125.291 214.151 127.279 212.168 127.279 209.723C127.279 207.278 125.291 205.295 122.839 205.295Z"
          fill="#DFEAFB"
        />
        <g filter="url(#filter1_d_1359_9224)">
          <path
            d="M174.64 9H47.36C43.2731 9 39.96 12.3039 39.96 16.3795V60.6567C39.96 64.7323 43.2731 68.0362 47.36 68.0362H174.64C178.727 68.0362 182.04 64.7323 182.04 60.6567V16.3795C182.04 12.3039 178.727 9 174.64 9Z"
            fill="#0072FF"
          />
        </g>
        <path
          d="M96.1993 25.2351H57.7193C55.2672 25.2351 53.2793 27.2175 53.2793 29.6628C53.2793 32.1082 55.2672 34.0905 57.7193 34.0905H96.1993C98.6514 34.0905 100.639 32.1082 100.639 29.6628C100.639 27.2175 98.6514 25.2351 96.1993 25.2351Z"
          fill="#B4DAFF"
        />
        <path
          d="M122.839 44.4214H57.7193C55.2672 44.4214 53.2793 46.4037 53.2793 48.8491C53.2793 51.2945 55.2672 53.2768 57.7193 53.2768H122.839C125.291 53.2768 127.279 51.2945 127.279 48.8491C127.279 46.4037 125.291 44.4214 122.839 44.4214Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d_1359_9224"
            x="33.96"
            y="75.2712"
            width="154.08"
            height="181.729"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-3" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1359_9224"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1359_9224"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_1359_9224"
            x="33.96"
            y="0"
            width="154.08"
            height="71.0361"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-3" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1359_9224"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1359_9224"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
      <h1 className="mt-8 text-azulfy-blue font-bold text-xl">
        {t("welcome_to_customer_support")}!
      </h1>
      <span className="mt-3 text-azulfy-rich-black text-sm font-raleway">
        {t("all_your_customer_support_chats_are_here")}.
      </span>
    </div>
  );
}
